var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": "auto",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c(
                "v-app-bar",
                { attrs: { color: "grey lighten-4", dense: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-account-clock-outline")
                      ]),
                      _vm._v(" Enviar email de disponibilidade")
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.modelTemplate.id
                                          ? "teal darken-1"
                                          : "red",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showEmailTemplate = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-card-account-mail-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.modelTemplate.id
                              ? "Template do email"
                              : "Template de email não cadastrado"
                          )
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "", disabled: _vm.saving },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1 mt-2",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.teachers,
                      "single-select": false,
                      "item-key": "name",
                      "show-select": "",
                      search: _vm.search,
                      dense: "",
                      itemsPerPage: 10,
                      "item-selected": _vm.selected,
                      loading: _vm.loading,
                      "fixed-header": "",
                      height: "360"
                    },
                    on: { "current-items": _vm.getFiltered },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-container",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "5" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "mx-4",
                                          attrs: { label: "Pesquisar..." },
                                          model: {
                                            value: _vm.search,
                                            callback: function($$v) {
                                              _vm.search = $$v
                                            },
                                            expression: "search"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c("sys-select-campus", {
                                          attrs: {
                                            model: _vm.campuses,
                                            label: "Campus",
                                            multiple: "",
                                            selectAll: ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              _vm.campuses = $event
                                            },
                                            loaded: function($event) {
                                              _vm.itemsCampuses = $event
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "pl-4",
                                          attrs: {
                                            loading:
                                              !_vm.firstCharge &&
                                              _vm.loadingPending,
                                            inset: "",
                                            label: "Somente pendentes",
                                            disabled: _vm.campuses.length == 0
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.getData()
                                            }
                                          },
                                          model: {
                                            value: _vm.onlyPending,
                                            callback: function($$v) {
                                              _vm.onlyPending = $$v
                                            },
                                            expression: "onlyPending"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "1" } },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                              loading:
                                                                _vm.loadingSearch,
                                                              icon: "",
                                                              dense: "",
                                                              disabled:
                                                                _vm.campuses
                                                                  .length == 0
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.getData(
                                                                  true
                                                                )
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { dark: "" }
                                                          },
                                                          [_vm._v("search")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Buscar professores")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.email",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b({}, "span", attrs, false),
                                              on
                                            ),
                                            [
                                              item.emails.length > 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.email) + " "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v(
                                                        "(+" +
                                                          _vm._s(
                                                            item.emails.length -
                                                              1
                                                          ) +
                                                          ")"
                                                      )
                                                    ])
                                                  ])
                                                : _c("span", [
                                                    _vm._v(_vm._s(item.email))
                                                  ])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      item.emails
                                        .map(function(o) {
                                          return o["email"]
                                        })
                                        .join("<br/>")
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.campuses",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b({}, "span", attrs, false),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.campuses
                                                    .map(function(o) {
                                                      return o["initials"]
                                                    })
                                                    .join(", ")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.campuses
                                          .map(function(o) {
                                            return o["name"]
                                          })
                                          .join(", ")
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.status",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.availabilities.length > 0
                                    ? item.availabilities[0].status.name
                                    : "Pendente"
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.modelTemplate.id
                                                      ? "blue"
                                                      : "red",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showEmail(item)
                                                    }
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "mdi-newspaper-variant-outline"
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.modelTemplate.id
                                        ? "Visualizar"
                                        : "Template de email não cadastrado"
                                    )
                                  )
                                ])
                              ]
                            ),
                            !_vm.workTime.is_available_availability
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: item.is_available_availability
                                                          ? "success"
                                                          : "error",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.showReleaseAvailability(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.is_available_availability
                                                            ? "mdi-lock-open-variant-outline"
                                                            : "mdi-lock-open-outline"
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      item.is_available_availability
                                        ? _c("span", [
                                            _vm._v(
                                              " Liberado até " +
                                                _vm._s(
                                                  _vm._f("date")(
                                                    item.release_availability_until
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(" Liberar disponibilidade ")
                                          ])
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    disabled:
                                                      _vm.saving ||
                                                      !_vm.modelTemplate.id ||
                                                      item.emails.length == 0
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showSending(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-send")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Enviar email para " + _vm._s(item.name)
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "footer.prepend",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  dense: "",
                                  color: "primary",
                                  loading: _vm.saving,
                                  disabled:
                                    _vm.saving ||
                                    !_vm.modelTemplate.id ||
                                    _vm.selected.length == 0
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.showSending()
                                  }
                                }
                              },
                              [
                                _vm._v(" Enviar para selecionados "),
                                _c(
                                  "v-icon",
                                  { attrs: { right: "", dark: "" } },
                                  [_vm._v("mdi-send")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  }),
                  _c("div", { staticClass: "caption mx-2" }, [
                    _c("strong", [_vm._v("Quantidade selecionada: ")]),
                    _vm._v(_vm._s(_vm.selected.length))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("sys-register-template-email", {
        attrs: { onModel: _vm.modelTemplate, show: _vm.showEmailTemplate },
        on: {
          "update:onModel": function($event) {
            _vm.modelTemplate = $event
          },
          "update:on-model": function($event) {
            _vm.modelTemplate = $event
          },
          "update:show": function($event) {
            _vm.showEmailTemplate = $event
          }
        }
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.loadingShowPreview } }),
      _c("emc-modal-form", {
        attrs: { show: _vm.showPreview },
        on: {
          "update:show": function($event) {
            _vm.showPreview = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.previewMail) } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          icon: "mdi-send",
          title: "Enviar email",
          text: _vm.textSending,
          disabled: _vm.saving,
          show: _vm.showSend
        },
        on: {
          "update:show": function($event) {
            _vm.showSend = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.saving,
                      disabled: _vm.saving
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.send()
                      }
                    }
                  },
                  [
                    _vm._v("Enviar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-send")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          title: "Liberação de disponibilidade",
          text: "Confirma a liberação da disponibilidade até o próximo dia?",
          disabled: _vm.saving,
          show: _vm.showRelease
        },
        on: {
          "update:show": function($event) {
            _vm.showRelease = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "success",
                      loading: _vm.saving,
                      disabled: _vm.saving
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.releaseAvailability()
                      }
                    }
                  },
                  [
                    _vm._v("Liberar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-lock-open-variant-outline")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }