export default [
    {
        path: '/login/teacher',
        name: 'login/teacher',
        component: () => import ('./pages/Index')
    },
    {
        path: '/access-teacher',
        name: 'access-teacher',
        component: () => import ('./pages/Access')
    },
]