
import { mapActions } from 'vuex'

export default {
  data: () => ({
    snackbar: false,
    loading: false,
    dialog: false,
    tab: 'data',
    type: 'error',
    message: null,
    disabled: false,
    token: null,
    errors: [],
    showCamera: false,
    show: false,
    model: {
      mode:         null,
      number_cpf:   '111.111.111-11',
      birth_date:   '2001-01-01',
      email: null,
      password: null,
    },
    selectedItem: -1,
    items: [],
  }),
  created (){
      
  },
  watch: {
      
      
  },
  methods: {
    
    ...mapActions('auth', ['ActionDoLoginTeacher']),
    
    completeCapture(file){
      let form = new FormData();
      form.append('photo', file);
      this.access(form);
    },

    access(form = null){
      
      this.disabled = true;
      this.loading = true;
      this.errors = [];

      let payload;

      if(form){
        form.append('mode', this.tab);
        payload = form;
      }else{
        this.model.mode = this.tab;
        payload = this.model;
      }

      this.ActionDoLoginTeacher(payload).then((res) => {
              
            this.type = 'success';
            this.message = res.message;
            this.$router.push({ path: '/teacher/page' });


          })
          .catch((error) =>{
              this.message = error.message;
              this.errors = error.errors ? error.errors : [];
              this.type = 'error';
          }).finally(() =>{
              this.snackbar = true;
              this.loading = false;
              this.disabled = false;
          });
    },

  },
}