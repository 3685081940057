var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.text && _vm.text != ""
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "top",
                        "colored-border": "",
                        type: "info",
                        elevation: "2"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.text) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  multiple: "",
                  items: _vm.items,
                  "return-object": "",
                  "item-text": "name_complete",
                  "sort-by": "name",
                  loading: _vm.loading,
                  chips: "",
                  label: "Selecione as disciplinas",
                  "deletable-chips": "",
                  clearable: "",
                  "auto-select-first": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(data) {
                      return [
                        _c(
                          "v-chip",
                          _vm._b(
                            {
                              attrs: {
                                "input-value": data.selected,
                                close: ""
                              },
                              on: {
                                "click:close": function($event) {
                                  return _vm.remove(data.item)
                                }
                              }
                            },
                            "v-chip",
                            data.attrs,
                            false
                          ),
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(data.item.name_complete) + " "
                              ),
                              data.item.type
                                ? _c("small", { staticClass: "ma-0 pa-0" }, [
                                    _c("br"),
                                    _vm._v(_vm._s(data.item.type.name))
                                  ])
                                : _vm._e()
                            ]),
                            data.item.syllabus
                              ? _c(
                                  "span",
                                  [
                                    _c("emc-info-help", {
                                      attrs: {
                                        title: "Ementa",
                                        text: data.item.syllabus
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(data) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(data.item.name_complete) + " "),
                          data.item.type
                            ? _c("small", { staticClass: "ma-0 pa-0" }, [
                                _c("br"),
                                _vm._v(_vm._s(data.item.type.name))
                              ])
                            : _vm._e()
                        ]),
                        data.item.syllabus
                          ? _c(
                              "span",
                              [
                                _c("emc-info-help", {
                                  attrs: {
                                    title: "Ementa",
                                    text: data.item.syllabus
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.disciplines,
                  callback: function($$v) {
                    _vm.disciplines = $$v
                  },
                  expression: "disciplines"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  disabled: _vm.items.length == 0,
                                  loading: _vm.loadingSave,
                                  color: "primary",
                                  dark: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.save()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("save")
                              ]),
                              _vm._v(" Salvar ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Salvar")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }