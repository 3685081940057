<template>
    <sys-register-base
        :show.sync="dialog"
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        :maxWidth="maxWidth"
    >
        <template v-slot:form>

            <sys-teacher-availability-week
                :release="release"
                :teacherId="teacherId"
                :workTimeId="workTimeId"
            />

        </template>

    </sys-register-base>
</template>

<script>
export default {
    name: "SysTeacherAvailabilityWeekCard",
    data: (vm) => ({
        dialog: false,
        loading: false,
        model: {}
    }),

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            this.dialog = show_new;
        },
        
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Disponibilidade Semanal"
        },
        release: {
            type: Boolean,
            default: false
        },
        workTimeId:{
            type: Number,
            required: true
        },
        teacherId: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
    },
}
</script>