var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "90%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                      _vm.content
                        ? _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.content) }
                          })
                        : _vm._e()
                    ])
                  ],
                  1
                ),
                _vm.model.id
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("a", { attrs: { id: "term" } }),
                            _vm.model.term.is_required
                              ? _c("v-checkbox", {
                                  staticClass: "ma-2",
                                  attrs: {
                                    label: _vm.model.term.label,
                                    color: "primary",
                                    "hide-details": "",
                                    disabled: _vm.view
                                  },
                                  model: {
                                    value: _vm.model.agree,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "agree", $$v)
                                    },
                                    expression: "model.agree"
                                  }
                                })
                              : _c(
                                  "span",
                                  [
                                    _vm.model.id
                                      ? _c(
                                          "v-radio-group",
                                          {
                                            staticClass: "ma-2",
                                            attrs: {
                                              row: "",
                                              disabled: _vm.view
                                            },
                                            model: {
                                              value: _vm.model.term_option_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.model,
                                                  "term_option_id",
                                                  $$v
                                                )
                                              },
                                              expression: "model.term_option_id"
                                            }
                                          },
                                          _vm._l(
                                            _vm.model.institution.term_options,
                                            function(opt, i) {
                                              return _c("v-radio", {
                                                key: i,
                                                attrs: {
                                                  label: opt.label,
                                                  value: opt.id,
                                                  color: opt.color
                                                }
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            }),
            _c("emc-alert-modal", {
              attrs: {
                text:
                  "Apó salvar a edição do termo, não será possível editar novamente. Deseja salvar?",
                show: _vm.showSave
              },
              on: {
                "update:show": function($event) {
                  _vm.showSave = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.updating },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _vm._v("Salvar"),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-content-save")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            !_vm.view
              ? _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.showSaveTerm()
                      }
                    }
                  },
                  [
                    _vm._v("Salvar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-content-save")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }