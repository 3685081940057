import Vue from 'vue'
import store from '../modules/auth/store'

const MyPlugin = {
  
  install(Vue, options) {
      Vue.prototype.globalColors = () => {
        return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
      },

      Vue.prototype.isAnima = (ies = null) => {
        
        let acesso = store.state.acesso;
        let instituicao = ies ? ies : acesso.instituicao;
        let env = process.env.VUE_APP_ENV.toString();

        return env.includes('anima') || (instituicao && instituicao.educational_group_id == 16);

      },

      Vue.prototype.hasIntegration = (id) => {
        
        let acesso = store.state.acesso;
        let instituicao = acesso.instituicao;

        if(instituicao && instituicao.integrations){
            return instituicao.integrations.find(i => i.id == id);
        }else{
            return false;
        }
        
      },

      Vue.prototype.showCalculateSalary = () => {
        
        let acesso = store.state.acesso;
        let instituicao = acesso.instituicao;

        let ids = [21, 12, 4, 22];

        return instituicao && ids.includes(instituicao.educational_group_id);

      },

      Vue.prototype.getRangeMonth = (workTime = null) => {

          let periodo = workTime ? workTime : store.state.acesso.periodo;
          const [year1, month1, day1] = periodo.start.split('-');
          const [year2, month2, day2] = periodo.end.split('-');

          let start   = new Date(year1, month1 - 1, day1, 0, 0, 0, 0).getMonth() + 1;
          let end     = new Date(year2, month2 - 1, day2, 0, 0, 0, 0).getMonth() + 1;
          let current = new Date().getMonth() + 1;
          let month   = (current < start) ? start : (current > end) ? end : current;

          return {
              start:      start,
              end:        end,
              current:    current,
              month:      month,
          };

      },

      Vue.prototype.getDateTime = (time = null) => {

        let date = time ? new Date(time) : new Date();
        const [day, month, year] = date.toLocaleDateString().split('/');

        return {
          date: `${year}-${month}-${day}`,
          time: date.toLocaleTimeString().substring(0, 5),
        };

      }

      Vue.prototype.isHourEqual = (hour1, hour2) => {

        hour1.start = hour1.start.substring(0, 5);
        hour1.end   = hour1.end.substring(0, 5);
        hour2.start = hour2.start.substring(0, 5);
        hour2.end   = hour2.end.substring(0, 5);

        if(hour1.start > hour2.start){
          
          let temp = hour1;

          hour1 = {
            start:  hour2.start,
            end:    hour2.end
          };

          hour2 = {
            start:  temp.start,
            end:    temp.end
          }
        }


        if(hour1.start == hour2.start){
          return true;
        }else if (hour1.start <= hour2.start && hour1.end > hour2.start){
          return true;
        }else if(hour1.start >= hour2.start && hour1.start < hour2.end){
          return true;
        }else if(hour1.end > hour2.start && hour1.end <= hour2.end){
          return true;
        }else{
          return false;
        }

      }

    },
  }
 Vue.use(MyPlugin)