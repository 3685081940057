<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :disabledSave="disabled"
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field autofocus :error="!!errors.description" :error-messages="errors.description" v-model="model.description" label="Descrição*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno"
                                :maxShift="3"
                                :disabled="model.dynamic_time_status_id != 1"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-combobox
                                v-model="model.campuses"
                                :items="itemsCampus"
                                label="Campus"
                                item-text="name"
                                :error="errors.length > 0"  
                                :error-messages="errors.campuses"
                                multiple
                                dense
                                clearable
                                :disabled="model.dynamic_time_status_id != 1"
                            >
                                <template v-slot:prepend-item>
                                    <v-checkbox
                                        v-model="selectAllCampus"
                                        label="Todos"
                                        @change="changeCheckbox"
                                        class="ml-2"
                                        />
                                </template>
                            
                                <template v-if="renderComponent" v-slot:selection="{ item, index }">

                                    <span v-if="index === 0">{{ item.name }}</span>
                                
                                    <span
                                        v-if="index === 1"
                                        class="grey--text caption ml-1"
                                    >
                                        (+{{ model.campuses.length - 1 }} outros)
                                    </span>
                                </template>
                                
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2  ">
                            <v-text-field dense :error="!!errors.total_grouping" :error-messages="errors.total_grouping" v-model="model.total_grouping" label="Agrupamentos*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                v-model="model.limit_seconds"
                                item-value="id"
                                item-text="name"
                                :items="minutes.filter(m => m.active)"
                                :error="!!errors.limit_seconds"
                                :error-messages="errors.limit_seconds"
                                label="Tempo máximo"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-category-group
                                :model.sync="model.categories"
                                :error="errors.length > 0"  
                                :errors="errors.categories"
                                label="Categoria"
                                multiple
                                dense
                                required
                                selectAll
                                :disabled="model.dynamic_time_status_id != 1"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-hour-group
                                :model.sync="model.hour_group_id"
                                :error="errors.length > 0"  
                                :errors="errors.hour_group_id"
                                label="Grupo de Horário"
                                dense
                                clearable
                                :disabled="model.dynamic_time_status_id != 1"
                            />
                        </v-col>
                        <v-col v-if="false" cols="12" sm="3">
                            <v-text-field dense :error="!!errors.max_classes_teacher" :error-messages="errors.max_classes_teacher" v-model="model.max_classes_teacher" label="Máximo Aulas Professor"></v-text-field>
                        </v-col>
                        <v-col  v-if="false" cols="12" sm="3">
                            <v-text-field dense :error="!!errors.max_classes_group" :error-messages="errors.max_classes_group" v-model="model.max_classes_group" label="Máximo Aulas Turma"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <s-switch text-helper='Tentar reduzir o número de janelas' inset v-model="model.reduce_windows" label="Reduzir Janelas"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-switch  text-helper="Tentar otimizar o número de dias" inset v-model="model.reduce_days" label="Reduzir Dias"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-switch text-helper="Validar interjonada entre dias consecutivos" inset v-model="model.validate_journey" label="Interjornada"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <s-switch text-helper="Validar deslocamento no turno" inset v-model="model.validate_desloc" label="Deslocamento"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-switch text-helper="Bloco de Aulas por dia" inset v-model="model.block_by_day" label="Bloco de Aulas"/>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDynamicTimeRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        itemsCampus: [],
        selectAllCampus: false,
        message: '',
        loading: false,
        disabled: false,
        renderComponent: true,
        minutes: [
            { id: 60,   name: '1 minuto',   active: false },
            { id: 120,  name: '2 minutos',  active: true },
            { id: 180,  name: '3 minutos',  active: true },
            { id: 240,  name: '4 minutos',  active: true },
            { id: 300,  name: '5 minutos',  active: true },
            { id: 600,  name: '10 minutos', active: true },
            { id: 900,  name: '15 minutos', active: true },
            { id: 1200, name: '20 minutos', active: true },
            { id: 1500, name: '25 minutos', active: true },
            { id: 1800, name: '30 minutos', active: true },
            { id: 2700, name: '45 minutos', active: true },
            { id: 3600, name: '1 Hora',     active: true },
        ]
        
    }),
    created() {
        this.loadCampus();
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.disabled = false;
                
                if(!this.model.id){
                    
                    this.model.allow_window = 0;
                    this.model.total_grouping = 2;
                    this.model.limit_seconds = 120;
                    this.model.validate_desloc = 1;
                    this.model.description = 'Novo Horário'
                    this.model.dynamic_time_status_id = 1;
                    this.model.campuses = [];
                    this.model.block_by_day = 0;

                }else{
                    if(this.model.dynamic_time_status_id == 5){
                        this.disabled = true;
                    }
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-puzzle-outline"
        },
        title: {
            type: String,
            default: "Horário Dinâmico"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('dynamicGeneration', ['ActionUpdateDynamicTime', 'ActionCreateDynamicTime']),
        ...mapActions('campus', ['ActionFindCampuses']),

        loadCampus(){
            
            let payload = {
                column_order: 'name',
            };
            
            this.ActionFindCampuses(payload)
                .then((res) => {
                    this.itemsCampus = res.data; 
                })
                .finally(() => {
                    
                })
        },

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {work_time_id: this.acesso.periodo.id})

            if(edit) {
                this.ActionUpdateDynamicTime(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateDynamicTime(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        changeCheckbox(ev){
            
            if(ev){
                this.model.campuses = this.itemsCampus.map(c => ({id: c.id, name: c.name}));
            }else{
                this.model.campuses = [];
            }

            this.forceRerender();

        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        })
        },

    }
}
</script>