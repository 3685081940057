<template>
    
    <v-autocomplete
        v-model="model"
        :label="label"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :outlined="outlined"
        :loading="loading"
        :disabled="disabled"
        :hide-details="hideDetails"
        :hide-no-data="hideNoData"
        :background-color="(disabled || loading) ? `grey lighten-2` : backgroundColor"
        :error="error"
        :error-messages="errorMessages"
        :clearable="clearable"
        :multiple="multiple"
        :small-chips="smallChips"
        @focus="$emit('onClick', true)"
    >
            <template v-slot:item="{ item }">
                <slot name="item" v-bind="{ item }">
                    <v-list-item-content>
                        <v-list-item-title v-html="item.name"></v-list-item-title>
                    </v-list-item-content>
                </slot>
            </template>

    </v-autocomplete> 

</template>

<script>
export default {
    name: "EmcFormAutoComplete",
    props: {
        outlined: { type: Boolean, required: false },
        clearable: { type: Boolean, required: false },
        backgroundColor: { type: String, required: false },
        hideDetails: { type: [Boolean, String], default: 'auto' },
        loading: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
        label: { type: String, required: false },
        value: { type: [String, Number, Object, Array] },
        items: { type: Array },
        itemText: { type: String, default: "name" },
        itemValue: { type: String, default: "id" },
        errorMessages: { type: Array, required: false },
        error: { type: Boolean, required: false },
        hideNoData: { type: Boolean, required: false },
        multiple: { type: Boolean, required: false },
        smallChips: { type: Boolean, required: false }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    }
}
</script>