var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-card-stats", {
    attrs: {
      color: "blue lighten-1",
      icon: "mdi-speedometer-medium",
      "icon-large": "",
      title: _vm.title,
      value: _vm.value,
      "show-actions": _vm.showActions,
      "text-actions": _vm.title,
      "sub-text": _vm.showActions ? null : "CH Média Docente"
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [
            _vm.showMoreButton
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { color: "primary", icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "showMoreWorkLoadAvg"
                                          )
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { btn: "" } }, [
                                    _vm._v("mdi-monitor-eye")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3093801949
                    )
                  },
                  [_c("span", [_vm._v(" Ver Mais... ")])]
                )
              : _vm._e(),
            _c("emc-button-icon", {
              attrs: {
                "btn-small": "",
                loading: _vm.loading,
                icon: "mdi-refresh-circle",
                text: _vm.updated_at || "Atualizar",
                color: "success"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.refresh()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }