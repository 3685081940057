import { render, staticRenderFns } from "./OtherActivities.vue?vue&type=template&id=59e7e0ae&scoped=true&"
import script from "./OtherActivities.vue?vue&type=script&lang=js&"
export * from "./OtherActivities.vue?vue&type=script&lang=js&"
import style0 from "./OtherActivities.vue?vue&type=style&index=0&id=59e7e0ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e7e0ae",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VIcon,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59e7e0ae')) {
      api.createRecord('59e7e0ae', component.options)
    } else {
      api.reload('59e7e0ae', component.options)
    }
    module.hot.accept("./OtherActivities.vue?vue&type=template&id=59e7e0ae&scoped=true&", function () {
      api.rerender('59e7e0ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sys/teacher/OtherActivities.vue"
export default component.exports