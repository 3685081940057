<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :showAdd="showAdd"
            :disabled="disabled"
            :multiple="multiple"
            :autoComplete="autoComplete"
            :dense="dense"
        />
        <sys-register-curriculum-category @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectCurriculumCategory',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        selectedObject: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        modelObject: {
        
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        
    },
    watch: {
        
        selected(val){
            
            if(val){
                this.$emit('update:model', val);
                var course = this.items.find(c => c.id == val);
                
                if(course) {
                    this.$emit('update:modelObject', course);
                }
            }
        },

        selectedObject(val){
            this.$emit('update:modelObject', val);
        },
        
        model(val){
            this.selected = val;
        },

        modelObject(val){
            this.selectedObject = val;
            
            if(val && val.id){
                this.selected = val.id;
            }else{
                 this.selected = 0;
            }
        },
    },
    methods: {
        ...mapActions('curriculumCategory', ['ActionFindCurriculumCategories']),        
        
        async getItems() {

            this.loading = true

            await this.ActionFindCurriculumCategories()
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                        this.$emit('onLoad', this.items);
                       
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
        
            if(item){
                this.items.push(item);
                
                if(this.multiple){
                    this.selected.push(item);
                }else{
                    this.selected = item.id;
                    this.selectedObject = item;
                }
                
                this.showForm = false;
                }
        }
    },
    
}
</script>