var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      label: _vm.label,
      items: _vm.items,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      outlined: _vm.outlined,
      loading: _vm.loading,
      disabled: _vm.disabled,
      "hide-details": _vm.hideDetails,
      "hide-no-data": _vm.hideNoData,
      "background-color":
        _vm.disabled || _vm.loading ? "grey lighten-2" : _vm.backgroundColor,
      error: _vm.error,
      "error-messages": _vm.errorMessages,
      clearable: _vm.clearable
    },
    on: {
      focus: function($event) {
        return _vm.$emit("onClick", true)
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm._t(
                "item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { innerHTML: _vm._s(item.name) }
                      })
                    ],
                    1
                  )
                ],
                null,
                { item: item }
              )
            ]
          }
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }