var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-grid",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _vm.tab == 1 && _vm.model.id
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled: _vm.disableSaveDisciplines,
                                        loading: _vm.loadingSaveDisciplines,
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.updateDisciplines()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-content-save-edit-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4121831979
                    )
                  },
                  [_c("span", [_vm._v(" Salvar alterações ")])]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados")]),
                _c("v-tab", { key: "disciplines" }, [_vm._v("Disciplinas")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "8" } },
                              [
                                _c("sys-select-course", {
                                  attrs: {
                                    model: _vm.model.course_id,
                                    error:
                                      _vm.errors.course_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.course_id,
                                    label: "Curso*",
                                    autoComplete: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "course_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-curriculum-category", {
                                  attrs: {
                                    model: _vm.model.curriculum_category_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.curriculum_category_id,
                                    label: "Categoria*"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "curriculum_category_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    autofocus: "",
                                    error: !!_vm.errors.name,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome/Sigla*"
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-frequency", {
                                  attrs: {
                                    model: _vm.model.frequency_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.frequency_id,
                                    label: "Periodicidade*",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "frequency_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.number_modules,
                                    "error-messages": _vm.errors.number_modules,
                                    label: "Períodos*"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.model
                                                                    .number_modules <
                                                                    1 ||
                                                                  _vm.model
                                                                    .number_modules >
                                                                    24,
                                                                small: "",
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                  return _vm.loadSequencies()
                                                                }
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                btn: "",
                                                                color:
                                                                  "green darken-1"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-flash"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(" Carregar Períodos ")
                                              ])
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value: _vm.model.number_modules,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "number_modules", $$v)
                                    },
                                    expression: "model.number_modules"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-campus", {
                                  attrs: {
                                    model: _vm.model.campuses,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.campuses,
                                    label: "Campus",
                                    multiple: "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "campuses",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-curriculum-status", {
                                  attrs: {
                                    model: _vm.model.curriculum_status_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.curriculum_status_id,
                                    label: "Status*",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "curriculum_status_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-curriculum-regime", {
                                  attrs: {
                                    model: _vm.model.curriculum_regime_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.curriculum_regime_id,
                                    label: "Regime*",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "curriculum_regime_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.year,
                                    "error-messages": _vm.errors.year,
                                    label: "Ano Ingresso"
                                  },
                                  model: {
                                    value: _vm.model.year,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "year", $$v)
                                    },
                                    expression: "model.year"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.period,
                                    "error-messages": _vm.errors.period,
                                    label: "Semestre Ingresso"
                                  },
                                  model: {
                                    value: _vm.model.period,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "period", $$v)
                                    },
                                    expression: "model.period"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.workload,
                                    "error-messages": _vm.errors.workload,
                                    label: "Carga Horária"
                                  },
                                  model: {
                                    value: _vm.model.workload,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "workload", $$v)
                                    },
                                    expression: "model.workload"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    error: !!_vm.errors.description,
                                    "error-messages": _vm.errors.description,
                                    label: "Descrição",
                                    counter: 255,
                                    rules: [
                                      function(v) {
                                        return (
                                          !v ||
                                          v.length <= 255 ||
                                          "Máximo de 255 caracteres atingido"
                                        )
                                      }
                                    ],
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.model.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "description", $$v)
                                    },
                                    expression: "model.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "disciplines" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _vm.renderComponent
                                  ? _c(
                                      "v-expansion-panels",
                                      {
                                        staticClass: "mx-auto elevation-0",
                                        attrs: { focusable: "", prominent: "" },
                                        model: {
                                          value: _vm.panel,
                                          callback: function($$v) {
                                            _vm.panel = $$v
                                          },
                                          expression: "panel"
                                        }
                                      },
                                      [
                                        _c(
                                          "emc-util-scroll",
                                          { attrs: { maxHeight: "400px" } },
                                          _vm._l(_vm.model.sequencies, function(
                                            sequency
                                          ) {
                                            return _c(
                                              "v-expansion-panel",
                                              {
                                                key:
                                                  sequency.curriculum_sequency_id
                                              },
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        sequency
                                                          .sequencyDisciplines
                                                          .length
                                                          ? _c(
                                                              "v-badge",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "blue lighten-2",
                                                                  content:
                                                                    sequency
                                                                      .sequencyDisciplines
                                                                      .length,
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      sequency
                                                                        .sequency
                                                                        .id ==
                                                                        99
                                                                        ? sequency
                                                                            .sequency
                                                                            .name
                                                                        : sequency
                                                                            .sequency
                                                                            .id
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "grey--text"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    sequency
                                                                      .sequency
                                                                      .id == 99
                                                                      ? sequency
                                                                          .sequency
                                                                          .name
                                                                      : sequency
                                                                          .sequency
                                                                          .id
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          (sequency.initials
                                                            ? sequency.initials
                                                            : "") +
                                                            (sequency.description
                                                              ? " - " +
                                                                sequency.description
                                                              : "")
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("v-spacer")
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "ma-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-data-table", {
                                                              staticClass:
                                                                "elevation-1",
                                                              attrs: {
                                                                headers:
                                                                  _vm.headers,
                                                                items:
                                                                  sequency.sequencyDisciplines,
                                                                "sort-by":
                                                                  "name",
                                                                "hide-default-footer":
                                                                  ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key: "top",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "indigo darken-1",
                                                                                                icon:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.preventDefault()
                                                                                                  $event.stopPropagation()
                                                                                                  return _vm.addItem(
                                                                                                    sequency,
                                                                                                    false
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            "v-btn",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-plus"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " Incluir disciplina "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  },
                                                                  {
                                                                    key:
                                                                      "item.discipline",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var item =
                                                                        ref.item
                                                                      return [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item
                                                                                .discipline
                                                                                .name_complete
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        item
                                                                          .discipline
                                                                          .code
                                                                          ? _c(
                                                                              "small",
                                                                              [
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                _vm._v(
                                                                                  "[" +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .discipline
                                                                                        .code
                                                                                    ) +
                                                                                    "]"
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    }
                                                                  },
                                                                  {
                                                                    key:
                                                                      "item.grid_category_id",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var item =
                                                                        ref.item
                                                                      return [
                                                                        _c(
                                                                          "sys-select-grid-category",
                                                                          {
                                                                            attrs: {
                                                                              model:
                                                                                item.grid_category_id,
                                                                              error:
                                                                                _vm
                                                                                  .errors
                                                                                  .length >
                                                                                0,
                                                                              errors:
                                                                                _vm
                                                                                  .errors
                                                                                  .grid_category_id,
                                                                              itemText:
                                                                                "initials",
                                                                              clearable:
                                                                                "",
                                                                              label:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              "update:model": function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.$set(
                                                                                  item,
                                                                                  "grid_category_id",
                                                                                  $event
                                                                                )
                                                                              },
                                                                              input: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.disableSaveDisciplines = false
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    }
                                                                  },
                                                                  {
                                                                    key:
                                                                      "item.actions",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var item =
                                                                        ref.item
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    var attrs =
                                                                                      ref.attrs
                                                                                    return [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs: {
                                                                                                small:
                                                                                                  "",
                                                                                                color:
                                                                                                  "red"
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.showDeleteItem(
                                                                                                    sequency,
                                                                                                    item
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            "v-icon",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            " mdi-delete "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " Excluir Disciplina "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    sequency.sequency.id != 99
                                                      ? _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  sm: "4"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "Módulo"
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.disableSaveDisciplines = false
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        sequency.initials,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          sequency,
                                                                          "initials",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "sequency.initials"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  sm: "8"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "Descrição"
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.disableSaveDisciplines = false
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        sequency.description,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          sequency,
                                                                          "description",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "sequency.description"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            }),
            _c("sys-find-discipline", {
              attrs: {
                showAdd: "",
                showSelect: "",
                singleSelect: false,
                show: _vm.dialogAddDiscipline
              },
              on: {
                onSelect: function($event) {
                  return _vm.selectDiscipline($event)
                },
                "update:show": function($event) {
                  _vm.dialogAddDiscipline = $event
                }
              }
            }),
            _c("emc-alert-modal", {
              attrs: {
                text: "Deseja excluir a disciplina?",
                show: _vm.showDelete
              },
              on: {
                "update:show": function($event) {
                  _vm.showDelete = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.deleteItem()
                            }
                          }
                        },
                        [
                          _vm._v("Excluir "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }