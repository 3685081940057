<template>
        <div>
            <v-text-field
                 v-if="!hideSearch"
                v-model="search"
                x-small
                placeholder="Pesquisar" 
                rounded 
                single-line
                class="mb-0 pb-0"
                ref="textSearch"
            >
                <template v-slot:append>
                    <emc-button-icon v-if="search" class="mt-0 pt-0" btnSmall icon="mdi-close" color="error" text="Limpar" @click="search = null" />
                    <emc-button-icon v-if="search" class="mt-0 pt-0" btnSmall :loading="loadingSearch" :disabled="search != null && search.length < 5" icon="search" text="Buscar turma" @click="searchGroup()" />
                    <emc-button-icon v-else btnSmall class="mt-0 pt-0" color="success" icon="mdi-refresh" :loading="loadingRefresh" text="Atualizar" @click="refresh()" />
                    <emc-button-icon v-if="showFilterPendencies" :loading="loadingPendings" btnSmall class="mt-0 pt-0" icon="mdi-progress-clock" :text="onlyPendings ? 'Limpar Filtro de Pendentes' : 'Filtrar Pendentes'" @click="changeOnlyPendinds()" :color="onlyPendings ? 'amber' : 'grey'" />
                </template>
            </v-text-field>
            <v-treeview
                ref="tree"
                v-model="selection"
                :active.sync="active"
                :items="items"
                :load-children="fetchItems"
                :open.sync="open"
                activatable
                item-children="children"
                color="warning"
                :open-on-click="false"
                transition
                item-key="key"
                return-object
                @update:active="selectItem($event[0])"
                :selectable="selectable"
                dense
                :search="searchInner"
                class="mt-0 pt-0"
            >
                    <template v-slot:label="{ item }">
                    <draggable v-if="allowDrag && item.level >= 3" @start="start($event, item)" @end="end($event, item)">
                        
                        <v-tooltip v-if="item.level == 3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" style="cursor: pointer;" >{{item.name}}</span>
                                <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                                <span v-bind="attrs" v-on="on" style="cursor: pointer;" >{{item.name}}</span>
                            </template>
                            <span>
                                {{ item.name }}
                                <small>{{ item.course.name }}</small> 
                                <small v-if="item.time_course"><br/>Período: {{ item.time_course }}</small>
                            </span>
                            
                        </v-tooltip>

                        <span style="cursor: pointer;" v-else>
                            <span v-if="item.level == 4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" style="cursor: pointer;" >{{ item.work_load_type.initials + ' - ' + item.name}}</span>
                                        <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                        <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                                    </template>
                                    <span>
                                        <span>{{ item.work_load_type.initials + ' - ' + item.name + ' (' + item.work_load + ')' }}</span>
                                        <span v-if="item.students_join">
                                            <br/>Alunos: {{ item.students_join }}
                                        </span>
                                        <span v-if="item.students_join_forecast">
                                            <br/>Previsão Alunos: {{ item.students_join_forecast }}
                                        </span>
                                        <span v-if="item.teacher">
                                            <br/>
                                            {{ item.teacher.name }}
                                        </span>
                                        
                                    </span>
                                </v-tooltip>
                            </span>
                            <span v-else>
                                <span>{{item.name}}</span>
                                <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                            </span>
                        </span>
                    </draggable>
                    <span v-else>
                        <v-tooltip v-if="item.level == 2" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" style="cursor: default;" >
                                    {{ item.name }}
                                </span>
                            </template>
                            <span>
                                {{ item.name }}
                            </span>
                        </v-tooltip>
                        <v-tooltip v-else-if="item.level == 3" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="cursor: default;" >{{item.name}}</span>
                                    <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                    <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                                    <span v-if="showPendencies">
                                        <v-icon v-if="item.checked" small color="green" btn>mdi-check-circle-outline</v-icon>
                                        <v-icon v-else small color="amber" btn>mdi-progress-clock</v-icon>
                                    </span>
                                    <span v-bind="attrs" v-on="on" style="cursor: default;" >
                                        {{item.name}}
                                    </span>
                                </template>
                                <span>
                                    {{ item.name }}
                                    <br/>
                                    <small>{{ item.course.name }}</small> 
                                    <small v-if="item.time_course"><br/>Período: {{ item.time_course }}</small>

                                </span>
                        </v-tooltip>
                        <span v-else>
                            <span style="cursor: default;" >{{item.name}}</span>
                            <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                        </span>
                        <emc-util-icon-category v-if="showCategory" :item="item" />
                    </span>
                </template>

            </v-treeview>
        </div>
   
</template>

<script>

import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
    name: "SysGroupTreeView",
    components: {
        draggable,
    },
    data: () => ({
      campuses: [],
      active: [],
      open: [],
      search: null,
      searchInner: null,
      selection: [],
      loadingRefresh: true,
      loadingSearch: false,
      loadingPendings: false,
      onlyPendings: false,
      msSleep: 500,
    }),
    computed: {
        ...mapState('auth', ['acesso']),

        items () {
            return this.campuses;
        },
      
    },

    created() {
      this.getCampuses();
    },
    
    watch: {
        
        search(val){
            
            this.searchInner = val;

            if(this.autoUpdateAll){
                if(val && val != ''){
                    this.$refs.tree.updateAll(true);
                }else{
                    this.$refs.tree.updateAll(false);
                }
            }
        },

        selection(val){
            this.$emit('update:selected', val.map(o => ({ id: o['id'], level: o['level'], key: o['key'] })))
        },

        pendencies(val){
            
            if(val != -1){
                
                if(this.active && this.active[0].level == 3){
                    this.active[0].checked = val <= 0;
                }
               
            }
        }
        
    },

    props: {
        
        model: {
            type: Boolean
        },
        selectable:{
            type: Boolean,
            default: false
        },
        autoUpdateAll: {
            type: Boolean,
            default: false
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        allowDrag: {
            type: Boolean,
            default: false
        },
        showGrids: {
            type: Boolean,
            default: false
        },
        pendencies: {
            type: Number,
            default: -1
        },
        showPendencies: {
            type: Boolean,
            default: false
        },
        showFilterPendencies: {
            type: Boolean,
            default: false
        },
        showCategory: {
            type: Boolean,
            default: false
        },
        selected: { }
        
    },
    methods: {
        ...mapActions('campus', ['ActionFindCampusesFilterGroup']),
        ...mapActions('hour', ['ActionGetShiftsFilter']),
        ...mapActions('group', ['ActionFindGroups']),
        ...mapActions('course', ['ActionFindCourses']),
        ...mapActions('grid', ['ActionFindGrids']),

        setFocus(){
            this.$refs.textSearch.focus();
        },

        refresh(){
            this.loadingRefresh = true;
            this.getCampuses();
        },

        searchGroup(){
        
            if(this.search && this.search.length >= 5){
                this.loadingSearch = true;
                this.loadByGroup();
            }
            
        },

        changeOnlyPendinds(){

            this.loadingPendings = true;
            this.onlyPendings = !this.onlyPendings;
            this.search = null;
            this.getCampuses();

        },
        
        updatePendencies(){

            this.items.forEach(campus => {
                
                let countShifts = campus.children.length;
                let chechedsShifts = 0;

                campus.children.forEach(shift => {
                    
                    let countGroups = shift.children.length;
                    let checkedsGroups = 0;
                    
                    shift.children.forEach(group => {
                        if(group.is_complete){
                            checkedsGroups++;
                        }
                    });

                    shift.checked = countGroups == checkedsGroups;

                    if(shift.checked){
                        chechedsShifts++;
                    }

                });

                campus.checked = countShifts == chechedsShifts;

            });

        },

        async fetchItems(item) {
        
            if(item.loadindByGroup){
                return;
            }
            
            switch (item.level) {
                case 0:
                    return await this.getShifts(item);
                case 1:
                    return await this.getCourses(item);
                case 2:
                    return await this.getGroups(item);
                case 3:
                    return await this.getGrids(item);
            }
        },

        async loadByGroup(){
            this.searchInner = null;
            await this.getCampuses(true);
        },  
        
        getCampuses(byGroup = false){
    
            this.campuses = [];

            var payload = {
                onlyPendings: this.onlyPendings
            };

            if(byGroup && this.search){
                payload.filter_relations = [
                    {
                        name: 'groups',
                        key: 'groups.name',
                        value: '%' + this.search + '%',
                        operator: 'like'
                    }
                ];
            }

            this.ActionFindCampusesFilterGroup(payload)
                .then((res) => {
                
                    res.data.forEach(campus => {
                        
                        let item = {
                            id: campus.id,
                            key: campus.id,
                            level: 0,
                            loadindByGroup: byGroup,
                            name: campus.name,
                            checked: false,
                            children: []
                        };
                        
                        this.campuses.push(item);

                        if(byGroup && this.search){
                            
                            setTimeout(() => {  
                                this.getShifts(item, true);
                            }, this.msSleep);
                            
                            
                        }


                    });
                })
                .finally(()=>{
                    this.loadingRefresh = false;
                    this.loadingSearch = false;
                    this.loadingPendings = false;
                });
        },

        async getShifts(campus, byGroup = false) {
            
            var payload = {
                campus_id: campus.id,
                onlyPendings: this.onlyPendings
            };

            if(byGroup && this.search){
                payload.filter_relations = [
                    {
                        name: 'groups',
                        key: 'groups.name',
                        value: '%' + this.search + '%',
                        operator: 'like'
                    }
                ];
            }

            return this.ActionGetShiftsFilter(payload)
                .then((res) => {
                    res.data.forEach(shift => {
                        
                        let item = {
                            id:         shift.id,
                            campus_id:  campus.id,
                            key:        campus.key + '|' + shift.id,
                            level:      1,
                            loadindByGroup: byGroup,
                            name:       shift.name,
                            checked:    false,
                            children:   []
                        };
                        
                        campus.children.push(item);

                        if(byGroup && this.search){
                            
                            setTimeout(() => {  
                                this.getCourses(item, true);
                            }, this.msSleep);
                            
                            
                        }

                    });
            })
        },

        async getCourses(shift, byGroup = false){

            var payload = {
                per_page:   1000,
                column_order: 'name',
                onlyPendings: this.onlyPendings,
                filter_relations: [
                    {
                        name:   'groups',
                        filters: [
                            { key: 'shift_id', value: shift.id },
                            { key: 'campus_id', value: shift.campus_id },
                            { key: 'work_time_id', value: this.acesso.periodo.id },
                            { key: 'status_group_id', value: '1,2', clause: 'whereIn' },
                        ]
                    }
                ],
            };

            if(byGroup && this.search){
                payload.filter_relations[0].filters.push(
                    {
                        key: 'name',
                        value: '%' + this.search + '%',
                        operator: 'like'
                    }
                );
            }

            return this.ActionFindCourses(payload)
                .then((res) => {
                    
                    res.data.forEach(course => {
                        
                        let item = {
                            id:                 course.id,
                            campus_id:          shift.campus_id,
                            shift_id:           shift.id,
                            key:                shift.key + '|' + course.id,
                            level:              2,
                            loadindByGroup:     byGroup,
                            name:               course.name,
                            children:           []
                        };

                        shift.children.push(item);

                        if(byGroup && this.search){
                            
                            setTimeout(() => {  
                                this.getGroups(item, true);
                            }, this.msSleep);
                            
                            
                        }

                    })
                    
                })
                .finally(() => {
                    
                });

        },

        async getGroups(course, byGroup = false){
      
            this.loadingSearch = byGroup;
            
            var payload = {
                per_page:   1000,
                column_order: 'name',
                onlyPendings: this.onlyPendings,
                find_columns: {
                    campus_id:  course.campus_id,
                    shift_id:   course.shift_id,
                    course_id:  course.id,
                    status_group_id: '1,2',
                },
                with: 'course,campus.hourGroups,category'
            }

            if(byGroup && this.search){
                payload.filter_columns = [
                    {
                        column:     'name',
                        value:      '%' + this.search + '%',
                        operator:   'like'
                    }
                ];
            }

            return this.ActionFindGroups(payload)
                .then((res) => {
                    
                    res.data.forEach(group => {
                        let children = {
                            id:                 group.id,
                            key:                course.key + '|' + group.id,
                            level:              3,
                            loadindByGroup:     byGroup,
                            name:               group.name,
                            shift_id:           group.shift_id,
                            pendencies:         this.showPendencies ? group.pendencies : null,
                            checked:            this.showPendencies && group.pendencies == 0,
                            classes:            [],
                            allocations:        [],
                            course:             group.course,
                            has_message:        group.has_message,
                            validated_time:     group.validated_time,
                            hour_group_id:      this.getHourGroupID(group),
                            students:           group.students,
                            students_forecast:  group.students_forecast,
                            time_course:        group.time_course,
                            time_shift_id:      group.time_shift_id,
                            category:           group.category,
                        };

                         if(this.showGrids){
                             children.children = [];
                         }

                         course.children.push(children);
                    })
                    
                })
                .finally(() => {
                    
                    this.loadingPendings = false;
                    this.loadingSearch = false;

                    if(byGroup && this.search){
                        this.$refs.tree.updateAll(true);
                    }
                });
        },

        async getGrids(group, selectedItem = false, byGroup = false){
      
            var payload = {
                withStudentsJoin: true,
                per_page:   1000,
                find_columns: {
                    group_id: group.id,
                },
                with: 'discipline,workLoadType,teacher,classes.ambience.block'
            }

            return this.ActionFindGrids(payload)
                .then((res) => {
                    
                    let classes = [];
                    let allocations = [];
                    let grids = [];

                    res.data.forEach(grid => {
                        
                        grid.classes.forEach(c => {
                            c.grid = grid;
                            c.grid.group = group;
                            classes.push(c);
                        });

                        grids.push({
                            id:                     grid.id,
                            key:                    group.key + '|' + grid.id,
                            level:                  4,
                            loadindByGroup:         byGroup,
                            name:                   grid.discipline_name,
                            work_load_type:         grid.work_load_type,
                            work_load:              grid.work_load,
                            students:               grid.students,
                            students_forecast:      grid.students_forecast,
                            students_join:          grid.students_join,
                            students_join_forecast: grid.students_join_forecast,
                            teacher:                grid.teacher,
                            group_number:           grid.group_number,
                            teacher_number:         grid.teacher_number,
                            group:                  group,
                            classes:                grid.classes,
                        });

                    });

                    group.children      = grids;
                    group.classes       = classes;
                    group.allocations   = allocations;

                    if(selectedItem){
                        this.$emit('select', group);
                    }
                    
                }).finally(()=>{
                    
                });
        },

        getHourGroupID(group){

            if(group.hour_group_id){
                return group.hour_group_id;
            }else{
                
                if(group.category_group_id){

                   var hourGroup = group.campus.hour_groups.find(g => g.category_group_id == group.category_group_id);
                   
                   if(hourGroup){
                        return hourGroup.hour_group_id;
                   }

                }
                
                return group.campus.hour_group_id;
            }

        },

        selectItem(item){
            
            if(item && item.level >= 3){
                
                if(item.level == 3){
                    
                    if(this.showGrids){
                        this.getGrids(item, true);
                    }else{
                        this.$emit('select', item);
                    }

                }else if(item.level == 4){
                    this.$emit('select', item.group);
                }
            }

        },

        start(event, item){
            
            let classes = [];

            if(item.classes){
                item.classes.forEach(c => {
                    classes.push(c.id);
                });
            }

            this.$emit('start', classes);
            
        },

        end(event, item){
            
            let classes = [];

            if(item.classes){
                item.classes.forEach(c => {
                    classes.push(c.id);
                });
            }
            
            this.$emit('end',  classes);
        }
    }
}
</script>