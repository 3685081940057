var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-comment-text-multiple-outline",
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _vm.tab === 1 && !!_vm.model.id
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "success darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showAddQuestion = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1194681768
                    )
                  },
                  [_c("span", [_vm._v(" Cadastrar Nova Pergunta ")])]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                on: {
                  change: function($event) {
                    return _vm.forceRerender()
                  }
                },
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Categoria")]),
                _c("v-tab", { key: "questions" }, [_vm._v("Perguntas")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "10" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    autofocus: "",
                                    error: !!_vm.errors.name,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome*"
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código*"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("span", [_vm._v("Descrição*")]),
                                _c("vue-editor", {
                                  attrs: { editorToolbar: _vm.customToolbar },
                                  model: {
                                    value: _vm.model.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "description", $$v)
                                    },
                                    expression: "model.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("s-text-field-icon", {
                                  attrs: { "error-messages": _vm.errors.icon },
                                  model: {
                                    value: _vm.model.icon,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "icon", $$v)
                                    },
                                    expression: "model.icon"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    error: !!_vm.errors.key_words,
                                    "error-messages": _vm.errors.key_words,
                                    label: "Palavras Chave"
                                  },
                                  model: {
                                    value: _vm.model.key_words,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "key_words", $$v)
                                    },
                                    expression: "model.key_words"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    error: !!_vm.errors.order,
                                    "error-messages": _vm.errors.order,
                                    label: "Ordem*"
                                  },
                                  model: {
                                    value: _vm.model.order,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "order", $$v)
                                    },
                                    expression: "model.order"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-switch", {
                                  attrs: { inset: "", label: "Ativo" },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "questions" },
                  [
                    _vm.renderComponent
                      ? _c("sys-faq-questions", {
                          attrs: {
                            showAdd: _vm.showAddQuestion,
                            item: _vm.model
                          },
                          on: {
                            "update:showAdd": function($event) {
                              _vm.showAddQuestion = $event
                            },
                            "update:show-add": function($event) {
                              _vm.showAddQuestion = $event
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }