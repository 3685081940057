var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data &&
      _vm.data.series &&
      _vm.data.series.length > 1 &&
      !_vm.hideRefresh
        ? _c("emc-button-icon", {
            attrs: {
              loading: _vm.loading,
              icon: "mdi-refresh",
              text: "Atualizar",
              color: "light-green"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.$emit("refresh")
              }
            }
          })
        : _vm._e(),
      _vm.renderComponent
        ? _c("apexchart", {
            attrs: {
              type: _vm.data.type,
              options: _vm.chartOptions,
              series: _vm.data.series
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }