<template>
    <emc-card-stats
        color="blue lighten-1"
        icon="mdi-speedometer-medium"
        icon-large
        :title="title"
        :value="value"
        :show-actions="showActions"
        :text-actions="title"
        :sub-text="showActions ? null : 'CH Média Docente'"
    >
        <template  v-slot:actions>
            <v-tooltip bottom v-if="showMoreButton">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMoreWorkLoadAvg')">
                        <v-icon btn>mdi-monitor-eye</v-icon>
                    </v-btn>
                </template>
                <span>
                    Ver Mais...
                </span>
            </v-tooltip>

            <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
        </template>

        </emc-card-stats>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartTeacherWorkLoadAvg',
    data () {
        return {
            value: '0',
            title: '',
            updated_at: null,
            loading: false,
            attempts: 1
        }
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        item: { },
        noLoad: {
            type: Boolean,
            default: false,
        },
        showActions: {
            type: Boolean,
            default: false,
        }
    },
    
    methods: {
        ...mapActions('chart', ['ActionChartTeacherWorkLoadAvg', 'ActionTeacherWorkLoadAvgRefresh']),
        async getData() {
            
            if(this.noLoad){
                this.value = this.item.value;
                this.title = this.item.title;
                return;
            }

            await this.ActionChartTeacherWorkLoadAvg()
                .then((r) => {
                    this.value = r.data.value.toString()
                    this.title = r.data.title
                    this.updated_at = r.data.updated_at
                })
        },
        refresh() {
            this.loading = true

            this.ActionTeacherWorkLoadAvgRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let number = this.value;

            setTimeout(async () => {

                    await this.getData()
                    
                    if(number != this.value || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 20000)
        }
    },
    created(){
        this.getData()
    }

    
}
</script>