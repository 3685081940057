export default {
    financialPostingTypesIndex: { method: 'get', url: 'emcadmin/financial-posting-types' },
    financialPostingTypesStore: { method: 'post', url: 'emcadmin/financial-posting-types' },
    financialPostingTypesUpdate: { method: 'put', url: 'emcadmin/financial-posting-types/{id}' },
    financialPostingTypesDestroy: { method: 'delete', url: 'emcadmin/financial-posting-types/{id}' },
    postingFinancialItemsIndex: { method: 'get', url: 'emcadmin/posting-financial-items' },
    postingFinancialItemsStore: { method: 'post', url: 'emcadmin/posting-financial-items' },
    postingFinancialItemsUpdate: { method: 'put', url: 'emcadmin/posting-financial-items/{id}' },
    postingFinancialItemsDestroy: { method: 'delete', url: 'emcadmin/posting-financial-items/{id}' },
    forecastMonthIndex: { method: 'get', url: 'emcadmin/posting-financial-items/forecast-month' },
    expenseForecastMonthIndex: { method: 'get', url: 'emcadmin/posting-financial-items/expense-forecast-month' },
    overdueInIndex: { method: 'get', url: 'emcadmin/posting-financial-items/overdue-in' },
    balanceEntriesIndex: { method: 'get', url: 'emcadmin/posting-financial-items/balance-entries' },
    releaseFromRecentMonthsIndex: { method: 'get', url: 'emcadmin/posting-financial-items/release-from-recent-months' },
    delayedExpenseIndex: { method: 'get', url: 'emcadmin/posting-financial-items/delayed-expense' },
    cashForecastIndex: { method: 'get', url: 'emcadmin/posting-financial-items/cash-forecast' },
    postingStatusFinancialIndex: { method: 'get', url: 'emcadmin/posting-status-financial' },
    postingStatusFinancialShow: { method: 'get', url: 'emcadmin/posting-status-financial/{id}'}
}