var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sys-register-base",
    {
      attrs: {
        model: {},
        title: "Impressão de Horários",
        icon: "mdi-printer-check",
        show: _vm.dialog,
        maxWidth: "50%",
        showRequiredFields: false,
        showButton: false
      },
      on: {
        "update:show": function($event) {
          _vm.dialog = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "form",
          fn: function() {
            return [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "ma-0",
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tab", { key: "groups" }, [
                                _vm._v("Turmas")
                              ]),
                              _c("v-tab", { key: "teachers" }, [
                                _vm._v("Professores")
                              ]),
                              _c("v-tab", { key: "ambiences" }, [
                                _vm._v("Ambientes")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "groups" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "pa-0",
                                                attrs: {
                                                  "prepend-inner-icon":
                                                    "search",
                                                  "x-small": "",
                                                  placeholder: "Pesquisar",
                                                  rounded: "",
                                                  "single-line": "",
                                                  clearable: "",
                                                  autofocus: ""
                                                },
                                                model: {
                                                  value: _vm.searchGroup,
                                                  callback: function($$v) {
                                                    _vm.searchGroup = $$v
                                                  },
                                                  expression: "searchGroup"
                                                }
                                              }),
                                              _c(
                                                "emc-util-scroll",
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    height: "45vh"
                                                  }
                                                },
                                                [
                                                  _c("v-treeview", {
                                                    ref: "treeGroups",
                                                    attrs: {
                                                      items: _vm.itemsGroups,
                                                      "item-key": "key",
                                                      "item-name": "name",
                                                      "return-object": "",
                                                      "item-children":
                                                        "children",
                                                      selectable: "",
                                                      "open-all": false,
                                                      search: _vm.searchGroup
                                                    },
                                                    model: {
                                                      value: _vm.modelGroups,
                                                      callback: function($$v) {
                                                        _vm.modelGroups = $$v
                                                      },
                                                      expression: "modelGroups"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir professor no horário das turmas e ambientes",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showTeacher,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showTeacher",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showTeacher"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir Professor"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir aulas pendentes ainda não alocadas no horário",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showPending,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showPending",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showPending"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir Pendências"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir ambiente no horário das turmas e professores",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showAmbience,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showAmbience",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showAmbience"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir Ambiente"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir todas as disciplinas presenciais e à distância",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showAllGrids,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showAllGrids",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showAllGrids"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir Todas Disciplinas"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir os horários das atividades no horário do professor",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showActivities,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showActivities",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showActivities"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir Horário das Atividades"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Exibir subturmas das ofertas",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .showSubGroup,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "showSubGroup",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.showSubGroup"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Exibir subturmas"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-action",
                                                        [
                                                          _c("s-switch", {
                                                            attrs: {
                                                              inset: "",
                                                              "text-helper":
                                                                "Separar um curso por aba quando a impressão for por turma",
                                                              color: "purple"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.config
                                                                  .multipleSheets,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.config,
                                                                  "multipleSheets",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "config.multipleSheets"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Separar por Cursos"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "teachers" },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          attrs: { color: "primary" },
                                          model: {
                                            value: _vm.selectedTeacher,
                                            callback: function($$v) {
                                              _vm.selectedTeacher = $$v
                                            },
                                            expression: "selectedTeacher"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: { ripple: "" },
                                              on: {
                                                mousedown: function($event) {
                                                  $event.preventDefault()
                                                },
                                                click: _vm.toggle
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _vm.itemsTeachers.length
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              _vm
                                                                .selectedIndexTeachers
                                                                .length > 0
                                                                ? "indigo darken-4"
                                                                : ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(_vm.icon) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.itemsTeachers
                                                            .length > 0
                                                            ? "Selecionar todos"
                                                            : "Selecione as turmas para listar os professores"
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _vm.loadingTeachers
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "yellow darken-2"
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "emc-util-scroll",
                                            { attrs: { height: "45vh" } },
                                            _vm._l(_vm.itemsTeachers, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: index,
                                                  on: {
                                                    mousedown: function(
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                    },
                                                    click: function($event) {
                                                      return _vm.selectTeacher(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", {
                                                        attrs: {
                                                          color: _vm.isCheckedTeacher(
                                                            index
                                                          )
                                                            ? "indigo darken-4"
                                                            : ""
                                                        },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            _vm.isCheckedTeacher(
                                                              index
                                                            )
                                                              ? "mdi-check-box-outline"
                                                              : "mdi-checkbox-blank-outline"
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.name
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "ambiences" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "emc-util-scroll",
                                            {
                                              attrs: {
                                                outlined: "",
                                                height: "45vh"
                                              }
                                            },
                                            [
                                              _c(
                                                "sys-ambience-tree-view-select",
                                                {
                                                  attrs: {
                                                    active: _vm.modelAmbiences
                                                  },
                                                  on: {
                                                    "update:active": function(
                                                      $event
                                                    ) {
                                                      _vm.modelAmbiences = $event
                                                    }
                                                  }
                                                }
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _vm.tab === 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "success",
                                          disabled:
                                            !_vm.modelGroups ||
                                            _vm.modelGroups.length == 0
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.printTimeTable("group")
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v("Imprimir"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-microsoft-excel")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        478108381
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Imprimir horário das turmas selecionadas")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.tab === 1
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "success",
                                          disabled:
                                            _vm.selectedIndexTeachers.length ==
                                            0
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.printTimeTable(
                                              "teacher",
                                              false
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v("Imprimir"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-microsoft-excel")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4290293846
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Imprimir horário dos professores selecionadas")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.tab === 2
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "success",
                                          disabled:
                                            _vm.modelAmbiences.length == 0
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.printTimeTable(
                                              "ambience",
                                              false
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v("Imprimir"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-microsoft-excel")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        808679758
                      )
                    },
                    [
                      _c("span", [
                        _vm._v("Imprimir horário dos ambientes selecionadas")
                      ])
                    ]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.renderComponent
        ? _c("emc-loader-progress", { attrs: { show: _vm.progress } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }