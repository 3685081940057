var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            dark: "",
                            small: "",
                            color: "grey"
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Validação Geral")]),
                          _c("v-list-item-subtitle", [_vm._v("Horário")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.fav ? "red--text" : "",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.fav = !_vm.fav
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.menu = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_shock_time_table,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "allow_shock_time_table",
                                  $$v
                                )
                              },
                              expression: "model.allow_shock_time_table"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Choque de Horário")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_journey,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "allow_journey", $$v)
                              },
                              expression: "model.allow_journey"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Permitir Interjornada")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_intra_day,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "allow_intra_day", $$v)
                              },
                              expression: "model.allow_intra_day"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Permitir Intrajornada")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_max_hours_day,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "allow_max_hours_day", $$v)
                              },
                              expression: "model.allow_max_hours_day"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Máximo de Horas por dia")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_work_load,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "allow_work_load", $$v)
                              },
                              expression: "model.allow_work_load"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Não Validar CH Disponível")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.model.allow_work_load_activity,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "allow_work_load_activity",
                                  $$v
                                )
                              },
                              expression: "model.allow_work_load_activity"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Não Validar CH Registrada na Atividade")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-actions", [_c("v-spacer")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }