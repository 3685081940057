var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-modal-form", {
        attrs: { title: _vm.title, icon: _vm.icon, show: _vm.dialog },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c(
                  "v-expansion-panels",
                  _vm._l(_vm.logs, function(log, i) {
                    return _c(
                      "v-expansion-panel",
                      { key: i },
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            attrs: { "disable-icon-rotate": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "actions",
                                  fn: function() {
                                    return [
                                      log.items_amount !==
                                      log.errors_amount + log.success_amount
                                        ? _c(
                                            "v-icon",
                                            { attrs: { color: "orange" } },
                                            [_vm._v("mdi-sync")]
                                          )
                                        : _c(
                                            "v-icon",
                                            { attrs: { color: "success" } },
                                            [_vm._v("mdi-check")]
                                          )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "span",
                              [
                                log.origin == "excel"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-microsoft-excel")]
                                    )
                                  : _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("mdi-api")]
                                    ),
                                _vm._v(" " + _vm._s(log.created_at))
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c("v-progress-linear", {
                              attrs: {
                                value:
                                  (log.success_amount / log.items_amount) * 100,
                                height: "10",
                                color: "success",
                                "background-color": "red lighten-3"
                              }
                            }),
                            _c("br"),
                            _c(
                              "v-list",
                              { attrs: { "two-line": "", dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Total de itens ")
                                        ]),
                                        _c("v-list-item-subtitle", {
                                          domProps: {
                                            innerHTML: _vm._s(log.items_amount)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Total de itens com erro ")
                                        ]),
                                        _c("v-list-item-subtitle", {
                                          domProps: {
                                            innerHTML: _vm._s(log.errors_amount)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" Total de itens cadastrados ")
                                        ]),
                                        _c("v-list-item-subtitle", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              log.success_amount
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            log.errors
                              ? _c(
                                  "v-card",
                                  { staticClass: "mx-auto" },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          height: "40",
                                          color: "grey lighten-1",
                                          dark: ""
                                        }
                                      },
                                      [
                                        _c("v-toolbar-title", [_vm._v("Erros")])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list",
                                      { attrs: { "two-line": "", dense: "" } },
                                      [
                                        _vm._l(log.errors, function(error, i) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              { key: i },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            error.code ||
                                                              "Sem código"
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            error.name ||
                                                              "sem nome"
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c(
                                                      "v-list-item-subtitle",
                                                      [
                                                        _c("v-textarea", {
                                                          attrs: {
                                                            name: "error",
                                                            label: "Mensagem",
                                                            value:
                                                              error.message,
                                                            disabled: ""
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "max-width" },
                                  [
                                    _c("v-pagination", {
                                      staticClass: "my-2",
                                      attrs: {
                                        length: _vm.length,
                                        circle: "",
                                        disabled: _vm.loading
                                      },
                                      model: {
                                        value: _vm.page,
                                        callback: function($$v) {
                                          _vm.page = $$v
                                        },
                                        expression: "page"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("EmcLoaderProgress", {
                      attrs: { show: _vm.loading },
                      on: {
                        "update:show": function($event) {
                          _vm.loading = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }