var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _vm.grid && _vm.grid.name
                      ? _c("caption", [
                          _c("strong", [_vm._v(_vm._s(_vm.grid.name))])
                        ])
                      : _vm._e(),
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th"),
                          _vm._l(
                            _vm.days.filter(function(i) {
                              return i.visible
                            }),
                            function(day) {
                              return _c(
                                "th",
                                { key: day.id, staticClass: "text-center" },
                                [_vm._v(_vm._s(day.name))]
                              )
                            }
                          )
                        ],
                        2
                      )
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.hoursFiltered, function(hour) {
                        return _c(
                          "tr",
                          { key: hour.id },
                          [
                            _c("td", [
                              _vm._v(" " + _vm._s(hour.start) + " "),
                              _c("br"),
                              _vm._v(" " + _vm._s(hour.end) + " ")
                            ]),
                            _vm._l(
                              _vm.days.filter(function(i) {
                                return i.visible
                              }),
                              function(day) {
                                return _c(
                                  "td",
                                  { key: day.id },
                                  _vm._l(
                                    _vm.getGrids(hour.id, day.id),
                                    function(grid, i) {
                                      return _c("span", { key: i }, [
                                        _c(
                                          "p",
                                          { staticClass: "text-center ma-0" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDisciplineName(grid)
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "text-center ma-0" },
                                          [
                                            _vm._v(
                                              _vm._s(grid.work_load_type_name)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "text-center ma-0" },
                                          [
                                            _vm.renderComponent && grid.join_id
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "mr-2": "",
                                                      "x-small": "",
                                                      color: "indigo"
                                                    }
                                                  },
                                                  [_vm._v("mdi-call-split")]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(grid.group_name) +
                                                " "
                                            )
                                          ],
                                          1
                                        ),
                                        (!_vm.teacher || !_vm.teacher.id) &&
                                        grid.teacher_name
                                          ? _c(
                                              "p",
                                              {
                                                staticClass: "text-center ma-0"
                                              },
                                              [
                                                grid.in_hiring
                                                  ? _c("span", [
                                                      _vm._v("A Contratar")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          grid.teacher_name
                                                        )
                                                      )
                                                    ])
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    }
                                  ),
                                  0
                                )
                              }
                            )
                          ],
                          2
                        )
                      }),
                      0
                    ),
                    !_vm.hideJoins && _vm.gridListJoins.length > 0
                      ? _c("tfoot", { attrs: { "mt-2": "" } }, [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "7" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "mr-2": "",
                                      "x-small": "",
                                      color: "indigo"
                                    }
                                  },
                                  [_vm._v("mdi-call-split")]
                                ),
                                _c(
                                  "strong",
                                  { staticClass: "text-center ma-0" },
                                  [_vm._v("Disciplinas com Junção:")]
                                ),
                                _c(
                                  "ul",
                                  { attrs: { type: "none" } },
                                  _vm._l(_vm.gridListJoins, function(grid, i) {
                                    return _c("li", { key: i }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            grid.discipline_name +
                                              " (" +
                                              grid.work_load_type_initials +
                                              "): " +
                                              _vm.getGroupNameJoinLegend(grid)
                                          ) +
                                          " "
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }