var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-card-stats", {
    attrs: {
      color: "blue lighten-1",
      icon: "mdi-school-outline",
      "icon-large": "",
      title: this.title,
      value: this.teachers,
      "sub-text": "Número de professores ativos"
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [
            _c("emc-button-icon", {
              attrs: {
                "btn-small": "",
                loading: _vm.loading,
                icon: "mdi-refresh-circle",
                text: _vm.updated_at || "Atualizar",
                color: "success"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.refresh()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }