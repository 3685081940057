var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": "auto",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "50%",
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto elevation-0 overflow-y-auto",
          attrs: { outlined: "", height: "75vh", prominent: "" }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "mb-0 elevation-1",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                    _c("span", { staticClass: "mb-0" }, [
                      _vm._v(
                        " Extrato - (" +
                          _vm._s(_vm.selected.length) +
                          ") Professor(es) selecionado(s) "
                      ),
                      _vm.selected.length > _vm.maxSelected
                        ? _c("strong", { staticClass: "red--text" }, [
                            _vm._v(
                              "Máximo " +
                                _vm._s(_vm.maxSelected) +
                                " professores"
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("v-spacer"),
                  _c("emc-button-icon", {
                    attrs: {
                      color: "primary lighten-2",
                      icon: "mdi-filter-remove-outline",
                      text: "Limpar Seleção",
                      disabled: _vm.selected.length === 0
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearFilter()
                      }
                    }
                  }),
                  _c("emc-button-icon", {
                    attrs: {
                      color: "error darken-1",
                      icon: "mdi-file-pdf",
                      text: "Gerar Extratos",
                      disabled: _vm.disableButton
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.exportExtracts(false)
                      }
                    }
                  }),
                  _c("emc-button-icon", {
                    attrs: {
                      color: "purple",
                      icon: "mdi-folder-zip-outline",
                      text: "Gerar Extratos Zipado",
                      disabled: _vm.disableButton || _vm.selected.length < 2
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.exportExtracts(true)
                      }
                    }
                  }),
                  _c("emc-button-icon", {
                    attrs: {
                      color: "red lighten-2",
                      icon: "mdi-close",
                      text: "Fechar"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.dialog = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-2" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { color: "primary" },
                                  model: {
                                    value: _vm.selectedTeacher,
                                    callback: function($$v) {
                                      _vm.selectedTeacher = $$v
                                    },
                                    expression: "selectedTeacher"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { ripple: "" },
                                      on: {
                                        mousedown: function($event) {
                                          $event.preventDefault()
                                        },
                                        click: _vm.toggle
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _vm.items.length
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.selected.length > 0
                                                        ? "indigo darken-4"
                                                        : ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(_vm.icon) + " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(" Selecionar todos ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _vm.loading
                                    ? _c("v-progress-linear", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "yellow darken-2"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "emc-util-scroll",
                                    { attrs: { height: "45vh" } },
                                    _vm._l(_vm.items, function(item, index) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: index,
                                          on: {
                                            mousedown: function($event) {
                                              $event.preventDefault()
                                            },
                                            click: function($event) {
                                              return _vm.selectTeacher(index)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", {
                                                attrs: {
                                                  color: _vm.isCheckedTeacher(
                                                    index
                                                  )
                                                    ? "indigo darken-4"
                                                    : ""
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.isCheckedTeacher(index)
                                                      ? "mdi-check-box-outline"
                                                      : "mdi-checkbox-blank-outline"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                domProps: {
                                                  textContent: _vm._s(item.name)
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "11" } },
                        [
                          _c("v-pagination", {
                            staticClass: "my-4",
                            attrs: { length: _vm.totalPages },
                            on: {
                              input: function($event) {
                                return _vm.pagination()
                              }
                            },
                            model: {
                              value: _vm.page,
                              callback: function($$v) {
                                _vm.page = $$v
                              },
                              expression: "page"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }