var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            true
              ? _c(
                  "v-menu",
                  {
                    attrs: { "offset-x": "", "offset-y": "", bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        fab: "",
                                        color: "primary",
                                        "x-small": "",
                                        disabled: !_vm.range
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-code-tags")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1958832858
                    ),
                    model: {
                      value: _vm.viewMenu,
                      callback: function($$v) {
                        _vm.viewMenu = $$v
                      },
                      expression: "viewMenu"
                    }
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "emc-util-scroll",
                          { attrs: { maxHeight: "300px" } },
                          _vm._l(_vm.tags, function(tag, i) {
                            return _c(
                              "v-list-item",
                              { key: i, attrs: { link: "" } },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.setField(tag)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag.tag))]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição*",
                            counter: 255,
                            rows: "2",
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: ""
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            "persistent-placeholder": "",
                            error: !!_vm.errors.label,
                            "error-messages": _vm.errors.label,
                            placeholder: "Exemplo: Li e concordo",
                            label: "Rótulo",
                            counter: 50,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 50 ||
                                  "Máximo de 50 caracteres atingido"
                                )
                              }
                            ]
                          },
                          model: {
                            value: _vm.model.label,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "label", $$v)
                            },
                            expression: "model.label"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("span", [_vm._v("Conteúdo*")]),
                        _c("vue-editor", {
                          ref: "editor",
                          attrs: {
                            error: !!_vm.errors.content,
                            "error-messages": _vm.errors.content,
                            editorToolbar: _vm.customToolbar
                          },
                          on: {
                            "selection-change": function($event) {
                              _vm.range = $event
                            }
                          },
                          model: {
                            value: _vm.model.content,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "content", $$v)
                            },
                            expression: "model.content"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Obrigatório concordar para avançar no cadastro",
                            label: "Obrigatório"
                          },
                          model: {
                            value: _vm.model.is_required,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_required", $$v)
                            },
                            expression: "model.is_required"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Enviar e-mail para o usuário com cópia do termo",
                            label: "Enviar E-mail"
                          },
                          model: {
                            value: _vm.model.send_email,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "send_email", $$v)
                            },
                            expression: "model.send_email"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Salvar cópia do termo em PDF no Servidor",
                            label: "Salvar PDF"
                          },
                          model: {
                            value: _vm.model.save_pdf,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "save_pdf", $$v)
                            },
                            expression: "model.save_pdf"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-switch", {
                          attrs: { color: "purple", inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }