var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          items: _vm.itemsFiltered,
          "item-value": _vm.itemValue,
          "item-text": _vm.itemText,
          label: _vm.label,
          required: "",
          disabled: _vm.disabled,
          dense: _vm.dense,
          error: _vm.error,
          "error-messages": _vm.errors,
          clearable: _vm.clearable
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }