var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.model.id ? "Editar Curso" : "Cadastrar Curso",
      icon: "mdi-book-open-page-variant",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.name,
                            label: "Nome*",
                            required: ""
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.initials,
                            label: "Sigla"
                          },
                          model: {
                            value: _vm.model.initials,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "initials", $$v)
                            },
                            expression: "model.initials"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-occupation-area", {
                          attrs: {
                            model: _vm.model.occupation_area_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.occupation_area_id,
                            label: "Área de atuação",
                            "show-add": "",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "occupation_area_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-category", {
                          attrs: {
                            model: _vm.model.category_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.category_id,
                            label: "Categoria",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "category_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-course-level", {
                          attrs: {
                            model: _vm.model.course_level_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.course_level_id,
                            label: "Nível de Curso*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "course_level_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-course-modality", {
                          attrs: {
                            model: _vm.model.course_modality_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.course_modality_id,
                            label: "Modalidade de ensino*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "course_modality_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-course-degree", {
                          attrs: {
                            model: _vm.model.course_academic_degree_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.course_academic_degree_id,
                            label: "Grau acadêmico"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "course_academic_degree_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-qualifier", {
                          attrs: {
                            model: _vm.model.qualifier_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.qualifier_id,
                            label: "Qualificador",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "qualifier_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-cost-center", {
                          attrs: {
                            model: _vm.model.cost_center_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.cost_center_id,
                            label: "Centro de Custo",
                            clearable: "",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "cost_center_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }