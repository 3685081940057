var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-clock-outline",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.start,
                            errors: _vm.errors.start,
                            time: _vm.model.start,
                            label: "Início*"
                          },
                          on: {
                            onSelect: _vm.selectHour,
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("emc-calendar-time-picker", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.end,
                            errors: _vm.errors.end,
                            time: _vm.model.end,
                            label: "Fim*"
                          },
                          on: {
                            "update:time": function($event) {
                              return _vm.$set(_vm.model, "end", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shift_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shift_id,
                            label: "Turno*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shift_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "text-helper":
                              "Número de aulas semanais consideradas na elaboração do horário",
                            dense: "",
                            "error-messages": _vm.errors.number_of_classes,
                            label: "*Nº Aulas"
                          },
                          model: {
                            value: _vm.model.number_of_classes,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "number_of_classes", $$v)
                            },
                            expression: "model.number_of_classes"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "text-helper":
                              "Código para agrupar os horários e exibir como opção única de início e fim na tela de cadastro da disponibilide",
                            dense: "",
                            "error-messages": _vm.errors.group,
                            label: "Agrupamento"
                          },
                          model: {
                            value: _vm.model.group,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "group", $$v)
                            },
                            expression: "model.group"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-hour-group", {
                          attrs: {
                            model: _vm.model.groups,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.groups,
                            label: "Grupo de Horário*",
                            multiple: "",
                            required: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "groups", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Exibir horário como opção na tela de cadastro da disponibilidade",
                            inset: "",
                            label: "Disponibilidade"
                          },
                          model: {
                            value: _vm.model.to_availability,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "to_availability", $$v)
                            },
                            expression: "model.to_availability"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Considerar horário no momento da alocação automática de horário",
                            inset: "",
                            label: "Horário Dinâmico"
                          },
                          model: {
                            value: _vm.model.to_time_table,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "to_time_table", $$v)
                            },
                            expression: "model.to_time_table"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }