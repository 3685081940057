var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: _vm.persistent
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                { attrs: { dense: "" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c(
                    "v-toolbar-title",
                    { staticClass: "subtitle-1" },
                    [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                    2
                  ),
                  _c("v-spacer"),
                  _vm._t("tools"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-2" }, [_vm._t("text")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }