import { service } from '../../../../helpers/http'

export const ActionDoLoginTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'loginTeacher',
        payload: payload
    }).then(res => {
        
        let user = res.data.user;

        dispatch('ActionSetUser', user);
        dispatch('ActionSetToken', res.data.token);
        
        return res;
    });
    
}

export const ActionTeacherLogout = ({ dispatch }, payload ) => {

    return service({
        module: 'auth',
        action: 'logoutTeacher',
        payload: payload
    })

}

export const ActionAccessTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'accessTeacher',
        payload: payload
    })
    
}

export const ActionAuthTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'authTeacher',
        payload: payload
    })
    
}

export const ActionTokenTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'tokenTeacher',
        payload: payload
    })
    
}