import { default as hour } from './hour/services'
import { default as shift } from  './shift/services'
import { default as process } from './process/services'
import { default as integration } from './integration/services'
import { default as schedule } from './schedule/services'
import { default as item } from './item/services'

export default {
    ...hour,
    ...shift,
    ...process,
    ...integration,
    ...schedule,
    ...item
}