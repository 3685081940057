var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-app-bar",
        {
          staticClass: "elevation-0",
          attrs: { color: "grey lighten-4", app: "", fixed: "" }
        },
        [
          _c(
            "v-toolbar-title",
            [
              _c("v-img", {
                attrs: { src: "/img/sysclass_logo.png", "max-width": "150" }
              })
            ],
            1
          ),
          _c("v-spacer"),
          false && _vm.user.id
            ? _c(
                "v-btn",
                { attrs: { to: "faq-questions", icon: "" } },
                [
                  _c("v-icon", { attrs: { color: "indigo", large: "" } }, [
                    _vm._v("mdi-help-circle-outline")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.user.id
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-width": 100,
                    "offset-x": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { text: "" } }, on),
                              [
                                _vm.user.id
                                  ? _c("EmcBaseAvatar", {
                                      attrs: {
                                        size: 36,
                                        src: _vm.user.url_avatar,
                                        text: _vm.user.initials
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "v-icon",
                                  { attrs: { color: "grey darken-1" } },
                                  [_vm._v("keyboard_arrow_down")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1609194273
                  )
                },
                [
                  _c(
                    "v-card",
                    { attrs: { "max-width": 400 } },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { color: "grey" } },
                                    [
                                      _vm.user.id && _vm.user.url_avatar
                                        ? _c("img", {
                                            attrs: { src: _vm.user.url_avatar }
                                          })
                                        : _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("mdi-account-circle")]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.user.name))
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.user.email))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        [
                          _vm.acesso.instituicao && _vm.acesso.instituicao.id
                            ? _c(
                                "v-list-item",
                                {
                                  attrs: { to: "profile" },
                                  on: {
                                    click: function($event) {
                                      _vm.showMenu = !_vm.showMenu
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-account")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v("Meus Dados")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.sair()
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.exit
                                          ? "mdi-spin mdi-loading"
                                          : "exit_to_app"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Sair")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }