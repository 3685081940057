var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-1", attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-1",
                      attrs: { color: "grey lighten-3", height: "40" }
                    },
                    [_c("v-toolbar-title", [_vm._v("Telefones")])],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask-phone",
                                    rawName: "v-mask-phone.br",
                                    modifiers: { br: true }
                                  }
                                ],
                                attrs: {
                                  autofocus: "",
                                  "append-icon": "mdi-phone",
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.mobile_phone,
                                  label: "Celular 1"
                                },
                                model: {
                                  value: _vm.model.mobile_phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "mobile_phone", $$v)
                                  },
                                  expression: "model.mobile_phone"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-row"),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask-phone",
                                    rawName: "v-mask-phone.br",
                                    modifiers: { br: true }
                                  }
                                ],
                                attrs: {
                                  "append-icon": "mdi-phone",
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.mobile_phone2,
                                  label: "Celular 2"
                                },
                                model: {
                                  value: _vm.model.mobile_phone2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "mobile_phone2", $$v)
                                  },
                                  expression: "model.mobile_phone2"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-1", attrs: { tile: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-1",
                      attrs: { color: "grey lighten-3", height: "40" }
                    },
                    [_c("v-toolbar-title", [_vm._v("Meus Links")])],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  clearable: "",
                                  "append-icon": "mdi-linkedin",
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.url_linkedin,
                                  label: "Linkedin"
                                },
                                model: {
                                  value: _vm.model.url_linkedin,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "url_linkedin", $$v)
                                  },
                                  expression: "model.url_linkedin"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  clearable: "",
                                  "append-icon": "mdi-school-outline",
                                  dense: "",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.url_lattes,
                                  label: "Currículo Lattes"
                                },
                                model: {
                                  value: _vm.model.url_lattes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "url_lattes", $$v)
                                  },
                                  expression: "model.url_lattes"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "grey lighten-2", height: "40" } },
                    [_c("v-toolbar-title", [_vm._v("Emails")])],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        ref: "addmail",
                        attrs: {
                          type: "email",
                          placeholder: "Adicionar e-mail",
                          error: _vm.errors.length > 0,
                          "error-messages": _vm.errors.emails
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-outer",
                            fn: function() {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "green white--text",
                                      disabled: !_vm.mail,
                                      fab: "",
                                      "x-small": ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addMail()
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-plus")])],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.mail,
                          callback: function($$v) {
                            _vm.mail = $$v
                          },
                          expression: "mail"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.emails, function(email, i) {
                          return _c(
                            "v-list-item",
                            { key: i },
                            [
                              !email.allow_editing
                                ? _c("emc-info-help", {
                                    attrs: {
                                      title: "E-mail Institucional",
                                      text: "Não é permitido edição!"
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      error: _vm.errors.length > 0,
                                      "error-messages":
                                        _vm.errors["emails." + i + ".email"],
                                      dense: "",
                                      disabled: !email.allow_editing
                                    },
                                    model: {
                                      value: email.email,
                                      callback: function($$v) {
                                        _vm.$set(email, "email", $$v)
                                      },
                                      expression: "email.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          disabled: !email.allow_editing,
                                                          color: "red",
                                                          icon: "",
                                                          "x-small": ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeMail(
                                                              email,
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-delete")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("Excluir email?")])]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  loading: _vm.loading,
                                  color: "primary",
                                  dark: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.save()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("save")
                              ]),
                              _vm._v(" Salvar ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Salvar")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }