<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="70%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <sys-select-course-level
                                :model.sync="model.course_level_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_level_id"
                                label="Nível de Curso*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-campus
                                :model.sync="model.campus_id"
                                :error="errors.length > 0"  
                                :errors="errors.campus_id"
                                label="Campus*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <sys-select-value-type
                                :model.sync="model.value_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.value_type_id"
                                label="Tipo de Valor*"
                                dense
                            /> 
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-budget-type
                                :model.sync="model.budget_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.budget_type_id"
                                label="Tipo de Orçamento*"
                                showAdd
                                dense
                            /> 
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.year"
                                :error="!!errors.year" 
                                :error-messages="errors.year" 
                                label="Ano*"
                                dense
                                disabled
                                />
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="12" sm="3">
                            <sys-select-occupation-area
                                :model.sync="model.occupation_area_id"
                                :error="errors.length > 0"  
                                :errors="errors.occupation_area_id"
                                label="Área"
                                clearable
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-course
                                :model.sync="model.course_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_id"
                                label="Curso"
                                clearable
                                dense
                                autoComplete
                            />
                        </v-col>
                    
                        <v-col cols="12" sm="3">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno"
                                clearable
                                dense
                            />
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="12" sm="2" :key="i" v-for="(month, i) in months.filter(m => m.show)">
                            <v-text-field 
                                dense 
                                :error="!!errors[month['month']]" 
                                :error-messages="errors[month['month']]" 
                                v-model="model[month['month']]"
                                outlined
                                :label="month['label']"
                                />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código" />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-switch inset v-model="model.digital" label="Digital"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="model.description" 
                                label="Descrição"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                            />
                        </v-col>
                    </v-row>

            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'
export default {
    name: "SysBudgetRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        workTime: state.acesso.periodo,
        months: [
            { month: 'january',     label: 'Janeiro',   show: true },
            { month: 'february',    label: 'Fevereiro', show: true },
            { month: 'march',       label: 'Março',     show: true },
            { month: 'april',       label: 'Abril',     show: true },
            { month: 'may',         label: 'Maio',      show: true },
            { month: 'june',        label: 'Junho',     show: true },
            { month: 'july',        label: 'Julho',     show: true },
            { month: 'august',      label: 'Agosto',    show: true },
            { month: 'september',   label: 'Setembro',  show: true },
            { month: 'october',     label: 'Outubro',   show: true },
            { month: 'november',    label: 'Novembro',  show: true },
            { month: 'december',    label: 'Dezembro',  show: true },
        ]
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    
                    this.model.year                     = this.workTime.year;
                    this.model.value_type_id            = 1;
                    this.model.january                  = 0;
                    this.model.february                 = 0;
                    this.model.march                    = 0;
                    this.model.april                    = 0;
                    this.model.may                      = 0;
                    this.model.june                     = 0;
                    this.model.july                     = 0;
                    this.model.august                   = 0;
                    this.model.september                = 0;
                    this.model.october                  = 0;
                    this.model.november                 = 0;
                    this.model.december                 = 0;
                }
                
            }

            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-cash-usd-outline"
        },
        title: {
            type: String,
            default: "Orçamento"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        }
    },
    methods:{
        ...mapActions('budget', ['ActionUpdateBudget', 'ActionCreateBudget']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            if(edit) {
                this.ActionUpdateBudget(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateBudget(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>