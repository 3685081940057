import { service } from '../../../../helpers/http'

export const ActionIndexPartnerCompanies = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'indexPartnerCompanies',
        payload: payload
    });
}

export const ActionCreatePartnerCompany = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'createPartnerCompany',
        payload: payload
    });
}

export const ActionUpdatePartnerCompany = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'updatePartnerCompany',
        payload: payload
    });
}

export const ActionDestroyPartnerCompany = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'destroyPartnerCompany',
        payload: payload
    });
}
