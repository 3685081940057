var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loadingSave,
      model: _vm.model,
      title: _vm.event.id ? _vm.event.name : "Novo Agendamento",
      maxWidth: "50%",
      icon: "mdi-calendar-plus",
      show: _vm.dialog,
      "text-button-save": "Salvar"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Agendamento")]),
                _c("v-tab", { key: "resources" }, [_vm._v("Recursos")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _vm.error &&
                        _vm.message &&
                        _vm.message.includes("Choque de Horário em")
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c(
                                      "v-alert",
                                      { attrs: { type: "error" } },
                                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "error-messages": _vm.errors.title,
                                    label: "Título*",
                                    autofocus: ""
                                  },
                                  model: {
                                    value: _vm.event.title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.event, "title", $$v)
                                    },
                                    expression: "event.title"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "5" } },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "" },
                                    model: {
                                      value: _vm.type,
                                      callback: function($$v) {
                                        _vm.type = $$v
                                      },
                                      expression: "type"
                                    }
                                  },
                                  [
                                    _vm.type == 1
                                      ? _c("v-radio", {
                                          attrs: {
                                            color: "success",
                                            label: "Aula",
                                            value: 1
                                          }
                                        })
                                      : _vm._e(),
                                    _c("v-radio", {
                                      attrs: {
                                        color: "success",
                                        label: "Interno",
                                        value: 2
                                      }
                                    }),
                                    _c("v-radio", {
                                      attrs: {
                                        color: "primary",
                                        label: "Externo",
                                        value: 3
                                      }
                                    }),
                                    _vm.type == 4
                                      ? _c("v-radio", {
                                          attrs: {
                                            color: "primary",
                                            label: "Externo - OnLine",
                                            value: 4
                                          }
                                        })
                                      : _vm._e(),
                                    _c("emc-info-help", {
                                      attrs: { title: "Informações" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "text",
                                          fn: function() {
                                            return [
                                              _c("strong", [
                                                _vm._v("Agendamento Interno")
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  "Agendamento realizado para um usuário da organização e o organizador possui cadastro no SysClass"
                                                )
                                              ]),
                                              _c("strong", [
                                                _vm._v("Agendamento Externo")
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  "Agendamento realizado para um usuário fora da organização ou o organizador não é usuário do SysClass"
                                                )
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _vm.type == 2
                                  ? _c("sys-select-user", {
                                      attrs: {
                                        model: _vm.event.organizer_user_id,
                                        data: _vm.userOrganizer,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.organizer_user_id,
                                        label: "Organizador*",
                                        autoComplete: "",
                                        dense: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.event,
                                            "organizer_user_id",
                                            $event
                                          )
                                        },
                                        "update:data": function($event) {
                                          _vm.userOrganizer = $event
                                        }
                                      }
                                    })
                                  : _vm.type == 3 || _vm.type == 4
                                  ? _c("s-text-field", {
                                      attrs: {
                                        "error-messages": _vm.errors.organizer,
                                        label: "Organizador*",
                                        autofocus: "",
                                        dense: ""
                                      },
                                      model: {
                                        value: _vm.event.organizer,
                                        callback: function($$v) {
                                          _vm.$set(_vm.event, "organizer", $$v)
                                        },
                                        expression: "event.organizer"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    name: "input-7-4",
                                    label: "Detalhes",
                                    "auto-grow": false,
                                    value: _vm.event.details,
                                    rows: "2"
                                  },
                                  model: {
                                    value: _vm.event.details,
                                    callback: function($$v) {
                                      _vm.$set(_vm.event, "details", $$v)
                                    },
                                    expression: "event.details"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model: _vm.startDate,
                                    label: "Data Início*",
                                    errorMessages: _vm.errors.start_date,
                                    show: _vm.dialog,
                                    min: _vm.acesso.periodo.start,
                                    max: _vm.acesso.periodo.end
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.startDate = $event
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("emc-calendar-date-picker2", {
                                  attrs: {
                                    model: _vm.endDate,
                                    label: "Data Fim*",
                                    errorMessages: _vm.errors.end_date,
                                    show: _vm.dialog,
                                    min: _vm.acesso.periodo.start,
                                    max: _vm.acesso.periodo.end
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.endDate = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-time-picker", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.hour_start,
                                    errors: _vm.errors.hour_start,
                                    time: _vm.event.hour_start,
                                    label: "Hora Início*"
                                  },
                                  on: {
                                    "update:time": function($event) {
                                      return _vm.$set(
                                        _vm.event,
                                        "hour_start",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("emc-calendar-time-picker", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.hour_end,
                                    errors: _vm.errors.hour_end,
                                    time: _vm.event.hour_end,
                                    label: "Hora Fim*"
                                  },
                                  on: {
                                    "update:time": function($event) {
                                      return _vm.$set(
                                        _vm.event,
                                        "hour_end",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.showRecurrence
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        "item-value": "id",
                                        "item-text": "name",
                                        items: _vm.days.filter(function(d) {
                                          return d.visible
                                        }),
                                        label: "Recorrência",
                                        dense: "",
                                        multiple: ""
                                      },
                                      model: {
                                        value: _vm.event.daysOfWeek,
                                        callback: function($$v) {
                                          _vm.$set(_vm.event, "daysOfWeek", $$v)
                                        },
                                        expression: "event.daysOfWeek"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "resources" },
                  [
                    _c("sys-ambience-allocation-resources", {
                      attrs: {
                        "ambience-id": _vm.ambienceId,
                        model: _vm.event,
                        errors: _vm.errors
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-modal", {
              attrs: {
                title: "Exclusão",
                text: "Deseja excluir o evento?",
                show: _vm.showDelete
              },
              on: {
                "update:show": function($event) {
                  _vm.showDelete = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.loadingSave || _vm.loadingDelete,
                            loading: _vm.loadingDelete,
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.deleteItem()
                            }
                          }
                        },
                        [
                          _vm._v("Excluir "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }