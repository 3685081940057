<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <sys-select-big-occupation-area
                                :model.sync="innerModel.big_occupation_area_id"
                                :error="errors.length > 0"  
                                :errors="errors.big_occupation_area_id"
                                label="Grande Área*"
                               
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-occupation-area
                                :model.sync="innerModel.occupation_area_id"
                                :error="errors.length > 0"  
                                :errors="errors.occupation_area_id"
                                :big-occupation-area-id="innerModel.big_occupation_area_id"
                                label="Área*"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                                <sys-select-discipline
                                    :model.sync="innerModel.discipline_id"
                                    :data.sync="innerModel.discipline"
                                    :error="errors.discipline_id && errors.length > 0"  
                                    :errors="errors.discipline_id"
                                    label="Unidade Curricular*"
                                    item-text="full_name"
                                    auto-complete
                                    filterOfferItems
                                    :occupationAreaId="innerModel.occupation_area_id"
                                    @change="changeInputs($event, 'discipline')"
                                />
                            </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-partner-company
                                :model.sync="innerModel.companies"
                                :error="errors.length > 0"  
                                :errors="errors.companies"
                                label="Empresas*"
                                multiple
                                auto-complete
                                @input="changeInputs($event, 'companies')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-text-field inset :error-messages="errors.name" v-model="innerModel.name" label="Nome*"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <s-switch text-helper="Se a parceria for local, obrigatório informar marca e campus" inset v-model="innerModel.is_local" label="Local" />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <sys-select-brand
                                :model.sync="innerModel.brand_id"
                                :error="errors.length > 0"  
                                :errors="errors.brand_id"
                                label="Marca"
                                clearable
                                :disabled="!innerModel.is_local"
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <sys-select-campus
                                :model.sync="innerModel.campus_id"
                                :error="errors.length > 0"  
                                :errors="errors.campus_id"
                                :brand-id="innerModel.brand_id"
                                label="Campus"
                                clearable
                                :disabled="!innerModel.is_local"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-user 
                                :model.sync="innerModel.user_id_focal"
                                :data.sync="innerModel.user"
                                :error="errors.length > 0"
                                :errors="errors.user_id_focal"
                                label="Usuário Focal"
                                autoComplete
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <s-text-field inset code :error-messages="errors.code" v-model="innerModel.code" label="Código"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-switch text-helper="Cases / Desafios reais, sem interação síncrona com a empresa" inset v-model="innerModel.timeless" label="Atemporal" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-switch inset v-model="innerModel.active" label="Parceria Ativa" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="innerModel.description" 
                                label="Descrição"
                                :counter="255"
                                rows="2"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                clearable
                            />
                        </v-col>
                    </v-row>
                   
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysGridDisciplinePortfolioRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        disciplineSelected: null,
        innerModel: {},
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.innerModel = {};
                Object.assign(this.innerModel, this.model);

                if(!this.innerModel.id){
                    this.innerModel.active = 1;
                }else{
                    this.disciplineSelected = this.innerModel.discipline;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Cadastro"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('grid', ['ActionUpdateDisciplinePortfolio', 'ActionStoreDisciplinePortfolio']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateDisciplinePortfolio(this.innerModel)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionStoreDisciplinePortfolio(this.innerModel)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        changeInputs(ev, type){

            
            let discipline = null;
            let companies = [];
            let text = '';
            
            if(type == 'discipline'){
                discipline = ev;
                this.disciplineSelected = discipline;
                companies = this.innerModel.companies;
            }else{
                discipline = this.disciplineSelected;
                companies = ev;
            }
            
            if(discipline){
                text = discipline.name;
            }

            if(companies && companies.length > 0){
                
                if(text != ''){
                    text += ' | ';
                }
                
                text += companies.map(c => c.name).join(', ');
            }

            this.innerModel.name = text;

        },

    }
}
</script>