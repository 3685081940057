var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "emc-card-stats",
    {
      attrs: {
        icon: _vm.data.icon,
        color: _vm.data.color,
        subtitle: null,
        classCard: "mt-0",
        classSheet: "mt-0",
        "sub-text": _vm.data.title,
        tools: "",
        "icon-large": ""
      }
    },
    [
      !_vm.hideTools
        ? _c(
            "template",
            { slot: "tools" },
            [
              _c("emc-button-icon", {
                attrs: {
                  loading: _vm.loadingRefresh,
                  color: "success",
                  icon: "mdi-refresh",
                  text: "Atualizar"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.$emit("refresh", _vm.data.index)
                  }
                }
              }),
              _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-filter-outline",
                  text: _vm.data.toolTipFilter
                    ? _vm.data.toolTipFilter
                    : "Filtrar"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.$emit("filterChart", _vm.data.title)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.data && _vm.data.data
            ? _c(
                "v-progress-circular",
                {
                  attrs: {
                    rotate: 360,
                    size: 140,
                    width: 15,
                    value: _vm.data.data.percent,
                    color: "blue accent-1"
                  }
                },
                [
                  [
                    _c("span", [
                      _c("span", [_vm._v(_vm._s(_vm.data.data.percent) + "%")]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.data.data.completed) +
                            " / " +
                            _vm._s(_vm.data.data.total)
                        )
                      ])
                    ])
                  ]
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }