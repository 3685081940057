var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.model.id ? "Editar Instituição" : "Cadastrar Instituição",
      icon: "mdi-city",
      show: _vm.dialog,
      maxWidth: "70%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _c("sys-institution-pop-over", {
              attrs: { model: _vm.model, errors: _vm.errors }
            })
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                false && _vm.model && _vm.model.id
                  ? _c("v-tab", { key: "extract-setting" }, [_vm._v("PIT")])
                  : _vm._e(),
                _vm.model && _vm.model.id
                  ? _c("v-tab", { key: "address" }, [_vm._v("Endereço")])
                  : _vm._e(),
                _vm.model && _vm.model.id
                  ? _c("v-tab", { key: "integration" }, [_vm._v("Integração")])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-brand", {
                                  attrs: {
                                    model: _vm.model.brand_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.brand_id,
                                    label: "Marca*",
                                    "auto-complete": "",
                                    "show-all": "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "brand_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.initials,
                                    label: "Sigla*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.initials,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "initials", $$v)
                                    },
                                    expression: "model.initials"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    code: "",
                                    dense: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.model.id
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("emc-avatar-institution", {
                                      attrs: {
                                        width: "80",
                                        height: "80",
                                        model: _vm.model
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "8" } },
                              [
                                _c("sys-select-company", {
                                  attrs: {
                                    model: _vm.model.company_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.company_id,
                                    label: "Empresa",
                                    clearable: "",
                                    dense: "",
                                    autoComplete: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "company_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.company_name,
                                    label: "Razão Social",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.company_name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "company_name", $$v)
                                    },
                                    expression: "model.company_name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask-cnpj",
                                      rawName: "v-mask-cnpj"
                                    }
                                  ],
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.cnpj,
                                    label: "Cnpj",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.cnpj,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "cnpj", $$v)
                                    },
                                    expression: "model.cnpj"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Professores com vínculo poderão ser atribuídos em qualquer unidade",
                                    inset: "",
                                    label: "Permitir Atribuições"
                                  },
                                  model: {
                                    value: _vm.model.allow_all_attributions,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "allow_all_attributions",
                                        $$v
                                      )
                                    },
                                    expression: "model.allow_all_attributions"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-switch", {
                                  attrs: { inset: "", label: "Ativo" },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.model.id
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { outlined: "" } },
                                      [
                                        _c(
                                          "v-app-bar",
                                          {
                                            staticClass: "elevation-0",
                                            attrs: {
                                              color:
                                                "grey lighten-3 grey--text",
                                              dense: ""
                                            }
                                          },
                                          [
                                            _c("v-toolbar-title", [
                                              _vm._v("Campus")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "2"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .campus_code,
                                                        label: "Código"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.campus_code,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "campus_code",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.campus_code"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "3"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .campus_initials,
                                                        label: "Sigla*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .campus_initials,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "campus_initials",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.campus_initials"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "7"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .campus_name,
                                                        label: "Nome*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model.campus_name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "campus_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.campus_name"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "7"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .campus_abbreviation,
                                                        label: "Abreviação*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .campus_abbreviation,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "campus_abbreviation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.campus_abbreviation"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-container",
                                  [
                                    _c(
                                      "v-card",
                                      { attrs: { outlined: "" } },
                                      [
                                        _c(
                                          "v-app-bar",
                                          {
                                            staticClass: "elevation-0",
                                            attrs: {
                                              color:
                                                "grey lighten-3 grey--text",
                                              dense: ""
                                            }
                                          },
                                          [
                                            _c("v-toolbar-title", [
                                              _vm._v("Período Letivo")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .worktime_code,
                                                        label: "Código"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .worktime_code,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "worktime_code",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.worktime_code"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .worktime_year,
                                                        label: "Ano*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .worktime_year,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "worktime_year",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.worktime_year"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .worktime_period,
                                                        label: "Período*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .worktime_period,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "worktime_period",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.worktime_period"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "8"
                                                    }
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        error:
                                                          _vm.errors.length > 0,
                                                        "error-messages":
                                                          _vm.errors
                                                            .worktime_name,
                                                        label: "Descrição*",
                                                        required: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.model
                                                            .worktime_name,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.model,
                                                            "worktime_name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "model.worktime_name"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4"
                                                    }
                                                  },
                                                  [
                                                    _c("sys-select-frequency", {
                                                      attrs: {
                                                        model:
                                                          _vm.model
                                                            .worktime_frequency_id,
                                                        error:
                                                          _vm.errors.length > 0,
                                                        errors:
                                                          _vm.errors
                                                            .worktime_frequency_id,
                                                        label: "Regime*",
                                                        dense: ""
                                                      },
                                                      on: {
                                                        "update:model": function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.model,
                                                            "worktime_frequency_id",
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                false
                  ? _c(
                      "v-tab-item",
                      { key: "extract-setting" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "emc-util-scroll",
                              { attrs: { maxHeight: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _vm.model.teacherExtractSetting
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", sm: "12" } },
                                          [
                                            _c("s-text-field", {
                                              attrs: {
                                                placeholder:
                                                  "Plano Individual de Trabalho",
                                                "text-helper":
                                                  "Cabeçalho do Extrato",
                                                dense: "",
                                                "error-messages":
                                                  _vm.errors.title,
                                                label: "Título"
                                              },
                                              model: {
                                                value:
                                                  _vm.model
                                                    .teacherExtractSetting
                                                    .title,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.model
                                                      .teacherExtractSetting,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "model.teacherExtractSetting.title"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-tab-item",
                  { key: "address" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-address-register", {
                                  attrs: {
                                    model: _vm.model,
                                    type: "institution"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.model = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "integration" },
                  [
                    _c(
                      "v-container",
                      [
                        _c("sysInstitutionIntegration", {
                          attrs: { institution: _vm.model }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }