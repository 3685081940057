import { default as specificity } from './specificity/routes'
import { default as dateMask } from './date_mask/routes'
import { default as dateMaskType } from './date_mask_type/routes'
import { default as modality } from './modality/routes'
import { default as offer } from './offer/routes'
import { default as offer_analitics } from './offer_analitics/routes'
import { default as offerThemeCore } from './offer_theme_core/routes'
import { default as offerEmphasis } from './offer_emphasis/routes'
import { default as disciplinePortfolio } from './discipline_portfolio/routes'
import { default as offerProtocol } from './offer_protocol/routes'

export default [
    ...offer,
    ...offer_analitics,
    ...specificity,
    ...dateMask,
    ...dateMaskType,
    ...modality,
    ...offerThemeCore,
    ...offerEmphasis,
    ...disciplinePortfolio,
    ...offerProtocol,
    {
        path: '/grids',
        name: 'grids',
        component: () => import ('./pages/Index')
    },
    {
        path: '/grid-categories',
        name: 'grid-categories',
        component: () => import ('./pages/GridCategoryRegister')
    },
]