import { service } from '../../../../helpers/http'

export const ActionIndexDisciplinePortfolios = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexDisciplinePortfolios',
        payload: payload
    })

}

export const ActionShowDisciplinePortfolios = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'showDisciplinePortfolios',
        payload: payload
    })

}

export const ActionDestroyDisciplinePortfolio = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyDisciplinePortfolio',
        payload: payload
    })
}
export const ActionStoreDisciplinePortfolio = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeDisciplinePortfolio',
        payload: payload
    })

}
export const ActionUpdateDisciplinePortfolio = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateDisciplinePortfolio',
        payload: payload
    })
}

export const ActionIndexOfferItemPortfolios = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemPortfolios',
        payload: payload
    })

}

export const ActionIndexOfferItemOpportunities = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemOpportunities',
        payload: payload
    })

}
