export default {
    findCourses:        { method: 'get', url: 'courses' },
    courseSearch:       { method: 'get', url: 'courses/search'}, 
    deleteCourse:       { method: 'delete', url: 'courses/{id}'},
    updateCourse:       { method: 'put', url: 'courses/{id}'},
    createCourse:       { method: 'post', url: 'courses'},
    findModalities:     { method: 'get', url: 'courses/modalities'},
    findDegrees:        { method: 'get', url: 'courses/degrees'}, 
    index:              { method: 'get', url: 'courses' },
    findQualifiers:     { method: 'get', url: 'qualifiers' },
    createQualifier:    { method: 'post', url: 'qualifiers' },
    updateQualifier:    { method: 'put', url: 'qualifiers/{id}' },
    deleteQualifier:    { method: 'delete', url: 'qualifiers/{id}' },
}