import { service } from '../../../../helpers/http'

export const ActionIndexDynamicAllocations = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicAllocations',
        payload: payload
    })
    
}

export const ActionCreateDynamicAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'createDynamicAllocation',
        payload: payload
    })
    
}

export const ActionUpdateDynamicAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateDynamicAllocation',
        payload: payload
    })
}

export const ActionDeleteDynamicAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'deleteDynamicAllocation',
        payload: payload
    })
    
}

export const ActionDynamicAllocationGenerate = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'generateDynamicAllocation',
        payload: payload
    })
}

export const ActionDynamicAllocationSync = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'syncDynamicAllocation',
        payload: payload
    })
}

export const ActionIndexDynamicAllocationTypes = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicAllocationTypes',
        payload: payload
    })
    
}

export const ActionDynamicAllocationViewResults = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'viewResultsDynamicAllocation',
        payload: payload,
        unformatResponse: true
    })
}

