<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-book-open-page-variant" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
        textButtonSave="Gerar Diários"
    >
        <template v-slot:form>

            <!-- <div id="map"></div> -->
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <sys-select-campus
                                :model.sync="model.campuses"
                                :error="errors.length > 0"  
                                :errors="errors.campuses"
                                label="Campus*"
                                multiple
                                only-active
                                select-all
                                autofocus
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-shift
                                :model.sync="model.shifts"
                                :error="errors.length > 0"  
                                :errors="errors.shifts"
                                label="Turno*"
                                multiple
                                only-active
                                select-all
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-occurrence-sheet-type
                                :model.sync="model.types"
                                :error="errors.length > 0"  
                                :errors="errors.types"
                                label="Tipo*"
                                dense
                                multiple
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field 
                                v-model="model.year" 
                                :error="errors.length > 0" 
                                :error-messages="errors.year" 
                                label="Ano*"
                                disabled
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-month 
                                :model.sync="model.month"
                                dense
                                :error="errors.length > 0" 
                                :errors="errors.month"
                                label="Mês*"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model.teacher"
                                :errors="errors.teacher_id"
                                label="Professor (Opcional - Deixar vazio para gerar de todos)"
                                autoComplete
                                clearable
                            />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
       
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysOccurrenceSheetAllRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        model: {},
    }),
    created(){
        
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.model = {};

                this.model.year         = this.acesso.periodo.year;
                this.model.types        = [{ id: 1, name: 'Aula'}];
                //this.model.date_start   = this.acesso.periodo.classes_start;
                //this.model.date_end     = this.acesso.periodo.classes_end;
                
                
            }
        },
        
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Diário de Aulas"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('timeSheet', ['ActionStoreOccurrenceSheetAll']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;
            var payload = {};

            Object.assign(payload, this.model);
            payload.campuses = this.model.campuses ? this.model.campuses.map(c => ({ id: c.id })) : [];
            payload.shifts = this.model.shifts ? this.model.shifts.map(c => ({ id: c.id })) : [];

            this.ActionStoreOccurrenceSheetAll(payload)
            .then((res) => {
                this.message = res.message;
                this.$emit('onCompleted', res.data);
            })
            .catch((error) =>{
                this.error      = error;
                this.errors     = error.errors;
                this.message    = error.message;
            })
            .finally(() => {
                this.loading = false;
            })
            

            
        },

    }
}
</script>