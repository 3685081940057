var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      disabledSave: _vm.disabled,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "100%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _vm.loading
                  ? _c("v-progress-linear", {
                      attrs: { indeterminate: "", color: "yellow darken-2" }
                    })
                  : _vm._e(),
                _c("v-data-iterator", {
                  attrs: {
                    items: _vm.filteredItems,
                    "sort-by": "course_name",
                    "sort-desc": _vm.sortDesc,
                    "items-per-page": _vm.itemsPerPage,
                    "hide-default-footer": "",
                    "no-data-text": ""
                  },
                  on: {
                    "update:itemsPerPage": function($event) {
                      _vm.itemsPerPage = $event
                    },
                    "update:items-per-page": function($event) {
                      _vm.itemsPerPage = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "mb-1",
                              attrs: { dark: "", color: "blue darken-3" }
                            },
                            [
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.model.campuses,
                                        flat: "",
                                        "solo-inverted": "",
                                        "return-object": "",
                                        "item-text": "name",
                                        "hide-details": "",
                                        "prepend-inner-icon":
                                          "mdi-filter-menu-outline",
                                        label: "Campus"
                                      },
                                      on: { change: _vm.changeCampus },
                                      model: {
                                        value: _vm.selectedCampus,
                                        callback: function($$v) {
                                          _vm.selectedCampus = $$v
                                        },
                                        expression: "selectedCampus"
                                      }
                                    }),
                                    _c("v-spacer"),
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.dynamic.shifts,
                                        loading: _vm.loading,
                                        "return-object": "",
                                        flat: "",
                                        "solo-inverted": "",
                                        "item-text": "name",
                                        "hide-details": "",
                                        "prepend-inner-icon":
                                          "mdi-filter-menu-outline",
                                        label: "Turno",
                                        clearable: "",
                                        disabled: _vm.selectedCampus == null
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.selectedCourse = null
                                          _vm.loadData(null)
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedShift,
                                        callback: function($$v) {
                                          _vm.selectedShift = $$v
                                        },
                                        expression: "selectedShift"
                                      }
                                    }),
                                    _c("v-spacer"),
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.filteredCourses,
                                        loading: _vm.loading,
                                        "return-object": "",
                                        flat: "",
                                        "solo-inverted": "",
                                        "item-text": "name",
                                        "hide-details": "",
                                        "prepend-inner-icon":
                                          "mdi-filter-menu-outline",
                                        label: "Curso",
                                        clearable: "",
                                        disabled:
                                          _vm.selectedCampus == null ||
                                          _vm.selectedShift == null
                                      },
                                      on: { change: _vm.loadData },
                                      model: {
                                        value: _vm.selectedCourse,
                                        callback: function($$v) {
                                          _vm.selectedCourse = $$v
                                        },
                                        expression: "selectedCourse"
                                      }
                                    }),
                                    _c("v-spacer")
                                  ]
                                : _vm._e(),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "white",
                                    disabled: _vm.filteredItems.length == 0
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showList = !_vm.showList
                                      _vm.expandeds = []
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showList
                                          ? _vm.iconOpen.closed.icon
                                          : _vm.iconOpen.open.icon
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "emc-util-scroll",
                            { attrs: { height: "60vh" } },
                            [
                              _c(
                                "v-row",
                                { staticClass: "mt-2" },
                                _vm._l(props.items, function(item, index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: index,
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "4",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "subheading font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.group.name) +
                                                  " "
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 mr-1"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getTextTotal(item)
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    loading:
                                                      _vm.loadingAll &&
                                                      _vm.selectedItem &&
                                                      _vm.selectedItem.key ==
                                                        item.key,
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.acceptedAll(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          _vm.icons[
                                                            _vm.getIndexIcon(
                                                              item
                                                            )
                                                          ].color
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.icons[
                                                            _vm.getIndexIcon(
                                                              item
                                                            )
                                                          ].icon
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    disabled: _vm.showList,
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.expand(
                                                        item.key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: _vm.expandeds.includes(
                                                          item.key
                                                        )
                                                          ? _vm.iconOpen.closed
                                                              .color
                                                          : _vm.iconOpen.open
                                                              .color
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.expandeds.includes(
                                                            item.key
                                                          )
                                                            ? _vm.iconOpen
                                                                .closed.icon
                                                            : _vm.iconOpen.open
                                                                .icon
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _vm.renderComponent &&
                                          (_vm.showList ||
                                            _vm.expandeds.includes(item.key))
                                            ? _c(
                                                "v-list",
                                                { attrs: { "two-line": "" } },
                                                [
                                                  _c(
                                                    "v-list-item-group",
                                                    {
                                                      attrs: {
                                                        multiple: "",
                                                        "active-class":
                                                          "primary--text"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedIndex[
                                                            index
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedIndex,
                                                            index,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedIndex[index]"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "emc-util-scroll",
                                                        {
                                                          attrs: {
                                                            height: "35vh"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.grids,
                                                            function(
                                                              grid,
                                                              index2
                                                            ) {
                                                              return _c(
                                                                "v-list-item",
                                                                {
                                                                  key: grid.id,
                                                                  class:
                                                                    grid.dynamic_attribution_result_status_id ==
                                                                    2
                                                                      ? "primary--text"
                                                                      : null,
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.save(
                                                                        grid,
                                                                        index,
                                                                        index2
                                                                      )
                                                                    }
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "v-list-item-content",
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  [
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          bottom:
                                                                                            ""
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function(
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                var attrs =
                                                                                                  ref.attrs
                                                                                                return [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {},
                                                                                                        "span",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          grid
                                                                                                            .discipline
                                                                                                            .name
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              }
                                                                                            }
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                grid
                                                                                                  .workLoadType
                                                                                                  .initials +
                                                                                                  "=" +
                                                                                                  grid.work_load +
                                                                                                  " - " +
                                                                                                  grid
                                                                                                    .discipline
                                                                                                    .name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text--primary"
                                                                                  },
                                                                                  [
                                                                                    grid.teacher
                                                                                      ? _c(
                                                                                          "small",
                                                                                          [
                                                                                            _c(
                                                                                              "v-tooltip",
                                                                                              {
                                                                                                attrs: {
                                                                                                  bottom:
                                                                                                    ""
                                                                                                },
                                                                                                scopedSlots: _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key:
                                                                                                        "activator",
                                                                                                      fn: function(
                                                                                                        ref
                                                                                                      ) {
                                                                                                        var on =
                                                                                                          ref.on
                                                                                                        var attrs =
                                                                                                          ref.attrs
                                                                                                        return [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            _vm._g(
                                                                                                              _vm._b(
                                                                                                                {},
                                                                                                                "span",
                                                                                                                attrs,
                                                                                                                false
                                                                                                              ),
                                                                                                              on
                                                                                                            ),
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  grid
                                                                                                                    .teacher
                                                                                                                    .name
                                                                                                                )
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ]
                                                                                                      }
                                                                                                    }
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                )
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        grid
                                                                                                          .teacher
                                                                                                          .name
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-list-item-action",
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      loading:
                                                                                        _vm.loadingItem &&
                                                                                        _vm.selectedItemResult &&
                                                                                        _vm
                                                                                          .selectedItemResult
                                                                                          .id ==
                                                                                          grid.id,
                                                                                      icon:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            _vm
                                                                                              .icons[
                                                                                              grid.dynamic_attribution_result_status_id -
                                                                                                1
                                                                                            ]
                                                                                              .color
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .icons[
                                                                                                grid.dynamic_attribution_result_status_id -
                                                                                                  1
                                                                                              ]
                                                                                                .icon
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          index <
                                                          item.grids.length - 1
                                                            ? _c("v-divider", {
                                                                key: item.id
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }