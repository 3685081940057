<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="45%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-payment-type
                                :model.sync="model.payment_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_type_id"
                                label="Tipo de Pagamento*"
                                dense
                                :disabled="model.id && model.payment_export_status_id != 1"
                            /> 
                        </v-col>
                        <v-col cols="12" sm="3">
                                <v-select
                                    v-model="model.month"
                                    :items="monthsFiltered"
                                    item-value="id"
                                    item-text="name"
                                    :error="!!errors.month" 
                                    :error-messages="errors.month" 
                                    label="Mês"
                                    required
                                    :disabled="disabledFields"
                                    dense
                                />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-payment-export-prorated-type
                                v-if="model && model.payment_type_id == 1"
                                :model.sync="model.payment_export_prorated_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_export_prorated_type_id"
                                label="Rateio*"
                                :disabled="disabledFields"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        
                        <v-col cols="12" sm="8">
                            <sys-select-company
                                :model.sync="model.companies"
                                :error="errors.length > 0"  
                                :errors="errors.companies"
                                label="Empresa (Vazio para gerar para todas as linhas)"
                                multiple
                                :disabled="model.id && model.payment_export_status_id != 1"
                            /> 
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch
                                v-model="model.complementary" 
                                text-helper='Gerar apenas os registros adicionais, que não foram processados nos arquivos anteriores' 
                                inset
                                :error="!!errors.complementary" 
                                :error-messages="errors.complementary" 
                                label="Complementar"
                                :disabled="disabledFields"
                            />
                        </v-col>
                        
                        <!-- <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.year"
                                :error="!!errors.year" 
                                :error-messages="errors.year" 
                                label="Ano*"
                                disabled
                                dense
                                />
                        </v-col> -->
                         
                    </v-row>
                    <v-row v-if="model.complementary">
                        <v-col cols="12" sm="12">
                            <sys-select-teacher-combo
                                :model.sync="model.teachers"
                                :error="errors.length > 0"  
                                :errors="errors.teachers"
                                label="Professores"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note" 
                                :error-messages="errors.note" 
                                v-model="model.note" 
                                label="Observação"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                :disabled="model.id && model.payment_export_status_id == 4"
                            />
                        </v-col>
                    </v-row>

            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'
export default {
    name: "SysPaymentExportRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        workTime: state.acesso.periodo,
        itemsTeachers: [],
        months: [ 
            { id: 1,     name: 'Janeiro' },
            { id: 2,     name: 'Fevereiro' },
            { id: 3,     name: 'Março' },
            { id: 4,     name: 'Abril' },
            { id: 5,     name: 'Maio' },
            { id: 6,     name: 'Junho' },
            { id: 7,     name: 'Julho' },
            { id: 8,     name: 'Agosto' },
            { id: 9,     name: 'Setembro' },
            { id: 10,    name: 'Outubro' },
            { id: 11,    name: 'Novembro' },
            { id: 12,    name: 'Dezembro' },
        ],
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),

      monthsFiltered(){
            
            let range = this.getRangeMonth();
            return this.months.filter(m => (m.id >= range.start && m.id <= range.end ));
            
        },

        disabledFields(){
            return this.model.id && this.model.payment_export_status_id != 1;
        }
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.payment_type_id                  = 1;
                    this.model.payment_export_prorated_type_id  = 1;
                    this.model.year                             = this.workTime.year;
                    this.model.month                            = this.getRangeMonth().month;
                }
                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-account-cash"
        },
        title: {
            type: String,
            default: "Folha de Pagamento"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        }
    },
    methods:{
        ...mapActions('paymentExport', ['ActionUpdatePaymentExport', 'ActionCreatePaymentExport']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(this.model.teachers && this.model.teachers.length > 0){
                this.model.teachers = this.model.teachers.map((t) => ({ id: t.id, name: t.name }));
            }

            if(edit) {
                this.ActionUpdatePaymentExport(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;

                    if(error && error.errors.length > 0){
                        this.message = error.errors[0];
                    }else{
                        this.message = error.message;
                    }

                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                
                this.model.institution_id           = this.acesso.instituicao.id;
                this.model.work_time_id             = this.acesso.periodo.id;
                this.model.payment_export_status_id = 1;
                
                this.ActionCreatePaymentExport(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        
        

    }
}
</script>