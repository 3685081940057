import { service } from '../../../../helpers/http'

export const ActionIndexIntegration = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegration',
        payload: payload
    })

}

export const ActionDestroyIntegration = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyIntegration',
        payload: payload
    })
}
export const ActionStoreIntegration = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeIntegration',
        payload: payload
    })

}
export const ActionUpdateIntegration = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateIntegration',
        payload: payload
    })
}

export const ActionIndexIntegrationItemsInstitution = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationItemsInstitution',
        payload: payload
    })

}

export const ActionIndexIntegrationItems = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationItems',
        payload: payload
    })

}

export const ActionIndexIntegrationProcessItemsType = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'indexIntegrationProcessItemsType',
        payload: payload
    })
}

export const ActionUpdateIntegrationProcessItems = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'updateIntegrationProcessItemsType',
        payload: payload
    })
}