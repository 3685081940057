<template>

  <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="model"
      :title="title"
      icon="mdi-clipboard-list-outline"
      v-bind:show.sync="showForm"
      maxWidth="50%"
  >
    <template v-slot:form>
      <v-card>
        <v-card-text>
          <SysSelectIntegration
              :model.sync="model.integration_id"
              :error="errors.length > 0"
              :errors="errors.integration_id"
              label="Integração" />
        </v-card-text>

        <v-card-text>

        <SysSelectEnvironment
            v-if="model.integration_id"
            :model.sync="model.environment"
            :error="errors.length > 0"
            :errors="errors.environment"
            label="Ambiente" />
        </v-card-text>

        <v-card-text v-if="model.integration_id">
          <v-row>
            <v-col cols="12" sm="10">
              <v-text-field dense prefix="https://" placeholder="seudominio.com.br" :error="errors.length > 0" :error-messages="errors.integration_url" v-model="model.integration_url" label="Domínio"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field dense placeholder="8080" :error="errors.length > 0" :error-messages="errors.integration_port" v-model="model.integration_port" label="Porta"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field dense prepend-inner-icon="mdi-account" :error="errors.length > 0" :error-messages="errors.integration_username" v-model="model.integration_username" label="Usuário"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field type="password" dense prepend-inner-icon="mdi-lock-open-check" :error="errors.length > 0" :error-messages="errors.integration_password" v-model="model.integration_password" label="Senha"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="model.integration_id == 1">
            <v-col cols="12" sm="8">
              <v-text-field dense placeholder="wsDataServer/MEX?wsdl" :error="errors.length > 0" :error-messages="errors.wsdl" v-model="model.wsdl" label="Wsdl"></v-text-field>
            </v-col>
          </v-row>
          <v-row  v-if="model.integration_id == 1">
            <v-col cols="12" sm="4">
              <v-text-field dense :error="errors.length > 0" :error-messages="errors.default_students" v-model="model.default_students" label="Vagas"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>

      <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

    </template>
  </sys-register-base>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "SysRegisterIntegrationParameter",
  data: () => ({
    loading: false,
    title: 'Parâmetro para integração',
    showForm: false,
    errors: {},
    error: false,
    message: ''
  }),
  props: {
    model: {},
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('institution', ['ActionUpdateIntegrationParameter', 'ActionStoreIntegrationParameter']),
    save(edit) {

      this.message = ''
      this.errors = {}
      this.error = false
      this.loading = true

      if(edit) {
        this.ActionUpdateIntegrationParameter(this.model)
            .then((r) => {
              this.message = r.message
              this.$emit('onCompleted')
              this.showForm = false
            })
            .catch((e) => {
              this.errors = e.errors
              this.message = e.message
              this.error = true
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        this.ActionStoreIntegrationParameter(this.model)
            .then((r) => {
              this.message = r.message
              this.$emit('onCompleted')
              this.showForm = false
            })
            .catch((e) => {
              this.errors = e.errors
              this.message = e.message
              this.error = true
            })
            .finally(() => {
              this.loading = false
            })
      }
    }
  },
  watch: {
    show(val) {
      this.showForm = val;

      if(val){
        this.errors = {};
        this.error = false;
      }

    },
    showForm(val) {
      if(!val) {
        this.$emit('update:show', val);
      }
    }
  }
}
</script>

<style scoped>

</style>