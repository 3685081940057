var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-registered-trademark",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-educational-group", {
                          attrs: {
                            model: _vm.model.educational_group_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.educational_group_id,
                            label: "Grupo Educacional*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "educational_group_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "10" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.name,
                            label: "Nome*",
                            required: ""
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.initials,
                            label: "Sigla*",
                            required: ""
                          },
                          model: {
                            value: _vm.model.initials,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "initials", $$v)
                            },
                            expression: "model.initials"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-region", {
                          attrs: {
                            model: _vm.model.region_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.region_id,
                            showAdd: "",
                            clearable: "",
                            label: "Região",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "region_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: { color: "purple", inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.model && _vm.model.id
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-avatar-calendar-setting", {
                              attrs: {
                                width: "80",
                                height: "80",
                                "brand-id": _vm.model.id
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }