var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Campus")]),
                        _c("th", [_vm._v("Atividade")]),
                        _c("th", [_vm._v("Dia")]),
                        _c("th", [_vm._v("Horário")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.times, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [_vm._v(_vm._s(item.campus.name))]),
                          _c("td", [_vm._v(_vm._s(item.activity.name))]),
                          _c("td", [
                            item.by_date
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm._f("date")(item.date)))
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(item.day_of_week.name))
                                ])
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(item.hour_start) +
                                " - " +
                                _vm._s(item.hour_end)
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }