var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, md: _vm.md } },
    [
      _c("emc-form-auto-complete", {
        attrs: {
          items: _vm.items,
          label: "Tipo",
          outlined: _vm.outlined,
          disabled: _vm.disabled,
          "error-messages": _vm.errorMessages,
          error: _vm.error,
          loading: _vm.loading,
          "hide-no-data": _vm.hideNoData
        },
        on: { onClick: _vm.getData },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-list-item-icon",
                  [
                    _c("v-icon", { attrs: { color: item.color } }, [
                      _vm._v(_vm._s(item.icon))
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      domProps: { innerHTML: _vm._s(item.name) }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }