import { render, staticRenderFns } from "./AttributionLogTable.vue?vue&type=template&id=8c1008cc&scoped=true&"
import script from "./AttributionLogTable.vue?vue&type=script&lang=js&"
export * from "./AttributionLogTable.vue?vue&type=script&lang=js&"
import style0 from "./AttributionLogTable.vue?vue&type=style&index=0&id=8c1008cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c1008cc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8c1008cc')) {
      api.createRecord('8c1008cc', component.options)
    } else {
      api.reload('8c1008cc', component.options)
    }
    module.hot.accept("./AttributionLogTable.vue?vue&type=template&id=8c1008cc&scoped=true&", function () {
      api.rerender('8c1008cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sys/teacher/AttributionLogTable.vue"
export default component.exports