<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-view-grid-plus-outline"
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-form
                ref="form"
            >
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab key="others">Demais Dados</v-tab>
                <v-tab key="dates">Cronograma</v-tab>
                <v-tab key="ambience">Ambiente</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <sys-select-course
                                        :modelObject.sync="course"
                                        :error="errors.course_id && errors.length > 0"  
                                        :errors="errors.course_id"
                                        label="Curso*"
                                        @onLoad="loadGroups"
                                        :disabled="disabledFields"
                                        autoComplete
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <sys-select-group
                                        :model.sync="group_id"
                                        :course-id="course.id"
                                        :modelObject="{course: course}"
                                        :error="errors.group_id && errors.length > 0"  
                                        :errors="errors.group_id"
                                        label="Turma*"
                                        :disableAdd="!course.id"
                                        :disabled="disabledFields || !course.id"
                                        auto-complete
                                        item-text="name_complete"
                                        with-curriculum
                                        @change="selectedGroup = $event"
                                        showAdd
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-discipline
                                        :model.sync="model.discipline_id"
                                        :data.sync="model.discipline"
                                        :error="errors.discipline_id && errors.length > 0"  
                                        :errors="errors.discipline_id"
                                        label="Disciplina*"
                                        autoComplete
                                        showAdd
                                        itemText="full_name"
                                        :disciplines="selectedGroup ? selectedGroup.disciplinesCurriculum : null"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="!newGrid">
                                <v-col cols="12" sm="6">
                                    <sys-select-work-load-type
                                        :model.sync="model.work_load_type_id"
                                        :error="errors.work_load_type_id && errors.length > 0"  
                                        :errors="errors.work_load_type_id"
                                        label="Tipo CH*"
                                        dense
                                        auto-complete
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.work_load"
                                        dense 
                                        :error="errors.work_load && errors.length > 0" 
                                        :error-messages="errors.work_load" 
                                        label="CH Original*" 
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.work_load_pay"
                                        dense 
                                        :error="errors.work_load_pay && errors.length > 0" 
                                        :error-messages="errors.work_load_pay" 
                                        label="CH Paga" 
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.work_load_time"
                                        dense 
                                        :error="errors.work_load_pay && errors.length > 0" 
                                        :error-messages="errors.work_load_time" 
                                        label="CH Horário" 
                                        required>
                                    </v-text-field>
                                </v-col>
                                
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <sys-select-periodicity
                                        :model.sync="model.periodicity_type_id"
                                        :error="errors.periodicity_type_id && errors.length > 0"  
                                        :errors="errors.periodicity_type_id"
                                        label="Periodicidade*"
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.students_max" 
                                        dense 
                                        :error="errors.students_max && errors.length > 0" 
                                        :error-messages="errors.students_max" 
                                        label="Vagas">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.students" 
                                        dense 
                                        :error="errors.students && errors.length > 0" 
                                        :error-messages="errors.students" 
                                        label="Alunos" 
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field 
                                        v-model="model.students_forecast" 
                                        dense 
                                        :error="errors.students_forecast && errors.length > 0" 
                                        :error-messages="errors.students_forecast" 
                                        label="Previsão Alunos"
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small :disabled="!model.id" :loading="loadingDivisionCreate" color="success darken-1" @click.prevent.stop="createDivisionGrid()" v-bind="attrs" v-on="on"><v-icon small>mdi-plus</v-icon> Dividir</v-btn>
                                        </template>
                                        <span> Acrescentar divisão </span>
                                    </v-tooltip>
                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        v-model="model.note"
                                        outlined
                                        name="input-7-4"
                                        label="Anotações"
                                        :value="model.note"
                                        :error="errors.length > 0"  
                                        :error-messages="errors.note"
                                        rows="2"
                                        row-height="20"
                                        :counter="255"
                                        :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                    />
                                </v-col>
                            </v-row>
                   
                </v-tab-item>
                <v-tab-item key="others">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno da Disponibilidade"
                                clearable
                                :maxShift="4"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-work-time-date
                                    :model.sync="model.work_time_date_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.work_time_date_id"
                                    label="Período de Oferta"
                                    
                                />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-grid-modality
                                :model.sync="model.grid_modality_id"
                                :error="errors.length > 0"  
                                :errors="errors.grid_modality_id"
                                showAdd
                                clearable
                                label="Modalidade"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="12" sm="4">
                            <v-text-field dense :error="errors.sub_group && errors.length > 0" :error-messages="errors.sub_group" v-model="model.sub_group" label="Sub Turma" required></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="4">
                            <sys-select-sub-group
                                    :model.sync="model.sub_group_id"
                                    :group="{id: group_id}"
                                    :error="errors.length > 0"  
                                    :errors="errors.sub_group_id"
                                    label="SubTurma"
                                    showAdd
                                    clearable
                                    auto-complete
                                />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-hour-group
                                :model.sync="model.hour_group_id"
                                :error="errors.length > 0"  
                                :errors="errors.hour_group_id"
                                clearable
                                label="Grupo de Horário"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-grid-category
                                :model.sync="model.grid_category_id"
                                :error="errors.length > 0"  
                                :errors="errors.grid_category_id"
                                showAdd
                                clearable
                                label="Categoria"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-specificity
                                :model.sync="model.specificity_id"
                                :error="errors.length > 0"  
                                :errors="errors.specificity_id"
                                showAdd
                                clearable
                                label="Especialidade"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field dense text-helper="Código utilizado para identificar disciplinas que pertecem ao mesmo grupo" :error-messages="errors.code_link" v-model="model.code_link" label="Código Cluster" />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.group_number"
                                dense 
                                :error="errors.work_load && errors.length > 0" 
                                :error-messages="errors.group_number" 
                                label="Nº Divisão"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.teacher_number"
                                dense 
                                :error="errors.work_load && errors.length > 0" 
                                :error-messages="errors.teacher_number" 
                                label="Nº Professor"
                                required>
                            </v-text-field>
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-date-mask-type
                                :model.sync="model.date_mask_type_id"
                                :error="errors.date_mask_type_id > 0"  
                                :errors="errors.date_mask_type_id"
                                label="Máscara"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-dynamic-time-grouping-type
                                :model.sync="model.dynamic_time_grouping_type_id"
                                label="Tipo de Agrupamento"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Bloquear atribuição de professor" inset v-model="model.lock_attribution" label="Bloquear Atribuição"/>
                        </v-col>
                    <v-row>
                    </v-row>
                            <v-col cols="12" sm="4">
                                <s-switch class="ml-2" text-helper="Grade paga via outras atividades" inset v-model="model.payment_by_activity" label="Pagar por Atividade"/>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn small dense :disabled="!model.id" :loading="loadingDivisionCreate" color="primary darken-1" @click.prevent.stop="addTeacher()" v-bind="attrs" v-on="on"><v-icon x-small>mdi-plus</v-icon>Professor</v-btn>
                                    </template>
                                    <span> Adicionar Professor </span>
                                </v-tooltip>
                            </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item key="dates">
                    <sys-grid-register-dates :model="model" :errors="errors"/>
                </v-tab-item>
                <v-tab-item key="ambience">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <sys-select-category-ambience
                                    :model.sync="model.category_ambience_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.category_ambience_id"
                                    label="Categoria de Ambiente"
                                    clearable
                                    auto-complete
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="8">
                                <sys-select-ambience
                                    :model.sync="model.ambience_id"
                                    :error="errors.ambience_id && errors.length > 0"  
                                    :errors="errors.ambience_id"
                                    label="Ambiente"
                                    clearable
                                    dense
                                    autoComplete
                                    :category-id="model.category_ambience_id"
                                    :campus-id="(model && model.group) ? model.group.campus_id : -1"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-resource
                                    :model.sync="model.resources"
                                    :error="errors.length > 0"  
                                    :errors="errors.resources"
                                    multiple
                                    label="Recursos Necessários"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-ambience-type
                                    :model.sync="model.ambience_type_id"
                                    :error="errors.ambience_type_id > 0"  
                                    :errors="errors.ambience_type_id"
                                    label="Tipo de Ambiente"
                                    clearable
                                    auto-complete
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <s-text-field clearable  dense :error-messages="errors.link" v-model="model.link" label="Link da Aula" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <s-switch text-helper="Habilitado caso a grade deve possuir link digital de aula" inset v-model="model.has_link" label="Possui Link"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <s-switch text-helper="Necessário vínculo de ambiente" inset v-model="model.has_ambience" label="Possui Ambiente"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
             </v-form>
            <v-alert v-if="errors.length > 0" type="error">
                <ul>
                    <li :key="error" v-for="error in errors">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
            
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterGrid",
    data: (vm) => ({
        dialog: false,
        loadingDivisionCreate: false,
        loadingDivisionDelete: false,
        selectedGroup: null,
        error: false,
        errors: {},
        message: '',
        loading: false,
        group_id: null,
        course: {},
        courses: [],
        disabledFields: false,
        newGrid: false,
        tab: null,
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            this.disabledFields = false;

            if(show_new){
                
                this.errors = {};
                this.tab = null;
                this.course = {
                    groups: []
                };

                this.group_id = null;
                
                this.error = false;
                this.errors = {};
                this.newGrid = this.model.id ? false : true;
                
                if(!this.model.id){
                    this.model.periodicity_type_id = 1;
                    this.model.group_number = 0;
                    this.model.teacher_number = 0;
                    this.model.has_ambience = 1;
                }
                
                if(this.model.group && this.courses.length > 0){
                    var course = this.courses.find(c => c.id == this.model.group.course_id);
                    this.course = course;
                    this.group_id = this.model.group_id;
                }

                if(this.model.disableFields && this.model.disableFields == true){
                    this.disabledFields = true;
                }
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Disciplina da Turma"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('grid', ['ActionCreateGrid', 'ActionUpdateGrid', 'ActionCreateDivisionGrid', 'ActionCreateAddTeacher']),
        ...mapActions('workLoadType', ['ActionGetWorkLoadTypes']),
        
        save(edit)
        {
            if(!this.$refs.form.validate()){
                return;
            }
            
            this.error = null;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, { 
                work_time_id: this.acesso.periodo.id,
                group_id: this.group_id
            });

            if(edit) {
                
                var payload = 
                {
                    id:                             this.model.id,
                    work_time_id:                   this.model.work_time_id,
                    group_id:                       this.model.group_id,
                    discipline_id:                  this.model.discipline_id,
                    teacher_id:                     this.model.teacher_id,
                    work_load_type_id:              this.model.work_load_type_id,
                    category_ambience_id:           this.model.category_ambience_id,
                    ambience_id:                    this.model.ambience_id,
                    periodicity_type_id:            this.model.periodicity_type_id,
                    shift_id:                       this.model.shift_id,
                    hour_group_id:                  this.model.hour_group_id,
                    grid_category_id:               this.model.grid_category_id,
                    grid_modality_id:               this.model.grid_modality_id,
                    specificity_id:                 this.model.specificity_id,
                    sub_group_id:                   this.model.sub_group_id,
                    group_number:                   this.model.group_number,
                    teacher_number:                 this.model.teacher_number,
                    code:                           this.model.code,
                    code_link:                      this.model.code_link,
                    link:                           this.model.link,
                    sub_group:                      this.model.sub_group,
                    work_load:                      this.model.work_load,
                    work_load_pay:                  this.model.work_load_pay,
                    work_load_time:                 this.model.work_load_time,
                    work_time_date_id:              this.model.work_time_date_id,
                    ambience_type_id:               this.model.ambience_type_id,
                    date_mask_type_id:              this.model.date_mask_type_id,
                    grid_status_id:                 this.model.grid_status_id,  
                    students:                       this.model.students,
                    students_max:                   this.model.students_max,
                    students_forecast:              this.model.students_forecast,
                    note:                           this.model.note,
                    in_hiring:                      this.model.in_hiring,
                    has_ambience:                   this.model.has_ambience,
                    has_link:                       this.model.has_link,
                    lock_attribution:               this.model.lock_attribution,
                    payment_by_activity:            this.model.payment_by_activity,
                    resources:                      this.model.resources,
                    dates:                          this.model.dates,
                    dynamic_time_grouping_type_id:  this.model.dynamic_time_grouping_type_id,
                };
                
                this.ActionUpdateGrid(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, false);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateGrid(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, true);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        createDivisionGrid(){
            
            this.loadingDivisionCreate = true;
            this.error = null;
            this.message = '';
            
            this.ActionCreateDivisionGrid(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, true);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                   this.loadingDivisionCreate = false;
                });
        },

        addTeacher(){
            
            this.loadingDivisionCreate = true;
            this.error = null;
            this.message = '';
            
            this.ActionCreateAddTeacher({grid_id: this.model.id})
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, true);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                   this.loadingDivisionCreate = false;
                });
        },

        loadGroups(items){
            
            this.courses = items;
            
            if(this.model.group){
                var course = this.courses.find(c => c.id == this.model.group.course_id);
                this.course = course;
                this.group_id = this.model.group_id;
            }
        },
    }
}
</script>