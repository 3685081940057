var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "search",
      show: _vm.dialog,
      maxWidth: "50%",
      showButton: false
    },
    on: {
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.search.campus_id,
                            error: _vm.errors && _vm.errors.campus_id > 0,
                            errors: _vm.errors ? _vm.errors.campus_id : [],
                            label: "Campus*",
                            dense: "",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.search, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-ambience-type", {
                          attrs: {
                            model: _vm.search.types,
                            label: "Tipo de Ambiente*",
                            multiple: "",
                            dense: "",
                            selectAll: "",
                            "campus-id": _vm.search.campus_id,
                            disabled: _vm.search.campus_id == null
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.search, "types", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "error-messages": _vm.errors
                              ? _vm.errors.capacity_min
                              : [],
                            label: "Cap. Mínima",
                            inset: "",
                            "text-helper":
                              "Capacidade mínima do ambiente. Essa informação é opcional"
                          },
                          model: {
                            value: _vm.search.capacity_min,
                            callback: function($$v) {
                              _vm.$set(_vm.search, "capacity_min", $$v)
                            },
                            expression: "search.capacity_min"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "error-messages": _vm.errors
                              ? _vm.errors.capacity_max
                              : [],
                            label: "Cap. Máxima",
                            "text-helper":
                              "Capacidade máxima do ambiente. Essa informação é opcional"
                          },
                          model: {
                            value: _vm.search.capacity_max,
                            callback: function($$v) {
                              _vm.$set(_vm.search, "capacity_max", $$v)
                            },
                            expression: "search.capacity_max"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { row: "" },
                            model: {
                              value: _vm.search.empty,
                              callback: function($$v) {
                                _vm.$set(_vm.search, "empty", $$v)
                              },
                              expression: "search.empty"
                            }
                          },
                          [
                            _c("v-radio", {
                              attrs: { label: "Vazias", value: "1" }
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: "Ocupadas",
                                value: "0",
                                color: "purple"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c(
                          "v-expansion-panels",
                          { attrs: { focusable: "" } },
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        "Recursos Disponíveis " +
                                          _vm._s(
                                            _vm.selectedIndexResources.length
                                              ? "(" +
                                                  _vm.selectedIndexResources
                                                    .length +
                                                  ")"
                                              : ""
                                          )
                                      )
                                    ]),
                                    _vm.selectedIndexResources.length
                                      ? _c("small", [
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getFilterResources()) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item-group",
                                          {
                                            attrs: { color: "primary" },
                                            model: {
                                              value: _vm.resourcesSelecteds,
                                              callback: function($$v) {
                                                _vm.resourcesSelecteds = $$v
                                              },
                                              expression: "resourcesSelecteds"
                                            }
                                          },
                                          [
                                            _c(
                                              "emc-util-scroll",
                                              { attrs: { height: "45vh" } },
                                              _vm._l(_vm.resources, function(
                                                item,
                                                index
                                              ) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    on: {
                                                      mousedown: function(
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-icon",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.selectResource(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", {
                                                          attrs: {
                                                            color: _vm.isCheckedResource(
                                                              index
                                                            )
                                                              ? "indigo darken-4"
                                                              : ""
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.isCheckedResource(
                                                                index
                                                              )
                                                                ? "mdi-check-box-outline"
                                                                : "mdi-checkbox-blank-outline"
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      sm: "8"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.name_complete
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    item.type
                                                                      ? _c(
                                                                          "small",
                                                                          [
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item
                                                                                  .type
                                                                                  .name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                ),
                                                                !item.shared
                                                                  ? _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "12",
                                                                          sm:
                                                                            "4"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs: {
                                                                              label:
                                                                                "Quantidade",
                                                                              error:
                                                                                _vm
                                                                                  .errors
                                                                                  .length >
                                                                                0,
                                                                              "error-messages":
                                                                                _vm
                                                                                  .errors[
                                                                                  "resources." +
                                                                                    index +
                                                                                    ".total"
                                                                                ],
                                                                              disabled: !_vm.isCheckedResource(
                                                                                index
                                                                              )
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                item.total,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  item,
                                                                                  "total",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "item.total"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-simple-table", {
                          attrs: { "fixed-header": "", height: "300px" },
                          scopedSlots: _vm._u([
                            {
                              key: "top",
                              fn: function() {
                                return [
                                  _vm.loadingHours
                                    ? _c("v-progress-linear", {
                                        attrs: {
                                          indeterminate: "",
                                          color: "yellow darken-2"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      [
                                        _c(
                                          "th",
                                          [
                                            _c("v-simple-checkbox", {
                                              attrs: {
                                                indeterminate:
                                                  _vm.indeterminate,
                                                value: _vm.valueCheckAll,
                                                color: "success"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.selectAll()
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._l(_vm.filteredDays, function(
                                          day,
                                          i
                                        ) {
                                          return _c(
                                            "th",
                                            {
                                              key: i,
                                              staticClass: "text-center"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(day.name) + " "
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.filteredHours, function(
                                      hour,
                                      i
                                    ) {
                                      return _c(
                                        "tr",
                                        { key: i },
                                        [
                                          _c("td", [
                                            _c("small", [
                                              _vm._v(_vm._s(hour.start))
                                            ]),
                                            _c("br"),
                                            _c("small", [
                                              _vm._v(_vm._s(hour.end))
                                            ])
                                          ]),
                                          _vm._l(_vm.filteredDays, function(
                                            day,
                                            i
                                          ) {
                                            return _c(
                                              "td",
                                              {
                                                key: i,
                                                staticClass: "text-center"
                                              },
                                              [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    value: _vm.isSelected(
                                                      day.id,
                                                      hour
                                                    ),
                                                    color: "success"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.select(
                                                        day.id,
                                                        hour
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                  _c("tfoot", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "3" } },
                                        [
                                          _c("sys-select-hour-group", {
                                            staticClass: "mt-4",
                                            attrs: {
                                              model: _vm.hour_group_id,
                                              label: "Grupo de Horário",
                                              dense: "",
                                              "auto-complete": ""
                                            },
                                            on: {
                                              "update:model": function($event) {
                                                _vm.hour_group_id = $event
                                              },
                                              change: _vm.changeHourGroup
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", { attrs: { colspan: "4" } })
                                    ])
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: _vm.disabledSearch || _vm.loading,
                  loading: _vm.loading,
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.searchAmbiences()
                  }
                }
              },
              [
                _vm._v("Pesquisar"),
                _c("v-icon", { attrs: { right: "", dark: "" } }, [
                  _vm._v("search")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }