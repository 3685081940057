var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-navigation-drawer",
        {
          attrs: {
            "mini-variant": _vm.mini,
            permanent: "",
            absolute: "",
            "expand-on-hover": "",
            temporary: !_vm.mini,
            app: ""
          },
          on: {
            "update:miniVariant": function($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function($event) {
              _vm.mini = $event
            }
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "px-2 py-0 my-0",
                  attrs: { link: "", href: "profile" }
                },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _vm.user.id
                        ? _c("EmcBaseAvatar", {
                            attrs: {
                              size: 36,
                              src: _vm.user.url_avatar,
                              text: _vm.user.initials
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "text-title" }, [
                        _vm._v(_vm._s(_vm.user.name))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { dense: "", app: "" } },
            [
              _vm._l(_vm.menu, function(item) {
                return [
                  Object.keys(item.children).length > 0
                    ? _c(
                        "v-list-group",
                        {
                          key: item.id,
                          attrs: { "prepend-icon": item.icon },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" " + _vm._s(item.text) + " ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.model,
                            callback: function($$v) {
                              _vm.$set(item, "model", $$v)
                            },
                            expression: "item.model"
                          }
                        },
                        _vm._l(item.children, function(child, i) {
                          return _c(
                            "v-list-item",
                            { key: i, attrs: { link: "", to: child.to } },
                            [
                              child.icon
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _vm.primaryDrawer.mini
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {},
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              _vm._s(child.icon)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(child.description) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _c("v-icon", [
                                            _vm._v(_vm._s(child.icon))
                                          ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(child.text))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-list-item",
                        { key: item.id, attrs: { link: "", to: item.to } },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _vm.primaryDrawer.mini
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(_vm._s(item.icon))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.description))
                                      ])
                                    ]
                                  )
                                : _c("v-icon", [_vm._v(_vm._s(item.icon))])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.text))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }