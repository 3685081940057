export default [
    {
        path: '/offer-item-protocol-processes',
        name: 'offer-item-protocol-processes',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Protocolos de Oferta',
            icon: 'mdi-ticket-confirmation-outline',
            breadcrumbs: [
                { text: 'Protocolos de Oferta', disabled: true }
            ]
        }
    }
]