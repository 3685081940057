<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :showAdd="showAdd"
            :disabled="disabled"
            :multiple="multiple"
            :autoComplete="autoComplete"
            :itemText="itemText"
            :dense="dense"
            :selectAll="selectAll"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
            :outlined="outlined"
        />
        <sys-register-course @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectCourse',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        selectedObject: null,
        showForm: false
    }),
    created() {
        this.getItems();
    },
    props: {    
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        modelObject: {
        
        },
        itemText: {
            type: String,
            default: 'name_complete'
        },  
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        onlyActive: {
            type: Boolean,
            default: false
        },
        withGroups:{
            type: Boolean,
            default: false
        },
        campusId: {
            default: null
        },
        occupationAreaId: {
            default: null
        },
        categoryId: {
            default: null
        },
        campusKey: {
           type: String,
           default: 'id'
        },
        shifts: {
            default: null
        },
        filter: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {
        selected(val){
            this.$emit('update:model', val);
            if(val){
                var course = this.items.find(c => c.id == val);
                if(course) {
                    this.$emit('update:modelObject', course);
                }
            }

        },
        selectedObject(val){
            this.$emit('update:modelObject', val);
        },
        model(val){
            this.selected = val;
            this.$emit('changeSelection', val);
        },
        modelObject(val){
            this.selectedObject = val;
            if(val && val.id){
                this.selected = val.id;
            }else{
                 this.selected = 0;
            }
        },

        campusId(){
            this.getItems();
        },

        categoryId(){
            this.getItems();
        },

        occupationAreaId(){
            this.getItems();
        },

        shifts(){
            this.getItems();
        },

    },
    methods: {
        ...mapActions('course', ['ActionIndexCourses']),        
        async getItems() {
            
            this.loading = true;
            
            var payload =  {
                column_order: 'name',
            }

            if(this.withGroups){
                payload.with = 'groups'
            }

            if(this.onlyActive){
                payload.find_columns = {
                    active: 1
                };
            }

            if(this.categoryId){
                
                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.category_id = this.categoryId;
             
            }

            if(this.occupationAreaId){
                
                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.occupation_area_id = this.occupationAreaId;
                
            }

            if(this.campusId && this.campusId.length !== 0){
                
                payload.filter_relations = [
                    {
                        name: 'groups',
                        innerRelations: [
                            {
                                name: 'campus',
                                key: this.campusKey,
                                value: this.campusId,
                            }
                        ]
                    }
                ];

            }else if(this.filter){
                this.items = [];
                this.selected = null;
                this.loading = false;
                return;
            }

            if(this.shifts && this.shifts.length !== 0){
                
                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations[0].innerRelations.push(
                    {
                        name: 'shift',
                        key: 'id',
                        clause: 'whereIn',
                        value: this.shifts.map(o => o['id']).join(','),
                    }
                );
            }

            await this.ActionIndexCourses(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                        this.$emit('onLoad', this.items);
                       
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

    newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.selectedObject = item;
                this.showForm = false;
            }
        }
    },
    
}
</script>