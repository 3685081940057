var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { cursor: "help" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.$emit("onOpen", _vm.model)
        }
      }
    },
    [
      _vm.model && _vm.model.has_message
        ? _c(
            "v-badge",
            {
              staticClass: "ma-0",
              attrs: {
                bordered: "",
                top: "",
                color: "red",
                overlap: "",
                dot: ""
              }
            },
            [
              _c(
                "v-icon",
                { attrs: { large: "", color: "blue darken-2", small: "" } },
                [_vm._v(" mdi-email ")]
              )
            ],
            1
          )
        : _c("v-icon", { attrs: { large: "", color: "grey", small: "" } }, [
            _vm._v(" mdi-email ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }