export default {
    chartFind:                  { method: 'get', url: 'charts' },
    chartEfficiencyOfWorkload:  { method: 'get', url: 'charts/efficiencyOfWorkload' },
    chartEvolutionOfWorkload:   { method: 'get', url: 'charts/evolutionOfWorkload' },
    chartGroupNumbers:          { method: 'get', url: 'charts/groupNumbers' },
    chartTeacherNumbers:        { method: 'get', url: 'charts/teacherNumbers' },
    chartTeacherWorkLoadAvg:    { method: 'get', url: 'charts/teacherWorkLoadAvg' },
    chartAmbienceNumbers:       { method: 'get', url: 'charts/ambienceNumbers' },
    chartGridInHiringNumbers:   { method: 'get', url: 'charts/gridInHiringNumbers'},
    chartTeacherTitlings:       { method: 'get', url: 'charts/teacherTitlings'},
    budgetClass:                { method: 'get', url: 'charts/budget_class'},
    budgetActivity:             { method: 'get', url: 'charts/budget_activity'},
    budgetGeneral:              { method: 'get', url: 'charts/budget_general'},
    teacherBalance:             { method: 'get', url: 'charts/teacher_balance'},
    indexChartsOfferItems:      { method: 'get', url: 'charts/offer_items/{index}'},

    chartEfficiencyClassWorkLoad:   { method: 'get', url: 'charts/efficiencyClassWorkLoad' },
    chartPerformanceStudents:       { method: 'get', url: 'charts/performanceStudents' },
    chartActivityWorkLoadAvg:       { method: 'get', url: 'charts/activityWorkLoadAvg' },

    //Refresh
    groupNumbersRefresh: { method: 'post', 'url': 'charts/group-numbers/refresh' },
    teacherNumbersRefresh: { method: 'post', 'url': 'charts/teacher-numbers/refresh' },
    ambienceNumbersRefresh: { method: 'post', 'url': 'charts/ambience-numbers/refresh' },
    gridInHiringNumbersRefresh: { method: 'post', 'url': 'charts/grid-in-hiring-numbers/refresh' },
    teacherWorkLoadAvgRefresh: { method: 'post', 'url': 'charts/teacher-workload-avg/refresh' },
    evolutionOfWorkloadRefresh: { method: 'post', 'url': 'charts/evolution-of-workload/refresh' },
    efficiencyOfWorkloadRefresh: { method: 'post', 'url': 'charts/efficiency-of-workload/refresh' },
    teacherTitlingsRefresh: { method: 'post', 'url': 'charts/teacher-titlings/refresh' },
}