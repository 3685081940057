<template>
    <v-container>
         <v-row>
             <v-col cols="12" sm="4">
                    <sys-select-teacher-color-race
                            :model.sync="model.teacher_color_race_id"
                            :error="errors.length > 0"  
                            :errors="errors.teacher_color_race_id"
                            label="Cor/Raça"
                            clearable
                    />
            </v-col>
            <v-col cols="12" sm="4">
                <sys-select-teacher-nationality
                        :model.sync="model.nationality_id"
                        :error="errors.length > 0"  
                        :errors="errors.nationality_id"
                        label="Nacionalidade*"
                />
            </v-col>
            <v-col cols="12" sm="4">
                <sys-select-genre
                        :model.sync="model.genre_id"
                        :error="errors.length > 0"  
                        :errors="errors.genre_id"
                        label="Sexo*"
                />
            </v-col>
         </v-row>
         <v-row>
             <v-col cols="12" sm="4">
                <sys-select-country
                        :model.sync="model.country_id"
                        :error="errors.length > 0"  
                        :errors="errors.country_id"
                        label="País*"
                        autoComplete
                />
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field dense :error="errors.length > 0" :error-messages="errors.mother_name" v-model="model.mother_name" label="Nome da Mãe" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field dense :error="errors.length > 0" :error-messages="errors.foreign_document" v-model="model.foreign_document" label="Documento Estrangeiro" required></v-text-field>
            </v-col>
         </v-row>
         <v-row>
             <v-col cols="12" sm="4">
                 <sys-select-teacher-status-deficiency
                        :model.sync="model.teacher_status_deficiency"
                        :error="errors.length > 0"  
                        :errors="errors.teacher_status_deficiency"
                        label="Docente com Deficiência*"
                />
            </v-col>
         </v-row>

    </v-container>
</template>
<script>

import { mapActions } from 'vuex'

export default {
   name: 'SysTeacherCensus',
   data: () => ({
     model: {},
    }),

    props: {
        teacher: {
            required: true
        },
        error: {
            required: true
        },
        errors: {
            required: true
        }
    },

    watch: {
      
      teacher(val){
          this.model = val;
      },

      model(val){
          this.$emit('update:teacher', val);
      }

    },
    components: {
      
    },

    mounted(){
       
    },
    created() {
        
    },
    methods: {
        

  },

}
</script>
