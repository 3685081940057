import { render, staticRenderFns } from "./Integration.vue?vue&type=template&id=0320b43e&"
import script from "./Integration.vue?vue&type=script&lang=js&"
export * from "./Integration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardTitle,VIcon,VSpacer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0320b43e')) {
      api.createRecord('0320b43e', component.options)
    } else {
      api.reload('0320b43e', component.options)
    }
    module.hot.accept("./Integration.vue?vue&type=template&id=0320b43e&", function () {
      api.rerender('0320b43e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sys/work_time/Integration.vue"
export default component.exports