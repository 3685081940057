var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-laptop-chromebook",
      show: _vm.dialog,
      maxWidth: _vm.maxWidth
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-resource-type", {
                          attrs: {
                            model: _vm.model.resource_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.resource_type_id,
                            label: "Tipo de Recurso",
                            clearable: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "resource_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            dense: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Descrição",
                            value: _vm.model.description,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.description,
                            rows: "2",
                            "row-height": "20",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ]
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Recurso de uso compartilhado. Na validação ao vincular ambientes não considera o número de alunos da turma. Exemplo: Elevador.",
                            inset: "",
                            label: "Recurso Compartilhado"
                          },
                          model: {
                            value: _vm.model.shared,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "shared", $$v)
                            },
                            expression: "model.shared"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Recurso móvel que pode ser utilizado por diferentes ambientes.",
                            inset: "",
                            label: "Recurso Móvel"
                          },
                          model: {
                            value: _vm.model.is_mobile,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_mobile", $$v)
                            },
                            expression: "model.is_mobile"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _vm.model.is_mobile
                          ? _c("s-text-field", {
                              attrs: {
                                "text-helper":
                                  "Quantidade de recursos disponíveis para serem disponibilizados aos ambientes",
                                "error-messages": _vm.errors.total,
                                label: "Total"
                              },
                              model: {
                                value: _vm.model.total,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "total", $$v)
                                },
                                expression: "model.total"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Recurso disponível para reserva e agendamento externo",
                            label: "Agendamento Externo"
                          },
                          model: {
                            value: _vm.model.allow_external_allocation,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "allow_external_allocation",
                                $$v
                              )
                            },
                            expression: "model.allow_external_allocation"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }