var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "open-on-hover": "", top: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { style: _vm.styleCustom, attrs: { small: "", icon: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "", color: _vm.iconColor } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-2 pa-0 ma-0", attrs: { outlined: "", tile: "" } },
        [
          _c(
            "v-app-bar",
            { staticClass: "elevation-0", attrs: { dense: "" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "text-body-2" },
                [
                  _c("strong", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                  _c("v-spacer"),
                  _vm.showCopy
                    ? _c(
                        "v-tooltip",
                        {
                          staticClass: "ml-2",
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: { cursor: "copy" },
                                            attrs: { "x-small": "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.copyToClipBoard()
                                              }
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-content-copy")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3791337407
                          )
                        },
                        [_c("span", [_vm._v(" Copiar ")])]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm.text
            ? _c(
                "div",
                {
                  staticClass:
                    "text-caption pb-2 pl-3 pr-3 grey--text text--darken-1"
                },
                [_vm._v(_vm._s(_vm.text))]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "text-caption pb-2 pl-3 pr-3 grey--text text--darken-1"
            },
            [_vm._t("text")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }