import { service } from '../../../../helpers/http'

export const ActionDoLoginTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'loginTeacher',
        payload: payload
    }).then(res => {
        
        if(payload.mode == 'login'){
            dispatch('setAccessTeacher', res.data);
        }

        return res;

    });
    
}

export const ActionDoLoginTeacherReload = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'loginTeacherReload',
        payload: payload
    }).then(res => {
        
        dispatch('setAccessTeacher', res.data);

        return res;
    });
    
}


export const setAccessTeacher = ({ dispatch }, data) => {
    
    let acesso = {
        grupo:          data.educationalGroup,
        instituicao:    data.institution,
        periodo:        data.workTime,
        campus:         [],
    };

    dispatch('ActionSetUser', data.user);
    dispatch('ActionSetToken', data.token);
    dispatch('ActionSetAcesso', acesso);
}

export const ActionTeacherLogout = ({ dispatch }, payload ) => {

    return service({
        module: 'auth',
        action: 'logoutTeacher',
        payload: payload
    })

}

export const ActionAccessTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'accessTeacher',
        payload: payload
    })
    
}

export const ActionAuthTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'authTeacher',
        payload: payload
    })
    
}

export const ActionTokenTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'tokenTeacher',
        payload: payload
    })
    
}