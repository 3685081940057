<template>
    <v-app id="inspire">
      <template v-if="$route.path.includes('login') || $route.path.includes('access-teacher') || $route.path.includes('login/teacher')">
        <LoginTeacher v-if="$route.path.includes('login/teacher')" />
        <AccessTeacher v-else-if="$route.path.includes('access-teacher')" />
        <Login v-else-if="$route.path.includes('login')" />
      </template>
      <SysCoreMaster v-else/>
    </v-app>
</template>
<script>

import Login from './modules/auth/pages/Login.vue'
import AccessTeacher from "./modules/auth/teacher/pages/Access.vue";
import LoginTeacher from "./modules/auth/teacher/pages/Index.vue";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
export default {
  name: "App",
  components: {
    Login,
    AccessTeacher,
    LoginTeacher
  },
  created() {
    loadMessages(ptMessages);
    locale(navigator.language);
  }
}
</script>