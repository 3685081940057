var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    attrs: { height: "260px", "fixed-header": "", loading: "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("v-progress-linear", {
              attrs: {
                indeterminate: "",
                color: _vm.loading ? "yellow darken-2" : ""
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("thead", [
              _c(
                "tr",
                [
                  _c(
                    "th",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("small", [
                                    _c("strong", _vm._g({}, on), [
                                      _vm._v(_vm._s(_vm.getTitleHour(_vm.item)))
                                    ])
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getToolTipoTitleHour(_vm.item)
                              )
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.days_index, function(day) {
                    return _c("th", { key: day, staticClass: "text-center" }, [
                      _vm._v(" " + _vm._s(_vm.days_name[day]) + " "),
                      _vm.date
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.date.all ? "*" : _vm.getDate(day)) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.hours, function(hour) {
                return _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.type == "ambience" ||
                          (_vm.shifts.includes(hour.shift_id) &&
                            _vm.hasGroups(hour)),
                        expression:
                          "type == 'ambience' || (shifts.includes(hour.shift_id) && hasGroups(hour))"
                      }
                    ],
                    key: hour.id
                  },
                  [
                    _c("td", [
                      _c("small", [
                        _vm._v(" " + _vm._s(hour.start) + " "),
                        _c("br"),
                        _vm._v(" " + _vm._s(hour.end) + " ")
                      ])
                    ]),
                    _vm._l(_vm.days_index, function(day) {
                      return _c(
                        "td",
                        {
                          key: day,
                          staticClass: "text-left",
                          on: {
                            drop: _vm.drop,
                            dragover: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: {
                                options: { disabled: _vm.type == "ambience" },
                                gostClass: "on-drag",
                                animation: "400"
                              },
                              on: {
                                start: function($event) {
                                  _vm.start(_vm.getClasses(hour.id, day + 1))
                                }
                              }
                            },
                            [
                              _vm.getClasses(hour.id, day + 1)
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "ma-1 black--text",
                                      attrs: {
                                        transition: "fab-transition",
                                        color: "blue lighten-3",
                                        dark: "",
                                        height: "40px",
                                        width: "100px"
                                      },
                                      on: {
                                        dblclick: function($event) {
                                          _vm.dblclick(
                                            _vm.getClasses(hour.id, day + 1)
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-actions",
                                        [
                                          _vm.getClasses(hour.id, day + 1)
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    cursor:
                                                                      "default"
                                                                  },
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      _vm.getTextClasses(
                                                                        hour.id,
                                                                        day + 1
                                                                      )
                                                                    )
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getToolTip(
                                                          hour.id,
                                                          day + 1
                                                        )
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          !!_vm.getClasses(hour.id, day + 1)
                                            .ambience_id
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "absolute",
                                                                    bottom:
                                                                      "4px",
                                                                    right:
                                                                      "-5px"
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                    left: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.$emit(
                                                                        "remove",
                                                                        _vm.getClasses(
                                                                          hour.id,
                                                                          day +
                                                                            1
                                                                        )
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-delete "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Liberar Ambiente")
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getClasses(hour.id, day + 1) &&
                                          _vm.getClasses(hour.id, day + 1).grid
                                            .join
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    position:
                                                                      "absolute",
                                                                    bottom:
                                                                      "20px",
                                                                    right:
                                                                      "-5px",
                                                                    cursor:
                                                                      "help"
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                    left: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " mdi-call-split "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm.renderComponent
                                                    ? _c(
                                                        "span",
                                                        [
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                attrs: {
                                                                  type: "none"
                                                                }
                                                              },
                                                              _vm._l(
                                                                _vm.getClasses(
                                                                  hour.id,
                                                                  day + 1
                                                                ).grid.join
                                                                  .grids,
                                                                function(grid) {
                                                                  return _c(
                                                                    "li",
                                                                    {
                                                                      key:
                                                                        grid.id
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            grid
                                                                              .group
                                                                              .name +
                                                                              " | " +
                                                                              (grid.group_number !=
                                                                              0
                                                                                ? "D" +
                                                                                  grid.group_number +
                                                                                  "-"
                                                                                : "") +
                                                                              grid
                                                                                .discipline
                                                                                .name
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        ],
                                                        2
                                                      )
                                                    : _c("span", [
                                                        _vm._v("Carregando...")
                                                      ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }