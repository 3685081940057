<template>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <v-data-table
                  :headers="filteredHeaders"
                  :items="model.dates"
                  hide-default-footer
                  :items-per-page="-1"
                  item-key="index"
                 
              >
                  <template v-slot:top="{ on, attrs }">
                      <v-btn 
                        :disabled="!model || !model.id"
                        color="indigo darken-1" 
                        @click.prevent.stop="addItem()" 
                        icon v-bind="attrs" 
                        v-on="on">
                        <v-icon>mdi-plus</v-icon></v-btn>
                  </template>

                  <template v-slot:item.frequency_id="{item, index}">
                      <sys-select-frequency
                            :model.sync="item.frequency_id"
                            :error="errors.length > 0"  
                            :errors="errors['dates.' + index + '.frequency_id']"
                            label=""
                            dense
                            @change="item.weeks = $event.weeks"
                            :filter="[2,3,5]"
                        />
                  </template>

                  <template dense v-slot:item.code="{item, index}">
                      <s-text-field 
                        v-model="item.code" 
                        :error="errors.length > 0" 
                        :error-messages="errors['dates.' + index + '.code']" 
                        dense
                        code
                      />
                  </template>
 
                  <template dense v-slot:item.name="{item, index}">
                      <v-text-field 
                        v-model="item.name" 
                        :error="errors.length > 0" 
                        :error-messages="errors['dates.' + index + '.name']" 
                        dense
                      />
                  </template>

                  <template dense v-slot:item.initials="{item, index}">
                      <v-text-field 
                        v-model="item.initials" 
                        dense 
                        :error="errors.length > 0"
                        :error-messages="errors['dates.' + index + '.initials']" 
                      />
                  </template>

                  <template dense v-slot:item.period="{ item, index }">
                      <emc-calendar-date-picker-range 
                        :errorMessages1="errors['dates.' + index + '.start']" 
                        :errorMessages2="errors['dates.' + index + '.end']" 
                        :show="dialog" 
                        :model1.sync="item.start" 
                        :model2.sync="item.end"
                        :min="model.start"
                        :max="model.end"
                      />
                  </template>

                  <template dense v-slot:item.classes="{ item, index }">
                      <emc-calendar-date-picker-range 
                        :errorMessages1="errors['dates.' + index + '.classes_start']" 
                        :errorMessages2="errors['dates.' + index + '.classes_end']" 
                        :show="dialog" 
                        :model1.sync="item.classes_start" 
                        :model2.sync="item.classes_end"
                        :min="item.start"
                        :max="item.end"
                      />
                  </template>

                  <template dense v-slot:item.attribution_work_load_percent="{ item, index }">

                    <s-text-field 
                        v-model="item.attribution_work_load_percent" 
                        :error-messages="errors['dates.' + index + '.attribution_work_load_percent']" 
                        dense
                        text-helper="Percentual da carga horária paga na atribuição"
                      />

                  </template>

                  
                  <template v-slot:item.actions="{ item, index }">
                      
                      <v-btn
                        @click.prevent.stop="deleteItem(item, index)"
                        :loading="loading && indexDelete == index"
                        :disabled="loading"
                        icon
                      >
                        <v-icon
                            small
                            color="error"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                      </v-btn>
                      
                      
                  </template>
                            
                </v-data-table>
        </v-col>
      </v-row>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysWorkTimeRegisterDates",
    data: () => ({
      fav: true,
      dates: [],
      dialog: false,
      loading: false,
      indexDelete: -1,
      headers: [
          { text: 'Periodicidade', align: 'start', sortable: false, value: 'frequency_id', width: "15%"    },
          { text: 'Código',             value: 'code',                          sortable: false,  width: "10%" },
          { text: 'Descrição',          value: 'name',                          sortable: false,  width: "10%" },
          { text: 'Sigla',              value: 'initials',                      sortable: false,  width: "8%" },
          { text: 'Período Pagamento',  value: 'period',                        sortable: false,  width: "24%" },
          { text: 'Período Aulas',      value: 'classes',                       sortable: false,  width: "24%" },
          { text: '%',                  value: 'attribution_work_load_percent', sortable: false,  width: "14%" },
          { text: '',                   value: 'actions',                       sortable: false,  width: "1%"  },
      ],
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {

        }
        
    },
     computed: {

      filteredHeaders(){
        
        if(this.model.attribution_work_time_dates){
          return this.headers;
        }else{
          return this.headers.filter(h => h.value != 'attribution_work_load_percent');
        }
        
        
      }
     },
    methods: {
      ...mapActions('worktime', ['ActionDeleteWorkTimeDate']),
      
      deleteItem(item, index){
          
          if(!item.id){
            this.removeItem(item);
            return;
          }
          
          this.loading = true;
          this.indexDelete = index;
          
          this.ActionDeleteWorkTimeDate(item)
            .then((res) => {
                this.removeItem(item);
            })
            .catch((error) =>{
                
                
            })
            .finally(() => {
                  this.loading = false;
                  this.indexDelete = -1;
            });
            
      },

      removeItem(item){
          var index = this.model.dates.indexOf(item);
          this.model.dates.splice(index, 1);
      },

      addItem(){
          
          if(!this.model || !this.model.id){
            return;
          }
          
          var item = {
              index: this.model.dates.length,
              id: 0,
              frequency_id: null,
              code: null,
              name: null,
              weeks: null,
              start: null,
              end: null,
          };

          this.model.dates.push(item);
          
      },
    }
}
</script>