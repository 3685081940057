<template>

    <div class="mt-3">
        
        <div id="extrato-teacher">

        <div><strong>Professor:</strong> {{ teacher.name}}</div>
        <div v-if="teacher.titling"><strong>Titulação:</strong> <span>{{ teacher.titling.name || '' }}</span></div>
        <div v-if="teacher.workRegime"><strong>Regime de Trabalho:</strong> <span>{{ teacher.workRegime.name || '' }}</span></div>
        
        <emc-base-card
            v-if="!grid"
            class-toolbar="elevation-1"
            class-title="body-1" 
            title="Disponibilidade"
            icon="mdi-account-clock-outline"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherAvailabilitySummary :teacher="teacher" />
            </template>
        </emc-base-card>

        <emc-base-card
            v-if="grid"
            class-toolbar="elevation-1"
            class-title="body-1"
            title="Horários da Grade"
            icon="mdi-timetable"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherConfirmedTimes :hide-joins="!!grid" :workTime="workTime" :grid="grid" />
            </template>
        </emc-base-card>

        

        <emc-base-card 
            class-toolbar="elevation-1"
            class-title="body-1"
            title="Horários do Professor"
            icon="mdi-timetable"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherConfirmedTimes :hide-joins="!!grid" :workTime="workTime" :teacher="teacher" />
            </template>
        </emc-base-card>

        <emc-base-card 
            class-toolbar="elevation-1"
            class-title="body-1"
            title="Atribuições"
            icon="school"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherAttributionTable :workTime="workTime" :teacher="teacher" />
            </template>
        </emc-base-card>

        <emc-base-card 
            class-toolbar="elevation-1"
            class-title="body-1"
            title="Atividades"
            icon="school"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherOtherActivities :teacher="teacher" />
            </template>
        </emc-base-card>

        <emc-base-card 
            class-toolbar="elevation-1"
            class-title="body-1"
            title="Horário das Atividades"
            icon="school"
            class="mt-3"
        >
            <template v-slot:card-text>
                <SysTeacherOtherActivityTimeTable :teacher="teacher" />
            </template>
        </emc-base-card>

        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import state from '../../../modules/auth/store/state'
export default {
    name: 'SysTeacherExtract',
    data: function() {
        return {
            workTime: null
        }
    },
    created(){
        this.workTime = state.acesso.periodo;
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        grid: {

        }
    },
    methods: {
        
        createPDF(){

            var pdf = new jsPDF({
                                orientation: "a4"
                                });
            var element = document.getElementById('extrato-teacher');

            var width= 1248/6;//element.style.width;
            var height = 949/6; //element.style.height;
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                pdf.addImage(image, 'JPEG', 1, 1, width, height);
                pdf.save('Extrato.pdf');
        });

  },
    }
    
}
</script>