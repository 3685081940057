var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-find-base", {
        attrs: {
          show: _vm.dialog,
          collection: _vm.items,
          headers: _vm.headers,
          icon: _vm.icon,
          loading: _vm.loading,
          title: _vm.title,
          singleSelect: _vm.singleSelect,
          maxWidth: _vm.maxWidth,
          validateExistJoin: _vm.validateExistJoin
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          onSearch: function($event) {
            return _vm.searchGrids($event)
          },
          onSelect: function($event) {
            return _vm.select($event)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
              return {
                key: slot,
                fn: function(scope) {
                  return [_vm._t(slot, null, null, scope)]
                }
              }
            }),
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.join
                    ? _c("v-icon", { attrs: { color: "blue", small: "" } }, [
                        _vm._v(" mdi-call-split ")
                      ])
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }