var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Campus/Departamento")]),
                        _c("th", [_vm._v("Atividade")]),
                        _c("th", [_vm._v("Periodicidade")]),
                        _c("th", [_vm._v("Tipo")]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Valor")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Compõe CH")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Período")
                        ])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [
                            item.activityItem.campus
                              ? _c("span", [
                                  _vm._v(_vm._s(item.activityItem.campus.name))
                                ])
                              : item.activityItem.departament
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(item.activityItem.departament.name)
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(item.activityItem.activity.name))
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(item.activityItem.periodicityType.name)
                            )
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(item.activityItem.valueType.name))
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(item.value))
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              item.activityItem.activity.is_work_load
                                ? _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v("mdi-check-bold")]
                                  )
                                : _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v("mdi-close-thick")]
                                  )
                            ],
                            1
                          ),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              _vm._s(_vm._f("date")(item.start)) +
                                " - " +
                                _vm._s(_vm._f("date")(item.end))
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }