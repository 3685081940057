<template>
    <div>
        <EmcChartLoading v-if="loading" />

        <!-- <emc-chart-filter :show.sync="showFilter" @filter="filter($event)" /> -->

        <v-card v-show="!loading" class="pa-2">
            <canvas v-if="renderComponent" :id="name" :width="width" :height="height"></canvas>
            <v-card-actions>

                <emc-info-help title="Legenda">
                                                            
                    <template v-slot:text>
                        
                        <strong>Bronze</strong>
                        <br/>
                         10 ou menos alunos
                        <br/>
                        
                        <strong>Prata</strong>
                        <br/>
                        Entre 11 e 35 Alunos
                        <br/>
            
                        <strong>Ouro</strong>
                        <br/>
                        Mais de 36 alunos
                        <br/>
            
                    </template>
            
                </emc-info-help>

                <v-spacer></v-spacer>

                <!-- <s-switch 
                    v-model="withJoin"     
                    text-helper='Considerar soma de alunos nas Junções' 
                    label="Junção"
                    inset
                    :loading="loadinData"
                /> -->

                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="showFilter = true">
                                <v-icon btn>mdi-filter-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Selecionar Categorias
                        </span>
                    </v-tooltip> -->
                    <!-- <v-tooltip v-if="showMoreButton" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMore', itemId)">
                                <v-icon btn>mdi-monitor-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Ver Mais...
                        </span>
                    </v-tooltip> -->
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions } from 'vuex'

export default {
    name: 'EmcChartPerformanceStudents',
    data () {
        return {
            name: 'PerformanceStudents',
            loading: true,
            width: '400',
            height: '240',
            chartData: [],
            showFilter: true,
            loadinData: false,
            withJoin: true,
            filterPayload: null,
            renderComponent: true,
            title: '',
            chartOptions: {
                plugins: {
                    datalabels: {
                        color: 'white',
                        display: function(context) {
                            return context.dataset.data[context.dataIndex] > 15;
                        },
                        font: {
                            weight: 'bold'
                        },
                        formatter: Math.round
                    },
                    legend: {
                        display: false,
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: '',
                        padding: {
                            bottom: 20
                        }
                    }
                },
                aspectRatio: 5 / 3,
                layout: {
                    padding: {
                        top: 24,
                        right: 16,
                        bottom: 0,
                        left: 8
                    }
                },
                elements: {
                    line: {
                        fill: false
                    },
                    point: {
                        hoverRadius: 7,
                        radius: 5
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                }        
            }
        }
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: Number,
        },
    },
    watch: {
        withJoin(newVal, oldVal){
            
            if(newVal != oldVal){
                this.getData(true);
            }

            
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartPerformanceStudents']),
        async getData(loading = false) {
            
            this.loadinData = loading;
            this.chartData = [];
            this.chartOptions.plugins.title.text = '';


            this.filterPayload = {
                withJoin: this.withJoin
            };

            await this.ActionChartPerformanceStudents(this.filterPayload)
                .then((r) => {
                    
                    this.chartData = r.data;
                    this.chartOptions.plugins.title.text = r.data['title'];

                }).finally(() => {
                    
                    this.loadinData = false;

                    // this.forceRerender();

                });
            
            if(!loading){
                this.getChart();
            }
            
           
        },
        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }
            
            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            var speedCanvas = document.getElementById(this.name)
            this.chart = new Chart(speedCanvas, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false;
           
        },

        filter(ev){
            this.filterPayload = ev;
            this.showFilter = false;
            this.forceRerender();
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                
                this.renderComponent = true;

            });
        },
    },

    created() {
        this.getData()
    }
}
</script>