<template>
    <emc-chart-apex-base :series="series" type="line" :options="chartOptions" :loading.sync="loading">

        <template v-slot:actions>
            <v-spacer></v-spacer>
            <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
        </template>
    </emc-chart-apex-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartEvolutionOfWorkload',
    data () {
        return {
            loading: true,
            name: 'EvolutionOfWorkload',
            updated_at: null,
            series: [],
            chartOptions: {
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: 'Carga horária'
                    }
                },
                colors: [],
                title: {}
            }
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartEvolutionOfWorkload', 'ActionEvolutionOfWorkloadRefresh']),
        async getData(){
            await this.ActionChartEvolutionOfWorkload()
                .then((r) => {
                    this.series = r.data.series
                    this.chartOptions.xaxis.categories = r.data.categories
                    this.chartOptions.title.text = r.data.title
                    this.chartOptions.colors = r.data.colors
                    this.updated_at = r.data.updated_at
                })
                .finally(() => {
                    this.loading = false
                })
            
        },
        refresh() {
            this.loading = true

            this.ActionEvolutionOfWorkloadRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let updated_at = this.updated_at;

            setTimeout(async () => {

                    await this.getData()

                    if(updated_at != this.updated_at || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 10000)
        }
    },
    created(){
        this.getData();
    }
}
</script>