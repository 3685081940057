var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-merge",
      show: _vm.dialog,
      maxWidth: "95%",
      maxHeight: "95%",
      showButton: false,
      showRequiredFields: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [_c("sys-pop-over-join", { attrs: { model: _vm.config } })]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-stepper",
              {
                model: {
                  value: _vm.step,
                  callback: function($$v) {
                    _vm.step = $$v
                  },
                  expression: "step"
                }
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    _c(
                      "v-stepper-step",
                      {
                        attrs: {
                          editable: _vm.editable,
                          complete: _vm.step > 1,
                          step: "1"
                        }
                      },
                      [_vm._v("Seleção de Cursos")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      {
                        attrs: {
                          editable: _vm.editable,
                          complete: _vm.step > 2,
                          step: "2"
                        }
                      },
                      [_vm._v("Seleção de Disciplinas")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { editable: _vm.editable, step: "3" } },
                      [_vm._v("Confirmação")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  [
                    _c(
                      "v-stepper-content",
                      { staticClass: "pa-2", attrs: { step: "1" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("div", { staticClass: "subtitle-1" }, [
                                  _vm._v("Selecione o Campus")
                                ]),
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: { column: "", multiple: true },
                                    model: {
                                      value: _vm.campusesSelected,
                                      callback: function($$v) {
                                        _vm.campusesSelected = $$v
                                      },
                                      expression: "campusesSelected"
                                    }
                                  },
                                  [
                                    _c(
                                      "emc-util-scroll",
                                      { attrs: { height: "320px" } },
                                      _vm._l(_vm.campuses, function(campus) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: campus.id,
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: campus.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(campus.name) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.shifts.length
                              ? _c("v-divider", { attrs: { vertical: "" } })
                              : _vm._e(),
                            _vm.shifts.length
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "2" } },
                                  [
                                    _c("div", { staticClass: "subtitle-1" }, [
                                      _vm._v("Selecione o Turno")
                                    ]),
                                    _c(
                                      "v-chip-group",
                                      {
                                        attrs: { column: "", multiple: true },
                                        model: {
                                          value: _vm.shiftsSelected,
                                          callback: function($$v) {
                                            _vm.shiftsSelected = $$v
                                          },
                                          expression: "shiftsSelected"
                                        }
                                      },
                                      _vm._l(_vm.shifts, function(shift) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: shift.id,
                                            attrs: {
                                              filter: "",
                                              outlined: "",
                                              value: shift.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(shift.name) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.courses.length
                              ? _c("v-divider", { attrs: { vertical: "" } })
                              : _vm._e(),
                            _vm.courses.length
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("span", { staticClass: "subtitle-1" }, [
                                      _vm._v("Selecione os Cursos")
                                    ]),
                                    _c("v-checkbox", {
                                      staticClass: "subtitle-1",
                                      staticStyle: {
                                        position: "absolute",
                                        top: "2px",
                                        right: "35px"
                                      },
                                      attrs: {
                                        label: "Todos",
                                        color: "blue lighten-1",
                                        "hide-details": "",
                                        dense: ""
                                      },
                                      on: { click: _vm.selectAllCourses },
                                      model: {
                                        value: _vm.bSelectAllCourses,
                                        callback: function($$v) {
                                          _vm.bSelectAllCourses = $$v
                                        },
                                        expression: "bSelectAllCourses"
                                      }
                                    }),
                                    _c(
                                      "v-chip-group",
                                      {
                                        attrs: { column: "", multiple: "" },
                                        model: {
                                          value: _vm.coursesSelected,
                                          callback: function($$v) {
                                            _vm.coursesSelected = $$v
                                          },
                                          expression: "coursesSelected"
                                        }
                                      },
                                      [
                                        _c(
                                          "emc-util-scroll",
                                          { attrs: { height: "320px" } },
                                          _vm._l(_vm.courses, function(course) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: course.id,
                                                attrs: {
                                                  filter: "",
                                                  outlined: "",
                                                  value: course.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      course.name_complete
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { staticClass: "pa-2", attrs: { step: "2" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("div", { staticClass: "subtitle-1" }, [
                                  _vm._v("Selecione as turmas")
                                ]),
                                _c("v-checkbox", {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "2px",
                                    left: "200px"
                                  },
                                  attrs: {
                                    label: "Todos",
                                    color: "blue lighten-1",
                                    "hide-details": "",
                                    dense: ""
                                  },
                                  model: {
                                    value: _vm.bSelectAllGroups,
                                    callback: function($$v) {
                                      _vm.bSelectAllGroups = $$v
                                    },
                                    expression: "bSelectAllGroups"
                                  }
                                }),
                                _c(
                                  "v-chip-group",
                                  {
                                    attrs: { column: "", multiple: "" },
                                    model: {
                                      value: _vm.groupsSelected,
                                      callback: function($$v) {
                                        _vm.groupsSelected = $$v
                                      },
                                      expression: "groupsSelected"
                                    }
                                  },
                                  [
                                    _c(
                                      "emc-util-scroll",
                                      { attrs: { height: "320px" } },
                                      _vm._l(_vm.groups, function(group, i) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: i,
                                            attrs: { filter: "", outlined: "" }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(group.name) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v("Selecione as disciplinas")
                                ]),
                                _c(
                                  "v-row",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "8px",
                                      right: "35px"
                                    }
                                  },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        label: "Exibir todas",
                                        color: "blue lighten-1",
                                        "hide-details": "",
                                        dense: ""
                                      },
                                      model: {
                                        value: _vm.bShowAllJoins,
                                        callback: function($$v) {
                                          _vm.bShowAllJoins = $$v
                                        },
                                        expression: "bShowAllJoins"
                                      }
                                    }),
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Selecionar Todas",
                                        color: "blue lighten-1",
                                        "hide-details": "",
                                        dense: ""
                                      },
                                      model: {
                                        value: _vm.bSelectAllJoins,
                                        callback: function($$v) {
                                          _vm.bSelectAllJoins = $$v
                                        },
                                        expression: "bSelectAllJoins"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.joinsFilters.length
                                  ? _c(
                                      "emc-util-scroll",
                                      { attrs: { height: "320px" } },
                                      _vm._l(_vm.joinsFilters, function(
                                        join,
                                        index
                                      ) {
                                        return _c(
                                          "v-alert",
                                          {
                                            key: index,
                                            staticClass: "pa-1 ma-1",
                                            attrs: {
                                              color: join.color,
                                              border: "left",
                                              elevation: "1",
                                              tile: "",
                                              "colored-border": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-app-bar",
                                              {
                                                staticClass: "elevation-0",
                                                attrs: {
                                                  color: "white",
                                                  "max-height": "40px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  { staticClass: "subtitle-1" },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          "open-on-hover": "",
                                                          top: "",
                                                          "offset-y": ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "pl-0",
                                                                          attrs: {
                                                                            text:
                                                                              ""
                                                                          }
                                                                        },
                                                                        "v-btn",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            join.preview
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _vm.isMultCampi(
                                                                        join
                                                                      ) > 1
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "orange"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-map-marker-plus-outline"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      join.byEquivalence
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "primary"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-code-equal"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass: "pb-2",
                                                            attrs: {
                                                              outlined: "",
                                                              tile: "",
                                                              "max-width":
                                                                "400px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-app-bar",
                                                              {
                                                                staticClass:
                                                                  "elevation-0",
                                                                attrs: {
                                                                  dense: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-toolbar-title",
                                                                  {
                                                                    staticClass:
                                                                      "text-body-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Informações "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "mb-2"
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-caption pl-3 grey--text text--darken-1"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Disciplina:"
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      join.name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-caption pl-3 grey--text text--darken-1"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Carga Horária:"
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      join.work_load
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-caption pl-3 grey--text text--darken-1"
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    "Tipo de Carga Horária:"
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      join
                                                                        .grids[0]
                                                                        .work_load_type
                                                                        .name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _vm.isMultCampi(
                                                              join
                                                            ) > 1
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-caption pl-3 grey--text text--darken-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "Campus:"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.getCampi(
                                                                            join
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("v-spacer"),
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    value: index,
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value: _vm.indexJoins,
                                                    callback: function($$v) {
                                                      _vm.indexJoins = $$v
                                                    },
                                                    expression: "indexJoins"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "pl-4 pb-2" },
                                              [
                                                _vm.isMultCampi(join) > 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption grey--text font-weight-light"
                                                      },
                                                      [
                                                        _vm._v("Campus: "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "font-weight-black"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.isMultCampi(
                                                                  join
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" - ")
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption grey--text font-weight-light"
                                                  },
                                                  [
                                                    _vm._v("Turmas: "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-black"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            join.grids.length
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" - Alunos: "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-black"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(join.students)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "subtitle-2 orange--text"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "orange" } },
                                          [_vm._v("mdi-alert")]
                                        ),
                                        _vm._v(
                                          " Não há sugestão de novas junções para as turmas selecionadas!"
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { staticClass: "pa-0", attrs: { step: "3" } },
                      [
                        _c(
                          "emc-util-scroll",
                          { attrs: { height: "400px" } },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-1",
                                attrs: { "no-gutters": "" }
                              },
                              _vm._l(_vm.selectedJoins, function(item, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: index,
                                    staticClass: "pa-2",
                                    attrs: {
                                      cols: "12",
                                      sm: "6",
                                      md: "6",
                                      lg: "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        attrs: {
                                          outlined: "",
                                          tile: "",
                                          color: item.is_error ? "red" : ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-app-bar",
                                          {
                                            staticClass: "elevation-0",
                                            attrs: { dense: "" }
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                value: item,
                                                color: "success",
                                                "hide-details": ""
                                              },
                                              model: {
                                                value: _vm.selected,
                                                callback: function($$v) {
                                                  _vm.selected = $$v
                                                },
                                                expression: "selected"
                                              }
                                            }),
                                            _c(
                                              "v-toolbar-title",
                                              { staticClass: "subtitle-1" },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "success"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.selecionarGrid(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [_vm._v("mdi-plus")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(" Adicionar Turma ")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          _vm._l(item.grids, function(grid) {
                                            return _c(
                                              "v-list-item",
                                              { key: grid.id },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {},
                                                                            "span",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getTextJoin(
                                                                                grid
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getTextJoin(
                                                                    grid
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    grid.subGroup
                                                      ? _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                grid.subGroup
                                                                  .name
                                                              )
                                                            }
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          grid.group.campus
                                                            .abbreviation
                                                        )
                                                      }
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          grid.group.shift.name
                                                        )
                                                      }
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          "Código: " +
                                                            (grid.code
                                                              ? grid.code
                                                              : "")
                                                        )
                                                      }
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          grid.work_load_type
                                                            .name +
                                                            " (" +
                                                            grid.work_load +
                                                            ")"
                                                        )
                                                      }
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          "Alunos: " +
                                                            (grid.students
                                                              ? grid.students
                                                              : "")
                                                        )
                                                      }
                                                    }),
                                                    _c("v-list-item-subtitle", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          "Professor: " +
                                                            (grid.teacher
                                                              ? grid.teacher
                                                                  .name
                                                              : "")
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-action",
                                                  { staticClass: "align-end" },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        value: grid,
                                                        disabled: !!grid.join,
                                                        "hide-details": ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.gridChecked(
                                                            item,
                                                            grid
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectedGrids,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectedGrids",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectedGrids"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "ma-0 white" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-divider", {
                                                  staticClass: "my-2"
                                                }),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("mdi-account")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption grey--text font-weight-light"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.students) +
                                                        " alunos"
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption grey--text font-weight-light"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Carga horária total " +
                                                        _vm._s(
                                                          item.work_load_amount
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-progress-linear",
                                                  {
                                                    attrs: {
                                                      "background-color":
                                                        "red lighten-3",
                                                      color: "success",
                                                      height: "25"
                                                    },
                                                    model: {
                                                      value:
                                                        item.work_load_percent,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "work_load_percent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.work_load_percent"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "caption"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.work_load_amount -
                                                              item.work_load
                                                          ) +
                                                            " carga(s) horária(s) otimizada(s)"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.showListErrors
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm.error.errors.grids
                    ? _c(
                        "ul",
                        _vm._l(_vm.error.errors.grids, function(error, i) {
                          return _c("li", { key: i }, [
                            _vm._v(" " + _vm._s(error) + " ")
                          ])
                        }),
                        0
                      )
                    : _c(
                        "ul",
                        _vm._l(_vm.error.errors, function(error, i) {
                          return _c("li", { key: i }, [
                            _vm._v(" " + _vm._s(error) + " ")
                          ])
                        }),
                        0
                      )
                ])
              : _vm._e(),
            _c("emc-alert-snackbar", {
              attrs: {
                show: _vm.showMessage,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              },
              on: {
                "update:show": function($event) {
                  _vm.showMessage = $event
                },
                "update:message": function($event) {
                  _vm.message = $event
                }
              }
            }),
            _c("emc-loader-progress", { attrs: { show: _vm.progress } }),
            _c("sys-find-grid", {
              attrs: {
                show: _vm.showSelectGrid,
                singleSelect: false,
                validateExistJoin: ""
              },
              on: {
                "update:show": function($event) {
                  _vm.showSelectGrid = $event
                },
                onSelect: function($event) {
                  return _vm.selectGrid($event)
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _vm.step == 1
              ? _c(
                  "span",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          loading: _vm.loadingStep,
                          disabled: _vm.coursesSelected.length < 1,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.loadGroups()
                          }
                        }
                      },
                      [
                        _vm._v(" Próximo "),
                        _c("v-icon", [_vm._v("mdi-step-forward")])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.step == 2
              ? _c(
                  "span",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", text: "" },
                        on: {
                          click: function($event) {
                            _vm.step = 1
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-step-backward")]),
                        _vm._v("Voltar")
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          loading: _vm.loadingStep,
                          disabled:
                            _vm.groupsSelected.length < 1 ||
                            _vm.indexJoins.length < 1,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.toConfirme()
                          }
                        }
                      },
                      [
                        _vm._v(" Próximo "),
                        _c("v-icon", [_vm._v("mdi-step-forward")])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", disabled: _vm.loading, text: "" },
                        on: {
                          click: function($event) {
                            _vm.step = 2
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-step-backward")]),
                        _vm._v("Voltar")
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          disabled: _vm.selected.length < 1 || _vm.loading,
                          color: "success"
                        },
                        on: {
                          click: function($event) {
                            _vm.showSaving = true
                          }
                        }
                      },
                      [
                        _vm._v(" Salvar "),
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-content-save-all-outline")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c("emc-alert-modal", {
              attrs: {
                text: "Deseja salvar as junções selecionadas?",
                disabled: _vm.loading,
                show: _vm.showSaving
              },
              on: {
                "update:show": function($event) {
                  _vm.showSaving = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            loading: _vm.loading,
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _vm._v("Salvar "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-content-save-all-outline")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }