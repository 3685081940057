var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _vm.title
            ? _c(
                "v-card-title",
                { class: _vm.color, attrs: { dark: "" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "single-select": false,
                  "item-key": _vm.itemKey,
                  "show-select": "",
                  "fixed-header": "",
                  height: "300",
                  "hide-default-footer": true,
                  "items-per-page": -1
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "12",
                                  sm: _vm.showUpdate ? 9 : 12
                                }
                              },
                              [
                                _c("v-file-input", {
                                  attrs: {
                                    accept: ".csv",
                                    placeholder: "Selecione um arquivo .csv",
                                    label: "Arquivo"
                                  },
                                  on: { change: _vm.onFileChange },
                                  model: {
                                    value: _vm.fileUpload,
                                    callback: function($$v) {
                                      _vm.fileUpload = $$v
                                    },
                                    expression: "fileUpload"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.showUpdate
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-switch", {
                                      attrs: { label: "Atualizar Dados" },
                                      model: {
                                        value: _vm.update,
                                        callback: function($$v) {
                                          _vm.update = $$v
                                        },
                                        expression: "update"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.failures.length > 0
                          ? _c(
                              "v-alert",
                              {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  type: "error"
                                }
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color:
                                                          "success darken-1",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.downloadErrors()
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-microsoft-excel"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1362384655
                                    )
                                  },
                                  [_c("span", [_vm._v(" Lista de erros ")])]
                                ),
                                _vm._v(" Houve erros na importação em "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.failures.length))
                                ]),
                                _vm._v(" linhas! ")
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-expansion-panels",
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c(
                                  "v-expansion-panel-header",
                                  {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "actions",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success darken-1",
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    $event.stopPropagation()
                                                                    return _vm.download()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-microsoft-excel"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(" Baixar layout ")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v(" $expand ")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(
                                      " Mais informações sobre os campos... "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-alert",
                                      { attrs: { dense: "", type: "info" } },
                                      [
                                        _c(
                                          "ul",
                                          _vm._l(_vm.headers, function(header) {
                                            return _c(
                                              "li",
                                              { key: header.value },
                                              [
                                                header.description
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          header.text +
                                                            ": " +
                                                            header.description
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(header.text)
                                                      )
                                                    ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.errorsList
                          ? _c("v-divider", {
                              attrs: {
                                dark: true,
                                light: false,
                                inset: "",
                                vertical: ""
                              }
                            })
                          : _vm._e(),
                        _vm.errorsList
                          ? _c("v-alert", { attrs: { type: "error" } }, [
                              _c(
                                "ul",
                                _vm._l(_vm.errorsList, function(error) {
                                  return _c("li", { key: error.key }, [
                                    _vm._v(" " + _vm._s(error) + " ")
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e(),
                        _c("v-divider", {
                          attrs: {
                            dark: true,
                            light: false,
                            inset: "",
                            vertical: ""
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.loading },
                  nativeOn: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.update ? "indigo" : "success",
                    loading: _vm.loading,
                    disabled: _vm.loading || _vm.selected.length == 0
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.save()
                    }
                  }
                },
                [
                  _vm._v(_vm._s(_vm.update ? "Atualizar" : "Importar") + " "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-cloud-upload")
                  ])
                ],
                1
              ),
              _vm._t("actions")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }