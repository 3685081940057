var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            dark: "",
                            large: "",
                            color: "grey"
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Calendário")]),
                          _c("v-list-item-subtitle", [
                            _vm._v("Configurações de Impressão")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.fav ? "red--text" : "",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.fav = !_vm.fav
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.menu = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-container",
                        [
                          _vm.model && _vm.model.id
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("emc-avatar-calendar-setting", {
                                        attrs: { width: "80", height: "80" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      dense: "",
                                      placeholder: "Calendário Acadêmico {Ano}",
                                      "error-messages": _vm.errors.title,
                                      label: "Título*"
                                    },
                                    model: {
                                      value: _vm.model.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "title", $$v)
                                      },
                                      expression: "model.title"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("s-switch", {
                                    attrs: {
                                      "text-helper": "Exibir Logo no cabeçalho",
                                      inset: "",
                                      label: "Exibir Logo"
                                    },
                                    model: {
                                      value: _vm.model.show_logo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "show_logo", $$v)
                                      },
                                      expression: "model.show_logo"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("s-switch", {
                                    attrs: {
                                      "text-helper":
                                        "Exibir Resumo de contador de dias letivos",
                                      inset: "",
                                      label: "Exibir Resumo"
                                    },
                                    model: {
                                      value: _vm.model.show_resume,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "show_resume", $$v)
                                      },
                                      expression: "model.show_resume"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Salvar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }