var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.name,
                        label: "Nome*"
                      },
                      model: {
                        value: _vm.model.name,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "name", $$v)
                        },
                        expression: "model.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-textarea", {
                      attrs: { label: "Descrição" },
                      model: {
                        value: _vm.model.description,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "description", $$v)
                        },
                        expression: "model.description"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-switch", {
                      attrs: {
                        color: "primary",
                        label: "Ativo",
                        inset: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.model.active,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "active", $$v)
                        },
                        expression: "model.active"
                      }
                    }),
                    _c("v-switch", {
                      attrs: {
                        color: "primary",
                        label: "Possui cobrança",
                        inset: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.model.has_charge,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "has_charge", $$v)
                        },
                        expression: "model.has_charge"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }