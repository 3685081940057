<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />
        <v-simple-table v-else>
            <template v-slot:default>
                <!-- <caption>
                    <strong></strong>
                </caption> -->
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-center" v-for="day in days.filter(i => i.visible)" :key="day.id">{{day.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="hour in hoursFiltered" :key="hour.id">
                        <td>
                            {{ hour.start }}
                            <br/>
                            {{ hour.end }}
                        </td>
                        <td v-for="day in days.filter(i => i.visible)" :key="day.id">
                            <span v-for="(grid, i) in getGrids(hour.id, day.id)" :key="i">
                                <p class="text-center ma-0">{{ getDisciplineName(grid) }}</p>
                                <p class="text-center ma-0">{{ grid.work_load_type_name }}</p>
                                <p class="text-center ma-0">
                                    <v-icon mr-2 v-if="renderComponent && grid.join_id" x-small color="indigo">mdi-call-split</v-icon>
                                    {{ grid.group_name }}
                                </p>
                                <p  v-if="(!teacher || !teacher.id) && grid.teacher_name" class="text-center ma-0">
                                    <span v-if="grid.in_hiring">A Contratar</span>
                                    <span v-else>{{ grid.teacher_name }}</span>
                                </p>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tfoot mt-2 v-if="!hideJoins && gridListJoins.length > 0">
                    <tr>
                        <td colspan="7">
                            <v-icon mr-2 x-small color="indigo">mdi-call-split</v-icon>
                            <strong class="text-center ma-0">Disciplinas com Junção:</strong>
                            <ul type="none">
                                <li :key="i" v-for="(grid, i) in gridListJoins">
                                    {{ grid.discipline_name + ' (' + grid.work_load_type_initials + '): ' + getGroupNameJoinLegend(grid) }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'SysTeacherConfirmedTimes',
    data: function() {
        return {
            loading: false,
            classes: [],
            hours: [],
            joins:  [],
            renderComponent: true,
            gridListJoins: [],
            loadingId: null,
            days: [
                { id: 1, name: 'Domingo',   visible: false },
                { id: 2, name: 'Segunda',   visible: true  },
                { id: 3, name: 'Terça',     visible: true  },
                { id: 4, name: 'Quarta',    visible: true  },
                { id: 5, name: 'Quinta',    visible: true  },
                { id: 6, name: 'Sexta',     visible: true  },
                { id: 7, name: 'Sábado',    visible: true  },
            ]
        }
    },
    props: {
        teacher: {
            type: Object,
            default: null
        },
        workTime: {
            type: Object,
            required: true
        },
        token: {
            type: String,
            default: null
        },
        grid: {
            type: Object,
            default: null
        },
        hideJoins: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('schedule', ['ActionIndexClassesOptimized']),
        ...mapActions('hour', ['ActionGetHours', 'ActionGetHoursIndexToken']),
        ...mapActions('join', ['ActionFindJoinByGroupId', 'ActionFindJoinByGroupIdToken']),

        getHours(){
            
            var method = this.token ? 'ActionGetHoursIndexToken' : 'ActionGetHours';

            var payload = {};

            if(this.token){
                payload.token = this.token;
            }
            
            this[method](payload)
                .then((r) => {
                    this.hours = r.data;
                })
                .finally(() => {
                   
                })
        },

        getData() {

            this.gridListJoins = [];
            var joins = [];
            this.classes = [];

            if((!this.teacher || !this.teacher.id) && (!this.grid || !this.grid.id)){
                return;
            }

            if(this.teacher){
                if(this.loadingId && (this.loadingId === this.teacher.id)) {
                    return;
                }
            }else{
                if(this.loadingId && (this.loadingId === this.grid.id)) {
                    return;
                }
            }

            this.loading = true;
            this.loadingId = this.teacher ? this.teacher.id : this.grid.id;
            
            var payload = {
                type:           this.teacher ? 'teacher' : 'grid',
                item_id:        this.teacher ? this.teacher.id : this.grid.id,
                work_time_id:   this.workTime.id,

            };

            this.ActionIndexClassesOptimized(payload)
                .then((r) => {
                    
                    this.classes = r.data;

                    this.classes.forEach(classe => {
                        if(classe.join_id && !joins.includes(classe.join_id)){
                            joins.push(classe.join_id);
                            this.gridListJoins.push(classe);
                            this.loadJoin(classe);
                        }
                    });

                })
                .finally(() => {
                    this.loading = false;
                    this.loadingId = null;
                })
        },

        loadJoin(grid){

            if(!grid.join_id){
                return;
            }

            var method = this.token ? 'ActionFindJoinByGroupIdToken' : 'ActionFindJoinByGroupId';
            var payload;
            
            if(this.token){
                payload = {
                    id: grid.group_id,
                    token: this.token,
                };
            }else{
                payload = {
                    group_id: grid.group_id,
                    showAllCampuses: true,
                    with: "grids,grids.discipline,grids.group"
                };
            }
           
            this[method](payload).then((res) => {
                   
                var join = res.data.find(j => j.id == grid.join_id);
                if(join){
                    grid.join = {};
                    grid.join.grids = join.grids;
                }

                this.forceRerender();
                
            });
        },

        getGrids(hour_id, day_id){
            var grids = [];
            var grid = this.gridFiltered(hour_id, day_id);

            if(grid){
                grids.push(grid);
            }

            return grids;
        },

        getDisciplineName(grid){

            if(grid.group_number){
                return "D" + grid.group_number + '-' + grid.discipline_name;
            }else{
                return grid.discipline_name;
            }

        },

        gridFiltered(hour_id, day_id) {

            return this.classes.find((classe) => {
                    return (classe.day_of_week_id === day_id && classe.hour_id === hour_id)
                });
        },

        getGroupNameJoinLegend(grid){

            if(grid && grid.join && grid.join.grids){
                
                var text = '';
                
                grid.join.grids.forEach(item => {
                    
                    if(text != ''){
                        text += ', ';
                    }
                    
                    text += item.group.name;

                });

                return text;

            }else{
                return "";
            }

        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
            });
        },
    },
    computed: {
        
        hoursFiltered: function() {
            
            if(!this.classes || this.classes.length == 0){
                return [];
            }

            return this.hours.filter((hour) => {
                return this.classes.find((classe) => {
                   return classe.hour_id == hour.id;
                })
            })
        },
    },
    created() {
        this.getHours();
        this.getData();
    },
    watch: {
        teacher: function(){
            this.getData();
        },
        grid: function(){
            this.getData();
        }
    }
}
</script>