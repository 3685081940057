export default [
    {
        path: '/dynamic-allocation',
        name: 'dynamic-allocation',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Ambiente',
            icon: 'mdi-puzzle-outline',
            breadcrumbs: [
                { text: 'Alocação dinâmica', disabled: true, href: '/' },
                { text: 'Horário', disabled: true, href: '/' },
            ],
        }
    },
    {
        path: '/dynamic-allocation-results',
        name: 'dynamic-allocation-results',
        component: () => import ('./pages/Result'),
        meta: {
            title: 'Resultados',
            icon: 'mdi-puzzle-outline',
        }
    }
]