var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-register-teacher", {
        attrs: {
          "only-hiring": "",
          model: {},
          icon: "event",
          show: _vm.showFormAddTeacher
        },
        on: {
          onCompleted: function($event) {
            return _vm.pesquisar("", false, _vm.is_hiring)
          },
          "update:show": function($event) {
            _vm.showFormAddTeacher = $event
          }
        }
      }),
      _c("sys-register-base", {
        attrs: {
          showButton: false,
          disabled: _vm.updatingAttribution || _vm.updatingHiring,
          model: _vm.model,
          title: "Atribuição de Professor",
          icon: "mdi-school-outline",
          show: _vm.dialog,
          maxWidth: "90%",
          showRequiredFields: false
        },
        on: {
          "update:model": function($event) {
            _vm.model = $event
          },
          "update:show": function($event) {
            _vm.dialog = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "tools",
              fn: function() {
                return [
                  _vm.tab == 4
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "primary darken-1",
                                              icon: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                _vm.showTextActivityId = !_vm.showTextActivityId
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-clipboard-list-outline")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3577996616
                          )
                        },
                        [_c("span", [_vm._v(" Vincular Atividade pelo ID ")])]
                      )
                    : _vm._e(),
                  _vm.tab == 4
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "indigo darken-1",
                                              icon: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                _vm.showFormAddActivity = true
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-plus")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4148295612
                          )
                        },
                        [_c("span", [_vm._v(" Vincular Nova Atividade ")])]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "title",
              fn: function() {
                return [
                  !!_vm.model.id
                    ? _c("div", [
                        _c(
                          "span",
                          [
                            _c("emc-info-help", {
                              attrs: { title: "Mais informações..." },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "text",
                                    fn: function() {
                                      return [
                                        _c("span", [
                                          _c("strong", [_vm._v("Campus: ")]),
                                          _vm._v(
                                            _vm._s(
                                              _vm.model.group.campus
                                                .abbreviation
                                            )
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("strong", [_vm._v("Curso: ")]),
                                          _vm._v(
                                            _vm._s(_vm.model.group.course.name)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("strong", [_vm._v("Turma: ")]),
                                          _vm._v(_vm._s(_vm.model.group.name))
                                        ]),
                                        _c("br"),
                                        _vm.model.group.category
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v("Categoria: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.model.group.category.name
                                                )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _c("span", [
                                          _c("strong", [
                                            _vm._v("Turno da Turma: ")
                                          ]),
                                          _vm._v(
                                            _vm._s(_vm.model.group.shift.name)
                                          )
                                        ]),
                                        _c("br"),
                                        !!_vm.model.shift_id
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v("Turno da Disciplina: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(_vm.model.shift.name)
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _c("span", [
                                          _c("strong", [
                                            _vm._v("Disciplina: ")
                                          ]),
                                          _vm._v(
                                            _vm._s(_vm.model.discipline.name)
                                          )
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _c("strong", [
                                            _vm._v("Carga Horária: ")
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              _vm.model.work_load_type.name +
                                                " (" +
                                                _vm.model.work_load +
                                                ")"
                                            )
                                          )
                                        ]),
                                        _vm.model.work_load_pay !== null
                                          ? _c("span", [
                                              _c("br"),
                                              _c("strong", [
                                                _vm._v("Carga Horária Paga: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.model.work_load_pay
                                                ) + " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.model.workTimeDate
                                          ? _c("span", [
                                              _c("br"),
                                              _c("strong", [
                                                _vm._v("Período: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.model.workTimeDate.name
                                                ) + " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _vm.model.group.baseGroups &&
                                        !!_vm.model.group.baseGroups.length > 0
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v("Ingressantes: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(_vm.getBaseGroups())
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _vm.model.joins
                                          ? _c("span", [
                                              _c("strong", [
                                                _vm._v("Turmas da Junção: ")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTextoJuncao(
                                                    _vm.model.joins
                                                  )
                                                ) + " "
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "blue--text" },
                                          [
                                            _c("strong", [
                                              _vm._v("Professor Atual: ")
                                            ]),
                                            _vm._v(
                                              _vm._s(_vm.actualTeacher) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2345964304
                              )
                            })
                          ],
                          1
                        ),
                        _c("span", [
                          _c("strong", [_vm._v("Curso: ")]),
                          _vm._v(_vm._s(_vm.model.group.course.name))
                        ]),
                        _vm._v(" "),
                        !_vm.model.shift_id
                          ? _c("span", [
                              _c("strong", [_vm._v("Turno: ")]),
                              _vm._v(_vm._s(_vm.model.group.shift.name))
                            ])
                          : _c("span", [
                              _c("strong", [_vm._v("Turno: ")]),
                              _vm._v(_vm._s(_vm.model.shift.name))
                            ]),
                        _vm._v(" "),
                        _vm.model.group.category
                          ? _c("span", [
                              _c("strong", [_vm._v("Turma: ")]),
                              _vm._v(
                                _vm._s(
                                  _vm.model.group.name +
                                    " (" +
                                    _vm.model.group.category.name +
                                    ")"
                                )
                              )
                            ])
                          : _c("span", [
                              _c("strong", [_vm._v("Turma: ")]),
                              _vm._v(_vm._s(_vm.model.group.name))
                            ]),
                        _vm._v(" / "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.model.discipline.name +
                                " - " +
                                _vm.model.work_load_type.name +
                                " (" +
                                _vm.model.work_load +
                                ")"
                            )
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "form",
              fn: function() {
                return [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        vertical: !(
                          _vm.$vuetify.breakpoint.name == "xs" ||
                          _vm.$vuetify.breakpoint.name == "sm"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.refreshHistory()
                        }
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        { staticStyle: { "justify-content": "left" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-account ")
                          ]),
                          _vm._v(" Atribuição ")
                        ],
                        1
                      ),
                      _vm.showAttributionGuide
                        ? _c(
                            "v-tab",
                            { staticStyle: { "justify-content": "left" } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(" mdi-check-decagram ")
                              ]),
                              _vm._v(" Status ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab",
                        { staticStyle: { "justify-content": "left" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-timetable ")
                          ]),
                          _vm._v(" Horário ")
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        { staticStyle: { "justify-content": "left" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" mdi-chart-timeline-variant ")
                          ]),
                          _vm._v(" Histórico ")
                        ],
                        1
                      ),
                      _vm.model.payment_by_activity
                        ? _c(
                            "v-tab",
                            { staticStyle: { "justify-content": "left" } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(" mdi-clipboard-list-outline ")
                              ]),
                              _vm._v(" Atividades ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-container",
                            [
                              _vm.attribution.id && !_vm.attribution.exported
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                border: "top",
                                                "colored-border": "",
                                                type: "info"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Para substituição, remova o professor atual na tela principal. "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.attribution.exported
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                dense: "",
                                                outlined: "",
                                                type: "error"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Atribuição exportada na folha de pagamento. Para remover ou finalizar acesse a guia "
                                              ),
                                              _c("strong", [_vm._v("STATUS")]),
                                              _vm._v(". ")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          error: !!_vm.errors.teacher_id,
                                          "error-messages":
                                            _vm.errors.teacher_id,
                                          value:
                                            _vm.model && _vm.model.teacher
                                              ? _vm.model.teacher.name
                                              : "",
                                          label: "Professor Selecionado",
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "1" } },
                                    [
                                      _vm.renderComponent
                                        ? _c("v-text-field", {
                                            attrs: {
                                              error: !!_vm.errors.work_load,
                                              "error-messages":
                                                _vm.errors.work_load,
                                              label: "CH",
                                              disabled:
                                                _vm.disabledFields ||
                                                !_vm.attribution.is_paid
                                            },
                                            model: {
                                              value: _vm.attribution.work_load,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.attribution,
                                                  "work_load",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "attribution.work_load"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "2" } },
                                    [
                                      _c("emc-calendar-date-picker2", {
                                        attrs: {
                                          model: _vm.attribution.date_start,
                                          label: "Início",
                                          errorMessages: _vm.errors.date_start,
                                          show: _vm.dialog,
                                          min: _vm.minDate,
                                          max: _vm.maxDate,
                                          disabled: _vm.disabledFields
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            return _vm.$set(
                                              _vm.attribution,
                                              "date_start",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "2" } },
                                    [
                                      _c("emc-calendar-date-picker2", {
                                        attrs: {
                                          model: _vm.attribution.date_end,
                                          label: "Fim",
                                          errorMessages: _vm.errors.date_end,
                                          show: _vm.dialog,
                                          min: _vm.minDate,
                                          max: _vm.maxDate,
                                          disabled: _vm.disabledFields
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            return _vm.$set(
                                              _vm.attribution,
                                              "date_end",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.showAttributionTemp
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "2" } },
                                        [
                                          _c("s-switch", {
                                            attrs: {
                                              "text-helper":
                                                "Aula eventual não será considerada na composição de carga horária",
                                              inset: "",
                                              label: "Eventual"
                                            },
                                            model: {
                                              value: _vm.attribution.is_temp,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.attribution,
                                                  "is_temp",
                                                  $$v
                                                )
                                              },
                                              expression: "attribution.is_temp"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showAttributionPay
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "2" } },
                                        [
                                          _c("s-switch", {
                                            attrs: {
                                              "text-helper":
                                                "Carga Horária Paga",
                                              disabled:
                                                _vm.disabledFields ||
                                                (!(
                                                  _vm.model.work_load_type &&
                                                  _vm.model.work_load_type
                                                    .has_paid_workload
                                                ) &&
                                                  !_vm.user.is_master),
                                              inset: "",
                                              label: "CH Paga"
                                            },
                                            on: {
                                              input: _vm.changeWorkLoadPay
                                            },
                                            model: {
                                              value: _vm.attribution.is_paid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.attribution,
                                                  "is_paid",
                                                  $$v
                                                )
                                              },
                                              expression: "attribution.is_paid"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "2" } },
                                    [
                                      _c("s-switch", {
                                        attrs: {
                                          disabled:
                                            _vm.disabledFields ||
                                            (_vm.model != null &&
                                              _vm.model.discipline != null &&
                                              _vm.model.discipline
                                                .unsanitary_type_id != null &&
                                              _vm.model.work_load_type
                                                .is_practice == 1),
                                          "text-helper":
                                            "Considerar pagamento de adicional de insalubridade",
                                          inset: "",
                                          label: "Insalubridade"
                                        },
                                        model: {
                                          value: _vm.attribution.is_unsanitary,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.attribution,
                                              "is_unsanitary",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "attribution.is_unsanitary"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    attrs: { cols: "12", sm: "2" }
                                  })
                                ],
                                1
                              ),
                              !_vm.attribution.id
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("s-switch", {
                                                staticClass: "ml-4",
                                                attrs: {
                                                  "text-helper":
                                                    "Informar um professor a contratar",
                                                  inset: "",
                                                  label: "Em contratação",
                                                  dense: ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.pesquisar(
                                                      "",
                                                      false,
                                                      _vm.is_hiring
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.is_hiring,
                                                  callback: function($$v) {
                                                    _vm.is_hiring = $$v
                                                  },
                                                  expression: "is_hiring"
                                                }
                                              }),
                                              _vm.is_hiring
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          icon:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            $event.stopPropagation()
                                                                            _vm.showFormAddTeacher = true
                                                                          }
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          btn:
                                                                            "",
                                                                          color:
                                                                            "green darken-1"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2904936057
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Novo professor a contratar"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "4" } },
                                        [
                                          _c("s-switch", {
                                            staticClass: "ml-4",
                                            attrs: {
                                              loading: _vm.loadingOnlyCampus,
                                              "text-helper":
                                                "Exibir apenas professores que possuem vínculo na unidade da oferta",
                                              inset: "",
                                              label: "Professores com vinculo",
                                              dense: ""
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.pesquisar(
                                                  _vm.search,
                                                  4,
                                                  _vm.is_hiring
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.showOnlyCampus,
                                              callback: function($$v) {
                                                _vm.showOnlyCampus = $$v
                                              },
                                              expression: "showOnlyCampus"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.attribution.id
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "elevation-1",
                                            attrs: {
                                              headers: _vm.headersProfessores,
                                              items: _vm.itemsProfessores,
                                              search: _vm.search,
                                              "item-key": "id",
                                              "single-select": "",
                                              "fixed-header": "",
                                              "hide-default-footer": "",
                                              height: "250"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "top",
                                                  fn: function() {
                                                    return [
                                                      _c("v-text-field", {
                                                        ref: "pesquisa",
                                                        staticClass: "mx-4",
                                                        attrs: {
                                                          id: "txt-pesquisa",
                                                          label: "Pesquisar...",
                                                          required: "",
                                                          "append-icon":
                                                            "search",
                                                          loading: _vm.loading,
                                                          autofocus: ""
                                                        },
                                                        on: {
                                                          keyup: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.pesquisar(
                                                              _vm.search,
                                                              "enter"
                                                            )
                                                          },
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.onChangeSearch(
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "append",
                                                              fn: function() {
                                                                return [
                                                                  _vm.showAddressTeacher
                                                                    ? _c(
                                                                        "emc-button-icon",
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "mdi-google-maps",
                                                                            text:
                                                                              "Ver professores próximos da Unidade",
                                                                            color:
                                                                              "purple"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              _vm.showDistances = true
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "emc-button-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "mdi-priority-high",
                                                                        text:
                                                                          "Ver professores com atribuição prioritária",
                                                                        color:
                                                                          "primary"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.pesquisar(
                                                                            "",
                                                                            3
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm.workTime
                                                                    .show_disciplines_interest
                                                                    ? _c(
                                                                        "emc-button-icon",
                                                                        {
                                                                          attrs: {
                                                                            icon:
                                                                              "mdi-star-half-full",
                                                                            text:
                                                                              "Ver professores que tem interesse em lecionar a disciplina",
                                                                            color:
                                                                              "amber"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.preventDefault()
                                                                              $event.stopPropagation()
                                                                              return _vm.pesquisar(
                                                                                "",
                                                                                1
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "emc-button-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "mdi-check-bold",
                                                                        text:
                                                                          "Ver professores que lecionaram essa disciplina",
                                                                        color:
                                                                          "green"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.pesquisar(
                                                                            "",
                                                                            2
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2 ml-2",
                                                                      attrs: {
                                                                        vertical:
                                                                          "",
                                                                        inset:
                                                                          "",
                                                                        dense:
                                                                          ""
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "emc-button-icon",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          !_vm.search ||
                                                                          _vm
                                                                            .search
                                                                            .length <
                                                                            3,
                                                                        icon:
                                                                          "search",
                                                                        text:
                                                                          "Pesquisar",
                                                                        color:
                                                                          "blue"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.pesquisar(
                                                                            _vm.search
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          1694295174
                                                        ),
                                                        model: {
                                                          value: _vm.search,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.search = $$v
                                                          },
                                                          expression: "search"
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                },
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      item
                                                        ? _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selecionarProfessor(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-left"
                                                                },
                                                                [
                                                                  _vm.selected
                                                                    .length >
                                                                    0 &&
                                                                  _vm
                                                                    .selected[0]
                                                                    .id ===
                                                                    item.id
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "blue",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-checkbox-marked-circle "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "blue",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-circle-outline "
                                                                          )
                                                                        ]
                                                                      ),
                                                                  _c(
                                                                    "emc-avatar-show-image",
                                                                    {
                                                                      attrs: {
                                                                        model: item
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.showAvatar(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c("strong", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("br"),
                                                                  _vm.isAnima()
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          item.brands &&
                                                                          item
                                                                            .brands
                                                                            .length >
                                                                            0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "text-caption grey--text text--darken-1"
                                                                                                      },
                                                                                                      "span",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " [" +
                                                                                                        _vm._s(
                                                                                                          _vm.getCampusBrands(
                                                                                                            item,
                                                                                                            "brands"
                                                                                                          )
                                                                                                        ) +
                                                                                                        "] "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getToolTipCampusBrands(
                                                                                                  item,
                                                                                                  "brands"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          item.campuses &&
                                                                          item
                                                                            .campuses
                                                                            .length >
                                                                            0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _c(
                                                                                    "v-tooltip",
                                                                                    {
                                                                                      attrs: {
                                                                                        bottom:
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "activator",
                                                                                            fn: function(
                                                                                              ref
                                                                                            ) {
                                                                                              var on =
                                                                                                ref.on
                                                                                              var attrs =
                                                                                                ref.attrs
                                                                                              return [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  _vm._g(
                                                                                                    _vm._b(
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "text-caption grey--text text--darken-1"
                                                                                                      },
                                                                                                      "span",
                                                                                                      attrs,
                                                                                                      false
                                                                                                    ),
                                                                                                    on
                                                                                                  ),
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " [" +
                                                                                                        _vm._s(
                                                                                                          _vm.getCampusBrands(
                                                                                                            item,
                                                                                                            "campuses"
                                                                                                          )
                                                                                                        ) +
                                                                                                        "] "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getToolTipCampusBrands(
                                                                                                  item,
                                                                                                  "campuses"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      small:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs: {
                                                                                              small:
                                                                                                "",
                                                                                              color:
                                                                                                "purple"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.openExtract(
                                                                                                  item
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-account-clock-outline"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Ver Horários"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  item.is_priority
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              icon:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              href:
                                                                                                item.url_linkedin,
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        "",
                                                                                                      color:
                                                                                                        "primary"
                                                                                                    }
                                                                                                  },
                                                                                                  "v-icon",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-priority-high"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Prioridade de Indicação"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item.url_linkedin
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              icon:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              href:
                                                                                                item.url_linkedin,
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        "",
                                                                                                      color:
                                                                                                        "primary"
                                                                                                    }
                                                                                                  },
                                                                                                  "v-icon",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-linkedin"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Ver Linkedin"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item.url_lattes
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              icon:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              href:
                                                                                                item.url_lattes,
                                                                                              target:
                                                                                                "_blank"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                _vm._b(
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        "",
                                                                                                      color:
                                                                                                        "primary"
                                                                                                    }
                                                                                                  },
                                                                                                  "v-icon",
                                                                                                  attrs,
                                                                                                  false
                                                                                                ),
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-school-outline"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Ver Lattes"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.workTime
                                                                    .show_disciplines_interest &&
                                                                  _vm.hasInterest(
                                                                    item
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticStyle: {
                                                                                                  cursor:
                                                                                                    "help"
                                                                                                },
                                                                                                attrs: {
                                                                                                  small:
                                                                                                    "",
                                                                                                  color:
                                                                                                    "amber"
                                                                                                }
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-star-half-full"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Tem interesse em lecionar essa disciplina"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.hasAttribution(
                                                                    item
                                                                  )
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                bottom:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs: {
                                                                                                  small:
                                                                                                    "",
                                                                                                  color:
                                                                                                    "green"
                                                                                                }
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-check-bold"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Leciona(ou) essa disciplina"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center black--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.titling
                                                                        ? item
                                                                            .titling
                                                                            .name
                                                                        : null
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        1
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        2
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        3
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        5
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        4
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-xs-center blue--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getCargaHoraria(
                                                                        item,
                                                                        -1
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.config
                                                                .showTotal
                                                                ? _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-center green--text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getCargaHoraria(
                                                                            item,
                                                                            0
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              383126559
                                            ),
                                            model: {
                                              value: _vm.selected,
                                              callback: function($$v) {
                                                _vm.selected = $$v
                                              },
                                              expression: "selected"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c("sys-teacher-attribution-status", {
                            attrs: { model: _vm.model },
                            on: {
                              onCompleted: function($event) {
                                return _vm.$emit("onCompleted")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("SysTeacherConfirmedTimes", {
                                    attrs: {
                                      workTime: _vm.workTime,
                                      grid: _vm.model
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("sys-teacher-attribution-log-table", {
                                    ref: "history",
                                    attrs: { model: _vm.model }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "3" } },
                                        [
                                          _vm.showTextActivityId
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  label: "ID Atividade",
                                                  error: _vm.errors.length > 0,
                                                  "error-messages":
                                                    _vm.errors[
                                                      "activityItems.0.id"
                                                    ],
                                                  autofocus: ""
                                                },
                                                model: {
                                                  value: _vm.activityId,
                                                  callback: function($$v) {
                                                    _vm.activityId = $$v
                                                  },
                                                  expression: "activityId"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("SysTeacherOtherActivitiesAttribution", {
                                    attrs: { attribution: _vm.attribution }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _vm.acesso &&
                  _vm.acesso.instituicao &&
                  _vm.acesso.instituicao.settings.allow_teacher_in_hiring
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              disabled:
                                                (_vm.model &&
                                                  _vm.model.in_hiring == 1) ||
                                                _vm.updatingAttribution ||
                                                _vm.updatingHiring,
                                              loading: _vm.updatingHiring,
                                              color: "red darken-1",
                                              small: "",
                                              text: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.saveHiring()
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" A Contratar ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1325015112
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " Definir que o professor está em processo de contratação "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.attribution.id
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.disableButtom,
                            loading: _vm.updatingAttribution,
                            color: "success darken-1"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.save()
                            }
                          }
                        },
                        [_vm._v(" Atribuir Professor ")]
                      )
                    : _vm._e(),
                  _vm.attribution.id && _vm.model && _vm.model.teacher_id
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.disabledFields,
                            color: "success darken-1"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.editAttribution()
                            }
                          }
                        },
                        [
                          _vm._v(" Salvar edição "),
                          _c("v-icon", [
                            _vm._v("mdi-content-save-edit-outline")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("emc-alert-modal", {
                    attrs: {
                      title: "Deseja finalizar a atribuição?",
                      text:
                        "Essa ação irá finalizar e remover a atribuição. Não será possível editar novamente!",
                      show: _vm.showFinishAttribution,
                      disabled: _vm.updatingAttribution
                    },
                    on: {
                      "update:show": function($event) {
                        _vm.showFinishAttribution = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.updatingAttribution,
                                  loading: _vm.updatingAttribution,
                                  color: "error"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.save()
                                  }
                                }
                              },
                              [
                                _vm._v("Finalizar Atribuição"),
                                _c(
                                  "v-icon",
                                  { attrs: { right: "", dark: "" } },
                                  [_vm._v("mdi-playlist-remove")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("emc-alert-modal", {
                    attrs: {
                      title: "Deseja editar a atribuição?",
                      text:
                        "Essa ação irá editar a carga horária e/ou o período informado",
                      show: _vm.showEditAttribution,
                      disabled: _vm.updatingAttribution
                    },
                    on: {
                      "update:show": function($event) {
                        _vm.showEditAttribution = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "actions",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.updatingAttribution,
                                  loading: _vm.updatingAttribution,
                                  color: "success"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.save()
                                  }
                                }
                              },
                              [
                                _vm._v(" Salvar edição"),
                                _c(
                                  "v-icon",
                                  { attrs: { right: "", dark: "" } },
                                  [_vm._v("mdi-content-save-edit-outline")]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ]
              },
              proxy: true
            },
            !_vm.attribution.id
              ? {
                  key: "footer",
                  fn: function() {
                    return [
                      _c(
                        "strong",
                        { staticClass: "d-none d-sm-flex blue--text" },
                        [_vm._v("*CH Atribuída/CH Disponível")]
                      ),
                      _c("v-spacer")
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "" },
          model: {
            value: _vm.showExtract,
            callback: function($$v) {
              _vm.showExtract = $$v
            },
            expression: "showExtract"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 grey lighten-2" },
                [
                  _vm._v(" Horários "),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-1",
                                      attrs: { icon: "", small: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.showExtract = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("SysTeacherExtract", {
                    attrs: { teacher: _vm.teacher, grid: _vm.model }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("sys-teacher-distance", {
        attrs: { show: _vm.showDistances, grid: _vm.model },
        on: {
          "update:show": function($event) {
            _vm.showDistances = $event
          }
        }
      }),
      _c("sys-register-activity-item", {
        attrs: { model: {}, grid: _vm.model, show: _vm.showFormAddActivity },
        on: {
          onCompleted: _vm.onCompletedActivityItem,
          "update:show": function($event) {
            _vm.showFormAddActivity = $event
          }
        }
      }),
      _c("emc-avatar-show", {
        attrs: {
          model: _vm.selectedTeacher,
          show: _vm.showImage,
          source: _vm.selectedTeacher ? _vm.selectedTeacher.url_avatar : null
        },
        on: {
          "update:show": function($event) {
            _vm.showImage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }