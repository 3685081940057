import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach'
import { routes as home } from '../modules/home'
import { routes as role } from '../modules/role'
import { routes as indication } from '../modules/indication'
import { routes as availability } from '../modules/availability'
import { routes as login } from '../modules/auth'
import { routes as worktime } from '../modules/worktime'
import { routes as user } from '../modules/user'
import { routes as schedule } from '../modules/schedule'
import { routes as account } from '../modules/account'
import { routes as course } from '../modules/course'
import { routes as teacher } from '../modules/teacher'
import { routes as discipline } from '../modules/discipline'
import { routes as campus } from '../modules/campus'
import { routes as group } from '../modules/group'
import { routes as ambience } from '../modules/ambience'
import { routes as institution } from '../modules/institution'
import { routes as hour } from '../modules/hour'
import { routes as grid } from '../modules/grid'
import { routes as join } from '../modules/join'
import { routes as workLoadType } from '../modules/work_load_type'
import { routes as occupationArea } from '../modules/occupation_area'
import { routes as category } from '../modules/category'
import { routes as ambienceType } from '../modules/ambience_type'
import { routes as block } from '../modules/block'
import { routes as floor } from '../modules/floor'
import { routes as categoryAmbience } from '../modules/category_ambience'
import { routes as menu } from '../modules/menu'
import { routes as resource } from '../modules/resource'
import { routes as mail } from '../modules/mail'
import { routes as educationalGroup } from '../modules/educational_group'
import { routes as brand } from '../modules/brand'
import { routes as controlPanel } from '../modules/control_panel'
import { routes as categoryGroup } from '../modules/category_group'
import { routes as calendar } from '../modules/calendar'
import { routes as eventItem } from '../modules/event_item'
import { routes as eventType } from '../modules/event_type'
import { routes as eventCategory } from '../modules/event_category'
import { routes as city } from '../modules/city'
import { routes as roomAllocation } from '../modules/room_allocation'
import { routes as curriculumCategory } from '../modules/curriculum_category'
import { routes as curriculum } from '../modules/curriculum'
import { routes as equivalence } from '../modules/equivalence'
import { routes as baseGroup } from '../modules/base_group'
import { routes as generateGrids } from '../modules/generate_grid'
import { routes as disciplineType } from '../modules/discipline_type'
import { routes as activity } from '../modules/activity'
import { routes as activityItem } from '../modules/activity_item'
import { routes as license } from '../modules/license'
import { routes as teacherLicense } from '../modules/teacher_license'
import { routes as resourceType } from '../modules/resource_type'
import { routes as dynamicGeneration } from '../modules/dynamic_generation'
import { routes as view } from '../modules/view'
import { routes as hourGroup } from '../modules/hour_group'
import { routes as chart } from '../modules/chart'
import { routes as budgetType } from '../modules/budget_type'
import { routes as budget } from '../modules/budget'
import { routes as paymentExport } from '../modules/payment_export'
import { routes as hourGroupDate } from '../modules/hour_group_date'
import { routes as bank } from '../modules/bank'
import { routes as bankData } from '../modules/bank_data'
import { routes as timeSheet } from '../modules/time_sheet'
import { routes as faq } from '../modules/faq'
import { routes as faqCategory } from '../modules/faq_category'
import { routes as faqQuestions } from '../modules/faq_questions'
import { routes as courseInfo } from '../modules/course_info'
import { routes as country } from '../modules/country'
import { routes as state } from '../modules/state'
import { routes as ambienceOccupation } from '../modules/ambience_occupation'
import { routes as teacherPosition } from '../modules/teacher_position'
import { routes as teacherContract } from '../modules/teacher_contract'
import { routes as courseLevel } from '../modules/course_level'
import { routes as contact } from '../modules/contact'
import { routes as logAccessApi } from '../modules/log_access_api'
import { routes as importLog } from '../modules/import_log'
import { routes as plan } from '../modules/plan'
import { routes as integration } from '../modules/integration'
import { routes as eventCalendarBase } from '../modules/event_calendar_base'
import { routes as term } from '../modules/term'
import { routes as management } from '../modules/management'
import { routes as webhook } from '../modules/webhook'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...home,
    ...role,
    ...indication,
    ...availability,
    ...login,
    ...worktime,
    ...user,
    ...schedule,
    ...account,
    ...course,
    ...teacher,
    ...discipline,
    ...campus,
    ...group,
    ...ambience,
    ...institution,
    ...hour,
    ...grid,
    ...join,
    ...workLoadType,
    ...occupationArea,
    ...category,
    ...ambienceType,
    ...block,
    ...floor,
    ...categoryAmbience,
    ...menu,
    ...resource,
    ...mail,
    ...educationalGroup,
    ...brand,
    ...controlPanel,
    ...categoryGroup,
    ...calendar,
    ...eventItem,
    ...eventType,
    ...eventCategory,
    ...city,
    ...roomAllocation,
    ...curriculumCategory,
    ...curriculum,
    ...equivalence,
    ...baseGroup,
    ...generateGrids,
    ...disciplineType,
    ...activity,
    ...activityItem,
    ...license,
    ...teacherLicense,
    ...resourceType,
    ...dynamicGeneration,
    ...view,
    ...hourGroup,
    ...chart,
    ...budgetType,
    ...budget,
    ...paymentExport,
    ...hourGroupDate,
    ...bank,
    ...bankData,
    ...timeSheet,
    ...faq,
    ...faqCategory,
    ...faqQuestions,
    ...courseInfo,
    ...country,
    ...state,
    ...ambienceOccupation,
    ...teacherPosition,
    ...teacherContract,
    ...courseLevel,
    ...contact,
    ...logAccessApi,
    ...importLog,
    ...plan,
    ...integration,
    ...eventCalendarBase,
    ...term,
    ...management,
    ...webhook
  ]
})

router.beforeEach(beforeEach);
export default router;


