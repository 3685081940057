import { service } from '../../../../helpers/http'

export const ActionIndexInstitutionIntegrationItems = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexInstitutionIntegrationItems',
        payload: payload
    })

}
export const ActionDestroyInstitutionIntegrationItem = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyInstitutionIntegrationItem',
        payload: payload
    })
}
export const ActionStoreInstitutionIntegrationItem = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeInstitutionIntegrationItem',
        payload: payload
    })

}
export const ActionUpdateInstitutionIntegrationItem = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateInstitutionIntegrationItem',
        payload: payload
    })
}