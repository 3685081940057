<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :showAdd="showAdd"
            :clearable="clearable"
            :multiple="multiple"
            appendIcon="mdi-clock-outline"
            item-text="name_complete"
        />
        <sys-register-hour-group-date @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectHourGroupDate',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        model: {},
        start:{},
        end:{},
        token: {},
        multiple: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        dayId: {
            type: Number,
            default: -1
        }
        
    },
    watch: {
        
        selected(val){
            
            var item = this.items.find(i => i.id == val);

            if(item){
                this.$emit('update:start', item.hour_start);
                this.$emit('update:end', item.hour_end);
            }
            
        },
    },
    methods: {
        ...mapActions('hourGroupDate', ['ActionFindHourGroupDates', 'ActionFindHourGroupDatesToken']),        
        
        async getItems() {

            this.loading = true;
            var method  = this.token ? 'ActionFindHourGroupDatesToken' : 'ActionFindHourGroupDates';
            var payload = this.token ? { token: this.token } : {};
            
            await this[method](payload)
                    .then((res) => {
                        if(this.dayId === -1){
                            this.items = res.data;
                        }else{
                            this.items = res.data.filter(i => i.day_of_week_id == this.dayId);
                        }

                        var item = this.items.find(i => i.name == this.model);
                        this.selected = item;
                        
                        if(this.required && !this.model && this.items.length == 1){
                            
                            if(this.multiple){
                                this.selected = [this.items[0]];
                            }else{
                                this.selected = this.items[0];
                            }
                            
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
            
            if(item){

                this.items.push(item);
                
                if(this.multiple){
                    if(!this.selected){
                        this.selected = [];
                    }
                    this.selected.push(item);
                }else{
                    this.selected = item.id;
                   
                }

                this.showForm = false;
                
                
            }
            
        }
    },
    
}
</script>