var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": _vm.maxHeight,
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  class: _vm.color + " mb-4 elevation-1",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c(
                    "v-toolbar-title",
                    { staticClass: "subtitle-1" },
                    [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                    2
                  ),
                  _c("v-spacer"),
                  _vm._t("tools"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm.loading
            ? _c("v-progress-linear", {
                staticClass: "ml-2 mr-2",
                attrs: { indeterminate: "", color: "yellow darken-2" }
              })
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pt-2" },
            [
              _c(
                "v-list",
                {
                  ref: "messages",
                  staticClass: "overflow-y-auto",
                  attrs: { "max-height": "300" }
                },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      class:
                        _vm.user && item.user.id == _vm.user.id
                          ? "text-right"
                          : "",
                      attrs: { id: "list-item-" + index }
                    },
                    [
                      _vm.user && item.user.id != _vm.user.id
                        ? _c(
                            "v-list-item-avatar",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: {
                                                      color: _vm.getColor(item),
                                                      size: "28"
                                                    }
                                                  },
                                                  "v-avatar",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getInitials(item)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.user.name) + " "),
                                    _c("br"),
                                    item.created_at == item.updated_at
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("dateTime")(
                                                  item.created_at
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " Enviado: " +
                                              _vm._s(
                                                _vm._f("dateTime")(
                                                  item.created_at
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " Editado: " +
                                              _vm._s(
                                                _vm._f("dateTime")(
                                                  item.updated_at
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                    item.auto
                                      ? _c("span", [
                                          _c("br"),
                                          _vm._v(" :. Mensagem Automática .: ")
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [
                              !_vm.showEditText &&
                              _vm.user &&
                              item.user.id == _vm.user.id
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    index ==
                                                    _vm.items.length - 1
                                                      ? _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.message
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("br"),
                                                          _c("small", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateTime"
                                                                )(
                                                                  item.updated_at
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.message)
                                                          )
                                                        ])
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      item.created_at == item.updated_at
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dateTime")(
                                                    item.created_at
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " Enviado: " +
                                                _vm._s(
                                                  _vm._f("dateTime")(
                                                    item.created_at
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " Editado: " +
                                                _vm._s(
                                                  _vm._f("dateTime")(
                                                    item.updated_at
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                      item.auto
                                        ? _c("span", [
                                            _c("br"),
                                            _vm._v(
                                              " :. Mensagem Automática .: "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _c("span", [
                                    _vm.showEditText &&
                                    index == _vm.selectedIndex
                                      ? _c(
                                          "span",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                autofocus: "",
                                                counter: "",
                                                loading: _vm.loadingUpdate,
                                                maxlength: _vm.count
                                              },
                                              on: {
                                                keyup: [
                                                  function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "esc",
                                                        27,
                                                        $event.key,
                                                        ["Esc", "Escape"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.preventDefault()
                                                    _vm.showEditText = false
                                                  },
                                                  function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.updateMessage(
                                                      item
                                                    )
                                                  }
                                                ],
                                                blur: function($event) {
                                                  _vm.showEditText = false
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function() {
                                                      return [
                                                        _vm.editMessage &&
                                                        item.message !==
                                                          _vm.editMessage
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  right: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.updateMessage(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-content-save "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.editMessage
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "indigo",
                                                                  right: "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    _vm.editMessage = null
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-close "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.editMessage,
                                                callback: function($$v) {
                                                  _vm.editMessage = $$v
                                                },
                                                expression: "editMessage"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          [
                                            index == _vm.items.length - 1
                                              ? _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.message) +
                                                      " "
                                                  ),
                                                  _c("small", [
                                                    _c("br"),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateTime")(
                                                          item.updated_at
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ])
                                              : _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "span",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.message
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("dateTime")(
                                                              item.updated_at
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !item.auto
                        ? _c("v-list-item-action", [
                            index == _vm.items.length - 1
                              ? _c(
                                  "span",
                                  [
                                    _vm.allowEdit &&
                                    _vm.user &&
                                    item.user.id == _vm.user.id
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              disabled:
                                                index == _vm.selectedIndex &&
                                                _vm.showEditText,
                                              color: "indigo darken-4",
                                              right: "",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.editText(item, index)
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-pencil ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      { attrs: { icon: "" } },
                                      [
                                        _vm.allowDelete &&
                                        _vm.user &&
                                        item.user.id == _vm.user.id
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "orange darken-4",
                                                  right: "",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.deleteItem(item)
                                                  }
                                                }
                                              },
                                              [_vm._v(" mdi-delete ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Anotação",
                  placeholder: "Digite aqui sua anotação...",
                  filled: "",
                  rounded: "",
                  dense: "",
                  counter: "",
                  maxlength: _vm.count,
                  autofocus: ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendMessage()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _vm._t("append"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                loading: _vm.loadingSend,
                                                icon: "",
                                                disabled:
                                                  !_vm.modelMessage ||
                                                  _vm.modelMessage == ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                right: "",
                                                color: "orange"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.sendMessage()
                                                }
                                              }
                                            },
                                            [_vm._v(" mdi-send ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v(" Enviar ")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.modelMessage,
                  callback: function($$v) {
                    _vm.modelMessage = $$v
                  },
                  expression: "modelMessage"
                }
              }),
              _vm._t("actions")
            ],
            2
          )
        ],
        1
      ),
      _c("emc-alert-modal", {
        attrs: {
          title: "Excluir",
          text: "Deseja excluir a anotação?",
          show: _vm.showDelete,
          disabled: _vm.loading
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      loading: _vm.loadingDelete,
                      color: "error"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteMessage()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }