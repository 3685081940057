var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-format-list-text",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            dense: "",
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-payment-event", {
                          attrs: {
                            model: _vm.model.payment_event_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.payment_event_id,
                            label: "Evento de Pagamento",
                            "item-text": "name_complete",
                            showAdd: "",
                            clearable: "",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "payment_event_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-teacher-contract-type", {
                          attrs: {
                            model: _vm.model.teacher_contract_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.teacher_contract_type_id,
                            label: "Tipo Vínculo"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "teacher_contract_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-activity-category", {
                          attrs: {
                            model: _vm.model.activity_category_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.activity_category_id,
                            label: "Categoria",
                            showAdd: "",
                            clearable: "",
                            "auto-complete": "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "activity_category_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-brand", {
                          attrs: {
                            model: _vm.model.brands,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.brands,
                            label: "Marca",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "brands", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-course-level", {
                          attrs: {
                            model: _vm.model.course_level_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.course_level_id,
                            label: "Nível de Curso",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "course_level_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-branch", {
                          attrs: {
                            model: _vm.model.branch_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.branch_id,
                            label: "Filial",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "branch_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Considerar atividade para compor a carga horária semestral para controle de redução.",
                            inset: "",
                            label: "Compõe CH"
                          },
                          model: {
                            value: _vm.model.is_work_load,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_work_load", $$v)
                            },
                            expression: "model.is_work_load"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Atividade com horário obrigatório definido",
                            inset: "",
                            label: "Com Horário"
                          },
                          model: {
                            value: _vm.model.has_time_table,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "has_time_table", $$v)
                            },
                            expression: "model.has_time_table"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: { inset: "", label: "Digital" },
                          model: {
                            value: _vm.model.digital,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "digital", $$v)
                            },
                            expression: "model.digital"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: { inset: "", label: "Padrão" },
                          model: {
                            value: _vm.model.is_default,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_default", $$v)
                            },
                            expression: "model.is_default"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }