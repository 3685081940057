<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="50%"
        :disabledSave="disabledUpdate"
        textButtonSave="Salvar"
    >
        <template v-slot:form>
            <v-form
                ref="form"
            >
            <!-- <v-tabs class="ma-0" v-model="tab">
                <v-tab key="realocation">Remanejamento</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="realocation"> -->
                    <v-container>
                        
                        <!-- Tipo Oferta -->
                        <v-row v-if="registerType == 'offer'">
                            <v-col cols="12" sm="4">
                                <sys-select-offer-item-status
                                    :model.sync="model.offer_item_status_id"
                                    :error="errors.length > 0"  
                                    :errors="errors['items.0.offer_item_status_id']"
                                    label="Status Oferta"
                                    dense
                                    statusIds="3"
                                    includeNull
                                    nameNull="A Cursar"
                                    @change="changeStatus"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <sys-select-offer-item-day
                                    :model.sync="model.offer_item_day_id"
                                    label="Dia da Semana"
                                    dense
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <sys-select-grid-modality
                                    :model.sync="model.grid_modality_id_rule"
                                    :error="errors.length > 0"  
                                    :errors="errors.grid_modality_id_rule"
                                    clearable
                                    label="Modalidade"
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'offer'">
                            <v-col cols="12" sm="4">
                                <sys-select-join-category
                                    :model.sync="model.join_category_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.join_category_id"
                                    clearable
                                    label="Tipo de Junção"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea
                                    v-model="model.annotation"
                                    outlined
                                    name="input-7-4"
                                    label="Observação do Gestor"
                                    :value="model.annotation"
                                    :error="errors.length > 0"  
                                    :error-messages="errors.annotation"
                                    clearable
                                    rows="2"
                                    row-height="20"
                                />
                            </v-col>
                        </v-row>

                        <!-- Tipo Remanejamento -->

                        <v-row v-if="registerType == 'relocation'" class="mt-1">    
                            <v-col cols="12" sm="3">
                                <sys-select-base-group-status
                                    :model.sync="model.base_group_status_id_calculate"
                                    label="Status*"
                                    :error="errors.brand_id_destiny && errors.length > 0" 
                                    :errors="errors.base_group_status_id_calculate"
                                    dense
                                    @change="forceRerender()"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <sys-select-brand
                                    v-if="renderComponent"
                                    :model.sync="model.brand_id_destiny"
                                    label="Marca*"
                                    :error="errors.brand_id_destiny && errors.length > 0"  
                                    :errors="errors.brand_id_destiny"
                                    dense
                                    autoComplete
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                            <v-col cols="12" sm="5">
                                <sys-select-institution
                                        v-if="renderComponent"
                                        :model.sync="model.institution_id_destiny"
                                        label="Instituicao*"
                                        :error="errors.institution_id_destiny && errors.length > 0"  
                                        :errors="errors.institution_id_destiny"
                                        dense
                                        auto-complete
                                        :brand-id="model.brand_id_destiny"
                                        :disabled="model.base_group_status_id_calculate != 2"
                                    />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'relocation'" >
                            <v-col cols="12" sm="6">
                                <sys-select-campus
                                        v-if="renderComponent"
                                        :model.sync="model.campus_id_destiny"
                                        label="Campus*"
                                        :error="errors.campus_id_destiny && errors.length > 0"  
                                        :errors="errors.campus_id_destiny"
                                        dense
                                        auto-complete
                                        :brand-id="model.brand_id_destiny"
                                        :institution-id="model.institution_id_destiny"
                                        :disabled="model.base_group_status_id_calculate != 2"
                                    />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-shift
                                    v-if="renderComponent"
                                    :model.sync="model.shift_id_destiny"
                                    label="Turno*"
                                    :error="errors.shift_id_destiny && errors.length > 0"  
                                    :errors="errors.shift_id_destiny"
                                    dense
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'relocation'" >
                            <v-col cols="12" sm="6">
                                <sys-select-course
                                    v-if="renderComponent"
                                    :model.sync="model.course_id_destiny"
                                    :error="errors.course_id && errors.length > 0"  
                                    :errors="errors.course_id_destiny"
                                    label="Curso*"
                                    autoComplete
                                    dense
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-category
                                    v-if="renderComponent"
                                    :model.sync="model.category_id_destiny"
                                    :error="errors.length > 0"  
                                    :errors="errors.category_id_destiny"
                                    clearable
                                    dense
                                    label="Modalidade*"
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'relocation'" >
                            <v-col cols="12" sm="6">
                                <sys-select-base-group
                                    v-if="renderComponent"
                                    :model.sync="model.base_group_id_destiny"
                                    :error="errors.length > 0"  
                                    :errors="errors.base_group_id_destiny"
                                    label="Turma Composição Destino"
                                    :course-id="model.course_id_destiny"
                                    :campus-id="model.campus_id_destiny"
                                    :shift-id="model.shift_id_destiny"
                                    dense
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <s-text-field
                                    v-model="model.curriculum_name_destiny"
                                    :error-messages="errors.curriculum_name_destiny"
                                    label="Matriz*"
                                    :disabled="model.base_group_status_id_calculate != 2"
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="renderComponent && registerType == 'relocation' && model.base_group_status_id_calculate == 3">
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="model.reason_note"
                                    outlined
                                    name="input-7-4"
                                    label="Motivo do Encerramento*"
                                    :value="model.reason_note"
                                    :error="errors.length > 0"  
                                    :error-messages="errors.reason_note"
                                    clearable
                                    rows="2"
                                    row-height="20"
                                    :counter="255"
                                    :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'relocation'">
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-if="renderComponent"
                                    v-model="model.observation_relocation"
                                    outlined
                                    name="input-7-4"
                                    label="Observação do Remanejamento/Encerramento"
                                    :value="model.note"
                                    :error="errors.length > 0"  
                                    :error-messages="errors.observation_relocation"
                                    clearable
                                    rows="2"
                                    row-height="20"
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="registerType == 'relocation'">
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-if="renderComponent"
                                    v-model="model.observation_relocation_manager"
                                    outlined
                                    name="input-7-4"
                                    label="Observação do Remanejamento/Encerramento - Gestor"
                                    :value="model.note"
                                    :error="errors.length > 0"  
                                    :error-messages="errors.observation_relocation_manager"
                                    clearable
                                    rows="2"
                                    row-height="20"
                                    :disabled="model.base_group_status_id_calculate != 2"
                                />
                            </v-col>
                        </v-row>
                        <v-row  v-if="registerType == 'relocation'">
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="model.observation"
                                    :items="observations"
                                    item-key="id"
                                    item-text="name"
                                    clearable
                                    label="Observação de Status"
                                />
                            </v-col>
                            
                        </v-row>

                        <!-- Tipo Equivalência -->

                        <v-row  v-if="registerType == 'equivalence'">
                            <v-col cols="12" sm="12">
                                <sys-select-discipline
                                    :model.sync="model.discipline_id_equivalence"
                                    :data.sync="model.disciplineEquivalence"
                                    :error="errors.discipline_id_equivalence && errors.length > 0"  
                                    :errors="errors.discipline_id_equivalence"
                                    label="Unidade Curricular Equivalente"
                                    item-text="full_name"
                                    autoComplete
                                    clearable
                                    autofocus
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                <!-- </v-tab-item>
            </v-tabs-items> -->
             </v-form>
            <v-alert v-if="errors.length > 0" type="error">
                <ul>
                    <li :key="error" v-for="error in errors">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
            
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    name: "SysRegisterOfferItem",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        tab: null,
        renderComponent: true,
        disabledUpdate: false,
        observations: [
            { id: 'Concordo',                     name: 'Concordo' },
            { id: 'Concordo - Alteração Destino', name: 'Concordo - Alteração Destino' },
            { id: 'Não Concordo',                 name: 'Não Concordo' },
            { id: 'Indicação Gestor',             name: 'Indicação Gestor' }
        ],
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.errors = {};
                this.tab = null;
                this.course = {
                    groups: []
                };

                this.group_id = null;
                
                this.error = false;
                this.errors = {};

                if(this.model.id && this.statusDefault){
                    if(this.statusDefault == -1){
                        this.model.offer_item_status_id = null;
                    }else{
                        this.model.offer_item_status_id = this.statusDefault;
                    }
                }

                if(this.registerType == 'offer' && (this.models && this.models.length > 0)){
                    
                    let cursar = 0;
                    let ofertar = 0;
                    let days = [];
                    let modalities = [];
                    let categoryJoins = [];

                    this.models.forEach(m => {
       
                        if(!m.offer_item_status_id){
                            cursar++;
                        }

                        if(m.offer_item_status_id == 3){
                            ofertar++;
                        }

                        if(m.offer_item_day_id){
                            if(!days.includes(m.offer_item_day_id)){
                                days.push(m.offer_item_day_id);
                            }
                        }

                        if(m.grid_modality_id_rule){
                            if(!modalities.includes(m.grid_modality_id_rule)){
                                modalities.push(m.grid_modality_id_rule);
                            }
                        }

                        if(m.join_category_id){
                            if(!categoryJoins.includes(m.join_category_id)){
                                categoryJoins.push(m.join_category_id);
                            }
                        }

                    });

                    if(cursar > 0 && ofertar == 0){
                        this.model.offer_item_status_id = null;
                    }else if(cursar == 0 && ofertar > 0){
                        this.model.offer_item_status_id = 3;
                    }else{
                        this.disabledUpdate = true;
                    }

                    if(days.length == 1){
                        this.model.offer_item_day_id = days[0];
                    }

                    if(modalities.length == 1){
                        this.model.grid_modality_id_rule = modalities[0];
                    }

                    if(categoryJoins.length == 1){
                        this.model.join_category_id = categoryJoins[0];
                    }
                    
                }
                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        models: {

        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Edição de Registro de Oferta"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        registerType: {
            type: String,
            required: true
        },
        statusDefault: {

        },
        config: {

        }
    },
    methods:{
        ...mapActions('grid', ['ActionUpdateOfferItem', 'ActionUpdateOfferItemAll']),
        
        save()
        {
            this.error = null;
            this.message = '';
            this.loading = true;

            if(!this.models || this.models.length == 0){
                
                let payload = {};
                
                Object.assign(payload, this.model);

                if(this.model.base_group_status_id_calculate){
                    payload.base_group_status_id = parseInt(this.model.base_group_status_id_calculate);
                }
                
                if(this.config){
                    payload.config = this.config;
                }else{
                    payload.config = { 
                        validate_time_table:    false,
                        validate_ucdp:          false
                    };
                }

                if(this.registerType == 'relocation' || this.registerType == 'equivalence'){
                    delete payload.reason_exception;
                }
                
                this.ActionUpdateOfferItem(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, false);
                })
                .catch((error) =>{
                
                    this.error      = error;
                    this.errors     = error.errors;

                    if(this.errors.blocked){
                        this.message = this.errors.blocked[0];
                    }else{
                        this.message    = error.message;
                    }

                    
                })
                .finally(() => {
                        this.loading = false;
                })

            }else{

                let payload = {
                    items: [],
                };

                if(this.config){
                    payload.config = this.config;
                }else{
                    payload.config = { 
                        validate_time_table:    false,
                        validate_ucdp:          false
                    };
                }

                this.models.forEach(m => {

                    payload.items.push({
                        id: parseInt(m.id),
                        offer_item_status_id:   this.model.offer_item_status_id     ? parseInt(this.model.offer_item_status_id) : null,
                        offer_item_day_id:      this.model.offer_item_day_id        ? parseInt(this.model.offer_item_day_id) : null,
                        grid_modality_id_rule:  this.model.grid_modality_id_rule    ? parseInt(this.model.grid_modality_id_rule) : null,
                        join_category_id:       this.model.join_category_id         ? parseInt(this.model.join_category_id) : null,
                        annotation:             this.model.annotation
                    });

                });

                this.ActionUpdateOfferItemAll(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data, false);
                })
                .catch((error) =>{
                
                    this.error      = error;
                    this.errors     = error.errors;

                    if(this.errors.blocked){
                        this.message = this.errors.blocked[0];
                    }else{
                        this.message    = error.message;
                    }
                    
                })
                .finally(() => {
                        this.loading = false;
                })

            }

            
           
        },

        changeStatus(item){

            if(item && item.name){
                this.disabledUpdate = false;
            }else{
                this.disabledUpdate = true;
            }

        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },
        
    }
}
</script>