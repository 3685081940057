var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "80%",
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { height: "600px", "max-height": "600px" } },
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "primary lighten-2 mb-4 elevation-1",
                  attrs: { dense: "", height: "35" }
                },
                [
                  _c("v-toolbar-title", [
                    _c("span", { staticClass: "subtitle-1" })
                  ]),
                  _vm.ambience &&
                  _vm.ambience.images &&
                  _vm.ambience.images.length > 0
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: { icon: "", "x-small": "" },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.openImage($event)
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "", color: "white" }
                                          },
                                          [_vm._v("mdi-folder-image")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3146581123
                          )
                        },
                        [_c("span", [_vm._v("Carregar Foto")])]
                      )
                    : _vm._e(),
                  _vm.ambience &&
                  _vm.ambience.images &&
                  _vm.ambience.images.length > 0
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: { icon: "", "x-small": "" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.showCamera = true
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "", color: "white" }
                                          },
                                          [_vm._v("mdi-camera")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            160550023
                          )
                        },
                        [_c("span", [_vm._v("Tirar Foto")])]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.ambience
                    ? _c(
                        "strong",
                        { staticClass: "text-subtitle-1 white--text" },
                        [_vm._v(_vm._s(_vm.ambience.name_complete))]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "", "x-small": "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "white" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              !_vm.overlay &&
              _vm.ambience &&
                _vm.ambience.images &&
                _vm.ambience.images.length == 0
                ? _c(
                    "v-row",
                    {
                      staticClass: "fill-height",
                      attrs: { "align-content": "center", justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-subtitle-1 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm._v(" Nenhuma imagem disponível! "),
                          _c("br"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  "x-large": ""
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.openImage($event)
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  "x-larg": "",
                                                  color: "cyan"
                                                }
                                              },
                                              [_vm._v("mdi-folder-image")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1341875649
                              )
                            },
                            [_c("span", [_vm._v("Carregar Foto")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  "x-large": ""
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    _vm.showCamera = true
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  "x-large": "",
                                                  color: "cyan"
                                                }
                                              },
                                              [_vm._v("mdi-camera")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3877830688
                              )
                            },
                            [_c("span", [_vm._v("Tirar Foto")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.overlay
                ? _c(
                    "v-row",
                    {
                      staticClass: "fill-height",
                      attrs: { "align-content": "center", justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-subtitle-1 text-center",
                          attrs: { cols: "12" }
                        },
                        [_vm._v(" Carregando imagens... ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              color: "deep-purple accent-4",
                              indeterminate: "",
                              rounded: "",
                              height: "6"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    _vm._l(_vm.ambience.images, function(item, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          staticClass: "d-flex child-flex",
                          attrs: { cols: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-img", {
                                staticClass: "grey lighten-2",
                                attrs: { src: item.url, "aspect-ratio": "1" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                style: item.main
                                                                  ? "cursor: default;"
                                                                  : "cursor: pointer;",
                                                                attrs: {
                                                                  loading:
                                                                    _vm.loadingMain &&
                                                                    i ==
                                                                      _vm.index,
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateMain(
                                                                      item,
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ma-1",
                                                                attrs: {
                                                                  loading: "",
                                                                  color: item.main
                                                                    ? "yellow"
                                                                    : "black"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.main
                                                                      ? "mdi-star-half-full"
                                                                      : "mdi-star-outline"
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.main
                                                      ? "Imagem principal"
                                                      : "Tornar imagem principal"
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "placeholder",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "fill-height ma-0",
                                              attrs: {
                                                align: "center",
                                                justify: "center"
                                              }
                                            },
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "grey lighten-5"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "v-card-title",
                                [
                                  _vm.edit && i == _vm.index
                                    ? _c(
                                        "span",
                                        [
                                          _vm.edit && i == _vm.index
                                            ? _c("v-text-field", {
                                                attrs: {
                                                  dense: "",
                                                  error: _vm.errors.length > 0,
                                                  "error-messages":
                                                    _vm.errors.title,
                                                  label: "Título"
                                                },
                                                model: {
                                                  value: item.title,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "title", $$v)
                                                  },
                                                  expression: "item.title"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(" " + _vm._s(item.title) + " ")
                                      ]),
                                  _c("v-spacer"),
                                  false
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                              icon: ""
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editItem(
                                                                  i
                                                                )
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "primary"
                                                            }
                                                          },
                                                          [_vm._v("mdi-pencil")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [_c("span", [_vm._v("Editar")])]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          loading:
                                                            _vm.loading &&
                                                            i == _vm.index,
                                                          "x-small": "",
                                                          icon: ""
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openDelete(
                                                              item,
                                                              i
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "error"
                                                        }
                                                      },
                                                      [_vm._v("mdi-delete")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("Excluir")])]
                                  )
                                ],
                                1
                              ),
                              _c("v-card-subtitle", [
                                _vm.edit && i == _vm.index
                                  ? _c(
                                      "span",
                                      [
                                        _vm.edit && i == _vm.index
                                          ? _c("v-text-field", {
                                              attrs: {
                                                dense: "",
                                                error: _vm.errors.length > 0,
                                                "error-messages":
                                                  _vm.errors.description,
                                                label: "Descrição"
                                              },
                                              model: {
                                                value: item.description,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.description"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      )
                                    ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "form"
        },
        [
          _c("v-file-input", {
            attrs: {
              id: "inputAvatar",
              rules: _vm.rules,
              accept: "image/png, image/jpeg, image/bmp",
              placeholder: "Pick an avatar",
              "prepend-icon": "mdi-camera",
              label: "Avatar",
              multiple: ""
            },
            on: { change: _vm.onFiledPicked },
            model: {
              value: _vm.files,
              callback: function($$v) {
                _vm.files = $$v
              },
              expression: "files"
            }
          })
        ],
        1
      ),
      _c("emc-avatar-capture", {
        attrs: { show: _vm.showCamera },
        on: {
          "update:show": function($event) {
            _vm.showCamera = $event
          },
          completeCapture: _vm.upload
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: "Confirma a exclusão da imagem?", show: _vm.showDelete },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "error" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }