<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-payment-type
                                :model.sync="model.payment_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_type_id"
                                label="Tipo de Pagamento*"
                                dense
                            /> 
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-work-time-category
                                :model.sync="model.work_time_category_id"
                                :error="errors.length > 0"  
                                :errors="errors.work_time_category_id"
                                label="Categoria Período"
                                dense
                                clearable
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-payment-event
                                :model.sync="model.payment_event_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_event_id"
                                label="Evento de Pagamento*"
                                dense
                                auto-complete
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-company
                                :model.sync="model.company_id"
                                :error="errors.length > 0"  
                                :errors="errors.company_id"
                                label="Empresa"
                                dense
                                clearable
                                auto-complete
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-brand 
                                :model.sync="model.brand_id"
                                :error="errors.length > 0"  
                                :errors="errors.brand_id"
                                label="Marca"
                                clearable
                                auto-complete
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-teacher-titling 
                                :model.sync="model.teacher_titling_id"
                                :error="errors.length > 0"  
                                :errors="errors.teacher_titling_id"
                                label="Titulação"
                                clearable
                                auto-complete
                                dense
                            />
                        </v-col>
                    </v-row>
                    
                    <!-- <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-institution 
                                :model.sync="model.institution_id"
                                :error="errors.length > 0"  
                                :errors="errors.institution_id"
                                label="Instituição"
                                clearable
                            />
                        </v-col>
                    </v-row> -->
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterPaymentTypeSetting",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        range: null,
        message: '',
        loading: false,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.range = null;

                if(!this.model.id){
                    this.model.active = 1;
                    this.model.has_salary = 1;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Cadastro"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('paymentExport', ['ActionUpdatePaymentTypeSetting', 'ActionCreatePaymentTypeSetting']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdatePaymentTypeSetting(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreatePaymentTypeSetting(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },
    }
}
</script>