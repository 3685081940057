var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-avatar",
        {
          attrs: { width: 36, height: 36 },
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("click")
            }
          }
        },
        [
          _vm.loading && _vm.model.url_avatar
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "cyan lighten-4" }
              })
            : _vm._e(),
          _vm.model.url_avatar
            ? _c("img", {
                staticStyle: { cursor: "pointer" },
                attrs: { src: _vm.model.url_avatar },
                on: {
                  load: function($event) {
                    _vm.loading = false
                  }
                }
              })
            : _c(
                "v-icon",
                { staticClass: "mdi-36px", attrs: { color: "grey darken-1" } },
                [_vm._v(_vm._s(_vm.icon))]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }