var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "300" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: _vm.color, dark: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm._v(" " + _vm._s(_vm.text) + " "),
              _c("v-progress-linear", {
                staticClass: "mb-0",
                attrs: { indeterminate: "", color: "white" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }