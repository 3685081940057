var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "mt-12": _vm.breads.length <= 1 } },
    [
      _vm.breads.length > 1
        ? _c("v-breadcrumbs", {
            attrs: { items: _vm.breads },
            scopedSlots: _vm._u(
              [
                {
                  key: "divider",
                  fn: function() {
                    return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3681283568
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }