var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "50%",
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "grey lighten-5 mb-4 elevation-1'",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-file-eye-outline")
                  ]),
                  _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                    _vm._v(" Detalhes da Atividade ")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.model
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "ma-0",
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", { key: "main" }, [_vm._v("Detalhes")]),
                      _c("v-tab", { key: "logs" }, [
                        _vm._v("Histórico de Aprovações")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "main", staticClass: "mt-1" },
                        [
                          _vm._l(_vm.model.teachers, function(teacher, i) {
                            return _c("span", { key: i }, [
                              _c("strong", [_vm._v("Professor: ")]),
                              _vm._v(_vm._s(teacher.teacher.name) + " "),
                              _c("br"),
                              _c("strong", [_vm._v("Valor: ")]),
                              _vm._v(_vm._s(teacher.value) + " "),
                              _c("br"),
                              _c("strong", [_vm._v("Período: ")]),
                              _vm._v(
                                _vm._s(_vm._f("date")(teacher.start)) +
                                  " - " +
                                  _vm._s(_vm._f("date")(teacher.end)) +
                                  " "
                              )
                            ])
                          }),
                          _c("br"),
                          _c("strong", [_vm._v("Tipo: ")]),
                          _vm._v(
                            _vm._s(
                              _vm.model.periodicityType.name +
                                " | " +
                                _vm.model.valueType.name
                            ) + " "
                          ),
                          _c("br"),
                          _vm.model.campus
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [_vm._v("Campus: ")]),
                                _vm._v(_vm._s(_vm.model.campus.name) + " ")
                              ])
                            : _vm._e(),
                          _vm.model.departament
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [_vm._v("Departamento: ")]),
                                _vm._v(_vm._s(_vm.model.departament.name) + " ")
                              ])
                            : _vm._e(),
                          _vm.model.courses.length
                            ? _c(
                                "span",
                                [
                                  _c("br"),
                                  _c("strong", [_vm._v("Curso: ")]),
                                  _vm._l(_vm.model.courses, function(
                                    course,
                                    j
                                  ) {
                                    return _c("span", { key: j }, [
                                      j > 0
                                        ? _c("span", [_vm._v(_vm._s(", "))])
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(course.name) + " ")
                                    ])
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.model.business_unit
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [_vm._v("Uneg: ")]),
                                _vm._v(_vm._s(_vm.model.business_unit) + " ")
                              ])
                            : _vm._e(),
                          _vm.model.costCenter
                            ? _c("span", [
                                _c("strong", [_vm._v(" CC: ")]),
                                _vm._v(_vm._s(_vm.model.costCenter.name) + " ")
                              ])
                            : _vm._e(),
                          _vm.model.activity.paymentEvent
                            ? _c("span", [
                                !_vm.model.business_unit ? _c("br") : _vm._e(),
                                _c("strong", [_vm._v(" Evento: ")]),
                                _vm._v(
                                  _vm._s(
                                    _vm.model.activity.paymentEvent.initials
                                  ) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("strong", [_vm._v("Atividade: ")]),
                          _vm._v(_vm._s(_vm.model.activity.name) + " "),
                          _vm.model.activity.category
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [_vm._v("Categoria: ")]),
                                _vm._v(
                                  _vm._s(_vm.model.activity.category.name) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.activity.category &&
                          _vm.model.activity.category.departament
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [_vm._v("Departamento: ")]),
                                _vm._v(
                                  _vm._s(
                                    _vm.model.activity.category.departament.name
                                  ) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Observação: ")]),
                          _vm._v(_vm._s(_vm.model.note) + " "),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Usuário: ")]),
                          _vm._v(_vm._s(_vm.model.userCreator.name) + " "),
                          _c("br"),
                          _c("strong", [_vm._v("Data Cadastro: ")]),
                          _vm._v(
                            _vm._s(_vm._f("dateTime")(_vm.model.created_at)) +
                              " "
                          ),
                          _vm.model.userApproval
                            ? _c("span", [
                                _c("br"),
                                _c("br"),
                                _c("strong", [_vm._v("Aprovador: ")]),
                                _vm._v(
                                  _vm._s(_vm.model.userApproval.name) + " "
                                ),
                                _c("br"),
                                _c("strong", [_vm._v("Data Validação: ")]),
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateTime")(_vm.model.date_approval)
                                  ) + " "
                                ),
                                _c("br"),
                                _c("strong", [_vm._v("Status: ")]),
                                _vm._v(
                                  _vm._s(
                                    _vm.statuses[
                                      _vm.model.activity_item_status_id - 1
                                    ].name
                                  ) + " "
                                ),
                                _c("br"),
                                _c("strong", [_vm._v("Observação: ")]),
                                _vm._v(_vm._s(_vm.model.note_approval) + " ")
                              ])
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "v-tab-item",
                        { key: "logs" },
                        [
                          _c("v-simple-table", {
                            attrs: { "fixed-header": "", height: "300" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Status")]),
                                          _c("th", [_vm._v("Observação")]),
                                          _c("th", [_vm._v("Usuário")])
                                        ])
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.model.logs.filter(function(i) {
                                            return i.is_change_status
                                          }),
                                          function(log, i) {
                                            return _c("tr", { key: i }, [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    log.data
                                                      .activity_item_status_id
                                                      ? _vm.statuses[
                                                          log.data
                                                            .activity_item_status_id -
                                                            1
                                                        ].name
                                                      : null
                                                  )
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(log.data.note_approval)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(log.user.name) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("dateTime")(
                                                          log.created_at
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        log.created_at_human
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _c("tfoot", { attrs: { "mt-2": "" } }, [
                                        _c("tr", [
                                          _c("td", { attrs: { colspan: "4" } })
                                        ])
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1373540923
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "ligth green" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("FECHAR")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }