var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loadingSave,
      model: _vm.model,
      title: _vm.event.id ? _vm.event.name : "Novo Evento",
      maxWidth: "50%",
      icon: "mdi-calendar-plus",
      show: _vm.dialog
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("SysSelectEventItem", {
                          attrs: {
                            model: _vm.event.event_item_id,
                            error:
                              _vm.errors.event_item_id && _vm.errors.length > 0,
                            errors: _vm.errors.event_item_id,
                            label: "Evento*",
                            showAdd: "",
                            autoComplete: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.event,
                                "event_item_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12",
                          sm: _vm.selectedScope == 1 ? "12" : "6"
                        }
                      },
                      [
                        _c("SysSelectEventScope", {
                          attrs: {
                            model: _vm.event.event_scope_id,
                            error:
                              _vm.errors.event_scope_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.event_scope_id,
                            label: "Escopo do Evento*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.event,
                                "event_scope_id",
                                $event
                              )
                            },
                            change: _vm.changeScope
                          }
                        })
                      ],
                      1
                    ),
                    _vm.selectedScope == 2
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("SysSelectStates", {
                              attrs: {
                                model: _vm.event.states,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.states,
                                label: "Estados*",
                                multiple: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.event, "states", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedScope == 3
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("sys-select-city", {
                              attrs: {
                                model: _vm.event.cities,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.cities,
                                label: "Municípios*",
                                multiple: "",
                                autoComplete: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.event, "cities", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedScope == 5
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("SysSelectBrand", {
                              attrs: {
                                model: _vm.event.brands,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.brands,
                                label: "Marca*",
                                multiple: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.event, "brands", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedScope == 7
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("SysSelectCampus", {
                              attrs: {
                                model: _vm.event.campuses,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.campuses,
                                label: "Campus*",
                                multiple: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.event, "campuses", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("SysSelectEventType", {
                          attrs: {
                            model: _vm.event.event_type_id,
                            error:
                              _vm.errors.event_type_id && _vm.errors.length > 0,
                            errors: _vm.errors.event_type_id,
                            label: "Tipo de Evento*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.event,
                                "event_type_id",
                                $event
                              )
                            },
                            change: _vm.changeType,
                            "new-item": _vm.changeType
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.event.date_start,
                            label: "Início*",
                            errorMessages: _vm.errors.date_start,
                            show: _vm.dialog
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.event, "date_start", $event)
                            },
                            change: function($event) {
                              return _vm.changeStart($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.renderComponent
                          ? _c("emc-calendar-date-picker2", {
                              attrs: {
                                model: _vm.event.date_end,
                                label: "Fim*",
                                errorMessages: _vm.errors.date_end,
                                show: _vm.dialog
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.event, "date_end", $event)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("SysSelectEventArea", {
                          attrs: {
                            model: _vm.model.areas,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.areas,
                            label: "Área",
                            showAdd: "",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "areas", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("SysSelectEventPublic", {
                          attrs: {
                            model: _vm.model.publics,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.publics,
                            label: "Público",
                            showAdd: "",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "publics", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("SysSelectEventCategory", {
                          attrs: {
                            model: _vm.model.categories,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.categories,
                            label: "Categoria",
                            showAdd: "",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "categories", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Os eventos fixos serão replicados no calendário dos próximos anos",
                            color: "purple",
                            label: "Evento Fixo"
                          },
                          model: {
                            value: _vm.event.fixed,
                            callback: function($$v) {
                              _vm.$set(_vm.event, "fixed", $$v)
                            },
                            expression: "event.fixed"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _vm.renderComponent
                          ? _c("s-switch", {
                              attrs: {
                                inset: "",
                                "text-helper":
                                  "A data de um evento não letivo não será considerado como dia de aula no diário de aula do professor",
                                disabled: _vm.disabledSchoolDay,
                                label: "Evento Letivo"
                              },
                              model: {
                                value: _vm.event.school_day,
                                callback: function($$v) {
                                  _vm.$set(_vm.event, "school_day", $$v)
                                },
                                expression: "event.school_day"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-color-base", {
                          attrs: { color: _vm.event.color_origin },
                          on: {
                            "update:color": function($event) {
                              return _vm.$set(_vm.event, "color_origin", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Detalhes",
                            "auto-grow": false,
                            value: _vm.event.details
                          },
                          model: {
                            value: _vm.event.details,
                            callback: function($$v) {
                              _vm.$set(_vm.event, "details", $$v)
                            },
                            expression: "event.details"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-row")
              ],
              1
            ),
            _c("emc-alert-modal", {
              attrs: {
                title: "Exclusão",
                text: "Deseja excluir o evento?",
                show: _vm.showDelete
              },
              on: {
                "update:show": function($event) {
                  _vm.showDelete = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.loadingSave || _vm.loadingDelete,
                            loading: _vm.loadingDelete,
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.deleteItem()
                            }
                          }
                        },
                        [
                          _vm._v("Excluir "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }