<template>
    <div>
        <EmcBaseProgressBar v-if="isLoading" />
            <v-treeview
                    ref="tree"
                    :active.sync="active"
                    :items="campuses"
                    :load-children="fetchItems"
                    :open.sync="open"
                    activatable
                    item-children="children"
                    color="warning"
                    :open-on-click="true"
                    transition
                    item-key="key"
                    return-object
                    @update:active="selectItem($event)"
                >
                    <template slot="label" slot-scope="{ item }">
                        
                        <v-tooltip v-if="item.level == 3" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                <small v-if="item.grid.periodicity_type_id != 1">
                                    <br/>{{ item.grid.periodicity_type.name }} - ({{ item.grid.classeDates.length }})
                                </small>
                            </template>
                            <span>
                                {{ item.name }}
                                <small v-if="item.grid.teacher_id"><br/>{{ item.grid.teacher.name }}</small>
                            </span>
                        </v-tooltip>
                        <span v-else> {{ item.name  }}</span>

                        

                    </template>
                </v-treeview>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'SysGridTreeView',
    data: function() {
        return {
            campuses: '',
            active: [],
            open: [],
            search: '',
            isLoading: false,
        }
    },
    created() {
        this.getCampuses()
    },
    methods: {
        ...mapActions('hour', ['ActionGetShiftsFilter']),
        ...mapActions('group', ['ActionFindGroupById', 'ActionIndexGridsDates']),
        ...mapActions('grid', ['ActionFindGroupsHourDate', 'ActionFindShiftsHourDate' , 'ActionFindGrids']),
        ...mapActions('campus', ['ActionFindCampusesFilterGroup']),
        async fetchItems (item) {        
            switch (item.level) {
                case 0:
                    return await this.getShifts(item);
                case 1:
                    return await this.getGroups(item);
                case 2: 
                    return await this.getGrids(item)
            }
        },
        getCampuses(){
            
            this.campuses = [];
            this.isLoading = true

            this.ActionFindCampusesFilterGroup()
                .then((res) => {
                    res.data.forEach(campus => {
                        this.campuses.push({
                            id: campus.id,
                            key: campus.id,
                            level: 0,
                            name: campus.name,
                            children: []
                        })
                    });
                })
                .finally(() => {
                    this.isLoading = false
                });
        },

        async getGrids(group) {
            
            var payload = {
                id: group.id,
            };

            return this.ActionIndexGridsDates(payload)
                .then((res) => {
                    
                    res.data.forEach(grid => {
                        group.children.push({
                            id:         grid.id,
                            group_id:   group.id,
                            key:        group.id + '|' + grid.id, 
                            level:      3, 
                            name:       grid.discipline_name + ' (' + grid.work_load_type.initials + ')',
                            classes:    grid.classeDates,
                            grid:       grid,
                            parent:     group,
                        })
                    });
                   
                }).finally(() => {
                    
                });
        },

        async getShifts(campus) {  
            var payload = {
                campus_id: campus.id,
            };
            return this.ActionFindShiftsHourDate(payload)
                .then((res) => {
                    res.data.forEach(shift => {
                        campus.children.push({
                            id: shift.id,
                            campus_id: campus.id,
                            key: campus.key + '|' + shift.id,
                            level: 1,
                            name: shift.name,
                            children: []
                        });
                    });
            })
        },
        async getGroups(shift){

            const payload = {
                campus_id: shift.campus_id,
                shift_id: shift.id
            }

            return this.ActionFindGroupsHourDate(payload)
                .then((res) => {
                    
                    res.data.forEach(group => {
                        
                        shift.children.push({
                            id: group.id,
                            key: shift.key + '|' + group.id,
                            level: 2,
                            name: group.name,
                            children: [],
                            
                        });
                        
                    })
                    
                });

        },

        selectItem(item){
            
            if(item && item.length > 0){
                this.$emit('selected', item[0]);
            }
            
        },

    }
}
</script>