var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: _vm.maxWidth
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _vm.selected &&
                _vm.selected.filter(function(i) {
                  return i.exported
                }).length
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  type: "error"
                                }
                              },
                              [
                                _vm.statusId == 7
                                  ? _c("span", [
                                      _vm._v(
                                        "Atividade exportada na folha de pagamento. Ao editar a data fim a atividade será finalizada."
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "Atividade exportada na folha de pagamento. Ao remover será gerado um desconto retroativo."
                                      )
                                    ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-activity-item-status", {
                          attrs: {
                            model: _vm.model.activity_item_status_id,
                            error: _vm.errors.activity_item_status_id > 0,
                            errors: _vm.errors.activity_item_status_id,
                            label: "Status*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "activity_item_status_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.statusId == 7
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker2", {
                              attrs: {
                                model: _vm.model.date_start,
                                label: "Início*",
                                errorMessages: _vm.errors.date_start,
                                show: _vm.dialog,
                                disabled: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "date_start",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("emc-calendar-date-picker2", {
                              attrs: {
                                model: _vm.model.date_end,
                                label: "Fim*",
                                errorMessages: _vm.errors.date_end,
                                show: _vm.dialog
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(_vm.model, "date_end", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note_approval,
                            "error-messages": _vm.errors.note_approval,
                            label: "Observação",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            clearable: "",
                            autofocus: ""
                          },
                          model: {
                            value: _vm.model.note_approval,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note_approval", $$v)
                            },
                            expression: "model.note_approval"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }