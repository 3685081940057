var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _vm.renderComponent
            ? _c("v-simple-table", {
                attrs: { "fixed-header": "", height: "400px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c("emc-table-icon-edit", {
                            attrs: {
                              icon: "mdi-plus",
                              toolTip: "Incluir Professor"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showAddItem()
                              }
                            }
                          }),
                          _c("v-progress-linear", {
                            attrs: {
                              indeterminate: "",
                              color: _vm.loadingTable ? "yellow darken-2" : ""
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Professor")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("CH")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Status")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Período")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Usuário")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Data")
                              ]),
                              _c("th", { staticClass: "text-center" })
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.items, function(item, i) {
                              return _c("tr", { key: i }, [
                                _c("td", [
                                  _vm._v(_vm._s(_vm._f("teacherName")(item)))
                                ]),
                                _c("td", [_vm._v(_vm._s(item.work_load))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.status ? item.status.name : null
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("date")(item.date_start)) +
                                      " - " +
                                      _vm._s(_vm._f("date")(item.date_end))
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(item.user ? item.user.name : null)
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("dateTime")(item.updated_at))
                                  )
                                ]),
                                _c(
                                  "td",
                                  [
                                    item.exported &&
                                    (item.attribution_status_id == 1 ||
                                      item.attribution_status_id == 5)
                                      ? _c("emc-table-icon", {
                                          attrs: {
                                            color: "purple",
                                            icon: "mdi-account-convert",
                                            "tool-tip": "Finalizar atribuição"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onShowEdit(
                                                item,
                                                "finalize"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    item.exported &&
                                    (item.attribution_status_id == 1 ||
                                      item.attribution_status_id == 5)
                                      ? _c("emc-table-icon", {
                                          attrs: {
                                            color: "error",
                                            icon: "mdi-account-remove",
                                            "tool-tip": "Remover atribuição"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onShowEdit(
                                                item,
                                                "remove"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    !item.exported &&
                                    (item.attribution_status_id == 1 ||
                                      item.attribution_status_id == 4 ||
                                      item.attribution_status_id == 5)
                                      ? _c("emc-table-icon-edit", {
                                          on: {
                                            click: function($event) {
                                              return _vm.onShowEdit(
                                                item,
                                                "edit"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    !item.exported &&
                                    (item.attribution_status_id == 1 ||
                                      item.attribution_status_id == 4 ||
                                      item.attribution_status_id == 5)
                                      ? _c("emc-table-icon-delete", {
                                          on: {
                                            click: function($event) {
                                              return _vm.onShowDelete(item)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1645427179
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("sys-teacher-attribution-register", {
        attrs: { type: _vm.type, model: _vm.itemEdit, show: _vm.showForm },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          },
          onCompleted: function($event) {
            return _vm.onCompleted()
          }
        }
      }),
      _c("sys-teacher-attribution-register", {
        attrs: {
          type: _vm.type,
          model: _vm.itemNew,
          "new-item": "",
          show: _vm.showFormNewtItem
        },
        on: {
          "update:show": function($event) {
            _vm.showFormNewtItem = $event
          },
          onCompleted: function($event) {
            return _vm.onCompleted()
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          title: "Deseja excluir a atribuição?",
          text: "Excluir",
          show: _vm.showDelete,
          disabled: _vm.loadingDelete
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loadingDelete,
                      loading: _vm.loadingDelete,
                      color: "error"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteAttribution()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }