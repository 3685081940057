var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { width: "256", tile: "" } },
    [
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ]),
                  _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.subTitle))])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    gostClass: "on-drag",
                    group: _vm.group,
                    animation: "400"
                  },
                  on: { remove: _vm.remove, add: _vm.add, start: _vm.start }
                },
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(item.icon) }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(item.text) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }