<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :showAdd="showAdd"
            :clearable="clearable"
            :multiple="multiple"
            :dense="dense"
            :disabled="disabled"
            @change="$emit('change', $event)"
            :auto-complete="autoComplete"
        />
        <sys-register-hour-group @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectHourGroup',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        multiple: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('hourGroup', ['ActionFindHourGroups']),        
        
        async getItems() {

            this.loading = true

            await this.ActionFindHourGroups()
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;

                        if(this.required && !this.model && this.items.length == 1){
                            
                            if(this.multiple){
                                this.selected = [this.items[0]];
                            }else{
                                this.selected = this.items[0];
                            }
                            
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
            
            if(item){

                this.items.push(item);
                
                if(this.multiple){
                    if(!this.selected){
                        this.selected = [];
                    }
                    this.selected.push(item);
                }else{
                    this.selected = item.id;
                }

                this.showForm = false;
                
                
            }
            
        }
    },
    
}
</script>