import { default as sub_group } from  './sub_group/services'
import { default as identifier } from  './identifier/services'

export default {
    ...sub_group,
    ...identifier,
    groups:             { method: 'get',     url: 'groups' },
    groupById:          { method: 'get',     url: 'groups/{id}' },
    groupsTree:         { method: 'get',     url: 'groups/tree' },
    groupsTreePrint:    { method: 'get',     url: 'groups/tree/print' },
    groupsTimeSheet:    { method: 'get',     url: 'groups/time_sheet' },
    status:             { method: 'get',     url: 'status_groups' },
    groupCreate:        { method: 'post',    url: 'groups' },
    groupUpdate:        { method: 'put',     url: 'groups/{id}' },
    groupDelete:        { method: 'delete',  url: 'groups/{id}' },
    generateGrids:      { method: 'put',     url: 'groups/{id}/grids/generate' },
    generateGridsAll:   { method: 'put',     url: 'groups/grids/generate/all' },
    gridsOptimized:     { method: 'get',     url: 'groups/{type}/{item_id}/grids_optimized' },
    updateValidation:   { method: 'put',     url: 'groups/{id}/validation_time' },
    refreshPendencies:  { method: 'post',    url: 'groups/refresh-pendencies' },
    indexGridsDates:    { method: 'get',     url: 'groups/grids/dates/{id}' },
}