var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Detalhes",
                            "auto-grow": false,
                            value: _vm.model.description,
                            rows: "2"
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Permitir bloquear/desbloquear atribuições",
                            label: "Bloqueio de Atribuição"
                          },
                          model: {
                            value: _vm.model.allow_lock_attribution,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "allow_lock_attribution", $$v)
                            },
                            expression: "model.allow_lock_attribution"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Permitir edição dos horários das aulas",
                            label: "Alterar Horário"
                          },
                          model: {
                            value: _vm.model.allow_change_time_table,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "allow_change_time_table",
                                $$v
                              )
                            },
                            expression: "model.allow_change_time_table"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Permitir edição das atividades extra classe",
                            label: "Alterar Atividades"
                          },
                          model: {
                            value: _vm.model.allow_change_activities,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "allow_change_activities",
                                $$v
                              )
                            },
                            expression: "model.allow_change_activities"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            inset: "",
                            "text-helper":
                              "Permitir editar indicação e atividades com o sistem fechado para folha de pagamento",
                            label: "Editar CH Professor"
                          },
                          model: {
                            value: _vm.model.allow_edit_work_load,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "allow_edit_work_load", $$v)
                            },
                            expression: "model.allow_edit_work_load"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: { color: "purple", inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Permitir alterações restritas no Planejamento de Oferta",
                            color: "purple",
                            inset: "",
                            label: "Alterar Oferta"
                          },
                          model: {
                            value: _vm.model.allow_change_offer_item,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.model,
                                "allow_change_offer_item",
                                $$v
                              )
                            },
                            expression: "model.allow_change_offer_item"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Ocultar ícones de importações no Menu Importação",
                            inset: "",
                            label: "Ocultar Importações"
                          },
                          model: {
                            value: _vm.model.hide_imports,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "hide_imports", $$v)
                            },
                            expression: "model.hide_imports"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "error-messages": _vm.errors.level,
                            label: "Nível"
                          },
                          model: {
                            value: _vm.model.level,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "level", $$v)
                            },
                            expression: "model.level"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }