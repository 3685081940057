var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      attrs: { "max-width": "450", oncontextmenu: "return false;" },
      on: {
        mousedown: function($event) {
          return _vm.$emit("mousedown", $event)
        }
      }
    },
    [
      _c("emc-message-chat", {
        attrs: {
          show: _vm.dialogChat,
          model: _vm.gridChat,
          type: "grid",
          title: "Registro de Anotações da Disciplina (Chat)"
        },
        on: {
          "update:show": function($event) {
            _vm.dialogChat = $event
          }
        }
      }),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm.showActions
            ? _c(
                "v-subheader",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { small: "", icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.openDialogNewGrid()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          color: "green darken-1"
                                        }
                                      },
                                      [_vm._v("mdi-plus")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2364284793
                      )
                    },
                    [_c("span", [_vm._v(" Novo ")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.selected == -1,
                                          small: "",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.openDialogEditGrid()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          color: "purple darken-1"
                                        }
                                      },
                                      [_vm._v("mdi-pencil")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3180480088
                      )
                    },
                    [_c("span", [_vm._v(" Editar ")])]
                  ),
                  _vm.showHeader
                    ? _c(
                        "span",
                        [
                          _c("span", { staticClass: "blue--text" }, [
                            _vm._v(" " + _vm._s(_vm.title) + " ")
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  fab: "",
                                                  dark: "",
                                                  "x-small": "",
                                                  color: "red"
                                                },
                                                on: { click: _vm.drawer }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-format-list-checkbox")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                492547589
                              )
                            },
                            [_c("span", [_vm._v(" Selecionar turma... ")])]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item-group",
            {
              attrs: { color: "blue" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            _vm._l(_vm.items, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  on: {
                    click: function($event) {
                      return _vm.$emit("click", item)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          class:
                            _vm.getPendencies(item) > 0
                              ? "red--text"
                              : "blue--text"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getNameDiscipline(item)) + " "
                          )
                        ]
                      ),
                      item.specificity
                        ? _c(
                            "v-list-item-subtitle",
                            {
                              class:
                                _vm.getPendencies(item) > 0
                                  ? "red--text"
                                  : "blue--text"
                            },
                            [
                              _vm._v(
                                "[" +
                                  _vm._s(item.specificity.abbreviation) +
                                  "]"
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-subtitle",
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                "text-color": "white",
                                color: item.color,
                                outlined: false,
                                pill: "",
                                "x-small": ""
                              }
                            },
                            [
                              _vm.getPendencies(item) <= 0
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.work_load_time_table) +
                                          " "
                                      ),
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-check ")
                                      ])
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getPendencies(item)) +
                                        " / " +
                                        _vm._s(item.work_load_time_table) +
                                        " "
                                    )
                                  ])
                            ]
                          ),
                          item.work_time_date
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      color: "orange",
                                                      label: "",
                                                      "x-small": "",
                                                      outlined: ""
                                                    }
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "x-small": ""
                                                    }
                                                  },
                                                  [_vm._v(" event ")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.work_time_date
                                                        .initials
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.work_time_date.name))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          item.join
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      color: "indigo",
                                                      label: "",
                                                      "x-small": "",
                                                      outlined: ""
                                                    }
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-small": "" } },
                                                  [_vm._v(" mdi-call-split ")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        "[" +
                                          item.join.join_id +
                                          "] " +
                                          _vm.getTextJoin(item.join)
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          item.category && !item.category.default
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      color: "purple",
                                                      label: "",
                                                      "x-small": "",
                                                      outlined: ""
                                                    }
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.category.initials
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.category.name))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          item.ambience
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    color: "indigo",
                                    label: "",
                                    "x-small": "",
                                    outlined: ""
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { "x-small": "" } }, [
                                    _vm._v(" mdi-home ")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          item.grid_note
                            ? _c("emc-info-help", {
                                attrs: {
                                  icon: "mdi-information-outline",
                                  title: "Observação",
                                  text: item.grid_note
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      item.teacher || item.in_hiring
                        ? _c("v-list-item-subtitle", { staticClass: "ml-1" }, [
                            item.teacher
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.getFirstName(item.teacher.name))
                                  )
                                ])
                              : item.in_hiring
                              ? _c("span", [_vm._v("A Contratar")])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            cursor: "help",
                            position: "relative",
                            right: "10px"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openChat(item)
                            }
                          }
                        },
                        [
                          item.has_message
                            ? _c(
                                "v-badge",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    bordered: "",
                                    top: "",
                                    color: "red",
                                    overlap: "",
                                    dot: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        large: "",
                                        color: "blue darken-2",
                                        small: ""
                                      }
                                    },
                                    [_vm._v(" mdi-email ")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-icon",
                                {
                                  staticClass: "ml-1",
                                  attrs: { large: "", color: "grey", small: "" }
                                },
                                [_vm._v(" mdi-email ")]
                              )
                        ],
                        1
                      ),
                      _c("emc-info-popover", {
                        attrs: { title: item.discipline.name },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "card-text",
                              fn: function() {
                                return [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      item.teacher
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-school-outline")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(item.teacher.name)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.ambience
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  item.ambience.type &&
                                                  item.ambience.type.is_virtual
                                                    ? _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-monitor-dashboard"
                                                        )
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v("mdi-home")
                                                      ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(item.ambience.name)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-pencil-ruler")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.work_load_type +
                                                      "=" +
                                                      item.work_load_time_table
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      item.group_number != 0
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-shield-half-full"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      "D" +
                                                        _vm._s(
                                                          item.group_number
                                                        )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.teacher_number != 0
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-school")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      "P" +
                                                        _vm._s(
                                                          item.teacher_number
                                                        )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.join && item.join.grids
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-call-split")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTextJoin(
                                                          item.join
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.category
                                        ? _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-grid")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(item.category.name)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("sys-register-grid", {
        attrs: { model: _vm.selectedGrid, show: _vm.showRegisterGrid },
        on: {
          "update:show": function($event) {
            _vm.showRegisterGrid = $event
          },
          onCompleted: _vm.completedGrid
        }
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.progress } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }