var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": _vm.maxHeight,
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "mt-4 mx-auto", attrs: { "max-width": "400" } },
        [
          _c(
            "v-sheet",
            {
              staticClass: "v-sheet--offset mx-auto",
              attrs: {
                color: "cyan",
                elevation: "12",
                "max-width": "calc(100% - 32px)"
              }
            },
            [
              _c("v-sparkline", {
                attrs: {
                  labels: _vm.labels,
                  value: _vm.value,
                  color: "white",
                  "line-width": "2",
                  padding: "16"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0" },
            [
              _c("div", { staticClass: "text-h6 font-weight-light mb-2" }, [
                _vm._v(" User Registrations ")
              ]),
              _c(
                "div",
                { staticClass: "subheading font-weight-light grey--text" },
                [_vm._v(" Last Campaign Performance ")]
              ),
              _c("v-divider", { staticClass: "my-2" }),
              _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                _vm._v(" mdi-clock ")
              ]),
              _c(
                "span",
                { staticClass: "text-caption grey--text font-weight-light" },
                [_vm._v("last registration 26 minutes ago")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }