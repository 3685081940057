<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-school-outline" 
        v-bind:show.sync="dialog"
        maxWidth="90%"
    >
        <template v-slot:tools>
            
            <emc-button-icon v-if="(acesso.instituicao && acesso.instituicao.settings.enable_photo_teacher) && !onlyHiring && model.id" text="Cadastro de Fotos" icon="mdi-camera-image" @click.prevent.stop="dialogImages = true" />
            
            <v-btn v-if="tab === 1 && !!model.id" icon ><v-icon color="red lighten-1" @click="$refs.extract.createPDF()">mdi-file-pdf-box</v-icon></v-btn>
            <v-tooltip v-if="tab === 2 && !!model.id" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success darken-1" icon @click.prevent.stop="showAddLicense = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span> Cadastrar Nova Licença </span>
            </v-tooltip>
            <v-tooltip v-if="tab === 3 && !!model.id" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success darken-1" icon @click.prevent.stop="showAddActivity = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span> Cadastrar Nova Atividade </span>
            </v-tooltip>
            <v-tooltip v-if="tab === 4 && !!model.id" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success darken-1" icon @click.prevent.stop="showAddContract = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span> Cadastrar Novo Vínculo </span>
            </v-tooltip>
        </template>

        <template v-slot:form>

            <template>
                <v-tabs v-model="tab" @change="forceRerender()" class="mb-4">
                    <v-tab key="dados">Dados</v-tab>
                    <v-tab v-if="!onlyHiring" key="extrato">Extrato</v-tab>
                    <v-tab v-if="!onlyHiring" key="licenca">Licenças</v-tab>
                    <v-tab v-if="!onlyHiring" key="atividade">Atividades</v-tab>
                    <v-tab v-if="!onlyHiring" key="vinculo">Vínculos</v-tab>
                    <v-tab v-if="!onlyHiring" key="endereco">Endereço</v-tab>
                    <!-- <v-tab v-show="false" key="opcionais">Opcionais</v-tab> -->
                    <!-- <v-tab v-show="false" key="censo">Censo</v-tab> -->
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item key="dados">                        
                        
                            <v-row class="mt-3">
                                <v-col cols="12" sm="3">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.code" v-model="model.code" label="Código" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-text-field autofocus dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                                </v-col>
                            </v-row>
                        <v-row> 
                        <v-col cols="8" xs="12"> 
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-card tile class="elevation-1">
                                        <v-toolbar class="elevation-1" color="grey lighten-3" height="40">
                                            <v-toolbar-title>Informações acadêmicas</v-toolbar-title>
                                        </v-toolbar>

                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field dense append-icon="mdi-cash" :error="errors.length > 0" :error-messages="errors.hour_value" v-model="hour_value" label="Valor Hora" v-mask-decimal.br="2" maxlength="6"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <sys-select-teacher-titling 
                                                        :model.sync="model.teacher_titling_id"
                                                        :error="errors.length > 0"  
                                                        :errors="errors.teacher_titling_id"
                                                        label="Titulação"
                                                        clearable
                                                        dense
                                                    />
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <sys-select-teacher-work-regime 
                                                        :model.sync="model.teacher_work_regime_id"
                                                        :error="errors.length > 0"  
                                                        :errors="errors.teacher_work_regime_id"
                                                        label="Regime de Trabalho"
                                                        clearable
                                                        dense
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-card tile class="elevation-1">
                                        <v-toolbar class="elevation-1" color="grey lighten-3" height="40">
                                            <v-toolbar-title>Demais informações</v-toolbar-title>
                                        </v-toolbar>

                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-combobox
                                                        v-model="model.campuses"
                                                        :items="itemsCampus"
                                                        label="Campus"
                                                        item-text="abbreviation"
                                                        multiple
                                                        clearable
                                                        dense
                                                    >
                                                        <template v-slot:selection="{ item, index }">
            
                                                            <span v-if="index === 0">{{ item.abbreviation }}</span>
                                                        
                                                            <span
                                                                v-if="index === 1"
                                                                class="grey--text caption ml-1"
                                                            >
                                                                (+{{ model.campuses.length - 1 }} outros)
                                                            </span>
                                                        </template>
                                                        
                                                    </v-combobox>
                                                </v-col>
                                                <!-- <v-col cols="12" sm="4">
                                                    <sys-select-relationship-type
                                                            :model.sync="model.relationships"
                                                            :error="errors.length > 0"  
                                                            :errors="errors.relationships"
                                                            label="Tipo de Vínculo"
                                                            multiple
                                                        />
                                                </v-col> -->
                                    <v-col cols="12" sm="3">
                                        <emc-calendar-date-picker2 
                                            label="Disponibilidade" 
                                            :model.sync="model.release_availability_until" 
                                            :errorMessages="errors.release_availability_until" 
                                            :show="dialog"
                                            dense
                                            clearable
                                            textHelper="Data limite para liberaração individual da disponibilidade."
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <emc-calendar-date-picker2 
                                            label="Confirmação CH" 
                                            :model.sync="model.release_confirmation_until" 
                                            :errorMessages="errors.release_confirmation_until" 
                                            :show="dialog"
                                            dense
                                            clearable
                                            textHelper="Data limite para liberaração individual da confirmação de carga horária."
                                        />
                                    </v-col>
                                    </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-card tile class="elevation-1">
                                <v-toolbar class="elevation-1" color="grey lighten-3" height="40">
                                    <v-toolbar-title>Informações pessoais</v-toolbar-title>
                                </v-toolbar>

                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense append-icon="mdi-phone" :error="errors.length > 0" v-mask-phone.br :error-messages="errors.mobile_phone" v-model="model.mobile_phone" label="Celular 1"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense append-icon="mdi-phone" :error="errors.length > 0" v-mask-phone.br :error-messages="errors.mobile_phone2" v-model="model.mobile_phone2" label="Celular 2"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense append-icon="mdi-card-account-details-outline" maxlength=14 :error="errors.length > 0" v-mask-cpf :error-messages="errors.number_cpf" v-model="model.number_cpf" label="CPF"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <emc-calendar-date-picker2 
                                                label="Data Nascimento" 
                                                :model.sync="model.birth_date" 
                                                :errorMessages="errors.birth_date" 
                                                :show="dialog"
                                                :readonly="false"
                                                :openOnClick="false"
                                                clearable
                                                dense
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                <v-row>
                    <v-col cols="12">
                    <v-card class="mx-auto">
                        <v-toolbar color="grey lighten-2" height="40">
                            <v-toolbar-title>Emails</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field v-model="mail" type="email" placeholder="Digite o email" :error="errors.length > 0" :error-messages="errors.emails">
                                <template v-slot:append-outer>
                                    <v-btn @click="addMail()" color="green white--text" fab x-small :loading="loadingAddMail" :disabled="loadingAddMail">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-card-text>
                        <v-card-text>
                            <v-list v-if="renderComponent">
                                <v-list-item v-for="(email, i) in model.emails" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="email.email"></v-list-item-title>
                                    </v-list-item-content>
                                   
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                <v-switch inset v-model="email.allow_editing"></v-switch>
                                            </span>
                                        </template>
                                        <span>Permitir edição pelo professor na sua página</span>
                                    </v-tooltip>
                                   
                                    <v-list-item-icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon :color="email.is_validated ? 'green' : ''" small class="mr-2" v-bind="attrs" v-on="on">mdi-check-all</v-icon>
                                            </template>
                                            <span>{{ email.is_validated ? 'Email validado!' : 'Aguardando validação!' }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" @click="removeMail(email, i)" color="red" icon x-small :loading="loadingRemoveMail && (mailDeleteId == i)" :disabled="loadingRemoveMail && (mailDeleteId == i)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Excluir email?</span>
                                        </v-tooltip>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                    </v-card>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-textarea
                            :error="!!errors.note" 
                            :error-messages="errors.note" 
                            v-model="model.note" 
                            label="Observação"
                            :counter="255"
                            rows="2"
                            :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                            outlined
                            clearable
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="2">
                        <v-switch class="pl-2" inset v-model="model.active" label="Professor Ativo"></v-switch>
                    </v-col>
                </v-row>

            </v-tab-item>
            <v-tab-item key="extrato">  
                <SysTeacherExtract ref="extract" :teacher="model" />  
            </v-tab-item>
            <v-tab-item key="licenca">  
                <sys-teacher-license v-if="renderComponent" :showAdd.sync="showAddLicense" :teacher="model" /> 
            </v-tab-item>
            <v-tab-item key="atividade">  
                <sys-teacher-activity v-if="renderComponent" :showAdd.sync="showAddActivity" :teacher="model" /> 
            </v-tab-item>
            <v-tab-item key="vinculo">  
                <sys-teacher-contract v-if="renderComponent"  :showAdd.sync="showAddContract" :teacher="model" /> 
            </v-tab-item>
            <v-tab-item key="endereco">  
                <sys-address-register :model.sync="model" type='teacher' />
            </v-tab-item>
            <!-- <v-tab-item key="opcionais">  
               <v-container>
                <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-resource
                                :model.sync="model.resources"
                                :error="errors.length > 0"  
                                :errors="errors.resources"
                                multiple
                                label="Recursos Necessários"
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item> -->
            <!-- <v-tab-item key="censo">  
                <sys-teacher-census :teacher="model" :error="error" :errors="errors" />
            </v-tab-item> -->
        </v-tabs-items>

        <emc-user-photos :model="model" :show.sync="dialogImages" />

            </template>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar timeout="10000" :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
        <template v-slot:actions>
            <v-btn v-if="onlyHiring && model && model.id" @click.prevent.stop="changeStatus()" >{{ model.teacher_status_id == 2 ? 'Tornar Ativo' : 'Tornar a Contratar' }}<v-icon right dark>{{ model.teacher_status_id == 2 ? 'mdi-tag-outline' : 'mdi-tag-off-outline' }}</v-icon></v-btn>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysRegisterTeacher",
    data: (vm) => ({
        dialog: false,
        tab: null,
        error: false,
        skill: 10,
        errors: {},
        message: '',
        showAddLicense: false,
        itemsCampus: [],
        showAddActivity: false,
        showAddContract: false,
        loading: false,
        renderComponent: false,
        hour_value: 0,
        mail: '',
        loadingAddMail: false,
        loadingRemoveMail: false,
        dialogImages: false,
        mailDeleteId: 0,
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    created() {
        this.loadCampus();
    },
    watch: {  
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.tab = null
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.tab = null
            this.dialog = show_new;

            if(show_new){
                
                this.errors = {};
                this.error = false;
                this.mail = "";

                if(!this.model.id){
                    this.model.nationality_id = 1;
                    this.model.country_id = 1;
                    this.model.genre_id = 3;
                    this.model.teacher_status_deficiency = 3;
                    this.model.active = true;
                }

            }
        },
        'hour_value': function(val) {
            if(val){
                this.model.hour_value = val.replace(',','.');
            }else{
                this.model.hour_value = "";
            }
            
        },
        'model.hour_value': function(val, old) {
            this.hour_value = val;
        }
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Professor"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        onlyHiring: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionCreateTeacher', 'ActionUpdateTeacher', 'ActionCreateEmail', 'ActionRemoveEmail']),
        ...mapActions('campus', ['ActionFindCampuses']),
        
        
        loadCampus(){
            
            let payload = {
                column_order: 'name',
            };
            
            this.ActionFindCampuses(payload)
                .then((res) => {
                    this.itemsCampus = res.data; 
                })
                .finally(() => {
                    
                })
        },
        
        addMail(){
            
            this.errors = {};
            
            if(this.isEmailValid()){

                this.loadingAddMail = true

                if(this.model.id) {

                    var payload = {
                        institution_id: this.acesso.instituicao.id,
                        email: this.mail,
                        teacher_id: this.model.id,
                        is_main: 0,
                        allow_editing: 1
                    }

                    this.ActionCreateEmail(payload)
                    .then((r) => {
                        this.model.emails.push(r.data);
                        this.mail = ''
                    })
                    .catch((e) => {
                        
                        this.errors = {
                            emails: e.body.errors['email']
                        };
                       
                    })
                    .finally(() => {
                        this.loadingAddMail = false
                    })
                } else {

                    var payload = {
                        email: this.mail,
                        is_main: 0,
                        allow_editing: 1
                    }

                    if(this.model.emails) {
                        this.model.emails.push(payload)
                    } else {
                        var emails = []
                        emails.push(payload)
                        Object.assign(this.model, {emails : emails})
                    }

                    this.mail = ''
                    this.loadingAddMail = false
                }
                    
            } else {
                this.errors = {
                    mail: 'Informe um email válido'
                }
            }
        },
        removeMail(email, i) {

            this.loadingRemoveMail = true;
            this.mailDeleteId = i;

            if(this.model.id) {
                this.ActionRemoveEmail(email)
                .then((r) => {
                    this.model.emails.splice(this.model.emails.indexOf(email), 1);
                })
                .finally(() => {
                    this.loadingRemoveMail = false;
                    this.forceRerender();
                })
            } else {
                this.model.emails.splice(this.model.emails.indexOf(email), 1);
                this.loadingRemoveMail = false;
                this.forceRerender();
            }
        },
        isEmailValid: function() {
            return (this.mail == "")? "" : (this.reg.test(this.mail)) ? true : false;
        },

        changeStatus(){
            this.model.teacher_status_id = this.model.teacher_status_id == 2 ? 1 : 2;
        },

        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateTeacher(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    
                    this.error      = error;

                    if(error.errors.attribution){
                        this.message    = error.errors.attribution;
                    }else{
                        this.errors     = error.errors;
                        this.message    = error.message;
                    }
                   
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {

                this.model.institution_id = this.acesso.instituicao.id;
                this.model.active = 1;
                
                if(this.onlyHiring){
                    this.model.teacher_status_id = 2;
                }else{
                    this.model.teacher_status_id = 1;
                }

                this.ActionCreateTeacher(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },

    }
}
</script>