var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { ref: "form" },
    [
      _c("v-file-input", {
        attrs: {
          name: "file",
          accept: _vm.accept,
          placeholder: _vm.placeholder,
          label: _vm.label,
          disabled: _vm.disabled || _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      icon: "",
                                      small: "",
                                      loading: _vm.loading,
                                      disabled:
                                        _vm.loading || _vm.fileUpload == null
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.upload()
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: "ligth green",
                                      right: "",
                                      dark: ""
                                    }
                                  },
                                  [_vm._v("mdi-cloud-upload")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v("Fazer upload do arquivo")])]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.fileUpload,
          callback: function($$v) {
            _vm.fileUpload = $$v
          },
          expression: "fileUpload"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }