import { render, staticRenderFns } from "./IconCategory.vue?vue&type=template&id=74a9f520&"
import script from "./IconCategory.vue?vue&type=script&lang=js&"
export * from "./IconCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74a9f520')) {
      api.createRecord('74a9f520', component.options)
    } else {
      api.reload('74a9f520', component.options)
    }
    module.hot.accept("./IconCategory.vue?vue&type=template&id=74a9f520&", function () {
      api.rerender('74a9f520', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/emc/util/IconCategory.vue"
export default component.exports