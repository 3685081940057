var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "search",
      show: _vm.dialog,
      maxWidth: "50%",
      showButton: false
    },
    on: {
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return [
            _vm.subHeaderAmbience ||
            _vm.subHeaderDateTime ||
            _vm.subHeaderResources
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: "indigo darken-1",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.clearFind()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-eraser")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2419787729
                    )
                  },
                  [_c("span", [_vm._v(" Limpar Pesquisa ")])]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-expansion-panels",
              {
                attrs: { focusable: "" },
                model: {
                  value: _vm.panel,
                  callback: function($$v) {
                    _vm.panel = $$v
                  },
                  expression: "panel"
                }
              },
              [
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        attrs: { "disable-icon-rotate": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(" mdi-google-classroom ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v("Local e Tipo de Ambiente *")
                          ]),
                          _vm.subHeaderAmbience
                            ? _c("small", [
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.subHeaderAmbience) + " "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.search.empty == "1"
                                        ? "Disponível"
                                        : "Ocupado"
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-campus", {
                                      attrs: {
                                        model: _vm.search.campus_id,
                                        error: _vm.errors.campus_id > 0,
                                        errors: _vm.errors.campus_id,
                                        label: "Campus*",
                                        dense: "",
                                        "auto-complete": "",
                                        "filter-relation": "ambiences"
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.search,
                                            "campus_id",
                                            $event
                                          )
                                        },
                                        change: function($event) {
                                          _vm.campus = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-ambience-type", {
                                      attrs: {
                                        model: _vm.search.types,
                                        label: "Tipo de Ambiente*",
                                        multiple: "",
                                        dense: "",
                                        selectAll: "",
                                        "campus-id": _vm.search.campus_id,
                                        disabled: _vm.search.campus_id == null
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.search,
                                            "types",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        "error-messages": _vm.errors.capacity,
                                        label: "Capacidade",
                                        clearable: "",
                                        inset: "",
                                        "text-helper":
                                          "Capacidade mínima do ambiente. Essa informação é opcional"
                                      },
                                      model: {
                                        value: _vm.search.capacity,
                                        callback: function($$v) {
                                          _vm.$set(_vm.search, "capacity", $$v)
                                        },
                                        expression: "search.capacity"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: { row: "" },
                                        model: {
                                          value: _vm.search.empty,
                                          callback: function($$v) {
                                            _vm.$set(_vm.search, "empty", $$v)
                                          },
                                          expression: "search.empty"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Disponível",
                                            value: "1"
                                          }
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            label: "Ocupado",
                                            value: "0",
                                            color: "purple"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        attrs: { "disable-icon-rotate": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(" mdi-calendar-clock ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _c("strong", [_vm._v("Data e Hora *")]),
                          _vm.subHeaderDateTime
                            ? _c("small", { staticClass: "mt-1" }, [
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.subHeaderDateTime) + " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-1" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _vm.renderComponent
                                      ? _c("emc-calendar-date-picker2", {
                                          attrs: {
                                            model: _vm.startDate,
                                            label: "Data Início*",
                                            errorMessages:
                                              _vm.errors.start_date,
                                            show: _vm.dialog,
                                            min: _vm.acesso.periodo.start,
                                            max: _vm.acesso.periodo.end,
                                            dense: ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              _vm.startDate = $event
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _vm.renderComponent
                                      ? _c("emc-calendar-date-picker2", {
                                          attrs: {
                                            model: _vm.search.end_date,
                                            label: "Data Fim*",
                                            errorMessages: _vm.errors.end_date,
                                            show: _vm.dialog,
                                            min: _vm.acesso.periodo.start,
                                            max: _vm.acesso.periodo.end,
                                            dense: ""
                                          },
                                          on: {
                                            "update:model": function($event) {
                                              return _vm.$set(
                                                _vm.search,
                                                "end_date",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("emc-calendar-time-picker", {
                                      attrs: {
                                        dense: "",
                                        error: !!_vm.errors.hour_start,
                                        errors: _vm.errors.hour_start,
                                        time: _vm.search.hour_start,
                                        label: "Hora Início*"
                                      },
                                      on: {
                                        "update:time": function($event) {
                                          return _vm.$set(
                                            _vm.search,
                                            "hour_start",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("emc-calendar-time-picker", {
                                      attrs: {
                                        dense: "",
                                        error: !!_vm.errors.hour_end,
                                        errors: _vm.errors.hour_end,
                                        time: _vm.search.hour_end,
                                        label: "Hora Fim*"
                                      },
                                      on: {
                                        "update:time": function($event) {
                                          return _vm.$set(
                                            _vm.search,
                                            "hour_end",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        attrs: { "disable-icon-rotate": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(" mdi-projector ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _c("strong", [
                            _vm._v(
                              "Recursos " +
                                _vm._s(
                                  _vm.selectedIndexResources.length
                                    ? "(" +
                                        _vm.selectedIndexResources.length +
                                        ")"
                                    : ""
                                )
                            )
                          ]),
                          _vm.subHeaderResources
                            ? _c("small", { staticClass: "mt-1" }, [
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.subHeaderResources) + " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item-group",
                              {
                                attrs: { color: "primary" },
                                model: {
                                  value: _vm.resourcesSelecteds,
                                  callback: function($$v) {
                                    _vm.resourcesSelecteds = $$v
                                  },
                                  expression: "resourcesSelecteds"
                                }
                              },
                              [
                                _c(
                                  "emc-util-scroll",
                                  { attrs: { height: "45vh" } },
                                  _vm._l(_vm.resources, function(item, index) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        on: {
                                          mousedown: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.selectResource(index)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", {
                                              attrs: {
                                                color: _vm.isCheckedResource(
                                                  index
                                                )
                                                  ? "indigo darken-4"
                                                  : ""
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.isCheckedResource(index)
                                                    ? "mdi-check-box-outline"
                                                    : "mdi-checkbox-blank-outline"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "8"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.name_complete
                                                            ) +
                                                            " "
                                                        ),
                                                        item.type
                                                          ? _c("small", [
                                                              _c("br"),
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.type.name
                                                                )
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    !item.shared
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "4"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Quantidade",
                                                                error:
                                                                  _vm.errors
                                                                    .length > 0,
                                                                "error-messages":
                                                                  _vm.errors[
                                                                    "resources." +
                                                                      index +
                                                                      ".total"
                                                                  ],
                                                                disabled: !_vm.isCheckedResource(
                                                                  index
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  item.total,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "total",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.total"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel",
                  { attrs: { disabled: _vm.disabledResult } },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        attrs: { "disable-icon-rotate": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(" mdi-format-list-checkbox ")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _c("strong", [
                            _vm._v("Resultado da Pesquisa "),
                            _vm.results.length
                              ? _c("span", [
                                  _vm._v(
                                    " (" + _vm._s(_vm.results.length) + ")"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("small", { staticClass: "mt-1" })
                        ])
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-simple-table", {
                          attrs: { "fixed-header": "", height: "300px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("ID")]),
                                      _c("th", [_vm._v("Ambiente")]),
                                      _c("th", [_vm._v("Cap.")]),
                                      _c("th")
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.results, function(r, i) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [_vm._v(_vm._s(r.id))]),
                                        _c("td", [_vm._v(_vm._s(r.slug_full))]),
                                        _c("td", [_vm._v(_vm._s(r.capacity))]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "indigo darken-1",
                                                                    icon: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      $event.stopPropagation()
                                                                      return _vm.selectAmbience(
                                                                        r
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-hand-pointing-left"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(" Selecionar ")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                false
                  ? _c(
                      "v-expansion-panel",
                      [
                        _c("v-expansion-panel-header", [
                          _c("strong", [_vm._v("Horários")])
                        ]),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c("v-simple-table", {
                              attrs: { "fixed-header": "", height: "300px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c(
                                                "th",
                                                [
                                                  _c("v-simple-checkbox", {
                                                    attrs: {
                                                      indeterminate:
                                                        _vm.indeterminate,
                                                      value: _vm.valueCheckAll,
                                                      color: "success"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.selectAll()
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._l(_vm.filteredDays, function(
                                                day,
                                                i
                                              ) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: i,
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(day.name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.filteredHours, function(
                                            hour,
                                            i
                                          ) {
                                            return _c(
                                              "tr",
                                              { key: i },
                                              [
                                                _c("td", [
                                                  _c("small", [
                                                    _vm._v(_vm._s(hour.start))
                                                  ]),
                                                  _c("br"),
                                                  _c("small", [
                                                    _vm._v(_vm._s(hour.end))
                                                  ])
                                                ]),
                                                _vm._l(
                                                  _vm.filteredDays,
                                                  function(day, i) {
                                                    return _c(
                                                      "td",
                                                      {
                                                        key: i,
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "v-simple-checkbox",
                                                          {
                                                            attrs: {
                                                              value: _vm.isSelected(
                                                                day.id,
                                                                hour
                                                              ),
                                                              color: "success"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.select(
                                                                  day.id,
                                                                  hour
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2224528194
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c("v-spacer"),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled: _vm.disabledSearch || _vm.loading,
                  loading: _vm.loading,
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.searchAmbiences()
                  }
                }
              },
              [
                _vm._v("Pesquisar"),
                _c("v-icon", { attrs: { right: "", dark: "" } }, [
                  _vm._v("search")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }