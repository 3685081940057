var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-message-base", {
        ref: "message",
        attrs: {
          items: _vm.items,
          title: _vm.title,
          show: _vm.dialog,
          loading: _vm.loading,
          loadingUpdate: _vm.loadingUpdate,
          loadingDelete: _vm.loadingDelete,
          loadingSend: _vm.loadingSend,
          sent: _vm.sent
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          onSend: _vm.sendMessage,
          onDelete: _vm.deleteItem,
          onUpdate: _vm.updateMessage,
          getData: function($event) {
            return _vm.getData()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }