var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "80%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _vm.loading
                          ? _c("v-progress-linear", {
                              attrs: {
                                indeterminate: "",
                                color: "yellow darken-2"
                              }
                            })
                          : _vm._e(),
                        _c("v-simple-table", {
                          attrs: { "fixed-header": "", height: "400px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _vm.items.length > 0
                                      ? _c(
                                          "tr",
                                          _vm._l(
                                            Object.keys(_vm.items[0]),
                                            function(item, i) {
                                              return _c(
                                                "th",
                                                { key: i },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "strong",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "strong",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            Object.values(
                                                              _vm.items[0]
                                                            )[i]["campus2"]
                                                              ? Object.values(
                                                                  _vm.items[0]
                                                                )[i]["campus2"]
                                                              : item
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm.items.length > 0
                                    ? _c(
                                        "tbody",
                                        _vm._l(_vm.items, function(item, i) {
                                          return _c(
                                            "tr",
                                            { key: i },
                                            _vm._l(Object.keys(item), function(
                                              item2,
                                              i
                                            ) {
                                              return _c(
                                                "td",
                                                { key: i },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {},
                                                                      "span",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        Object.values(
                                                                          item
                                                                        )[i][
                                                                          "value"
                                                                        ]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  Object.values(
                                                                    item
                                                                  )[i][
                                                                    "campus1"
                                                                  ]
                                                                ) +
                                                                " "
                                                            ),
                                                            Object.values(item)[
                                                              i
                                                            ]["campus2"]
                                                              ? _c("span", [
                                                                  _c("br"),
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      Object.values(
                                                                        item
                                                                      )[i][
                                                                        "campus2"
                                                                      ]
                                                                    )
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }