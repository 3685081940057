import { default as occurrence } from './occurrence/routes'
import { default as occurrence_sheet } from './occurrence_sheet/routes'
import { default as occurrence_type } from './occurrence_type/routes'
import { default as occurrence_validate } from './occurrence_validate/routes'
import { default as print } from './print/routes'
import { default as class_diary } from './class_diary/routes'

export default [
    ...occurrence,
    ...occurrence_sheet,
    ...occurrence_type,
    ...occurrence_validate,
    ...print,
    ...class_diary,
]