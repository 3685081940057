var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loadingSaving,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "60%",
      textButtonSave: "Salvar"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "12" } },
                          [
                            _c("v-data-table", {
                              staticClass: "elevation-1",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.portfolios,
                                "single-select": false,
                                "show-select": "",
                                loading: _vm.loading
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.day_of_week_id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("sys-select-day-of-week", {
                                        attrs: {
                                          model: item.day_of_week_id,
                                          error: _vm.errors.length > 0,
                                          errors: _vm.errors.day_of_week_id,
                                          label: "",
                                          filter: _vm.days,
                                          disabled: _vm.days.length == 0,
                                          "item-text": "initials",
                                          clearable: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            return _vm.$set(
                                              item,
                                              "day_of_week_id",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.selected,
                                callback: function($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.errors.length > 0
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function(error) {
                      return _c("li", { key: error }, [
                        _vm._v(" " + _vm._s(error) + " ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }