var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: _vm.maxWidth,
      showRequiredFields: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "form",
          fn: function() {
            return [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.items,
                              loading: _vm.isLoading,
                              "search-input": _vm.search,
                              "append-icon": "mdi-magnify",
                              "hide-no-data": "",
                              "hide-selected": "",
                              "item-text": "name",
                              label: "Pesquisar pela turma ou disciplina...",
                              "return-object": "",
                              autofocus: ""
                            },
                            on: {
                              "update:searchInput": function($event) {
                                _vm.search = $event
                              },
                              "update:search-input": function($event) {
                                _vm.search = $event
                              },
                              "click:append": function($event) {
                                return _vm.searchGrids(_vm.search)
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchGrids(_vm.search)
                              }
                            },
                            model: {
                              value: _vm.selectedGrid,
                              callback: function($$v) {
                                _vm.selectedGrid = $$v
                              },
                              expression: "selectedGrid"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("s-text-field", {
                            attrs: {
                              code: "",
                              "error-messages": _vm.errors.code,
                              label: "Código"
                            },
                            model: {
                              value: _vm.model.code,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "code", $$v)
                              },
                              expression: "model.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("s-text-field", {
                            attrs: {
                              "text-helper":
                                "Código utilizado para identificar junções que pertecem ao mesmo grupo",
                              "error-messages": _vm.errors.code_link,
                              label: "Código Cluster"
                            },
                            model: {
                              value: _vm.model.code_link,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "code_link", $$v)
                              },
                              expression: "model.code_link"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("sys-select-join-category", {
                            attrs: {
                              model: _vm.model.join_category_id,
                              label: "Categoria",
                              clearable: ""
                            },
                            on: {
                              "update:model": function($event) {
                                return _vm.$set(
                                  _vm.model,
                                  "join_category_id",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("sys-select-join-type", {
                            attrs: {
                              model: _vm.model.join_type_id,
                              label: "Tipo"
                            },
                            on: {
                              "update:model": function($event) {
                                return _vm.$set(
                                  _vm.model,
                                  "join_type_id",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.settings && _vm.settings.allow_replicate_teacher_join
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "3" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  inset: "",
                                  label: "Replicar Professor"
                                },
                                model: {
                                  value: _vm.model.replicate_teacher,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model,
                                      "replicate_teacher",
                                      $$v
                                    )
                                  },
                                  expression: "model.replicate_teacher"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "", height: "50%" } },
                            [
                              _c(
                                "v-app-bar",
                                {
                                  staticClass: "elevation-0",
                                  attrs: {
                                    color:
                                      "grey lighten-2 grey--text text--darken-1",
                                    dense: ""
                                  }
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v(" Turmas da junção "),
                                    _vm.getTotalStudents() > 0
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.getTotalStudents()) +
                                              " Alunos)"
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                staticClass: "elevation-0",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.grids,
                                  "single-select": false,
                                  "item-key": "id",
                                  "show-select": "",
                                  "item-selected": _vm.selected,
                                  "hide-default-footer": "",
                                  height: "300",
                                  "items-per-page": -1,
                                  "fixed-header": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.group",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm.model && _vm.model.id
                                            ? _c(
                                                "span",
                                                [
                                                  item.join && item.join.main
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            cursor: "default"
                                                          },
                                                          attrs: {
                                                            color: "amber",
                                                            small: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-star-half-full "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "grey",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.setMain(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-star-outline "
                                                          )
                                                        ]
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " + _vm._s(item.group.name) + " "
                                          ),
                                          item.subGroup
                                            ? _c("small", [
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(item.subGroup.name)
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.work_load",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.work_load_type.initials +
                                                "=" +
                                                item.work_load
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.join",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.join &&
                                        item.join.join_id !== _vm.model.id &&
                                        item.join.join_id > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "red--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "Essa disciplina já faz parte da junção: " +
                                                      item.join.join_id
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.selected,
                                  callback: function($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.errors.length > 0
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error) {
                        return _c("li", { key: error }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _c("emc-alert-snackbar", {
                attrs: {
                  show: !!_vm.message.length,
                  message: _vm.message,
                  color: _vm.error ? "error" : "success"
                }
              })
            ]
          },
          proxy: true
        },
        _vm.model && _vm.model.id
          ? {
              key: "actions",
              fn: function() {
                return [
                  _c("v-icon", { attrs: { color: "amber", small: "" } }, [
                    _vm._v(" mdi-star-half-full ")
                  ]),
                  _c("small", [_vm._v("Turma Principal")]),
                  _c("v-spacer")
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }