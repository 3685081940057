export default [
    {
        path: '/discipline_portfolios',
        name: 'discipline_portfolios',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Portfólio UC Dual',
            icon: 'mdi-playlist-star',
            breadcrumbs: [
                { text: 'Lista de Portfólio UC Dual', disabled: true }
            ]
        }
    }
]