export default {

    indexDisciplinePortfolios:      { method: 'get',    url: 'discipline_portfolios' },
    showDisciplinePortfolios:       { method: 'get',    url: 'discipline_portfolios/{id}' },
    storeDisciplinePortfolio:       { method: 'post',   url: 'discipline_portfolios' },
    updateDisciplinePortfolio:      { method: 'put',    url: 'discipline_portfolios/{id}'},
    destroyDisciplinePortfolio:     { method: 'delete', url: 'discipline_portfolios/{id}'},
    indexOfferItemPortfolios:       { method: 'get',    url: 'offer_item_portfolios' },
    indexOfferItemOpportunities:    { method: 'get',    url: 'discipline_portfolios/{offer_item_id}/opportunities' },
    indexGridOpportunities:         { method: 'get',    url: 'discipline_portfolios/{grid_id}/opportunities/grid' },

}