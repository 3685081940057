var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("EmcChartLoading") : _vm._e(),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "pa-2"
        },
        [
          _vm.renderComponent
            ? _c("canvas", {
                attrs: { id: _vm.name, width: _vm.width, height: _vm.height }
              })
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _vm.process
                ? _c("span", [
                    _vm.process.updated_last
                      ? _c("small", [
                          _vm._v(
                            " Atualização: " +
                              _vm._s(
                                _vm._f("dateTime")(_vm.process.updated_last)
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("small", [
                      _vm.process.budget_process_status_id <= 3
                        ? _c("strong", [
                            _vm._v(
                              "(" + _vm._s(_vm.process.status.name) + "...)"
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c("emc-button-icon", {
                attrs: {
                  loading: _vm.loadingRefresh,
                  icon: "mdi-refresh-circle",
                  text: "Atualizar",
                  color: "success"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.forceRerender()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }