var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 400,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "", color: "grey", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Configurações")]),
                          _c("v-list-item-subtitle", [_vm._v("Instituição")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.fav ? "red--text" : "",
                              attrs: { icon: "", small: "" },
                              on: {
                                click: function($event) {
                                  _vm.fav = !_vm.fav
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.menu = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "emc-util-scroll",
                    { attrs: { maxHeight: "350px" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "Texto da Disponibilidade Semanal",
                              value: _vm.model.text_availability,
                              error: _vm.errors.length > 0,
                              errors: _vm.errors.text_availability,
                              clearable: "",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.model.text_availability,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "text_availability", $$v)
                              },
                              expression: "model.text_availability"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "Texto da Disponibilidade Data",
                              value: _vm.model.text_availability_date,
                              error: _vm.errors.length > 0,
                              errors: _vm.errors.text_availability_date,
                              clearable: "",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.model.text_availability_date,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "text_availability_date",
                                  $$v
                                )
                              },
                              expression: "model.text_availability_date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "Texto Interesse Disciplinas",
                              value: _vm.model.text_interest,
                              error: _vm.errors.length > 0,
                              errors: _vm.errors.text_interest,
                              clearable: "",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.model.text_interest,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "text_interest", $$v)
                              },
                              expression: "model.text_interest"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              name: "input-7-4",
                              label: "Texto Confirmação de CH",
                              value: _vm.model.text_confirmation,
                              error: _vm.errors.length > 0,
                              errors: _vm.errors.text_confirmation,
                              clearable: "",
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.model.text_confirmation,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "text_confirmation", $$v)
                              },
                              expression: "model.text_confirmation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _vm.user.is_master &&
                          _vm.model.settings &&
                          _vm.acesso &&
                            _vm.acesso.periodo &&
                            _vm.acesso.periodo.attribution_work_time_dates
                            ? _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  name: "input-7-4",
                                  label: "Regra Percentual de Alunos",
                                  value:
                                    _vm.model.settings
                                      .attribution_students_percent,
                                  error: _vm.errors.length > 0,
                                  errors:
                                    _vm.errors.attribution_students_percent,
                                  clearable: "",
                                  "auto-grow": "",
                                  rows: "3"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .attribution_students_percent,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "attribution_students_percent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.attribution_students_percent"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              label:
                                "Enviar email de confirmação para professor"
                            },
                            model: {
                              value: _vm.model.is_confirm_email,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "is_confirm_email", $$v)
                              },
                              expression: "model.is_confirm_email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              label: "Exibir Atualização Cadastral"
                            },
                            model: {
                              value: _vm.model.show_update_data,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "show_update_data", $$v)
                              },
                              expression: "model.show_update_data"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              label: "Derrubar horário ao trocar professor"
                            },
                            model: {
                              value: _vm.model.clear_classes_attribution,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "clear_classes_attribution",
                                  $$v
                                )
                              },
                              expression: "model.clear_classes_attribution"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  inset: "",
                                  "text-helper":
                                    "Validar curso do usário na Atribuição de Professores",
                                  label: "Validar Cursos"
                                },
                                model: {
                                  value: _vm.model.settings.validate_courses,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "validate_courses",
                                      $$v
                                    )
                                  },
                                  expression: "model.settings.validate_courses"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  inset: "",
                                  "text-helper":
                                    "Validar disponibilidade e choque de horário na Atribuição de Professores",
                                  label: "Validar Choque de Horário"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .validate_time_table_attribution,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "validate_time_table_attribution",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.validate_time_table_attribution"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              label: "Habilitar Hora Atividade"
                            },
                            model: {
                              value: _vm.model.enable_activity_time,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "enable_activity_time", $$v)
                              },
                              expression: "model.enable_activity_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", label: "Calcular Resilição" },
                            model: {
                              value: _vm.model.calculate_variation,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "calculate_variation", $$v)
                              },
                              expression: "model.calculate_variation"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  inset: "",
                                  "text-helper":
                                    "Exibir opção de atribuição temporária na tela de Professores das Turmas",
                                  label: "Atribuição Eventual"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.show_attribution_temp,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_attribution_temp",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_attribution_temp"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  inset: "",
                                  "text-helper":
                                    "Exibir opção de carga horária paga na tela de Professores das Turmas",
                                  label: "Atribuição Paga/Não Paga"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.show_attribution_pay,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_attribution_pay",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_attribution_pay"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                attrs: {
                                  inset: "",
                                  label:
                                    "Permitir configuração de replicar junção"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .allow_replicate_teacher_join,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "allow_replicate_teacher_join",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.allow_replicate_teacher_join"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings &&
                      _vm.model.settings.allow_replicate_teacher_join
                        ? _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                attrs: { inset: "", label: "Replicar Junção" },
                                model: {
                                  value:
                                    _vm.model.settings.replicate_teacher_join,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "replicate_teacher_join",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.replicate_teacher_join"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              label: "Fluxo de Aprovação de Atividade"
                            },
                            model: {
                              value: _vm.model.enable_activity_approval_flow,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.model,
                                  "enable_activity_approval_flow",
                                  $$v
                                )
                              },
                              expression: "model.enable_activity_approval_flow"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                attrs: {
                                  inset: "",
                                  label: "Enviar e-mail de aprovação"
                                },
                                model: {
                                  value: _vm.model.settings.send_email_activity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "send_email_activity",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.send_email_activity"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                attrs: { inset: "", label: "Exibir Termos" },
                                model: {
                                  value:
                                    _vm.model.settings.show_term_signatures,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_term_signatures",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_term_signatures"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("v-switch", {
                                attrs: {
                                  inset: "",
                                  label: "Multi Docente nas Atividades"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .allow_multi_teachers_activities,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "allow_multi_teachers_activities",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.allow_multi_teachers_activities"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Atividades - Obrigatório Centro de Custos quando curso for vazio",
                                  inset: "",
                                  label:
                                    "Centro de Custo obrigatório - Atividades"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .cost_center_activity_required,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "cost_center_activity_required",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.cost_center_activity_required"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Atividades - Filtrar Atividades por departamento do Usuário",
                                  inset: "",
                                  label: "Atividades por Departamento"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .activities_by_departament,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "activities_by_departament",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.activities_by_departament"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Habilitar professor genérico A Contratar",
                                  inset: "",
                                  label: "Habilitar A Contratar"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.allow_teacher_in_hiring,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "allow_teacher_in_hiring",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.allow_teacher_in_hiring"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Exibir Grupo de Horário na página da disponibilidade",
                                  inset: "",
                                  label: "Exibir Grupo de Horário"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .show_hour_group_availability,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_hour_group_availability",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_hour_group_availability"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Exibir opção de horário por data na tela de horário",
                                  inset: "",
                                  label: "Horário por data"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.allow_time_table_by_date,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "allow_time_table_by_date",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.allow_time_table_by_date"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Exibir opção de atualização de endreço na página do professor",
                                  inset: "",
                                  label: "Atualização Endereço"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.show_address_teacher,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_address_teacher",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_address_teacher"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Pesquisar profesores próximos da localidade na atribuição de professor",
                                  inset: "",
                                  label: "Pesquisar Localidade Professor"
                                },
                                model: {
                                  value:
                                    _vm.model.settings
                                      .show_address_teacher_attribution,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "show_address_teacher_attribution",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.show_address_teacher_attribution"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("s-switch", {
                                attrs: {
                                  "text-helper":
                                    "Habilitar cadastro de fotos do professor",
                                  inset: "",
                                  label: "Habilitar Foto Professor"
                                },
                                model: {
                                  value:
                                    _vm.model.settings.enable_photo_teacher,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.model.settings,
                                      "enable_photo_teacher",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "model.settings.enable_photo_teacher"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      "text-helper":
                                        "Máximo de horas de aulas + atividades no mesmo dia",
                                      "error-messages":
                                        _vm.errors.max_day_hours,
                                      label: "Máximo de horas por dia"
                                    },
                                    model: {
                                      value: _vm.model.max_day_hours,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "max_day_hours",
                                          $$v
                                        )
                                      },
                                      expression: "model.max_day_hours"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("s-text-field", {
                                    attrs: {
                                      "text-helper":
                                        "Máximo de aulas consecutivas no dia sem intervalo",
                                      "error-messages":
                                        _vm.errors.max_times_break_day,
                                      label: "Máximo aulas consecutivas"
                                    },
                                    model: {
                                      value: _vm.model.max_times_break_day,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.model,
                                          "max_times_break_day",
                                          $$v
                                        )
                                      },
                                      expression: "model.max_times_break_day"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.model.settings
                                ? _c(
                                    "v-col",
                                    [
                                      _c("s-text-field", {
                                        attrs: {
                                          "text-helper":
                                            "Dia limite para aprovação das atividades",
                                          "error-messages": _vm.errors.settings
                                            ? _vm.errors.settings
                                                .approval_activity_deadline
                                            : [],
                                          label:
                                            "Dia limite aprovação atividades"
                                        },
                                        model: {
                                          value:
                                            _vm.model.settings
                                              .approval_activity_deadline,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model.settings,
                                              "approval_activity_deadline",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.settings.approval_activity_deadline"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                [
                                  _c("emc-calendar-time-picker", {
                                    attrs: {
                                      error: !!_vm.errors.min_time_break,
                                      errors: _vm.errors.min_time_break,
                                      time: _vm.model.min_time_break,
                                      label: "Intervalo mínimo"
                                    },
                                    on: {
                                      "update:time": function($event) {
                                        return _vm.$set(
                                          _vm.model,
                                          "min_time_break",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.model.settings
                        ? _c(
                            "v-list-item",
                            [
                              _c("sys-select-activity-item-status", {
                                attrs: {
                                  model:
                                    _vm.model.settings.activity_item_status,
                                  error: _vm.errors.length > 0,
                                  errors: _vm.errors.activity_item_status_id,
                                  label: "Status Atividade para pagamento",
                                  multiple: ""
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.model.settings,
                                      "activity_item_status",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }