var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-avatar-base", {
        attrs: {
          src: _vm.model.url_company_logo,
          width: _vm.width,
          height: _vm.height,
          model: _vm.model,
          tile: "",
          showCamera: false,
          iconDefault: "mdi-image-outline"
        },
        on: { onComplete: _vm.upload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }