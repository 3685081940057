var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.model.dates,
              "hide-default-footer": "",
              "items-per-page": -1,
              "item-key": "index"
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              disabled: !_vm.model || !_vm.model.id,
                              color: "indigo darken-1",
                              icon: ""
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.addItem()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.name",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("v-text-field", {
                      attrs: {
                        error: _vm.errors.length > 0,
                        "error-messages":
                          _vm.errors["dates." + index + ".name"],
                        dense: ""
                      },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.initials",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        error: _vm.errors.length > 0,
                        "error-messages":
                          _vm.errors["dates." + index + ".initials"]
                      },
                      model: {
                        value: item.initials,
                        callback: function($$v) {
                          _vm.$set(item, "initials", $$v)
                        },
                        expression: "item.initials"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.date",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("emc-calendar-date-picker2", {
                      attrs: {
                        model: item.date,
                        label: "",
                        error: _vm.errors.length > 0,
                        "error-messages":
                          _vm.errors["dates." + index + ".date"],
                        show: _vm.dialog
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(item, "date", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.hour_start",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("emc-calendar-time-picker", {
                      attrs: {
                        dense: "",
                        error: !!_vm.errors.hour_start,
                        errors: _vm.errors["dates." + index + ".hour_start"],
                        time: item.hour_start,
                        label: "",
                        clearable: ""
                      },
                      on: {
                        "update:time": function($event) {
                          return _vm.$set(item, "hour_start", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.hour_end",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("emc-calendar-time-picker", {
                      attrs: {
                        dense: "",
                        error: !!_vm.errors.hour_end,
                        errors: _vm.errors["dates." + index + ".hour_end"],
                        time: item.hour_end,
                        label: "",
                        clearable: ""
                      },
                      on: {
                        "update:time": function($event) {
                          return _vm.$set(item, "hour_end", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.loading && _vm.indexDelete == index,
                          disabled: _vm.loading,
                          icon: ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.deleteItem(item, index)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "error", dense: "" } },
                          [_vm._v(" mdi-delete ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }