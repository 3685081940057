var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            dark: "",
                            large: "",
                            color: "grey"
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Validação Geral")]),
                          _c("v-list-item-subtitle", [_vm._v("Horário")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.fav ? "red--text" : "",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.fav = !_vm.fav
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.menu = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: {
                              disabled: !_vm.user.is_master,
                              inset: "",
                              color: "purple"
                            },
                            model: {
                              value: _vm.config.allowShock,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowShock", $$v)
                              },
                              expression: "config.allowShock"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Choque de Professor")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: {
                              disabled: !_vm.user.is_master,
                              inset: "",
                              color: "purple"
                            },
                            model: {
                              value: _vm.config.allowDoubleClass,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowDoubleClass", $$v)
                              },
                              expression: "config.allowDoubleClass"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Sobreposição de Aulas")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: {
                              disabled: !_vm.user.is_master,
                              inset: "",
                              color: "purple"
                            },
                            model: {
                              value: _vm.config.allowDoubleClassJoin,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.config,
                                  "allowDoubleClassJoin",
                                  $$v
                                )
                              },
                              expression: "config.allowDoubleClassJoin"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Sobreposição nas turmas da Junção")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowDesloc,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowDesloc", $$v)
                              },
                              expression: "config.allowDesloc"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Permitir Deslocamento")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: {
                              disabled: !_vm.user.is_master,
                              inset: "",
                              color: "purple"
                            },
                            model: {
                              value: _vm.config.allowJourney,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowJourney", $$v)
                              },
                              expression: "config.allowJourney"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Permitir Interjornada")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowShockAmbience,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowShockAmbience", $$v)
                              },
                              expression: "config.allowShockAmbience"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Choque de Ambiente")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowHourGroup,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowHourGroup", $$v)
                              },
                              expression: "config.allowHourGroup"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Alocação em outros horários")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowIntraDay,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowIntraDay", $$v)
                              },
                              expression: "config.allowIntraDay"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Permitir Intrajornada")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowMaxHoursDay,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowMaxHoursDay", $$v)
                              },
                              expression: "config.allowMaxHoursDay"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Permitir Máximo de Horas por Dia")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            model: {
                              value: _vm.config.allowAvailability,
                              callback: function($$v) {
                                _vm.$set(_vm.config, "allowAvailability", $$v)
                              },
                              expression: "config.allowAvailability"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Não Validar Disponibilidade")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-actions", [_c("v-spacer")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }