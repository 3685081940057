export default {
    availabilityCreate:         { method: 'post',   url: 'availabilities' },
    availabilityUpdate:         { method: 'put',    url: 'availabilities/{id}' },
    optimized:                  { method: 'get',    url: 'availabilities/{teacher_id}/optimized' },
    workTimeByToken:            { method: 'get',    url: 'work_times/token/{token}'},
    teacherByTokenAttribution:  { method: 'get',    url: 'teachers/token/{token}/attribution'},
    teacherByTokenActivities:   { method: 'get',    url: 'teachers/token/{token}/activities'},
    availabilityDates:          { method: 'get',    url: 'availability_dates'},
    crateDate:                  { method: 'post',   url: 'availability_dates'},
    updateDate:                 { method: 'post',   url: 'availability_dates/{id}'},
    deleteDate:                 { method: 'delete', url: 'availability_dates/{id}'},
    updateDateAll:              { method: 'put',    url: 'availability_dates/all'},
    updateDateAllToken:         { method: 'put',    url: 'availability_dates/token/all'},
    availabilityDatesToken:     { method: 'get',    url: 'availability_dates/token/{token}'},
    
}