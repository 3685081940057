<template>
    <span>
        <v-avatar v-if="!imageError && src" @click.prevent.stop="$emit('click')" style="cursor: pointer;" :color="color" :size="size" :class="strClass">
            <v-img v-if="text" :src="src" :alt="text" @error="onImgError()"></v-img>
        </v-avatar>
        <v-avatar  v-else :color="color" :size="size" :class="strClass">
            <span v-if="text" :class="textColor">{{ text }} </span>
        </v-avatar>
    </span>
</template>

<script>
export default {
    name: "BaseAvatar",
    data () {
        return {
            imageError: false,
        }
    },
    props: {
        color: {
            type: String,
            default: "grey"
        },
        textColor: {
            type: String,
            default: "white--text"
        },
        text: {
            required: true
        },
        size: {
            type: Number,
            default: 48
        },
        src: {
            type: String,
            required: false
        },
        strClass: {
            type: String,
            required: false
        }
    },
    methods: {
        onImgError() {
            this.imageError = true
        }
    }
}
</script>