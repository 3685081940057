<template>
    <v-footer app fixed>
        <span id="footer">
          <a href="https://emcsoft.com.br" target="_blank"><v-img src="/img/emcsoft_logo.png" max-width="80" /></a>
        </span>
        <v-spacer/>
        <span id="footer">
          <span color="white" v-if="acesso.grupo != null" ><strong>{{ footer }}</strong></span>
        </span>
    </v-footer>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'SysCoreFooter',
    data: () => ({
        year: new Date().getFullYear(),
    }),
    computed: {
        ...mapState('auth', ['acesso']),

        footer() {
            
            if(!! Object.keys(this.acesso).length){
                return this.acesso.instituicao.name + " | " + this.acesso.periodo.name;
            }

            return "";
        }

    },
    
    
}
</script>