var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-chart-apex-base", {
    attrs: {
      series: _vm.series,
      type: "line",
      options: _vm.chartOptions,
      loading: _vm.loading
    },
    on: {
      "update:loading": function($event) {
        _vm.loading = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [
            _c("emc-chart-filter", {
              attrs: { show: _vm.showFilter },
              on: {
                "update:show": function($event) {
                  _vm.showFilter = $event
                },
                filter: function($event) {
                  return _vm.filter($event)
                }
              }
            }),
            _c("v-spacer"),
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: { color: "primary", icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilter = true
                                  }
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { btn: "" } }, [
                              _vm._v("mdi-filter-outline")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v(" Selecionar Categorias ")])]
            ),
            _c("emc-button-icon", {
              attrs: {
                "btn-small": "",
                loading: _vm.loading,
                icon: "mdi-refresh-circle",
                text: _vm.updated_at || "Atualizar",
                color: "success"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.refresh()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }