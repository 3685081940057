<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :dense="dense"
            :itemText="itemText"
            :disabled="disabled"
            :autoComplete="autoComplete"
            :showAdd="showAdd"
            @change="$emit('change', $event)"
        />
       
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectTeacherContract',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        itemText: {
            type: String,
            default: 'name_complete'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        teacherId: {
            required: true
        },
        campusId: {
            
        },
        filterCompany: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },

        teacherId(){
            this.getItems();
        },

        campusId(){
            this.getItems();
        }
    },
    methods: {
        ...mapActions('teacherContract', ['ActionFindTeacherContracts']),        
        
        async getItems() {

            if(!this.teacherId){
                return;
            }
            
            this.loading = true;

            let payload = {
                find_columns: {
                    teacher_id: this.teacherId,
                    active: 1,
                },
            };

            if(this.campusId){
                payload.filter_relations = [
                    {
                        name:   'campuses',
                        key:    'campus_id', 
                        value:  this.campusId,
                    }
                ];
            }

            await this.ActionFindTeacherContracts(payload)
                    .then((res) => {
                        
                        if(this.filterCompany){
                            this.items = res.data.filter(i => (i.company_id != null && i.branch_id != null));
                        }else{
                            this.items = res.data;
                        }
                        
                        
                        
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>