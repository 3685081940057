var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-app-bar",
            {
              staticClass: "elevation-0",
              attrs: { color: "grey lighten-4", dense: "" }
            },
            [
              _c(
                "v-toolbar-title",
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-calendar-clock")
                  ]),
                  _vm._v(" Enviar Email com horários do professor")
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: _vm.modelTemplate.id
                                      ? "teal darken-1"
                                      : "red",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showEmailTemplate = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-card-account-mail-outline")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.modelTemplate.id
                          ? "Template do email"
                          : "Template de email não cadastrado"
                      )
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.teachers,
              "single-select": false,
              "item-key": "id",
              "show-select": "",
              search: _vm.search,
              dense: "",
              itemsPerPage: 5,
              "item-selected": _vm.selected,
              loading: _vm.loading
            },
            on: { "current-items": _vm.getFiltered },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-text-field", {
                      staticClass: "mx-4",
                      attrs: { label: "Pesquisar..." },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: _vm.modelTemplate.id
                                              ? "blue"
                                              : "red",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showEmail(item)
                                            }
                                          }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-newspaper-variant-outline")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.modelTemplate.id
                                ? "Visualizar"
                                : "Template de email não cadastrado"
                            )
                          )
                        ])
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("div", { staticClass: "caption mx-2" }, [
            _c("strong", [_vm._v("Quantidade selecionada: ")]),
            _vm._v(_vm._s(_vm.selected.length))
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-switch", {
                attrs: { inset: "", label: "Exibir apenas turmas principais" },
                model: {
                  value: _vm.onlyMain,
                  callback: function($$v) {
                    _vm.onlyMain = $$v
                  },
                  expression: "onlyMain"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.saving },
                  nativeOn: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled:
                      _vm.saving ||
                      !_vm.modelTemplate.id ||
                      !_vm.selected.length
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.send()
                    }
                  }
                },
                [
                  _vm._v("Enviar"),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-send")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("sys-register-template-email", {
        attrs: {
          onModel: _vm.modelTemplate,
          show: _vm.showEmailTemplate,
          templateId: 2
        },
        on: {
          "update:onModel": function($event) {
            _vm.modelTemplate = $event
          },
          "update:on-model": function($event) {
            _vm.modelTemplate = $event
          },
          "update:show": function($event) {
            _vm.showEmailTemplate = $event
          }
        }
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.loadingShowPreview } }),
      _c("emc-modal-form", {
        attrs: { show: _vm.showPreview },
        on: {
          "update:show": function($event) {
            _vm.showPreview = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.previewMail) } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }