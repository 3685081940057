export default {
    
    indexDynamicAllocations:        { method: 'get',    url: 'dynamic_allocations' },
    createDynamicAllocation:        { method: 'post',   url: 'dynamic_allocations'},
    updateDynamicAllocation:        { method: 'put',    url: 'dynamic_allocations/{id}'},
    deleteDynamicAllocation:        { method: 'delete', url: 'dynamic_allocations/{id}'},
    generateDynamicAllocation:      { method: 'put',    url: 'dynamic_allocations/{id}/generate'},
    syncDynamicAllocation:          { method: 'put',    url: 'dynamic_allocations/{id}/sync'},
    indexDynamicAllocationTypes:    { method: 'get',    url: 'dynamic_allocations/types' },
    viewResultsDynamicAllocation:   { method: 'get',    url: 'dynamic_allocations/view-results/{id}'},
}