import { service } from '../../../../helpers/http'

export const ActionIndexOfferItemProtocolProcesses = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemProtocolProcesses',
        payload: payload
    })

}
export const ActionDestroyOfferItemProtocolProcess = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyOfferItemProtocolProcess',
        payload: payload
    })
}
export const ActionStoreOfferItemProtocolProcess = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeOfferItemProtocolProcess',
        payload: payload
    })

}
export const ActionUpdateOfferItemProtocolProcess = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateOfferItemProtocolProcess',
        payload: payload
    })
}


export const ActionIndexOfferItemProtocolProcessStatus = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemProtocolProcessStatus',
        payload: payload
    })

}