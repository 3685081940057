<template>

  <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="model"
      :title="title"
      icon="mdi-clipboard-list-outline"
      v-bind:show.sync="showForm"
      maxWidth="50%"
  >
    <template v-slot:form>
      <v-card elevation="0">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="2">
              <v-text-field dense :error="errors.length > 0" :error-messages="errors.code" v-model="model.code" label="Código*"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field dense :error="errors.length > 0" :error-messages="errors.note" v-model="model.note" label="Sigla"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <sys-select-company
                  :model.sync="model.company_id"
                  :error="errors.length > 0"  
                  :errors="errors.company_id"
                  label="Empresa*"
                  clearable
                  dense
                  autoComplete
              />
            </v-col>
            <v-col cols="12" sm="4">
                <sys-select-branch
                    :model.sync="model.branch_id"
                    :error="errors.length > 0"  
                    :errors="errors.branch_id"
                    label="Filial*"
                    clearable
                    dense
                    :disabled="model.company_id === null"
                    :company-id="model.company_id"
                />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <SysSelectCourseLevel
                :model.sync="model.course_level_id"
                :error="errors.length > 0" 
                clearable 
                :errors="errors.course_level_id" 
                label="Nível de Curso*" 
                :company-id="model.company_id"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

    </template>
  </sys-register-base>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "SysRegisterWorkTimeIntegration",
  data: () => ({
    loading: false,
    title: 'Parâmetro para integração',
    showForm: false,
    errors: {},
    error: false,
    message: ''
  }),
  props: {
    model: {},
    workTime: {},
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('worktime', ['ActionUpdateIntegrationWorkTime', 'ActionStoreIntegrationWorkTime']),
    save(edit) {

      this.message = ''
      this.errors = {}
      this.error = false
      this.loading = true

      if(edit) {
        this.ActionUpdateIntegrationWorkTime(this.model)
            .then((r) => {
              this.message = r.message
              this.$emit('onCompleted')
              this.showForm = false
            })
            .catch((error) => {
              this.error      = error;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false
            })
      } else {
        let params = this.model
        params.work_time_id = this.workTime.id
        params.institution_id = this.workTime.institution_id

        this.ActionStoreIntegrationWorkTime(params)
            .then((r) => {
              this.message = r.message
              this.$emit('onCompleted')
              this.showForm = false
            })
            .catch((error) => {
              this.error      = error;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false
            })
      }
    }
  },
  watch: {
    show(val) {
      this.showForm = val
    },
    showForm(val) {
      if(!val) {
        this.$emit('update:show', val);
      }
    }
  }
}
</script>