var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-btn", [_c("v-icon", [_vm._v("mdi-")])], 1),
      _c("h3", [_vm._v("Selecione um Ícone:")]),
      _c("v-text-field", {
        attrs: { label: "Solo", solo: "" },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = $$v
          },
          expression: "search"
        }
      }),
      _c(
        "div",
        { staticClass: "icon-grid" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    dark: "",
                    "background-color": "teal darken-3",
                    "show-arrows": ""
                  },
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "teal lighten-3" } }),
                  _vm._l(_vm.categorizedIcons, function(icons, category) {
                    return _c(
                      "v-tab",
                      { key: category, attrs: { href: "#tab-" + category } },
                      [
                        _c("v-icon", [
                          _vm._v("mdi-" + _vm._s(icons[icons.length - 1].name))
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                _vm._l(_vm.categorizedIcons, function(icons, category) {
                  return _c(
                    "v-tab-item",
                    { key: category, attrs: { value: "tab-" + category } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-3" },
                        _vm._l(icons, function(icon, index) {
                          return _c(
                            "v-icon",
                            { key: index, staticClass: "ma-2" },
                            [_vm._v("mdi-" + _vm._s(icon.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }