var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "300", tile: "" } },
    [
      _c(
        "v-list",
        { attrs: { shaped: "" } },
        [
          _c("v-subheader", [_c("span", [_vm._v(_vm._s(_vm.title))])]),
          _c(
            "v-list-item-group",
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c(
                "emc-util-scroll",
                { attrs: { maxHeight: "200px" } },
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      on: {
                        click: function($event) {
                          return _vm.$emit("on-select", item)
                        },
                        dblclick: function($event) {
                          return _vm.$emit("on-open", item)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          item
                            ? _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(_vm._s(item.name))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.name))])]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }