var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-6 mb-4 mx-auto card-title--chart",
      attrs: { "max-width": "400" }
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "v-sheet--offset mx-auto pa-2",
          attrs: {
            color: _vm.color,
            elevation: "6",
            "max-width": "calc(100% - 32px)",
            rounded: "",
            dark: ""
          }
        },
        [
          _vm.title
            ? _c("div", { staticClass: "text-h6" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            : _vm._e(),
          _vm._t("card-title")
        ],
        2
      ),
      _c("v-card-text", { staticClass: "pt-0" }, [_vm._t("card-text")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }