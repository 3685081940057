var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("emc-table-icon-download-base", {
        attrs: {
          type: _vm.type,
          item: _vm.item,
          color: _vm.color,
          icon: _vm.icon,
          "tool-tip": _vm.toolTip,
          url: _vm.url,
          disabled: _vm.disabled
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }