import { service } from '../../../../helpers/http'

export const ActionIndexOfferItemEmphasis = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemEmphasis',
        payload: payload
    })

}
export const ActionDestroyOfferItemEmphasis = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyOfferItemEmphasis',
        payload: payload
    })
}
export const ActionStoreOfferItemEmphasis = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeOfferItemEmphasis',
        payload: payload
    })

}
export const ActionUpdateOfferItemEmphasis = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateOfferItemEmphasis',
        payload: payload
    })
}