<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :showAdd="showAdd"
            :dense="dense"
            :multiple="multiple"
            :autoComplete="autoComplete"
            :disabled="disabled"
            :outlined="outlined"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
            :hideDetails="hideDetails"
            :class="classe"
            :classe="classe"
            :background-color="backgroundColor"
            :color="color"
        />
        <sys-register-brand @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectBrand',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        regionId:{

        },
        showAll: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showInactive: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: [Boolean,String],
            default: false
        },
        classe: {

        },
        backgroundColor: {},
        color: {},
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },

        regionId(val){
            this.getItems();
        },
    },
    methods: {
        ...mapActions('brand', ['ActionFindBrands']),    
        
        async getItems() {

            this.loading = true;

            let payload = {
                column_order: 'name',
                find_columns: {
                    active: this.showInactive ? '0,1' : 1,
                },
                show_all: this.showAll
            };

            if(this.regionId){
                payload.find_columns.region_id = this.regionId;
            }

            await this.ActionFindBrands(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>