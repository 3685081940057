var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SysCoreNavigationBar"),
      _c(
        "v-main",
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-bottom-sheet",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.sheet,
                callback: function($$v) {
                  _vm.sheet = $$v
                },
                expression: "sheet"
              }
            },
            [
              _c(
                "v-sheet",
                [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-6",
                          attrs: { text: "", outlined: "", color: "error" },
                          on: {
                            click: function($event) {
                              _vm.sheet = !_vm.sheet
                            }
                          }
                        },
                        [
                          _vm._v(" Fechar mensagem "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-h5 mt-2 red--text" }, [
                        _vm._v("Ocorreu um erro inesperado!")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-app-bar",
                        {
                          staticClass: "elevation-0 pr-10 mb-2",
                          attrs: { color: "white", dense: "" }
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "info", outlined: "" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.capturaTela($event)
                                }
                              }
                            },
                            [
                              _vm._v(" Capturar imagem "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-image-size-select-actual")
                              ])
                            ],
                            1
                          ),
                          _vm.acesso.instituicao &&
                          _vm.acesso.instituicao.educational_group_id != 16
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  attrs: { color: "info", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showOpenTicket = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Abrir um chamado "),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-text-box-plus")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "py-3" },
                    _vm._l(_vm.errorsHttp, function(item, i) {
                      return _c(
                        "v-alert",
                        {
                          key: i,
                          staticClass: "mx-10",
                          attrs: {
                            border: "top",
                            outlined: "",
                            prominent: "",
                            type: "error",
                            elevation: "2"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center", "no-gutters": "" } },
                            [
                              _c("v-col", { staticClass: "grow" }, [
                                _c("p", { staticClass: "ma-0" }, [
                                  _c("strong", [_vm._v("Requisição:")]),
                                  _vm._v(" " + _vm._s(item.url))
                                ]),
                                _c("p", { staticClass: "ma-0" }, [
                                  _c("strong", [_vm._v("Mensagem:")]),
                                  _vm._v(" " + _vm._s(item.message))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("OpenTicket", {
        attrs: { show: _vm.showOpenTicket },
        on: {
          "update:show": function(value) {
            return (_vm.showOpenTicket = value)
          }
        }
      }),
      _c("SysCoreFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }