var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("EmcChartLoading") : _vm._e(),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "pa-2"
        },
        [
          _vm.renderComponent
            ? _c("canvas", {
                attrs: { id: _vm.name, width: _vm.width, height: _vm.height }
              })
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("emc-info-help", {
                attrs: { title: "Legenda" },
                scopedSlots: _vm._u([
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _c("strong", [_vm._v("Bronze")]),
                        _c("br"),
                        _vm._v(" 10 ou menos alunos "),
                        _c("br"),
                        _c("strong", [_vm._v("Prata")]),
                        _c("br"),
                        _vm._v(" Entre 11 e 35 Alunos "),
                        _c("br"),
                        _c("strong", [_vm._v("Ouro")]),
                        _c("br"),
                        _vm._v(" Mais de 36 alunos "),
                        _c("br")
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }