<template>
    <sys-register-base 
        :loading="loadingSave" 
        @onSave="save" 
        :model.sync="model" 
        :title="event.id ? event.name : 'Novo Evento'" 
        maxWidth="50%"
        icon="mdi-calendar-plus" 
        v-bind:show.sync="dialog"
    >
            <template v-slot:form>
                <v-container>    
                    <v-row>
                        <v-col cols="12" sm="12">
                            <SysSelectEventItem
                                :model.sync="event.event_item_id"
                                :error="errors.event_item_id && errors.length > 0"  
                                :errors="errors.event_item_id"
                                label="Evento*"
                                showAdd
                                autoComplete
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" :sm="selectedScope == 1 ? '12' : '6'">
                            <SysSelectEventScope
                                :model.sync="event.event_scope_id" 
                                :error="errors.event_scope_id && errors.length > 0"  
                                :errors="errors.event_scope_id"
                                label="Escopo do Evento*"
                                @change="changeScope"
                            />
                        </v-col>
                        <v-col v-if="selectedScope == 2" cols="12" sm="6">
                            <SysSelectStates
                                :model.sync="event.states"
                                :error="errors.length > 0"  
                                :errors="errors.states"
                                label="Estados*"
                                multiple
                            />
                        </v-col>
                        <v-col v-if="selectedScope == 3" cols="12" sm="6">
                            <sys-select-city
                                :model.sync="event.cities"
                                :error="errors.length > 0"  
                                :errors="errors.cities"
                                label="Municípios*"
                                multiple
                                autoComplete
                            />
                        </v-col>
                        <v-col v-if="selectedScope == 5"  cols="12" sm="6">
                            <SysSelectBrand
                                :model.sync="event.brands"
                                :error="errors.length > 0"  
                                :errors="errors.brands"
                                label="Marca*"
                                multiple
                            />
                        </v-col>
                        <v-col v-if="selectedScope == 7"  cols="12" sm="6">
                            <SysSelectCampus
                                :model.sync="event.campuses"
                                :error="errors.length > 0"  
                                :errors="errors.campuses"
                                label="Campus*"
                                multiple
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <SysSelectEventType
                                :model.sync="event.event_type_id"
                                :error="errors.event_type_id && errors.length > 0"  
                                :errors="errors.event_type_id"
                                label="Tipo de Evento*"
                                @change="changeType"
                                @new-item="changeType"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <emc-calendar-date-picker2 
                                :model.sync="event.date_start" 
                                label="Início*" 
                                :errorMessages="errors.date_start"
                                :show="dialog"
                                @change="changeStart($event)"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <emc-calendar-date-picker2
                                v-if="renderComponent"
                                :model.sync="event.date_end" 
                                label="Fim*" 
                                :errorMessages="errors.date_end"
                                :show="dialog"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <SysSelectEventArea
                                :model.sync="model.areas"
                                :error="errors.length > 0"  
                                :errors="errors.areas"
                                label="Área"
                                showAdd
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <SysSelectEventPublic
                                :model.sync="model.publics"
                                :error="errors.length > 0"  
                                :errors="errors.publics"
                                label="Público"
                                showAdd
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <SysSelectEventCategory
                                :model.sync="model.categories"
                                :error="errors.length > 0"  
                                :errors="errors.categories"
                                label="Categoria"
                                showAdd
                                multiple
                                dense
                            />
                        </v-col>
                                  
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Os eventos fixos serão replicados no calendário dos próximos anos" v-model="event.fixed" color="purple" label="Evento Fixo" />
                        </v-col>
                         <v-col cols="12" sm="6">
                            <s-switch v-if="renderComponent" inset text-helper="A data de um evento não letivo não será considerado como dia de aula no diário de aula do professor" :disabled="disabledSchoolDay" v-model="event.school_day" label="Evento Letivo" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="event.details"
                                outlined
                                name="input-7-4"
                                label="Detalhes"
                                :auto-grow="false"
                                :value="event.details"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                                
                <v-row>
            </v-row>
        </v-container>    

            <emc-alert-modal 
              title="Exclusão" text="Deseja excluir o evento?" 
              :show.sync="showDelete"
            >
              <template v-slot:actions>
                  <v-btn :disabled="loadingSave || loadingDelete" :loading="loadingDelete" color="error" @click.prevent.stop="deleteItem()">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
              </template>
            </emc-alert-modal>

        </template>

</sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterEventCalendar",
    data: (vm) => ({
        event: {},
        dialog: false,
        showDelete: false,
        selectedScope: null,
        selectedType: null,
        selectedItem: null,
        renderComponent: true,
        schoolDay: true,
        error: false,
        errors: {},
        message: '',
        loadingSave: false,
        loadingDelete: false,
        disabledSchoolDay: false,
        selectedScope: 1,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.disabled = false;
                this.disabledSchoolDay = false;
                this.event = {};
                this.selectedScope = 1;

                Object.assign(this.event, this.model);
                
                if(this.model.id){
                    this.selectedScope = this.event.event_scope_id;
                    this.disabledSchoolDay = this.event.event_type.allow_editing == 0;
                }else{
                    this.event.school_day = true;
                }
            }
        },
        
    },

    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "300"
        },
        maxWidth: {
            type: String,
            default: "60%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Calendário de Eventos"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('calendar', ['ActionCreateCalendar', 'ActionDeleteCalendar', 'ActionUpdateCalendar']),

        save()
        {
            this.error              = false;
            this.message            = '';
            this.loadingSave        = true;
            var edit                = this.event.id != null;
            this.event.color        = null;
            this.event.areas        = this.model.areas ? this.model.areas.map(o => ({ id: o.id })) : [];
            this.event.categories   = this.model.categories ? this.model.categories.map(o => ({ id: o.id })) : [];
            this.event.publics      = this.model.publics ? this.model.publics.map(o => ({ id: o.id })) : [];

            if(edit) {
                this.ActionUpdateCalendar(this.event)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                        this.loadingSave = false;
                })
            } else {
                 Object.assign(this.event, {institution_id: this.acesso.instituicao.id});
                this.ActionCreateCalendar(this.event)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loadingSave = false;
                })

            }
        },

        deleteItem()
        {
            
            this.loadingDelete = true;

            this.ActionDeleteCalendar({id: this.event.id })
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                        this.loadingDelete = false;
                });
        
        },

        changeType(item){
            
            this.event.school_day = item.school_day;
            this.disabledSchoolDay = !item.allow_editing;

            this.forceRerender();

        },

        changeScope(item){
            this.selectedScope = item.id;
        },

        changeStart(value){

            if(value && (!this.event.date_end || this.event.date_end < value)){
                this.event.date_end = value;
                this.forceRerender();
            }

        },

        forceRerender () {
            
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
    }
}
</script>