var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "emc-card-stats",
    {
      attrs: {
        icon: "mdi-flask-outline",
        color: "blue darken-4",
        "sheet-width": "50",
        "sheet-heigth": "50",
        "icon-class": "px-3 py-1",
        subtitle: _vm.subtitle,
        tools: !_vm.hideFooter,
        classSubTitle: _vm.classSubTitle
      }
    },
    [
      _c(
        "template",
        { slot: "tools" },
        [
          !_vm.type
            ? _c("emc-button-icon", {
                attrs: {
                  loading: _vm.loading,
                  icon: "mdi-refresh-circle",
                  text: "Atualizar",
                  color: "success"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.refresh()
                  }
                }
              })
            : _vm._e(),
          _vm.showMoreButton
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { color: "primary", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "showMoreAmbiences",
                                          _vm.itemId
                                        )
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { btn: "" } }, [
                                  _vm._v("mdi-monitor-eye")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    57269959
                  )
                },
                [_c("span", [_vm._v(" Ver Mais... ")])]
              )
            : _vm._e(),
          !_vm.hideTools
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "indigo darken-1",
                                      icon: "",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.download(12)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1811347343
                  )
                },
                [_c("span", [_vm._v("Horários")])]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 360,
                size: 140,
                width: 15,
                value:
                  _vm.ambience && _vm.ambience.percent
                    ? _vm.ambience.percent
                    : 0,
                color: "blue darken-4"
              }
            },
            [
              [
                _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.ambience && _vm.ambience.percent
                          ? _vm.ambience.percent
                          : 0
                      ) + "%"
                    )
                  ]),
                  _c("br"),
                  _vm.ambience && _vm.ambience.total
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.ambience.completed) +
                            " / " +
                            _vm._s(_vm.ambience.total)
                        )
                      ])
                    : _vm._e()
                ])
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }