var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.renderComponent
        ? _c(
            "v-avatar",
            {
              staticClass: "mx-auto",
              style: !_vm.showMenu && _vm.source ? "cursor: pointer;" : "",
              attrs: {
                color: "grey lighten-4",
                width: _vm.width,
                height: _vm.height,
                tile: _vm.tile
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onShowDialog()
                }
              }
            },
            [
              _vm.renderComponent && _vm.source
                ? _c("v-img", {
                    attrs: { width: "100%", height: "100%", src: _vm.source }
                  })
                : _c(
                    "v-icon",
                    { staticClass: "ma-1", attrs: { size: "90px" } },
                    [_vm._v(_vm._s(_vm.iconDefault))]
                  )
            ],
            1
          )
        : _vm._e(),
      _vm.showMenu
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "white--text ml-1",
                                staticStyle: {
                                  position: "relative",
                                  bottom: "-30px",
                                  left: "-30px"
                                },
                                attrs: {
                                  color: "orange",
                                  fab: "",
                                  "x-small": "",
                                  bottom: ""
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-camera")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3232773444
              )
            },
            [
              _c(
                "v-list",
                [
                  _vm.source
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.dialog = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "indigo" } }, [
                                _vm._v("mdi-eye")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Ver Imagem")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showCamera
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.show = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "amber" } }, [
                                _vm._v("mdi-camera-plus-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Câmera")])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onLoad($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-folder-image")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Carregar")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.source
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onRemove()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v("mdi-delete-outline")
                              ])
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Remover")])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "form"
        },
        [
          _vm.forceRerender
            ? _c("v-file-input", {
                attrs: {
                  id: "inputAvatar",
                  name: "avatar",
                  rules: _vm.rules,
                  accept: "image/png, image/jpeg, image/bmp",
                  placeholder: "Pick an avatar",
                  "prepend-icon": "mdi-camera",
                  label: "Avatar"
                },
                on: { change: _vm.onFiledPicked },
                model: {
                  value: _vm.fileAvatar,
                  callback: function($$v) {
                    _vm.fileAvatar = $$v
                  },
                  expression: "fileAvatar"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("emc-avatar-capture", {
        attrs: { show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          },
          onSelect: _vm.onSelect
        }
      }),
      _c("emc-avatar-show", {
        attrs: { model: _vm.model, show: _vm.dialog, source: _vm.source },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: "Confirma a exclusão da imagem?", show: _vm.showDelete },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteImage()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }