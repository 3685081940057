export default [
    {
        path: '/teacher/page',
        name: 'teacher/page',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Página do Professor',
            icon: 'mdi-layers-triple',
            breadcrumbs: [
                { text: 'Página do Professor', disabled: true }
            ]
        }
    }
]