<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-account-group-outline" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados</v-tab>
                <v-tab key="offer">Oferta</v-tab>
                <v-tab key="others">Demais Dados</v-tab>
                <v-tab key="students">Alunos</v-tab>
                <v-tab key="sub_groups" v-if="model && model.id">Sub-turmas</v-tab>
                <v-tab key="availability" v-if="model && model.id">Disponibilidade</v-tab>
                
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item key="main">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-course
                                :model.sync="model.course_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_id"
                                label="Curso*"
                                @onLoad="loadCourse"
                                :disabled="disableCourse"
                                autoComplete
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field autofocus :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-status-group
                                :model.sync="model.status_group_id"
                                :error="errors.length > 0"  
                                :errors="errors.status_group_id"
                                label="Status*"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-campus
                                :model.sync="model.campus_id"
                                :error="errors.length > 0"  
                                :errors="errors.campus_id"
                                label="Campus*"
                                dense
                                auto-complete
                            />
                        </v-col>
                        <v-col>
                            <sys-select-category-group
                                :model.sync="model.category_group_id"
                                :error="errors.length > 0"  
                                :errors="errors.category_group_id"
                                label="Categoria"
                                clearable
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.group" 
                                dense 
                                :error="errors.group && errors.length > 0" 
                                :error-messages="errors.group" 
                                label="Turma" 
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                v-model="model.time_course" 
                                dense 
                                :error="errors.time_course && errors.length > 0" 
                                :error-messages="errors.time_course" 
                                label="Período" 
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-text-field 
                                v-model="model.code" 
                                dense
                                code
                                :error-messages="errors.code" 
                                label="Código" 
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-text-field 
                                v-model="model.code_control" 
                                dense
                                code
                                :error-messages="errors.code_control" 
                                label="Código Controle" 
                            />
                        </v-col>
                        
                    </v-row>
                    
                 </v-tab-item>

                 <!-- Oferta -->
                  <v-tab-item key="offer">
                    <v-container>
                    <v-row v-if="showOffer">
                        <v-col cols="12" sm="12">
                            <v-card>
                                    <v-toolbar-title >
                                        <v-app-bar dense height="45" class="mb-4 elevation-1">
                                            <v-icon left></v-icon>
                                                <v-toolbar-title class="subtitle-1">
                                                    <slot name="title">Oferta
                                                        
                                                        <emc-info-help title="Tipos de Oferta">
                                                            
                                                            <template v-slot:text>
                                                                
                                                                <strong>Oferta avulsa</strong>
                                                                <br/>
                                                                Oferta de disciplinas avulsas, pertencentes ou não a uma matriz.
                                                                <br/><br/>
                                                                
                                                                <strong>Oferta por módulo</strong>
                                                                <br/>
                                                                Oferta de um módulo completo de uma matriz.
                                                                <br/><br/>

                                                                <strong>Oferta por disciplina</strong>
                                                                <br/>
                                                                Oferta de disciplinas de uma matriz, independente de estarem no mesmo módulo.

                                                            </template>

                                                        </emc-info-help>

                                                    </slot>
                                                </v-toolbar-title>
                                            
                                            <v-spacer></v-spacer>

                                            <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click.native="clearOffer()" v-bind="attrs" v-on="on">
                                                            <v-icon color="error lighten-2">mdi-eraser</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Limpar</span>
                                            </v-tooltip>
                                            
                                            <v-tooltip v-if="!workTime.auto_generate_grids && model && model.id" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon @click.native="showGenerateOffer = true" v-bind="attrs" v-on="on">
                                                            <v-icon color="orange lighten-2">mdi-flash</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Gerar Oferta</span>
                                            </v-tooltip>

                                            <v-spacer></v-spacer>
                                            
                                        </v-app-bar>
                                    </v-toolbar-title>
                            <v-card-text>
                        <v-row>
                                    <v-col class='ma-0' cols="12" sm="12">
                                        <v-radio-group
                                            v-model="model.offer_type_id"
                                            @change="changeOfferType"
                                            row
                                            >
                                            <v-radio
                                                label="Oferta Avulsa"
                                                color="red darken-3"
                                                :value='1'
                                            />
                                            <v-radio
                                                label="Por Módulo"
                                                color="blue darken-3"
                                                :value='2'
                                            />
                                            <v-radio
                                                label="Por Disciplina"
                                                color="green darken-3"
                                                :value='3'
                                            />
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row v-if="offerTypeMode == 1">
                                     <v-col cols="12" sm="12">
                                         <v-data-table
                                                    :headers="headers"
                                                    :items="model.disciplines"
                                                    sort-by="name"
                                                    class="elevation-1"
                                                    hide-default-footer
                                                >
                                                    <template v-slot:top>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn 
                                                                    color="indigo darken-1" 
                                                                    @click.prevent.stop="dialogAddDiscipline = true" 
                                                                    icon
                                                                    v-bind="attrs"
                                                                    v-on="on"  
                                                                >
                                                                    <v-icon>mdi-plus</v-icon>
                                                                </v-btn>
                                                                </template>
                                                            <span> Incluir Disciplina </span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-slot:item.actions="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    @click="showDeleteItem(item)"
                                                                    color="red"
                                                                    v-bind="attrs"
                                                                    v-on="on"  
                                                                >
                                                                    mdi-delete
                                                                </v-icon>
                                                            </template>
                                                            <span> Excluir Disciplina </span>
                                                        </v-tooltip>

                                                    </template>
                                                
                                                </v-data-table>
                                    </v-col>
                                </v-row>
                                <v-row v-if="offerTypeMode != 1">
                                    <v-col cols="12" sm="5">
                                        <sys-select-curriculum
                                            :model.sync="model.curriculum_id"
                                            :error="errors.length > 0"  
                                            :errors="errors.curriculum_id"
                                            label="Matriz Curricular"
                                            :courseId="model.course_id"
                                            clearable
                                            :modelObject.sync="selectedCurriculum"
                                            @clearSelected="selectedCurriculum = {}"
                                            autoComplete
                                            dense
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="7">
                                        
                                        <v-select
                                            v-if="offerTypeMode != 3"
                                            v-model="curriculumSequencyItemId"
                                            :items="sequencies"
                                            label="Módulo da Matriz"
                                            item-value="id"
                                            item-text="name"
                                            :error="errors.length > 0"  
                                            :error-messages="errors.curriculum_sequency_item_id"
                                            :disabled="!selectedCurriculum || !selectedCurriculum.id"
                                            dense
                                        >
                                            <template v-slot:item="{ item }">
                                                
                                                <v-tooltip v-if="item.description" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on"> {{ item.name }} </span>
                                                    </template>
                                                    <span> {{ item.description }} </span>
                                                </v-tooltip>
                                                <span v-else>{{ item.name }}</span>
                                            
                                            </template>

                                        </v-select>

                                        <v-select
                                            v-if="offerTypeMode == 3"
                                            v-model="disciplinesSelected"
                                            return-object
                                            :items="disciplines"
                                            label="Disciplinas"
                                            item-value="discipline_id"
                                            item-text="discipline.name_complete"
                                            :error="errors.length > 0"  
                                            :error-messages="errors.disciplines"
                                            dense
                                            :disabled="!selectedCurriculum || !selectedCurriculum.id"
                                            multiple
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                
                                                <span v-if="index === 0">{{ item.discipline.name }}</span>
                                                
                                                <span
                                                    v-if="index === 1"
                                                    class="grey--text caption ml-1"
                                                >
                                                        (+{{ model.disciplines.length - 1 }} outros)
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <emc-util-scroll v-if="showDisciplines && showDisciplines.length > 0" height="130px">
                                        <ul>
                                            <li :key="i" v-for="(item, i) in showDisciplines">
                                                {{ item.discipline.name_complete }}
                                                <v-tooltip v-if="item.category && !item.category.default" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-chip style="cursor: help" v-bind="attrs" v-on="on" color="purple" label x-small outlined>
                                                            {{ item.category.initials }}
                                                        </v-chip>
                                                    </template>
                                                    <span>{{ item.category.name }}</span>
                                                </v-tooltip>
                                            </li>
                                        </ul>
                                    </emc-util-scroll>
                                </v-col>   
                            </v-row>
                            
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    </v-container>
                     
                 </v-tab-item>

                 <!-- Demais Dados -->
                 <v-tab-item key="others">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-base-group
                                    :model.sync="model.baseGroups"
                                    :error="errors.length > 0"  
                                    :errors="errors.groups"
                                    label="Ingressantes"
                                    :course-id="model.course_id"
                                    :campus-id="model.campus_id"
                                    :shift-id="model.shift_id"
                                    multiple
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field 
                                    v-model="model.code_curriculum" 
                                    dense 
                                    :error="errors.code_curriculum && errors.length > 0" 
                                    :error-messages="errors.code_curriculum" 
                                    label="Matriz Aplicada" 
                                    required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-resource
                                    :model.sync="model.resources"
                                    :error="errors.length > 0"  
                                    :errors="errors.resources"
                                    multiple
                                    label="Recursos Necessários"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-hour-group
                                    :model.sync="model.hour_group_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.hour_group_id"
                                    label="Grupo de Horário"
                                    clearable
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-shift
                                    :model.sync="model.time_shift_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.time_shift_id"
                                    label="Turno do Horário"
                                    clearable
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <s-text-field 
                                    v-model="model.weeks"
                                    text-helper="Número de semanas letivas. Utilizado para converter a carga horária total para semanal. Se não informado, utiliza o valor padrão cadastrado no Período Letivo"
                                    dense 
                                    :error-messages="errors.weeks" 
                                    label="Número de Semanas" 
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-branch
                                    :model.sync="model.branch_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.branch_id"
                                    label="Filial"
                                    :campus-id="model.campus_id"
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-group-identifier
                                    :model.sync="model.group_identifier_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.group_identifier_id"
                                    label="Identificador"
                                    clearable
                                    show-add
                                    auto-complete
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-category-ambience
                                    :model.sync="model.category_ambience_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.category_ambience_id"
                                    label="Categoria de Ambiente"
                                    clearable
                                    auto-complete
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-ambience
                                    :model.sync="model.ambience_id"
                                    :error="errors.ambience_id && errors.length > 0"  
                                    :errors="errors.ambience_id"
                                    label="Ambiente"
                                    clearable
                                    autoComplete
                                    :category-id="model.category_ambience_id"
                                    :campus-id="model.campus_id"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-work-time-date
                                        :model.sync="model.work_time_date_id"
                                        :error="errors.length > 0"  
                                        :errors="errors.work_time_date_id"
                                        label="Subperíodo"
                                        dense
                                    />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-campus
                                    :model.sync="model.campus_id_alternative"
                                    :error="errors.length > 0"  
                                    :errors="errors.campus_id_alternative"
                                    label="Campus Alternativo"
                                    dense
                                    auto-complete
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <s-switch text-helper="Bloquear atribuição de professor" inset v-model="model.lock_attribution" label="Bloquear Atribuição"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <s-switch inset v-model="model.freshmen_group" label="Calouro"/>
                            </v-col>
                        </v-row>
                    </v-container>
                 </v-tab-item>

                <!-- Alunos -->
                <v-tab-item key="others">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-text-field 
                                    v-model="model.students" 
                                    dense
                                    :error-messages="errors.students" 
                                    label="Alunos"
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-text-field 
                                    v-model="model.students_forecast" 
                                    dense
                                    :error-messages="errors.students_forecast" 
                                    label="Alunos Previsão"
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-text-field 
                                    v-model="model.students_max" 
                                    dense
                                    :error-messages="errors.students_max" 
                                    label="Vagas"
                                    clearable
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-text-field 
                                    v-model="model.students_freshmen" 
                                    dense
                                    :error-messages="errors.students_freshmen" 
                                    label="Calouros"
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-text-field 
                                    v-model="model.students_free" 
                                    dense
                                    :error-messages="errors.students_free"
                                    label="Bolsistas"
                                    clearable
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- Subturmas -->
                 <v-tab-item key="sub_groups">
                    <sys-group-sub-group :model="model" />
                 </v-tab-item>
                
                <!-- Disponibilidade -->
                 <v-tab-item key="availability">
                    <sys-availability-register :model="model" />
                 </v-tab-item>

                 
            </v-tabs-items>

            <v-alert v-if="errors.generate" type="error">
                <ul>
                    <li :key="i" v-for="(error, i) in errors.generate">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>
            
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

            <sys-find-discipline 
                showAdd 
                showSelect
                :singleSelect="false" 
                @onSelect="selectDiscipline($event)" 
                :show.sync="dialogAddDiscipline"
            />

            <emc-alert-modal :disabled="loadingOffer"  text="Confirma a geração da oferta?" v-bind:show.sync="showGenerateOffer">
                <template v-slot:actions>
                    <v-btn :disabled="loadingOffer" :loading="loadingOffer" color="orange lighten-2" @click.prevent.stop="generateOffer()" >Gerar Oferta<v-icon right dark>mdi-flash</v-icon></v-btn>
                </template>
            </emc-alert-modal>

            <emc-alert-modal text="Deseja excluir a disciplina?" v-bind:show.sync="showDeleteDiscipline">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="deleteItem()" >Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                </template>
            </emc-alert-modal>

        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'
export default {
    name: "SysRegisterGroup",
    data: (vm) => ({
        dialog: false,
        workTime: null,
        tab: null,
        curriculumSequencyItemId: null,
        disciplinesSelected: [],
        offerTypeMode: 1,
        showOffer: true,
        showGenerateOffer: false,
        showDeleteDiscipline: false,
        selectedItem: null,
        error: false,
        errors: {},
        message: '',
        loading: false,
        loadingOffer: false,
        disableCourse: false,
        selectedCurriculum: {},
        dialogAddDiscipline: false,
        sequencies: [],
        disciplines: [],
        itemDelete: {},
        headers: [
            { text: 'Disciplina', value: 'name_complete'},
            { text: 'Carga Horária', value: 'work_load'},
            { text: 'Ações', value: 'actions', sortable: false}
        ]
    }),
    created(){
        this.workTime = state.acesso.periodo;
    },

    computed: {
        ...mapState('auth', ['acesso']),

        showDisciplines: function(){

            if(this.offerTypeMode == 2){
                
                if(this.sequencies && this.model && this.curriculumSequencyItemId){
                
                    var sequency = this.sequencies.find(s => (s.id && s.id == this.model.curriculum_sequency_item_id));
                
                    if(sequency && sequency.sequencyDisciplines && sequency.sequencyDisciplines.length > 0){
                        return sequency.sequencyDisciplines;
                    }
                }
            }else if(this.offerTypeMode == 3){
                return this.disciplinesSelected;
            }
            
            return [];

        }
    },

    watch: {
        
        curriculumSequencyItemId(val){
            this.model.curriculum_sequency_item_id = val;
        },

        disciplinesSelected(val){
            
            this.model.disciplines = [];

            val.forEach(dic => {
                this.model.disciplines.push(dic.discipline);
            });
            
        },
        
        selectedCurriculum(val){

            this.sequencies = [];
            this.disciplines = [];

            if(val && val.sequencies){
                
                val.sequencies.forEach(seq => {
                    
                    if(seq.curriculum_sequency_id == 99){
                        seq.name = 'Atividades';
                    }else{
                        
                        if(seq.initials){
                            seq.name = seq.curriculum_sequency_id + ' - ' + seq.initials;
                        }else{
                            seq.name = seq.curriculum_sequency_id + 'º Período';
                        }
                    }

                    seq.sequencyDisciplines.forEach(dis => {
                        this.disciplines.push(dis);
                    });

                });

                this.sequencies = val.sequencies;


            }else{
                this.model.curriculum_sequency_item_id = null;
                this.model.curriculum_id = null;
                this.model.disciplines = [];
            }

        },
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
           
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.tab = null;
                this.model.updateAvailability = false;
                this.disciplinesSelected = [];

                if(!this.model || !this.model.id){
                    
                    this.model.status_group_id = 1;
                    this.model.offer_type_id = 1;
                    this.offerTypeMode = 1;
                    this.model.disciplines = [];
                    this.curriculumSequencyItemId = null;
                    this.model.baseGroups = [];
                    this.model.availabilities = [];
                    
                    if(this.model.course){
                        this.model.course_id = this.model.course.id;
                        this.disableCourse = true;
                    }
                }else{
                    this.offerTypeMode = this.model.offer_type_id;
                    this.curriculumSequencyItemId = this.model.curriculum_sequency_item_id;

                    this.model.disciplines.forEach(dic => {
                        this.disciplinesSelected.push({
                            discipline_id:  dic.id,
                            discipline:     { id: dic.id, name: dic.name, name_complete: dic.name_complete, work_load: dic.work_load },
                            category:       null,
                        });
                    });
                }
               
            }
                
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Turmas"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('group', ['ActionCreateGroup', 'ActionUpdateGroup', 'ActionGenerateGrids']),
                
        loadCourse(items){

            if(!this.model.id && this.model.course){
                this.model.course_id = this.model.course.id;
                this.disableCourse = true;
            }
        },

        changeOfferType(val){
            this.offerTypeMode = val;
        },

        clearOffer(){
            this.disciplines = [];
            this.model.curriculum_sequency_item_id = null;
            this.selectedCurriculum = {};
            this.model.disciplines = [];
            this.model.curriculum_id = null;
            this.model.baseGroups = [];
            this.model.offer_type_id = 1;
            this.offerTypeMode = 1;
        },

        generateOffer(){
                
            this.error = null;
            this.message = '';
            this.loadingOffer = true;
           
            var payload = {
                id:                             this.model.id,
                curriculum_id:                  this.model.curriculum_id,
                curriculum_sequency_item_id:    this.model.curriculum_sequency_item_id,
                offer_type_id:                  this.model.offer_type_id,
                disciplines:                    this.model.disciplines
            };
            
            this.ActionGenerateGrids(payload)
                .then((res) => {
                   if(res.success){
                        this.message = 'Oferta gerada com sucesso!';
                   }
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loadingOffer = false;
                    this.showGenerateOffer = false;
                });
        },
        
        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;
            let payload = { groups: [] };

            Object.assign(payload, this.model);
            Object.assign(payload, { work_time_id: this.acesso.periodo.id });
            Object.assign(payload.groups, this.model.baseGroups.map(o => ({ base_group_id: o['id'] })));
            

            if (edit) {
                this.ActionUpdateGroup(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;

                    let count = 0;
                    Object.keys(error.errors).forEach(e => {
                        if(e.includes('groups.')){
                            count++;
                        }
                    });

                    if(count > 0){
                        this.errors.groups = ['Turma base ja utilizada em outra turma'];
                    }
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateGroup(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;

                    let count = 0;
                    Object.keys(error.errors).forEach(e => {
                        if(e.includes('groups.')){
                            count++;
                        }
                    });

                    if(count > 0){
                        this.errors.groups = ['Turma base ja utilizada em outra turma'];
                    }
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        showDeleteItem(item){

            this.selectedItem = item;
            this.showDeleteDiscipline = true;

        },

        deleteItem(){
            
            var index = this.model.disciplines.indexOf(this.selectedItem);
            this.model.disciplines.splice(index, 1);
            this.showDeleteDiscipline = false;
            
        },

        selectDiscipline(items){
            
            if(items && items.length > 0){
                items.forEach(i => {
                    var find = this.model.disciplines.find(d => d.id == i.id);
                    if(!find){
                        this.model.disciplines.push(i);
                    }
                });
            }
           
        },
        
    }
}
</script>