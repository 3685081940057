import { service } from '../../../../helpers/http'

export const ActionIndexDisciplinePortfolios = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexDisciplinePortfolios',
        payload: payload
    })

}
export const ActionDestroyDisciplinePortfolio = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyDisciplinePortfolio',
        payload: payload
    })
}
export const ActionStoreDisciplinePortfolio = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeDisciplinePortfolio',
        payload: payload
    })

}
export const ActionUpdateDisciplinePortfolio = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateDisciplinePortfolio',
        payload: payload
    })
}