var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-clipboard-list-outline",
      show: _vm.showForm,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.showForm = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-card",
              { attrs: { elevation: "0" } },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.code,
                                label: "Código*"
                              },
                              model: {
                                value: _vm.model.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "code", $$v)
                                },
                                expression: "model.code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "2" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: _vm.errors.length > 0,
                                "error-messages": _vm.errors.note,
                                label: "Sigla"
                              },
                              model: {
                                value: _vm.model.note,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "note", $$v)
                                },
                                expression: "model.note"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("sys-select-company", {
                              attrs: {
                                model: _vm.model.company_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.company_id,
                                label: "Empresa*",
                                clearable: "",
                                dense: "",
                                autoComplete: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "company_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("sys-select-branch", {
                              attrs: {
                                model: _vm.model.branch_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.branch_id,
                                label: "Filial*",
                                clearable: "",
                                dense: "",
                                disabled: _vm.model.company_id === null,
                                "company-id": _vm.model.company_id
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "branch_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("SysSelectCourseLevel", {
                              attrs: {
                                model: _vm.model.course_level_id,
                                error: _vm.errors.length > 0,
                                clearable: "",
                                errors: _vm.errors.course_level_id,
                                label: "Nível de Curso*",
                                "company-id": _vm.model.company_id
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "course_level_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }