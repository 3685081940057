var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-pop-over-config-base", {
        attrs: { model: _vm.model, configs: _vm.configs, errors: _vm.errors },
        scopedSlots: _vm._u([
          {
            key: "toolbar",
            fn: function() {
              return [
                _c("emc-button-icon", {
                  attrs: {
                    icon: "mdi-content-save",
                    text: _vm.error ? _vm.message : "Salvar",
                    loading: _vm.loadingSaving,
                    color: _vm.error ? "error" : "success"
                  },
                  on: {
                    click: function($event) {
                      return _vm.update()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }