var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { staticStyle: { "min-height": "600px" } },
        [
          _vm.showToolBar
            ? _c(
                "v-toolbar",
                { attrs: { dense: "", height: "45", color: "grey lighten-3" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c(
                    "v-toolbar-title",
                    { staticClass: "subtitle-1" },
                    [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("title")],
                    2
                  ),
                  _c("v-spacer"),
                  _vm._t("tools")
                ],
                2
              )
            : _vm._e(),
          _c("v-card-text", [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }