var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: _vm.maxWidth
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "10" } },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.items,
                            loading: _vm.isLoading,
                            "search-input": _vm.search,
                            "append-icon": "mdi-magnify",
                            "hide-no-data": "",
                            "hide-selected": "",
                            "item-text": "name_complete",
                            label: "Pesquisar disciplina...",
                            "return-object": "",
                            autofocus: "",
                            "persistent-hint": ""
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.search = $event
                            },
                            "update:search-input": function($event) {
                              _vm.search = $event
                            },
                            "click:append": function($event) {
                              return _vm.searchDisciplines(_vm.search)
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchDisciplines(_vm.search)
                            }
                          },
                          model: {
                            value: _vm.selectedDiscipline,
                            callback: function($$v) {
                              _vm.selectedDiscipline = $$v
                            },
                            expression: "selectedDiscipline"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.code,
                            label: "Código",
                            required: ""
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "", height: "50%" } },
                          [
                            _c("v-data-table", {
                              staticClass: "elevation-0",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.equivalenceDisciplines,
                                "single-select": false,
                                "item-key": "discipline_id",
                                "show-select": "",
                                "item-selected": _vm.selected,
                                "hide-default-footer": "",
                                height: "300",
                                "items-per-page": -1,
                                "fixed-header": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.curriculum",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c("sys-select-curriculum", {
                                        attrs: {
                                          model: item.curriculum_id,
                                          error: _vm.errors.length > 0,
                                          errors:
                                            _vm.errors[
                                              "disciplines." +
                                                index +
                                                ".curriculum_id"
                                            ],
                                          label: "",
                                          dense: "",
                                          "discipline-id": item.discipline_id,
                                          "auto-complete": ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            return _vm.$set(
                                              item,
                                              "curriculum_id",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.selected,
                                callback: function($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.errors.length > 0
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function(error) {
                      return _c("li", { key: error }, [
                        _vm._v(" " + _vm._s(error) + " ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }