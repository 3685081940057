var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "40%",
      disabledSave: _vm.model.term_signature_status_id == 2
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            data: _vm.model.teacher,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-term", {
                          attrs: {
                            model: _vm.model.term_id,
                            error: _vm.errors.term_id && _vm.errors.length > 0,
                            errors: _vm.errors.term_id,
                            label: "Termo*",
                            autoComplete: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "term_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-term-signature-status", {
                          attrs: {
                            model: _vm.model.term_signature_status_id,
                            error:
                              _vm.errors.term_signature_status_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.term_signature_status_id,
                            label: "Status*",
                            autoComplete: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "term_signature_status_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note,
                            "error-messages": _vm.errors.note,
                            label: "Observação",
                            counter: 255,
                            rows: "2",
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note_user,
                            "error-messages": _vm.errors.note_user,
                            label: "Observação do Usuário",
                            counter: 255,
                            rows: "2",
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            disabled: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.note_user,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note_user", $$v)
                            },
                            expression: "model.note_user"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }