var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { disabled: _vm.disabled, icon: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.executeMethod()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.color,
                            small: _vm.$vuetify.breakpoint.name != "xs"
                          }
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(" " + _vm._s(_vm.toolTip) + " ")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }