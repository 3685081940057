var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-pause-circle-outline",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "7" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            data: _vm.model.teacher,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: "",
                            disabled: _vm.teacher != null,
                            autofocus: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "5" } },
                      [
                        _c("sys-select-license", {
                          attrs: {
                            model: _vm.model.license_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.license_id,
                            label: "Tipo de Licença*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "license_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_start,
                            label: "Início*",
                            errorMessages: _vm.errors.date_start,
                            show: _vm.dialog
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date_start", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_end,
                            label: "Fim",
                            errorMessages: _vm.errors.date_end,
                            show: _vm.dialog,
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "date_end", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("v-switch", {
                              attrs: { inset: "", label: "Licença Remunerada" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.clickRemunerated($event)
                                }
                              },
                              model: {
                                value: _vm.model.remunerated,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "remunerated", $$v)
                                },
                                expression: "model.remunerated"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c("s-text-field", {
                              attrs: {
                                code: "",
                                "error-messages": _vm.errors.code,
                                label: "Código"
                              },
                              model: {
                                value: _vm.model.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "code", $$v)
                                },
                                expression: "model.code"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.model.remunerated
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("sys-select-value-type", {
                              attrs: {
                                model: _vm.model.value_type_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.value_type_id,
                                label: "Tipo de Valor*",
                                filter: [1, 2],
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "value_type_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("sys-select-payment-periodicity-type", {
                              attrs: {
                                model: _vm.model.payment_periodicity_type_id,
                                error: _vm.errors.length > 0,
                                errors: _vm.errors.payment_periodicity_type_id,
                                label: "Periodicidade*",
                                filter: [1, 2],
                                dense: ""
                              },
                              on: {
                                "update:model": function($event) {
                                  return _vm.$set(
                                    _vm.model,
                                    "payment_periodicity_type_id",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm.model.remunerated
                          ? _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.value,
                                    "error-messages": _vm.errors.value,
                                    label: "Valor"
                                  },
                                  model: {
                                    value: _vm.model.value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "value", $$v)
                                    },
                                    expression: "model.value"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.note,
                            "error-messages": _vm.errors.note,
                            label: "Observação",
                            rows: "3",
                            "row-height": "20",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: ""
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }