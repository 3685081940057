var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "", color: "grey", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Configurações")
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v("Junção de Turmas")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: _vm.fav ? "red--text" : "",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.fav = !_vm.fav
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.menu = false
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { elevation: "0" } },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "text-wrap" },
                                        [_vm._v("Junção entre campus")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-switch", {
                                        attrs: { inset: "", dense: "" },
                                        model: {
                                          value: _vm.model.distinctCampus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "distinctCampus",
                                              $$v
                                            )
                                          },
                                          expression: "model.distinctCampus"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "text-wrap" },
                                        [_vm._v("Somente disciplina digital")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-switch", {
                                        attrs: { inset: "", dense: "" },
                                        model: {
                                          value: _vm.model.onlyDigitals,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "onlyDigitals",
                                              $$v
                                            )
                                          },
                                          expression: "model.onlyDigitals"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }