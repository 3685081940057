var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-credit-card-plus",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: "",
                            autofocus: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-bank", {
                          attrs: {
                            model: _vm.model.bank_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            errors: _vm.errors.bank_id,
                            label: "Banco*",
                            autoComplete: "",
                            autofocus: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "bank_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-bank-account-type", {
                          attrs: {
                            model: _vm.model.bank_account_type_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            errors: _vm.errors.bank_account_type_id,
                            label: "Tipo de Conta*",
                            autofocus: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "bank_account_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.agency,
                            "error-messages": _vm.errors.agency,
                            label: "Agência*"
                          },
                          model: {
                            value: _vm.model.agency,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "agency", $$v)
                            },
                            expression: "model.agency"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.agency_digit,
                            "error-messages": _vm.errors.agency_digit,
                            label: "Dígito"
                          },
                          model: {
                            value: _vm.model.agency_digit,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "agency_digit", $$v)
                            },
                            expression: "model.agency_digit"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.account,
                            "error-messages": _vm.errors.account,
                            label: "Conta*"
                          },
                          model: {
                            value: _vm.model.account,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "account", $$v)
                            },
                            expression: "model.account"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.account_digit,
                            "error-messages": _vm.errors.account_digit,
                            label: "Dígito"
                          },
                          model: {
                            value: _vm.model.account_digit,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "account_digit", $$v)
                            },
                            expression: "model.account_digit"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-bank-pix-type", {
                          attrs: {
                            model: _vm.model.bank_pix_type_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            errors: _vm.errors.bank_pix_type_id,
                            label: "Tipo de Chave Pix",
                            autofocus: "",
                            clearable: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "bank_pix_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-text-field", {
                          ref: "keyPix",
                          attrs: {
                            disabled: !_vm.model.bank_pix_type_id,
                            dense: "",
                            error: !!_vm.errors.key_pix,
                            "error-messages": _vm.errors.key_pix,
                            label: "Chave Pix*"
                          },
                          model: {
                            value: _vm.model.key_pix,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "key_pix", $$v)
                            },
                            expression: "model.key_pix"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Mesma Titularidade" },
                          model: {
                            value: _vm.model.same_ownership,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "same_ownership", $$v)
                            },
                            expression: "model.same_ownership"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.model.same_ownership
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { row: "" },
                                model: {
                                  value: _vm.model.legal_person,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "legal_person", $$v)
                                  },
                                  expression: "model.legal_person"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: { label: "Pessoa Física", value: 1 }
                                }),
                                _c("v-radio", {
                                  attrs: { label: "Pessoa Jurídica", value: 0 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.model.same_ownership
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                error: !!_vm.errors.ownership_name,
                                "error-messages": _vm.errors.ownership_name,
                                label: _vm.model.legal_person
                                  ? "Nome"
                                  : "Razão Social"
                              },
                              model: {
                                value: _vm.model.ownership_name,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "ownership_name", $$v)
                                },
                                expression: "model.ownership_name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                error: !!_vm.errors.ownership_document_number,
                                "error-messages":
                                  _vm.errors.ownership_document_number,
                                label: _vm.model.legal_person ? "CPF" : "CNPJ"
                              },
                              model: {
                                value: _vm.model.ownership_document_number,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "ownership_document_number",
                                    $$v
                                  )
                                },
                                expression: "model.ownership_document_number"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }