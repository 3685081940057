<template>
    <emc-chart-apex-base :series="series" type="pie" :options="chartOptions" :loading.sync="loading">

    <template v-slot:actions>
        <v-spacer></v-spacer>
        <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
    </template>
    </emc-chart-apex-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartTeacherTitlings',
    data () {
        return {
            loading: true,
            name: 'EmcChartTeacherTitlings',
            updated_at: null,
            title: '',
            attempts: 1,
            series: [],
            chartOptions: {
                chart: {},
                labels: [],
                title: {},
                colors: [],
                legend: {
                    position: 'right'
                }
            },
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartTeacherTitlings', 'ActionTeacherTitlingsRefresh']),
        async getData() {
            await this.ActionChartTeacherTitlings()
                .then((r) => {
                    this.series = r.data.series
                    this.chartOptions.title.text = r.data.title
                    this.chartOptions.labels = r.data.labels
                    this.chartOptions.colors = r.data.colors
                    this.updated_at = r.data.updated_at
                })
                .finally(() => {
                    this.loading = false
                })
        },
        refresh() {
            this.loading = true

            this.ActionTeacherTitlingsRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let updated_at = this.updated_at;

            setTimeout(async () => {

                    await this.getData()

                    if(updated_at != this.updated_at || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 5000)
        }
    },
    created() {
        this.getData()
    }
}
</script>