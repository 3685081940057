var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.color, top: "", timeout: _vm.timeout },
      scopedSlots: _vm._u(
        [
          {
            key: "action",
            fn: function(ref) {
              var attrs = ref.attrs
              return [
                _vm._t("action"),
                _c(
                  "v-btn",
                  _vm._b(
                    {
                      staticClass: "float-right ma-1",
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function($event) {
                          _vm.hidden = false
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.hidden,
        callback: function($$v) {
          _vm.hidden = $$v
        },
        expression: "hidden"
      }
    },
    [
      _vm.message && _vm.message != ""
        ? _c("span", [_vm._v(_vm._s(_vm.message))])
        : _c(
            "ul",
            _vm._l(_vm.errors, function(error, i) {
              return _c("li", { key: i }, [
                _vm._v(
                  " " +
                    _vm._s(
                      error
                        .toString()
                        .replace('[ "', "")
                        .replace('" ]', "")
                    ) +
                    " "
                )
              ])
            }),
            0
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }