<template>
  <div>
    <v-autocomplete
        v-model="selected"
        :items="items"
        :loading="loading"
        :label="label"
        item-value="id"
        item-text="name"  
        :clearable="clearable"
        :hint="hint"
        persistent-hint
    >

    <template v-slot:selection="data">
        
      
         <v-list-item-avatar>
                <v-icon :color="direction[data.item.direction_integration].color" >{{ direction[data.item.direction_integration].icon }}</v-icon>
              </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
                {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
        </v-list-item-content>

    </template>

    <template v-slot:item="data">
        
          <v-list-item-avatar>
                <v-icon :color="direction[data.item.direction_integration].color" >{{ direction[data.item.direction_integration].icon }}</v-icon>
              </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
                {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.description }}
            </v-list-item-subtitle>
        </v-list-item-content>
       

    </template>

    </v-autocomplete>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  name: 'SysSelectIntegrationItem',
  data: () => ({
    items: [],
    loading: false,
    selected: null,
    direction: {
        U: { icon: 'mdi-arrow-up-bold-box',   color: 'green' },
        D: { icon: 'mdi-arrow-down-bold-box', color: 'primary' }
      },
  }),
  created() {
    this.getItems();
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
    },
    label: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    autoComplete: {
        type: Boolean,
        default: false
    },
    model: {}
  },
  watch: {

    selected(val){
      this.$emit('update:model', val);
    },

    model(val){
      this.selected = val;
    },
  },

  computed: {
    ...mapState('auth', ['acesso'])
  },

  methods: {
    ...mapActions('integration', ['ActionIndexIntegrationItems']),
    async getItems() {
      this.loading = true;

      let integrationId = this.acesso.instituicao.integrations.map(i => i.id).join(',');

      let payload = {
          column_order: 'name',
          direction: 'asc',
          per_page: 1000,
          find_columns: {
            active: 1,
            integration_id: integrationId
          },
      };
      
      await this.ActionIndexIntegrationItems(payload)
          .then((res) => {
            this.items = res.data;
            this.selected = this.model;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>