var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "dialog-top-transition",
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "40%"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "max-height": "800px" } },
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "primary lighten-2 mb-4 elevation-1",
                  attrs: { dense: "", height: "35" }
                },
                [
                  _vm.model
                    ? _c("v-toolbar-title", [
                        _c("span", { staticClass: "white--text subtitle-1" }, [
                          _vm._v(_vm._s(_vm.model.name))
                        ])
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      nativeOn: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { small: "", color: "white lighten-2" } },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.source
                ? _c("v-img", {
                    staticClass: "ma-1",
                    attrs: { src: _vm.source }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }