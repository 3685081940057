var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "80%",
      actions: false
    },
    on: {
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _vm.loading
              ? _c("v-progress-linear", {
                  staticClass: "mb-0",
                  attrs: { indeterminate: "", color: "blue" }
                })
              : _vm._e(),
            _c("emc-chart-general-campus-work-load-avg", {
              attrs: { "brand-id": _vm.brandId, show: _vm.showMore },
              on: {
                "update:show": function($event) {
                  _vm.showMore = $event
                }
              }
            }),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  _vm._l(_vm.items, function(item, i) {
                    return _c(
                      "v-col",
                      { key: i, attrs: { cols: "12", md: "3" } },
                      [
                        _c("emc-chart-teacher-work-load-avg", {
                          attrs: {
                            item: item,
                            "no-load": "",
                            "show-actions": "",
                            "show-more-button": ""
                          },
                          on: {
                            showMoreWorkLoadAvg: function($event) {
                              return _vm.showChartCampuses(item)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }