var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-0 pa-0" },
    [
      _vm.toolTip
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  href: _vm.href,
                                  target: _vm.target,
                                  disabled: _vm.disabled,
                                  loading: _vm.loading,
                                  icon: "",
                                  "x-small": _vm.xSmall,
                                  small: _vm.small
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.$emit("click")
                                  }
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "ma-0 pa-0",
                                attrs: {
                                  color: _vm.color,
                                  "x-small": _vm.xSmall,
                                  small: _vm.small
                                }
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                100146149
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.toolTip))])]
          )
        : _c(
            "v-btn",
            _vm._g(
              _vm._b(
                {
                  staticClass: "ma-0 pa-0",
                  attrs: {
                    href: _vm.href,
                    target: _vm.target,
                    disabled: _vm.disabled,
                    loading: _vm.loading,
                    icon: "",
                    "x-small": _vm.xSmall,
                    small: _vm.small
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.$emit("click")
                    }
                  }
                },
                "v-btn",
                _vm.attrs,
                false
              ),
              _vm.on
            ),
            [
              _c(
                "v-icon",
                {
                  staticClass: "ma-0 pa-0",
                  attrs: {
                    color: _vm.color,
                    "x-small": _vm.xSmall,
                    small: _vm.small
                  }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }