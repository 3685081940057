var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              { staticStyle: { height: "250px" } },
              [
                _vm.model
                  ? _c(
                      "v-row",
                      {
                        attrs: { "align-content": "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-subtitle-1 text-center",
                            attrs: { cols: "12" }
                          },
                          [
                            _c("strong", [_vm._v("Descrição: ")]),
                            _vm._v(_vm._s(_vm.model.description) + " "),
                            _c("br"),
                            _vm.model.shift
                              ? _c("span", [
                                  _c("strong", [_vm._v("Turno: ")]),
                                  _vm._v(_vm._s(_vm.model.shift.name))
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm.model.campuses
                              ? _c("span", [
                                  _c("strong", [_vm._v("Campus: ")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.model.campuses
                                        .map(function(o) {
                                          return o["abbreviation"]
                                        })
                                        .join(",")
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.model
                  ? _c(
                      "v-row",
                      {
                        attrs: { "align-content": "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-subtitle-1 text-center",
                            attrs: { cols: "12" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "amber",
                                  loading: _vm.loading,
                                  disabled: _vm.loading
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.generate()
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "loader",
                                      fn: function() {
                                        return [
                                          _c("span", [
                                            _vm._v("Gerando Alocações...")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  483634729
                                )
                              },
                              [
                                _vm._v(" Gerar Alocações "),
                                _c(
                                  "v-icon",
                                  { attrs: { right: "", dark: "" } },
                                  [_vm._v("mdi-flash")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.loading
                  ? _c(
                      "v-row",
                      {
                        attrs: { "align-content": "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "6" } },
                          [
                            _c("v-progress-linear", {
                              attrs: {
                                indeterminate: "",
                                rounded: "",
                                height: "20",
                                striped: ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }