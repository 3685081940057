var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-table-icon", {
    attrs: {
      color: _vm.color,
      icon: _vm.icon,
      disabled: _vm.disabled,
      loading: _vm.loading,
      "tool-tip": _vm.toolTip
    },
    on: {
      click: function($event) {
        return _vm.$emit("click")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }