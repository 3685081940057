var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: _vm.color,
                        icon: "",
                        disabled: _vm.disabled,
                        small: _vm.btnSmall,
                        loading: _vm.loading,
                        href: _vm.href,
                        target: _vm.target
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click", $event)
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: _vm.iconSmall } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(" " + _vm._s(_vm.text) + " ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }