var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _vm.title
        ? _c(
            "v-toolbar",
            {
              class: _vm.classToolbar,
              attrs: { color: _vm.colorToolbar, height: _vm.heightToolbar }
            },
            [
              _c(
                "v-toolbar-title",
                { class: _vm.classTitle },
                [
                  _vm.icon
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            small: _vm.small,
                            medium: _vm.medium,
                            large: _vm.large
                          }
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.title))
                ],
                1
              ),
              _c("v-spacer"),
              _vm._t("tools"),
              _vm.dialog
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled: _vm.updating
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            _vm.dialog = false
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "red lighten-2" } },
                                      [_vm._v("mdi-close")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2641186536
                      )
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c("v-card-text", [_vm._t("card-text")], 2),
      _c("v-card-actions", [_vm._t("card-actions")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }