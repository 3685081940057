<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :label="label"
            :error="error"
            :errors="errors"
            :showAdd="showAdd"
            :clearable="clearable"
            :dense="dense"
            :multiple="multiple"
        />
    </div>
</template>

<script>

export default {
    name: 'SysSelecDayOkWeek',
    data: () => ({
        days: [
            { id: 1, name: 'Domingo',       show: false },
            { id: 2, name: 'Segunda-Feira', show: true },
            { id: 3, name: 'Terça-Feira',   show: true },
            { id: 4, name: 'Quarta-Feira',  show: true },
            { id: 5, name: 'Quinta-Feira',  show: true },
            { id: 6, name: 'Sexta-Feira',   show: true },
            { id: 7, name: 'Sábado',        show: true },
        ],
        selected: null,
        
    }),
    created() {
        this.selected = this.model;
    },
    computed: {
        items () {
             return this.days.filter(i => i.show);
        },
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        model: {},
        multiple: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
}
</script>