var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading ? _c("EmcBaseProgressBar") : _vm._e(),
      _c("v-treeview", {
        ref: "tree",
        attrs: {
          active: _vm.active,
          items: _vm.campuses,
          "load-children": _vm.fetchItems,
          open: _vm.open,
          activatable: "",
          "item-children": "children",
          color: "warning",
          "open-on-click": true,
          transition: "",
          "item-key": "key",
          "return-object": ""
        },
        on: {
          "update:active": [
            function($event) {
              _vm.active = $event
            },
            function($event) {
              return _vm.selectItem($event)
            }
          ],
          "update:open": function($event) {
            _vm.open = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              return [
                item.level == 3
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  item.grid.periodicity_type_id != 1
                                    ? _c("small", [
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(
                                            item.grid.periodicity_type.name
                                          ) +
                                            " - (" +
                                            _vm._s(
                                              item.grid.classeDates.length
                                            ) +
                                            ") "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.name) + " "),
                          item.grid.teacher_id
                            ? _c("small", [
                                _c("br"),
                                _vm._v(_vm._s(item.grid.teacher.name))
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  : _c("span", [_vm._v(" " + _vm._s(item.name))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }