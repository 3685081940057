var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.items,
          loading: _vm.loading,
          label: _vm.label,
          "item-value": "id",
          "item-text": "name",
          clearable: _vm.clearable,
          hint: _vm.hint,
          "persistent-hint": ""
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(data) {
              return [
                _c(
                  "v-list-item-avatar",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color:
                            _vm.direction[data.item.direction_integration].color
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.direction[data.item.direction_integration].icon
                          )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(data.item.name) + " ")
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(" " + _vm._s(data.item.description) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item",
            fn: function(data) {
              return [
                _c(
                  "v-list-item-avatar",
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color:
                            _vm.direction[data.item.direction_integration].color
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.direction[data.item.direction_integration].icon
                          )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(data.item.name) + " ")
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(" " + _vm._s(data.item.description) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }