var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-dialog-default", {
    attrs: {
      show: _vm.dialog,
      title: "Lista de cores",
      icon: "mdi-format-color-fill"
    },
    on: {
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "my-2" },
              _vm._l(_vm.colors, function(color, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass: "d-flex child-flex",
                    attrs: { cols: "4" }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-card",
                              { attrs: { tile: "", color: color.base } },
                              [
                                _c("v-card-title", [
                                  _vm._v(" " + _vm._s(index) + " ")
                                ])
                              ],
                              1
                            ),
                            _vm._l(color, function(item, i) {
                              return _c(
                                "v-card",
                                {
                                  key: i,
                                  attrs: { tile: "", color: item },
                                  on: {
                                    click: function($event) {
                                      return _vm.setSelected(index, i)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "text-caption pa-4" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [_vm._v(" " + _vm._s(i) + " ")]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c("v-spacer"),
                                              _vm._v(_vm._s(item) + " ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }