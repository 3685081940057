var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-tag",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-course", {
                          attrs: {
                            model: _vm.model.course_id,
                            error:
                              _vm.errors.course_id && _vm.errors.length > 0,
                            errors: _vm.errors.course_id,
                            label: "Curso*",
                            autoComplete: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "course_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shift_id,
                            error: _vm.errors.shift_id && _vm.errors.length > 0,
                            errors: _vm.errors.shift_id,
                            label: "Turno*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shift_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-curriculum", {
                          attrs: {
                            model: _vm.model.curriculum_id,
                            error:
                              _vm.errors.curriculum_id && _vm.errors.length > 0,
                            errors: _vm.errors.curriculum_id,
                            label: "Matriz",
                            courseId: _vm.model.course_id,
                            disabled: _vm.model.course_id == null,
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "curriculum_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campus_id,
                            error:
                              _vm.errors.campus_id && _vm.errors.length > 0,
                            errors: _vm.errors.campus_id,
                            label: "Campus*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.students,
                            "error-messages": _vm.errors.students,
                            label: "Alunos"
                          },
                          model: {
                            value: _vm.model.students,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "students", $$v)
                            },
                            expression: "model.students"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.year,
                            "error-messages": _vm.errors.year,
                            label: "Ano*"
                          },
                          model: {
                            value: _vm.model.year,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "year", $$v)
                            },
                            expression: "model.year"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.period,
                            "error-messages": _vm.errors.period,
                            label: "Período*"
                          },
                          model: {
                            value: _vm.model.period,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "period", $$v)
                            },
                            expression: "model.period"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Anotações",
                            value: _vm.model.note,
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.note,
                            rows: "2",
                            "row-height": "20",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ]
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Turma Ativa" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }