var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _vm.renderComponent
        ? _c("v-simple-table", {
            attrs: { dense: "", "fixed-header": "", height: "450px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Professor")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("CH")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Status")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Período")
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Usuário")
                        ]),
                        _c("th", { staticClass: "text-left" }, [_vm._v("Data")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item, i) {
                        return _c("tr", { key: i }, [
                          _c("td", [
                            _vm._v(
                              _vm._s(item.teacher ? item.teacher.name : null)
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(item.work_load))]),
                          _c("td", [_vm._v(_vm._s(item.status.name))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("date")(item.date_start)) +
                                " - " +
                                _vm._s(_vm._f("date")(item.date_end))
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(item.user.name))]),
                          _c("td", [
                            _vm._v(_vm._s(_vm._f("dateTime")(item.date_action)))
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }