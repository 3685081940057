var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ? "80%" : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  class: _vm.color + " mb-4 elevation-1",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _vm.icon
                    ? _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(_vm._s(_vm.icon))
                      ])
                    : _vm._e(),
                  _c(
                    "v-toolbar-title",
                    { staticClass: "subtitle-1" },
                    [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                    2
                  ),
                  _c("v-spacer"),
                  _vm._t("tools"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "ma-0 pa-0",
                            attrs: {
                              solo: "",
                              label: "Pesquisar...",
                              "append-icon": "mdi-magnify",
                              autofocus: "",
                              loading: _vm.searching
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchItems(_vm.search)
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _vm.showAdd
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "green darken-1",
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  $event.stopPropagation()
                                                                  return _vm.$emit(
                                                                    "onAdd"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-plus")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1204181133
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(" Nova Disciplina ")
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color:
                                                            "indigo darken-1",
                                                          icon: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            return _vm.searchItems(
                                                              _vm.search
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-magnify")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [_c("span", [_vm._v(" Pesquisar ")])]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1 ma-0 pa-0",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.items,
                              "single-select": _vm.singleSelect,
                              "item-key": "id",
                              "show-select": _vm.showSelect,
                              "fixed-header": "",
                              height: "250",
                              "hide-default-footer": "",
                              "items-per-page": -1
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.singleSelect
                                  ? {
                                      key: "item.name",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          item
                                            ? _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      _vm.selected = [item]
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-xs-left"
                                                    },
                                                    [
                                                      _vm.selected.length > 0 &&
                                                      _vm.selected[0].id ===
                                                        item.id
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-checkbox-marked-circle "
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "blue",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-circle-outline "
                                                              )
                                                            ]
                                                          ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.name) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  : null,
                                _vm._l(Object.keys(_vm.$scopedSlots), function(
                                  slot
                                ) {
                                  return {
                                    key: slot,
                                    fn: function(scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    }
                                  }
                                })
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("emc-alert-snackbar", {
                attrs: {
                  show: _vm.bShow,
                  message: _vm.message,
                  color: "error"
                },
                on: {
                  "update:show": function($event) {
                    _vm.bShow = $event
                  },
                  "update:message": function($event) {
                    _vm.message = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.selected.length == 0
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.select()
                    }
                  }
                },
                [
                  _vm._v("Selecionar"),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-cursor-pointer")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }