var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      disabled: _vm.disabled,
      model: _vm.model,
      title: "Selecionar a data aula",
      icon: "mdi-clock-outline",
      show: _vm.dialog,
      maxWidth: "30%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { attrs: { align: "center" } },
              [
                _vm.model
                  ? _c("v-date-picker", {
                      attrs: {
                        "year-icon": "mdi-calendar-blank",
                        "allowed-dates": _vm.allowedDates,
                        events: _vm.arrayEvents,
                        "event-color": "error",
                        "full-width": "",
                        min: _vm.acesso.periodo.classes_start,
                        max: _vm.acesso.periodo.classes_end
                      },
                      model: {
                        value: _vm.model.start_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "start_date", $$v)
                        },
                        expression: "model.start_date"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  loading: _vm.loadingDestroy,
                  disabled: _vm.disabled || _vm.model.start_date == null
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.save()
                  }
                }
              },
              [
                _vm._v("Salvar"),
                _c("v-icon", { attrs: { dark: "" } }, [
                  _vm._v("mdi-content-save")
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }