var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title: "Abrir chamado",
      icon: "mdi-text-box-plus",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.save($event)
                  }
                }
              },
              [
                _c(
                  "v-row",
                  { staticClass: "mt-1" },
                  [
                    _vm.message
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  outlined: "",
                                  color: "warning",
                                  prominent: "",
                                  border: "left"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.message) + " ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("EmcFormTextField", {
                      attrs: {
                        label: "Assunto*",
                        md: "12",
                        outlined: "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.subject,
                        autofocus: ""
                      },
                      model: {
                        value: _vm.item.subject,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "subject", $$v)
                        },
                        expression: "item.subject"
                      }
                    }),
                    _c("FormTicketSelectCategory", {
                      attrs: {
                        label: "Categoria*",
                        md: "12",
                        outlined: "",
                        "hide-no-data": "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.ticket_category_id
                      },
                      model: {
                        value: _vm.item.ticket_category_id,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "ticket_category_id", $$v)
                        },
                        expression: "item.ticket_category_id"
                      }
                    }),
                    _c("FormTicketSelectType", {
                      attrs: {
                        label: "Tipo*",
                        md: "12",
                        outlined: "",
                        "hide-no-data": "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.ticket_type_id
                      },
                      model: {
                        value: _vm.item.ticket_type_id,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "ticket_type_id", $$v)
                        },
                        expression: "item.ticket_type_id"
                      }
                    }),
                    _c("EmcFormTextArea", {
                      attrs: {
                        label: "Descrição*",
                        md: "12",
                        outlined: "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.description,
                        placeholder:
                          "Informe todos os detalhes do seu chamado..."
                      },
                      model: {
                        value: _vm.item.description,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "description", $$v)
                        },
                        expression: "item.description"
                      }
                    }),
                    _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                      _c(
                        "form",
                        { ref: "form" },
                        [
                          _c("EmcFormFile", {
                            attrs: {
                              name: "files",
                              multiple: "",
                              outlined: "",
                              error: _vm.hasValidationErrors.length
                                ? true
                                : false
                            },
                            model: {
                              value: _vm.files,
                              callback: function($$v) {
                                _vm.files = $$v
                              },
                              expression: "files"
                            }
                          }),
                          _vm.hasValidationErrors.length
                            ? _c(
                                "v-alert",
                                { attrs: { color: "error", outlined: "" } },
                                [
                                  _c("div", { staticClass: "text-subtitle2" }, [
                                    _vm._v(" Verifique os arquivos: ")
                                  ]),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _c(
                                      "ul",
                                      _vm._l(_vm.hasValidationErrors, function(
                                        item,
                                        index
                                      ) {
                                        return _c("li", { key: index }, [
                                          _vm._v(" " + _vm._s(item[1][0]) + " ")
                                        ])
                                      }),
                                      0
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }