var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-simple-table", {
        attrs: { "fixed-header": "", height: "300" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c(
                        "th",
                        { staticClass: "text-center" },
                        [
                          _c("emc-info-help", {
                            attrs: {
                              title: "Disponibilidade",
                              text:
                                "Informe os dias e horários indisponíveis para aula"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._l(_vm.headersFiltered, function(header, i) {
                        return _c(
                          "th",
                          { key: i, staticClass: "text-center" },
                          [_vm._v(" " + _vm._s(header.name) + " ")]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.hours, function(hour, i) {
                    return _c(
                      "tr",
                      { key: i },
                      [
                        _c("td", { staticClass: "text-caption text-center" }, [
                          _vm._v(_vm._s(hour.start) + " "),
                          _c("br"),
                          _vm._v(" " + _vm._s(hour.end))
                        ]),
                        _vm._l(_vm.headersFiltered, function(day, i) {
                          return _c(
                            "td",
                            { key: i, staticClass: "text-center" },
                            [
                              _vm.renderComponent
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.colors[hour[day.initials]]
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.select(hour, day)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.icons[hour[day.initials]]
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm.showFoot
                  ? _c("tfoot", [
                      _c("tr", [
                        _c("td", {
                          attrs: { colspan: _vm.headersFiltered.length + 1 }
                        })
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }