<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-registered-trademark" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-educational-group
                                    :model.sync="model.educational_group_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.educational_group_id"
                                    label="Grupo Educacional*"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        
                        <v-col cols="12" sm="2">
                            <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-text-field dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field dense :error="errors.length > 0" :error-messages="errors.initials" v-model="model.initials" label="Sigla*" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-region
                                :model.sync="model.region_id"
                                :error="errors.length > 0"  
                                :errors="errors.region_id"
                                showAdd
                                clearable
                                label="Região"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch color="purple" inset v-model="model.active" label="Ativo" />
                        </v-col>
                        <v-col v-if="model && model.id" cols="12" sm="6">
                            <emc-avatar-calendar-setting width="80" height="80" :brand-id="model.id" />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterBrand",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        categories: []
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
           
            if(show_new){
                this.errors = {};

                if(!this.model.id){
                    this.model.active = 1;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Marca"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('brand', ['ActionCreateBrand', 'ActionUpdateBrand']),
        
        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateBrand(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateBrand(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>