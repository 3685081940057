var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [_vm.loading ? _c("EmcBaseProgressBar") : _vm._e()]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-left", attrs: { width: "30px" } },
                  [_vm._v("Horas")]
                ),
                _c("th", { staticClass: "text-center" }, [_vm._v("Manhã")]),
                _c("th", { staticClass: "text-center" }, [_vm._v("Tarde")]),
                _c("th", { staticClass: "text-center" }, [_vm._v("Noite")]),
                _c("th", { staticClass: "text-center" }, [_vm._v("Digital")]),
                _c("th", { staticClass: "text-center" }, [_vm._v("Integral")]),
                _c("th", { staticClass: "text-center" }, [_vm._v("Total")])
              ])
            ]),
            _vm.availability
              ? _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("Disponibilizadas")]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(parseFloat(_vm.availability.morning_availability))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                parseFloat(
                                  _vm.availability.morning_availability
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(parseFloat(_vm.availability.evening_availability))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                parseFloat(
                                  _vm.availability.evening_availability
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(parseFloat(_vm.availability.night_availability))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                parseFloat(_vm.availability.night_availability)
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(parseFloat(_vm.availability.digital_availability))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                parseFloat(
                                  _vm.availability.digital_availability
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(parseFloat(_vm.availability.max_workload))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.availability.max_workload === 44
                                  ? parseFloat(_vm.availability.max_workload) +
                                      " (máximo)"
                                  : parseFloat(_vm.availability.max_workload)
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Atribuídas")]),
                    _c(
                      "td",
                      [
                        _c(
                          "v-progress-linear",
                          {
                            attrs: {
                              value:
                                (_vm.availability.morning_workload /
                                  _vm.availability.morning_availability) *
                                100,
                              height: "25",
                              rounded: "",
                              color:
                                _vm.availability.morning_availability == "0"
                                  ? "grey"
                                  : _vm.availability.morning_workload == "0"
                                  ? "orange"
                                  : "green"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.availability.morning_workload))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-progress-linear",
                          {
                            attrs: {
                              value:
                                (_vm.availability.evening_workload /
                                  _vm.availability.evening_availability) *
                                100,
                              height: "25",
                              rounded: "",
                              color:
                                _vm.availability.evening_availability == "0"
                                  ? "grey"
                                  : _vm.availability.evening_workload == "0"
                                  ? "orange"
                                  : "green"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.availability.evening_workload))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-progress-linear",
                          {
                            attrs: {
                              value:
                                (_vm.availability.night_workload /
                                  _vm.availability.night_availability) *
                                100,
                              height: "25",
                              rounded: "",
                              color:
                                _vm.availability.night_availability == "0"
                                  ? "grey"
                                  : _vm.availability.night_workload == "0"
                                  ? "orange"
                                  : "green"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.availability.night_workload))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-progress-linear",
                          {
                            attrs: {
                              value:
                                (_vm.availability.digital_workload /
                                  _vm.availability.digital_availability) *
                                100,
                              height: "25",
                              rounded: "",
                              color:
                                _vm.availability.digital_availability == "0"
                                  ? "grey"
                                  : _vm.availability.digital_workload == "0"
                                  ? "orange"
                                  : "green"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.availability.digital_workload))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.full_workload)
                        ? _c("strong", [
                            _vm._v(_vm._s(_vm.availability.full_workload))
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.getAvailableTotal(_vm.availability))
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.getAvailableTotal(_vm.availability))
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Saldo")]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.available_balance_morning)
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.availability.available_balance_morning)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.available_balance_evening)
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.availability.available_balance_evening)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.available_balance_night)
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.availability.available_balance_night)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.available_balance_digital)
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.availability.available_balance_digital)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("td"),
                    _c("td", { staticClass: "text-center" }, [
                      !isNaN(_vm.availability.max_workload)
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.availability.max_workload -
                                  (_vm.availability.morning_workload +
                                    _vm.availability.evening_workload +
                                    _vm.availability.night_workload +
                                    _vm.availability.digital_workload +
                                    _vm.availability.full_workload)
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }