var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
        _c("input", {
          ref: "fileInput",
          staticStyle: { display: "none" },
          attrs: { type: "file", multiple: "", accept: ".xlsx" },
          on: { change: _vm.onFiledPicked }
        })
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-container",
            { staticClass: "pa-4" },
            [
              _c(
                "v-row",
                { staticClass: "fill-height", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xs: "12", md: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      height: "225px",
                                      outlined: "",
                                      elevation: hover ? 16 : 2
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.onPickFile($event)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "fill-height ma-0",
                                        attrs: {
                                          align: "center",
                                          justify: "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mdi-48px",
                                            attrs: { btn: "" }
                                          },
                                          [_vm._v("mdi-plus")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.images, function(image, i) {
                    return [
                      _c(
                        "v-col",
                        { key: i, attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          class: { "on-hover": hover },
                                          attrs: { elevation: hover ? 12 : 2 }
                                        },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              attrs: {
                                                src: image.url,
                                                height: "225px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "title white--text"
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "fill-height ma-0",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          class: {
                                                            error: hover
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            fab: "",
                                                            elevation: !hover
                                                              ? 0
                                                              : 2,
                                                            color: "transparent"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteImage(
                                                                image
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: !hover
                                                                  ? "transparent"
                                                                  : "white"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }