var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-app-bar",
            { attrs: { color: "grey lighten-4", dense: "" } },
            [
              _c(
                "v-toolbar-title",
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              ),
              _c("v-spacer"),
              _vm._t("tools"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { icon: "", disabled: _vm.saving },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "red lighten-2" } },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Fechar")])]
              )
            ],
            2
          ),
          _c("v-card-text", [_c("v-container", [_vm._t("form")], 2)], 1),
          _c("v-card-actions", [_c("v-spacer"), _vm._t("actions")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }