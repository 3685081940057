<template>
    <div>
        <v-select
            v-model="selected"
            :items="itemsFiltered"
            :item-value="itemValue"
            :item-text="itemText"
            :label="label"
            required
            :disabled="disabled"
            :dense="dense"
            :error="error"
            :error-messages="errors"
            :clearable="clearable"
        />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectMonth',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        months: [ 
            { id: 1,     name: 'Janeiro' },
            { id: 2,     name: 'Fevereiro' },
            { id: 3,     name: 'Março' },
            { id: 4,     name: 'Abril' },
            { id: 5,     name: 'Maio' },
            { id: 6,     name: 'Junho' },
            { id: 7,     name: 'Julho' },
            { id: 8,     name: 'Agosto' },
            { id: 9,     name: 'Setembro' },
            { id: 10,    name: 'Outubro' },
            { id: 11,    name: 'Novembro' },
            { id: 12,    name: 'Dezembro' },
        ],
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
       this.selected = this.model;
    },
    watch: {
        
        selected(val){
            
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            default: "Mês"
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        itemText: {
            type: String,
            default: 'name'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        model: {

        },
        dense: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        }
        
    },
    computed: {
      ...mapState('auth', ['acesso']),

      itemsFiltered(){
            
            let range = this.getRangeMonth();
            return this.months.filter(m => (m.id >= range.start && m.id <= range.end ));
            
        },

        disabledFields(){
            return this.model.id && this.model.payment_export_status_id != 1;
        }
    },
    methods: {
        
    },
    
}
</script>