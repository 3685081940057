var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hideSearch
        ? _c("v-text-field", {
            ref: "textSearch",
            attrs: {
              "x-small": "",
              placeholder: "Pesquisar",
              rounded: "",
              "single-line": "",
              autofocus: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _vm.search
                        ? _c("emc-button-icon", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              btnSmall: "",
                              icon: "mdi-close",
                              color: "error",
                              text: "Limpar"
                            },
                            on: {
                              click: function($event) {
                                _vm.search = null
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.search
                        ? _c("emc-button-icon", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              btnSmall: "",
                              loading: _vm.loadingSearch,
                              disabled:
                                _vm.search != null && _vm.search.length < 5,
                              icon: "search",
                              text: "Buscar turma"
                            },
                            on: {
                              click: function($event) {
                                return _vm.searchGroup()
                              }
                            }
                          })
                        : _c("emc-button-icon", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              btnSmall: "",
                              icon: "mdi-refresh",
                              color: "success",
                              loading: _vm.loadingRefresh,
                              text: "Atualizar"
                            },
                            on: {
                              click: function($event) {
                                return _vm.refresh()
                              }
                            }
                          }),
                      _vm.showFilterPendencies
                        ? _c("emc-button-icon", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              loading: _vm.loadingPendings,
                              btnSmall: "",
                              icon: "mdi-progress-clock",
                              text: _vm.onlyPendings
                                ? "Limpar Filtro de Pendentes"
                                : "Filtrar Pendentes",
                              color: _vm.onlyPendings ? "amber" : "grey"
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeOnlyPendinds()
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3502349918
            ),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        : _vm._e(),
      _c("v-treeview", {
        ref: "tree",
        attrs: {
          active: _vm.active,
          items: _vm.items,
          "load-children": _vm.fetchItems,
          open: _vm.open,
          activatable: "",
          "item-children": "children",
          color: "warning",
          "open-on-click": false,
          transition: "",
          "item-key": "key",
          "return-object": "",
          selectable: _vm.selectable,
          dense: "",
          search: _vm.searchInner
        },
        on: {
          "update:active": [
            function($event) {
              _vm.active = $event
            },
            function($event) {
              return _vm.selectItem($event[0])
            }
          ],
          "update:open": function($event) {
            _vm.open = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  _vm._b(
                                    { staticStyle: { cursor: "default" } },
                                    "span",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  item.level == 2 && _vm.showPendencies
                                    ? _c(
                                        "span",
                                        [
                                          item.checked
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "green",
                                                    btn: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-check-circle-outline"
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "amber",
                                                    btn: ""
                                                  }
                                                },
                                                [_vm._v("mdi-progress-clock")]
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(item.name) + " ")
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("span", [
                      _vm._v(" " + _vm._s(item.name) + " "),
                      item.level == 2
                        ? _c("span", [
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(item.course.name))]),
                            item.time_course
                              ? _c("small", [
                                  _c("br"),
                                  _vm._v("Período: " + _vm._s(item.time_course))
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]
                ),
                _vm.showCategory
                  ? _c("emc-util-icon-category", { attrs: { item: item } })
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.selection,
          callback: function($$v) {
            _vm.selection = $$v
          },
          expression: "selection"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }