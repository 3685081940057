var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-avatar-base", {
        attrs: {
          src: _vm.model.url_avatar,
          width: _vm.width,
          height: _vm.height,
          model: _vm.model,
          showMenu: _vm.showMenu
        },
        on: { onComplete: _vm.upload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }