var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EmcCardStats", {
    attrs: {
      color: "blue darken-4",
      icon: "mdi-school-outline",
      "icon-large": "",
      value: this.grids,
      title: "A contratar",
      "sub-text": "Número de disciplinas a contratar"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }