var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-clipboard-list-outline",
      show: _vm.showForm,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.showForm = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-card",
              [
                _c(
                  "v-card-text",
                  [
                    _c("SysSelectIntegration", {
                      attrs: {
                        model: _vm.model.integration_id,
                        error: _vm.errors.length > 0,
                        errors: _vm.errors.integration_id,
                        label: "Integração"
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(_vm.model, "integration_id", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _vm.model.integration_id
                      ? _c("SysSelectEnvironment", {
                          attrs: {
                            model: _vm.model.environment,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.environment,
                            label: "Ambiente"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "environment", $event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.model.integration_id
                  ? _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "10" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    prefix: "https://",
                                    placeholder: "seudominio.com.br",
                                    error: _vm.errors.length > 0,
                                    "error-messages":
                                      _vm.errors.integration_url,
                                    label: "Domínio"
                                  },
                                  model: {
                                    value: _vm.model.integration_url,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "integration_url",
                                        $$v
                                      )
                                    },
                                    expression: "model.integration_url"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    placeholder: "8080",
                                    error: _vm.errors.length > 0,
                                    "error-messages":
                                      _vm.errors.integration_port,
                                    label: "Porta"
                                  },
                                  model: {
                                    value: _vm.model.integration_port,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "integration_port",
                                        $$v
                                      )
                                    },
                                    expression: "model.integration_port"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    "prepend-inner-icon": "mdi-account",
                                    error: _vm.errors.length > 0,
                                    "error-messages":
                                      _vm.errors.integration_username,
                                    label: "Usuário"
                                  },
                                  model: {
                                    value: _vm.model.integration_username,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "integration_username",
                                        $$v
                                      )
                                    },
                                    expression: "model.integration_username"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    type: "password",
                                    dense: "",
                                    "prepend-inner-icon": "mdi-lock-open-check",
                                    error: _vm.errors.length > 0,
                                    "error-messages":
                                      _vm.errors.integration_password,
                                    label: "Senha"
                                  },
                                  model: {
                                    value: _vm.model.integration_password,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "integration_password",
                                        $$v
                                      )
                                    },
                                    expression: "model.integration_password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.model.integration_id == 1
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "8" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        placeholder: "wsDataServer/MEX?wsdl",
                                        error: _vm.errors.length > 0,
                                        "error-messages": _vm.errors.wsdl,
                                        label: "Wsdl"
                                      },
                                      model: {
                                        value: _vm.model.wsdl,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "wsdl", $$v)
                                        },
                                        expression: "model.wsdl"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.model.integration_id == 1
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "4" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error: _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.default_students,
                                        label: "Vagas"
                                      },
                                      model: {
                                        value: _vm.model.default_students,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "default_students",
                                            $$v
                                          )
                                        },
                                        expression: "model.default_students"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }