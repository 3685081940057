var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, sm: _vm.sm, md: _vm.md } },
    [
      _c("v-textarea", {
        attrs: {
          outlined: _vm.outlined,
          "auto-grow": _vm.autoGrow,
          label: _vm.label,
          "hide-details": _vm.hideDetails,
          error: _vm.error,
          "error-messages": _vm.errorMessages,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          "background-color": _vm.disabled ? "grey lighten-2" : ""
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }