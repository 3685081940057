var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-button-icon", {
        attrs: { color: _vm.color, icon: _vm.icon, text: _vm.text },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.$emit("click")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }