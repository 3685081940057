var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { color: _vm.color, icon: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.clickAction()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(" " + _vm._s(_vm.toolTip) + " ")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width":
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? "90%"
                : "30%",
            persistent: ""
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "grey lighten-5 mb-4 elevation-1'",
                      attrs: { dense: "", height: "45" }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-filter-outline")
                      ]),
                      _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                        _vm._v(" Filtros ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialog = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm.filterCalendar
                    ? _c(
                        "v-container",
                        [
                          _vm.isAnima()
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-brand", {
                                        attrs: {
                                          model: _vm.brands,
                                          label: "Marca",
                                          multiple: "",
                                          dense: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.brands = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-campus", {
                                    attrs: {
                                      model: _vm.campuses,
                                      label: "Campus",
                                      multiple: "",
                                      dense: "",
                                      "brand-id": _vm.brands
                                        .map(function(i) {
                                          return i.id
                                        })
                                        .join(",")
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.campuses = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "4" } },
                                [
                                  _c("sys-select-states", {
                                    attrs: {
                                      model: _vm.statesIds,
                                      modelObject: _vm.states,
                                      label: "Estado",
                                      multiple: "",
                                      selectAll: "",
                                      "item-text": "initials"
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.statesIds = $event
                                      },
                                      "update:modelObject": function($event) {
                                        _vm.states = $event
                                      },
                                      "update:model-object": function($event) {
                                        _vm.states = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "8" } },
                                [
                                  _c("sys-select-city", {
                                    attrs: {
                                      model: _vm.cities,
                                      label: "Municípios",
                                      multiple: "",
                                      selectAll: "",
                                      states: _vm.statesIds
                                        ? _vm.statesIds
                                            .map(function(i) {
                                              return i.id
                                            })
                                            .join(",")
                                        : null
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.cities = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-event-area", {
                                    attrs: {
                                      model: _vm.areas,
                                      label: "Área",
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.areas = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("sys-select-event-category", {
                                    attrs: {
                                      model: _vm.categories,
                                      label: "Categoria",
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.categories = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-event-public", {
                                    attrs: {
                                      model: _vm.publics,
                                      label: "Público",
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.publics = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "ligth green" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.downloadReport()
                        }
                      }
                    },
                    [_vm._v("Download")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }