import { service } from '../../../../helpers/http'

export const ActionIndexOfferItemThemeCores = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemThemeCores',
        payload: payload
    })

}
export const ActionDestroyOfferItemThemeCore = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyOfferItemThemeCore',
        payload: payload
    })
}
export const ActionStoreOfferItemThemeCore = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeOfferItemThemeCore',
        payload: payload
    })

}
export const ActionUpdateOfferItemThemeCore = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateOfferItemThemeCore',
        payload: payload
    })
}