<template>
    <v-dialog v-model="dialog" scrollable :max-height="maxHeight" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense :height="height" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="tools"></slot>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small class="mr-1" @click.native="dialog = false" :disabled="disabled || updating" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>

                <v-card-text class="pt-0">
                    <slot name="form" />
                </v-card-text>
                
                <v-card-actions v-if="actions">
                    <small v-if="showRequiredFields">*Campos obrigatórios</small>
                    
                    <small v-if="footerText">
                        {{ footerText }}
                    </small>
                    <small v-else>
                        <slot name="footer"/>
                    </small>
                    <v-spacer></v-spacer>
                    <slot name="actions" />
                    <v-btn v-if="showButton" color="primary" @click.prevent.stop="save()" :loading="updating" :disabled="disabled || disabledSave" >{{ textButtonSave ? textButtonSave : ((edit) ? 'Atualizar' : 'Cadastrar')}} <v-icon right dark>{{ iconButtonSave }}</v-icon></v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "SysRegisterBase",
    data: () => ({
        dialog: false,
        edit: false,
        updating: false,
    }),
    created(){
        var vm = this
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                vm.dialog = false
            }
        });
    },
    computed: {
        
    },
    watch: {
        
        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.updating = false;
            }
        },

        loading(val) {
            this.updating = val;
        },

        model(model){
          if(this.model && this.model.id){
            this.edit = true;
          }else{
            this.edit = false;
          }
         
      },
    },
    props: {
        model: {
            required: true
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        height: {
            type: String,
            default: "45"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        maxHeight:{
            type: String,
            default: "auto"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            requered: false
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledSave: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: true
        },
        showButton: {
            type: Boolean,
            default: true
        },
        showRequiredFields: {
            type: Boolean,
            default: true
        },
        footerText: {
            type: String,
            default: null
        },
        textButtonSave: {

        },
        iconButtonSave: {
            type: String,
            default: 'mdi-content-save'
        }
    },
    methods:{
        
        save(){
            this.$emit('onSave', this.edit);
        },

    }
}
</script>