var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      show: _vm.dialog,
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      maxWidth: _vm.maxWidth
    },
    on: {
      "update:show": function($event) {
        _vm.dialog = $event
      },
      "update:model": function($event) {
        _vm.model = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c("sys-teacher-availability-week", {
              attrs: {
                release: _vm.release,
                teacherId: _vm.teacherId,
                workTimeId: _vm.workTimeId
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }