export default [
    {
        path: '/teacher/class-diary',
        name: 'teacher/class-diary',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Diário de Classe do Professor',
            icon: 'mdi-layers-triple',
            breadcrumbs: [
                { text: 'Diário de Classe', disabled: true }
            ]
        }
    }
]