export default {
    
    findDynamicTimes:              { method: 'get',    url: 'dynamic_times' },
    createDynamicTime:             { method: 'post',   url: 'dynamic_times'},
    updateDynamicTime:             { method: 'put',    url: 'dynamic_times/{id}'},
    deleteDynamicTime:             { method: 'delete', url: 'dynamic_times/{id}'},
    generateDynamicTime:           { method: 'put',    url: 'dynamic_times/{id}/generate'},
    syncDynamicTime:               { method: 'put',    url: 'dynamic_times/{id}/sync'},
    teacherSharingDynamicTime:     { method: 'get',    url: 'dynamic_times/teacher_sharing' },
    indexGroupingTypesDynamicTime: { method: 'get',   url: 'dynamic_times/grouping_types' },
    viewResultsDynamicTime:        { method: 'get',    url: 'dynamic_times/view-results/{id}'},
}