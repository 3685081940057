import { render, staticRenderFns } from "./RegisterTeachers.vue?vue&type=template&id=64195392&"
import script from "./RegisterTeachers.vue?vue&type=script&lang=js&"
export * from "./RegisterTeachers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64195392')) {
      api.createRecord('64195392', component.options)
    } else {
      api.reload('64195392', component.options)
    }
    module.hot.accept("./RegisterTeachers.vue?vue&type=template&id=64195392&", function () {
      api.rerender('64195392', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sys/activity_item/RegisterTeachers.vue"
export default component.exports