<template>
    <div>
        <emc-button-icon :color="color" :icon="icon" :text="text" @click.prevent.stop="$emit('click')" />
    </div>
</template>

<script>

import { myMixin } from '../../../mixins'

export default {
    name: "EmcButtonNewItem",
    mixins: [myMixin],
    data () {
        return {
           
        }
    },
    props: {
        color: {
            type: String,
            default: "indigo darken-1"
        },
        text: {
            type: String,
            default: "Novo Registro"
        },
        icon: {
            type: String,
            default: "mdi-plus"
        },
        attrs: {},
        on: {}
    },
    
    methods: {

       

    }
}
</script>
