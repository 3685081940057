var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("EmcChartLoading") : _vm._e(),
      _c("emc-chart-filter", {
        attrs: { show: _vm.showFilter },
        on: {
          "update:show": function($event) {
            _vm.showFilter = $event
          },
          filter: function($event) {
            return _vm.filter($event)
          }
        }
      }),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "pa-2"
        },
        [
          _vm.renderComponent
            ? _c("canvas", {
                attrs: { id: _vm.name, width: _vm.width, height: _vm.height }
              })
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showFilter = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { btn: "" } }, [
                                _vm._v("mdi-filter-outline")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Selecionar Categorias ")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }