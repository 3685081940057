var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "emc-card-stats",
    {
      attrs: {
        icon: "mdi-school-outline",
        color: "blue lighten-1",
        "sheet-width": "50",
        "sheet-heigth": "50",
        "icon-class": "px-3 py-1",
        subtitle: _vm.subtitle,
        "sub-icon": "mdi-clock-outline",
        "sub-text": _vm.limited,
        tools: !_vm.hideFooter,
        classSubTitle: _vm.classSubTitle
      }
    },
    [
      _c(
        "template",
        { slot: "tools" },
        [
          !_vm.type
            ? _c("emc-button-icon", {
                attrs: {
                  loading: _vm.loading,
                  icon: "mdi-refresh-circle",
                  text: "Atualizar",
                  color: "success"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.refresh()
                  }
                }
              })
            : _vm._e(),
          _vm.showMoreButton
            ? _c("emc-button-icon", {
                attrs: {
                  icon: "mdi-monitor-eye",
                  text: "Ver Mais...",
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.$emit("showMoreAttributions", _vm.itemId)
                  }
                }
              })
            : _vm._e(),
          !_vm.hideTools
            ? _c(
                "span",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "indigo darken-1",
                                          icon: "",
                                          small: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(3)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1322597087
                      )
                    },
                    [_c("span", [_vm._v("Resumido")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "green darken-1",
                                          icon: "",
                                          small: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(19)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-microsoft-excel")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1819539869
                      )
                    },
                    [_c("span", [_vm._v("Detalhado")])]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 360,
                size: 140,
                width: 15,
                value:
                  _vm.indication && _vm.indication.percent
                    ? _vm.indication.percent
                    : 0,
                color: "blue lighten-1"
              }
            },
            [
              [
                _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.indication && _vm.indication.percent
                          ? _vm.indication.percent
                          : 0
                      ) + "%"
                    )
                  ]),
                  _c("br"),
                  _vm.indication && _vm.indication.total
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.indication.completed) +
                            " / " +
                            _vm._s(_vm.indication.total)
                        )
                      ])
                    : _vm._e()
                ])
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }