var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c("v-tab", { key: "others" }, [_vm._v("Eventos de Variação")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    autofocus: "",
                                    error: !!_vm.errors.name,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome*"
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: !!_vm.errors.initials,
                                    "error-messages": _vm.errors.initials,
                                    label: "Sigla*"
                                  },
                                  model: {
                                    value: _vm.model.initials,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "initials", $$v)
                                    },
                                    expression: "model.initials"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-payment-periodicity-type", {
                                  attrs: {
                                    model:
                                      _vm.model.payment_periodicity_type_id,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.payment_periodicity_type_id,
                                    label: "Periodicidade",
                                    clearable: "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_periodicity_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-value-type", {
                                  attrs: {
                                    model: _vm.model.value_type_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.value_type_id,
                                    label: "Tipo de Valor",
                                    dense: "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "value_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    error: !!_vm.errors.description,
                                    "error-messages": _vm.errors.description,
                                    label: "Descrição",
                                    counter: 255,
                                    rows: "2",
                                    rules: [
                                      function(v) {
                                        return (
                                          !v ||
                                          v.length <= 255 ||
                                          "Máximo de 255 caracteres atingido"
                                        )
                                      }
                                    ],
                                    outlined: "",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.model.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "description", $$v)
                                    },
                                    expression: "model.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: { inset: "", label: "Hora Cheia" },
                                  model: {
                                    value: _vm.model.full_hour,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "full_hour", $$v)
                                    },
                                    expression: "model.full_hour"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: { inset: "", label: "Compõe Salário" },
                                  model: {
                                    value: _vm.model.has_salary,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "has_salary", $$v)
                                    },
                                    expression: "model.has_salary"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-payment-event-nature", {
                                  attrs: {
                                    model: _vm.model.payment_event_nature_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.payment_event_nature_id,
                                    label: "Natureza",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_event_nature_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "text-helper": "Tipo de Eventos: T, E ou C",
                                    "error-messages": _vm.errors.type,
                                    label: "Tipo*"
                                  },
                                  model: {
                                    value: _vm.model.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "type", $$v)
                                    },
                                    expression: "model.type"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    color: "purple",
                                    inset: "",
                                    label: "Ativo"
                                  },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "others" },
                  [
                    _c(
                      "v-container",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      outlined: "",
                                      color: "info",
                                      prominent: "",
                                      type: "info",
                                      border: "left"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "text-center" }, [
                                      _vm._v(
                                        " Essa configuração irá refletir apenas na exportação de "
                                      ),
                                      _c("strong", [
                                        _vm._v("Variação Salarial - Atividades")
                                      ])
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-payment-event", {
                                  attrs: {
                                    model: _vm.model.payment_event_id_discount,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.payment_event_id_discount,
                                    label: "Evento de Desconto",
                                    clearable: "",
                                    "auto-complete": "",
                                    dense: "",
                                    nature: 3
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_event_id_discount",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-payment-event", {
                                  attrs: {
                                    model:
                                      _vm.model.payment_event_id_difference,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.payment_event_id_difference,
                                    label: "Evento de Diferença",
                                    clearable: "",
                                    "auto-complete": "",
                                    dense: "",
                                    nature: 2
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "payment_event_id_difference",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }