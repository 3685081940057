<template>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-x
        left
        bottom
        :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '90%'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="grey"
            :small="small"
          > 
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="amber" v-on="on">mdi-flash-circle</v-icon>  
                </template>
                <span>{{ toolTip }}</span>
            </v-tooltip>
            
          </v-btn>
        </template>

      <v-card>
        <v-card-title class="ma-0 pa-0">
            <v-app-bar dense class="grey lighten-5 mb-4 elevation-1'">
                <v-toolbar-title class="subtitle-1">
                  {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small class="mr-1" @click.native="menu = false" v-bind="attrs" v-on="on">
                          <v-icon color="red lighten-2">mdi-close</v-icon>
                      </v-btn>
                  </template>
                  <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>
        </v-card-title>
        <v-card-text>
          <v-list dense>
              <emc-util-scroll maxHeight="400px">

                
                <emc-button-icon v-if="user.is_master" text="Iniciar Merge" icon="mdi-table-merge-cells" @click="$emit('startMerge')" />
                <emc-button-icon v-if="user.is_master" text="Iniciar Espelhamento de todas as turmas origens" icon="mdi-account-switch-outline" @click="$emit('startUpdateRelocation')" />
                <emc-button-icon v-if="user.is_master" text="Iniciar Espelhamento da turma origem selecionada" :disabled="disabled" icon="mdi-account-switch" @click="$emit('startUpdateRelocationItem')" color="purple" />
                
                <emc-button-icon :disabled="disabled" text="Atualizar Oferta da linha selecionada" color="amber" icon="mdi-flash-outline" @click="$emit('generateGrid', false)" />
                <emc-button-icon :disabled="disabled" text="Atualizar Oferta de todas as linhas do mesmo chamado" color="purple" icon="mdi-flash-outline" @click="$emit('generateGrid', true)" />

                <emc-button-icon v-if="user.is_internal || user.id == 334"  text="Gerar Todas as Ofertas" color="error" icon="mdi-flash-outline" @click="$emit('generateGridAll', 'grids')" />
                <emc-button-icon v-if="user.is_internal || user.id == 334"  text="Gerar Todas as Junções" color="primary" icon="mdi-flash-outline" @click="$emit('generateGridAll', 'joins')" />

                <!-- <v-list-item>
                  
                  <v-list-item-content>


                  </v-list-item-content>
                  
                </v-list-item> -->

                <!-- <v-list-item v-if="$slots.custom || !!$scopedSlots.custom">
                  <v-list-item-content>
                    <slot name="custom" />
                  </v-list-item-content>
                </v-list-item> -->

              </emc-util-scroll>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "SysPopOverOfferItem",
    data: () => ({
      menu: false,
    }),
    props: {
        title: {
            type: String,
            default: 'Ações de Oferta'
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        small: {
          type: Boolean,
          default: false,
        },
        toolTip: {
            type: String,
            default: 'Ações de Oferta'
        }
    },
    computed: {
      ...mapState('auth', ['user']),
    },
}
</script>