<template>
    <div>
        <emc-calendar-date-picker
            v-if="renderComponent"
            :model.sync="innerModel"
            :label="label"
            :icon="icon"
            :show="show"
            :errorMessages="errorMessages"
            :disabled="disabled"
            :fieldClass="fieldClass"
            :small="small"
            :dense="dense"
            :min="min"
            :max="max"
            :clearable="clearable"
            :readonly="readonly"
            :openOnClick="openOnClick"
            :textHelper="textHelper"
            @change="changeValue($event)"
            :outlined="outlined"
        />
    </div>
</template>

<script>
export default {
    name: "EmcCalendarDatePicker2",
    data: (vm) => ({
        innerModel: null,
        renderComponent: true,
    }),
    props: {
        label: {
            type: String,
            default: '',
            required: false
        },
        icon: {
            type: String,
            default: 'event'
        },
        model: {
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        fieldClass: {
            type: String,
            default: '',
            required: false
        },
        small: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        clearable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type:    Boolean,
            default: true
        },
        openOnClick: {
            type:    Boolean,
            default: true
        },
        textHelper: {
            type: String,
            default: undefined
        },
        outlined: {
            type:    Boolean,
            default: false
        }
    },

    created(){
        this.innerModel = this.model;
    },

    watch: {
        
        model(val) {
            this.innerModel = val;
        },
        
        innerModel (val) {
            this.$emit('update:model', val);
        },
    },

    methods: {
        
        changeValue(event){
            this.forceRerender();
            this.$emit('change', event);
        },
        
        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
        },
    }
}
</script>