import { service } from '../../../../helpers/http'

export const ActionGetWorkTimes = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'worktimes',
        payload: payload
    })
}

export const ActionCreateWorktime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'worktimeCreate',
        payload: payload
    })
}

export const ActionUpdateWorktime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'worktimeUpdate',
        payload: payload
    })
}

export const ActionDeleteWorkTime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'worktimeDelete',
        payload: payload
    })
}

export const ActionGetFrequencies = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'frequencies',
        payload: payload
    })
}

export const ActionFindWorkTimeDates = ({ dispatch }, payload) => {

    return service({
        module: 'worktime',
        action: 'workTimeDateFind',
        payload: payload
    })
    
}

export const ActionDeleteWorkTimeDate = ({ dispatch }, payload) => {

    return service({
        module: 'worktime',
        action: 'workTimeDateDelete',
        payload: payload
    })
    
}

export const ActionShowWorkTime = ( { dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'showWorkTime',
        payload: payload,
        idAndPayload: true
    })
}

export const ActionDestroyIntegrationWorkTime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'destroyIntegrationWorkTime',
        payload: payload
    })
}

export const ActionStoreIntegrationWorkTime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'storeIntegrationWorkTime',
        payload: payload
    })
}

export const ActionUpdateIntegrationWorkTime = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'updateIntegrationWorkTime',
        payload: payload
    })
}

export const ActionIntegrationWorkTimeReplicate = ({ dispatch }, payload) => {
    return service({
        module: 'worktime',
        action: 'replicateIntegrationWorkTime',
        payload: payload
    })
}