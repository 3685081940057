var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Campus")]),
                        _c("th", [_vm._v("Empresa")]),
                        _c("th", [_vm._v("Descrição")]),
                        _c("th", [_vm._v("Período")]),
                        _c("th", [_vm._v("Valor")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.items.filter(function(i) {
                          return i.registration !== null
                        }),
                        function(item, i) {
                          return _c("tr", { key: i }, [
                            _c("td", [
                              _vm._v(_vm._s(item.campus.abbreviation))
                            ]),
                            _c("td", [
                              item.company
                                ? _c("span", [
                                    _vm._v(_vm._s(item.company.name))
                                  ])
                                : _vm._e(),
                              item.registration
                                ? _c("small", [
                                    _c("br"),
                                    _vm._v(_vm._s(item.registration))
                                  ])
                                : _vm._e()
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.description
                                    ? item.description
                                    : item.note
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("date")(item.start)) +
                                  " - " +
                                  _vm._s(_vm._f("date")(item.end))
                              )
                            ]),
                            _c("td", [
                              item.payment_export_item_status_id == 1
                                ? _c("span", [
                                    _vm._v(_vm._s(parseFloat(item.value)))
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        parseFloat(item.value_prorated_grids)
                                      )
                                    )
                                  ])
                            ])
                          ])
                        }
                      ),
                      0
                    ),
                    _c("tfoot", [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { colspan: "4" }
                          },
                          [_c("strong", [_vm._v("Total: ")])]
                        ),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c("strong", [_vm._v(_vm._s(_vm.total))])
                        ])
                      ])
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }