import { default as hour } from './hour/routes'
import { default as shift } from './shift/routes'
import { default as process } from './process/routes'
import { default as integration } from './integration/routes'
import { default as schedule } from './schedule/routes'
import { default as item } from './item/routes'

export default [
    ...hour,
    ...shift,
    ...process,
    ...integration,
    ...schedule,
    ...item
]