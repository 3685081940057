var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-progress-linear", {
    attrs: { value: 100, color: _vm.color, striped: "", height: "25" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.text) + " ")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }