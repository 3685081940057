var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("sys-select-teacher-color-race", {
                attrs: {
                  model: _vm.model.teacher_color_race_id,
                  error: _vm.errors.length > 0,
                  errors: _vm.errors.teacher_color_race_id,
                  label: "Cor/Raça",
                  clearable: ""
                },
                on: {
                  "update:model": function($event) {
                    return _vm.$set(_vm.model, "teacher_color_race_id", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("sys-select-teacher-nationality", {
                attrs: {
                  model: _vm.model.nationality_id,
                  error: _vm.errors.length > 0,
                  errors: _vm.errors.nationality_id,
                  label: "Nacionalidade*"
                },
                on: {
                  "update:model": function($event) {
                    return _vm.$set(_vm.model, "nationality_id", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("sys-select-genre", {
                attrs: {
                  model: _vm.model.genre_id,
                  error: _vm.errors.length > 0,
                  errors: _vm.errors.genre_id,
                  label: "Sexo*"
                },
                on: {
                  "update:model": function($event) {
                    return _vm.$set(_vm.model, "genre_id", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("sys-select-country", {
                attrs: {
                  model: _vm.model.country_id,
                  error: _vm.errors.length > 0,
                  errors: _vm.errors.country_id,
                  label: "País*",
                  autoComplete: ""
                },
                on: {
                  "update:model": function($event) {
                    return _vm.$set(_vm.model, "country_id", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  error: _vm.errors.length > 0,
                  "error-messages": _vm.errors.mother_name,
                  label: "Nome da Mãe",
                  required: ""
                },
                model: {
                  value: _vm.model.mother_name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "mother_name", $$v)
                  },
                  expression: "model.mother_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  error: _vm.errors.length > 0,
                  "error-messages": _vm.errors.foreign_document,
                  label: "Documento Estrangeiro",
                  required: ""
                },
                model: {
                  value: _vm.model.foreign_document,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "foreign_document", $$v)
                  },
                  expression: "model.foreign_document"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("sys-select-teacher-status-deficiency", {
                attrs: {
                  model: _vm.model.teacher_status_deficiency,
                  error: _vm.errors.length > 0,
                  errors: _vm.errors.teacher_status_deficiency,
                  label: "Docente com Deficiência*"
                },
                on: {
                  "update:model": function($event) {
                    return _vm.$set(
                      _vm.model,
                      "teacher_status_deficiency",
                      $event
                    )
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }