var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    staticClass: "table-hour",
    attrs: { height: "260px", "fixed-header": "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "strong",
                          _vm._g(
                            _vm._b(
                              { staticStyle: { cursor: "help" } },
                              "strong",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(
                              _vm._s(
                                _vm.item
                                  ? _vm.item.name
                                  : _vm.type == "teacher"
                                  ? "Horário do Professor"
                                  : "Horário do Ambiente"
                              )
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.item
                        ? _vm.item.name
                        : _vm.type == "teacher"
                        ? "Selecione um Professor"
                        : "Selecione um Ambiente"
                    )
                  )
                ])
              ]
            ),
            _c("v-progress-linear", {
              attrs: {
                indeterminate: _vm.loading,
                color: _vm.loading ? "cyan" : ""
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("thead", [
              _c("tr", { staticStyle: { height: "1px" } }, [
                _c("th", { staticStyle: { width: "1px" } }),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Seg")]
                ),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Ter")]
                ),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Qua")]
                ),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Qui")]
                ),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Sex")]
                ),
                _c(
                  "th",
                  { staticClass: "text-center", staticStyle: { width: "1px" } },
                  [_vm._v("Sáb")]
                )
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.hours, function(hour) {
                return _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.shifts.includes(hour.shift_id) ||
                            _vm.showHours) &&
                          _vm.hasGroups(hour),
                        expression:
                          "(shifts.includes(hour.shift_id) || showHours) && hasGroups(hour)"
                      }
                    ],
                    key: hour.id,
                    staticStyle: { height: "1px" }
                  },
                  [
                    _c("td", [
                      _c("small", [
                        _vm._v(" " + _vm._s(hour.start) + " "),
                        _c("br"),
                        _vm._v(" " + _vm._s(hour.end) + " ")
                      ])
                    ]),
                    _vm._l(_vm.days, function(day) {
                      return _c(
                        "td",
                        {
                          key: day,
                          staticClass: "text-center",
                          class: _vm.getClassAvailability(day, hour),
                          on: {
                            dblclick: function($event) {
                              return _vm.selectGroup(day, hour)
                            }
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            {
                                              class: _vm.getClassText(
                                                day,
                                                hour
                                              ),
                                              staticStyle: { cursor: "help" }
                                            },
                                            on
                                          ),
                                          [
                                            _vm.getContent(day, hour)
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    staticClass: "ma-0 pa-0",
                                                    attrs: {
                                                      color: "transparent",
                                                      label: "",
                                                      "x-small": "",
                                                      outlined: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "ma-0 pa-0"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "black--text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getContent(
                                                                  day,
                                                                  hour
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm.getContentCount(
                                                          day,
                                                          hour
                                                        ) === 0
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  "x-small": ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-vector-polyline"
                                                                )
                                                              ]
                                                            )
                                                          : _vm.getContentCount(
                                                              day,
                                                              hour
                                                            ) >= 1
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  "x-small": ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-cancel"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            _vm._l(_vm.getToolTip(day, hour), function(
                              item,
                              i
                            ) {
                              return _c("span", { key: i }, [
                                _vm._v(" " + _vm._s(item) + " "),
                                _c("br")
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }