var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-notebook",
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c("v-tab", { key: "others" }, [_vm._v("Demais Dados")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    autofocus: "",
                                    dense: "",
                                    error: !!_vm.errors.name,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.initials,
                                    label: "Abreviação",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.initials,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "initials", $$v)
                                    },
                                    expression: "model.initials"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages":
                                      _vm.errors.work_load_total,
                                    label: "CH Total",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.work_load_total,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "work_load_total",
                                        $$v
                                      )
                                    },
                                    expression: "model.work_load_total"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    "error-messages": _vm.errors.code_control,
                                    label: "Código Controle"
                                  },
                                  model: {
                                    value: _vm.model.code_control,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code_control", $$v)
                                    },
                                    expression: "model.code_control"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.filterHeaders,
                                    items: _vm.itemsWorkLoads,
                                    "sort-by": "calories",
                                    "hide-default-footer": true,
                                    "items-per-page": -1
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "top",
                                      fn: function(ref) {
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "10"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "indigo darken-1",
                                                                          icon:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            $event.stopPropagation()
                                                                            return _vm.addItem()
                                                                          }
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Adicionar Carga Horária"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "2" }
                                                },
                                                [
                                                  _vm.itemsWorkLoads.length >
                                                    0 && !_vm.hasWorkLoadPay
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "success",
                                                                              icon:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                $event.stopPropagation()
                                                                                _vm.showColumns = !_vm.showColumns
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.showColumns
                                                                                  ? "mdi-arrow-expand-right"
                                                                                  : "mdi-arrow-expand-left"
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Mais opções"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.work_load_type",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("sys-select-work-load-type", {
                                            attrs: {
                                              model: item.work_load_type_id,
                                              error: _vm.errors.length > 0,
                                              errors:
                                                _vm.errors[
                                                  "workLoads." +
                                                    index +
                                                    ".work_load_type_id"
                                                ],
                                              label: "",
                                              dense: "",
                                              "auto-complete": ""
                                            },
                                            on: {
                                              "update:model": function($event) {
                                                return _vm.$set(
                                                  item,
                                                  "work_load_type_id",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.work_load",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors[
                                                  "workLoads." +
                                                    index +
                                                    ".work_load"
                                                ],
                                              dense: ""
                                            },
                                            model: {
                                              value: item.work_load,
                                              callback: function($$v) {
                                                _vm.$set(item, "work_load", $$v)
                                              },
                                              expression: "item.work_load"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.work_load_pay",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors[
                                                  "workLoads." +
                                                    index +
                                                    ".work_load_pay"
                                                ],
                                              dense: ""
                                            },
                                            model: {
                                              value: item.work_load_pay,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "work_load_pay",
                                                  $$v
                                                )
                                              },
                                              expression: "item.work_load_pay"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.work_load_time",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors[
                                                  "workLoads." +
                                                    index +
                                                    ".work_load_time"
                                                ],
                                              dense: ""
                                            },
                                            model: {
                                              value: item.work_load_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "work_load_time",
                                                  $$v
                                                )
                                              },
                                              expression: "item.work_load_time"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.number_divisions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              error: _vm.errors.length > 0,
                                              "error-messages":
                                                _vm.errors[
                                                  "workLoads." +
                                                    index +
                                                    ".number_divisions"
                                                ],
                                              dense: ""
                                            },
                                            model: {
                                              value: item.number_divisions,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  item,
                                                  "number_divisions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.number_divisions"
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                dense: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.deleteItem(item)
                                                }
                                              }
                                            },
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    name: "input-7-4",
                                    label: "Ementa",
                                    value: _vm.model.syllabus,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.syllabus,
                                    rows: "3",
                                    "row-height": "20",
                                    counter: 600,
                                    clearable: "",
                                    rules: [
                                      function(v) {
                                        return (
                                          !v ||
                                          v.length <= 600 ||
                                          "Máximo de 600 caracteres atingido"
                                        )
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.model.syllabus,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "syllabus", $$v)
                                    },
                                    expression: "model.syllabus"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.showConfigs
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        label: "Com Professor"
                                      },
                                      model: {
                                        value: _vm.model.has_teacher,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "has_teacher",
                                            $$v
                                          )
                                        },
                                        expression: "model.has_teacher"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        label: "Carga Hor. Paga"
                                      },
                                      model: {
                                        value: _vm.model.has_paid_workload,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "has_paid_workload",
                                            $$v
                                          )
                                        },
                                        expression: "model.has_paid_workload"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        label: "Possui Horário"
                                      },
                                      model: {
                                        value: _vm.model.has_time_table,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "has_time_table",
                                            $$v
                                          )
                                        },
                                        expression: "model.has_time_table"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-switch", {
                                      attrs: { inset: "", label: "Ativa" },
                                      model: {
                                        value: _vm.model.active,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "active", $$v)
                                        },
                                        expression: "model.active"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        label:
                                          "Exibir na página de disponibilidade"
                                      },
                                      model: {
                                        value: _vm.model.show_availability,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "show_availability",
                                            $$v
                                          )
                                        },
                                        expression: "model.show_availability"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        inset: "",
                                        label: "Disciplina Ativa"
                                      },
                                      model: {
                                        value: _vm.model.active,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "active", $$v)
                                        },
                                        expression: "model.active"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "others" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-category-ambience", {
                                  attrs: {
                                    model: _vm.model.category_ambience_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.category_ambience_id,
                                    clearable: "",
                                    label: "Categoria de Ambiente",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "category_ambience_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-ambience", {
                                  attrs: {
                                    model: _vm.model.ambience_id,
                                    error:
                                      _vm.errors.ambience_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.ambience_id,
                                    label: "Ambiente",
                                    clearable: "",
                                    autoComplete: "",
                                    dense: "",
                                    "category-id":
                                      _vm.model.category_ambience_id
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "ambience_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-resource", {
                                  attrs: {
                                    model: _vm.model.resources,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.resources,
                                    multiple: "",
                                    label: "Recursos Necessários",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "resources",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-unsanitary-type", {
                                  attrs: {
                                    model: _vm.model.unsanitary_type_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.unsanitary_type_id,
                                    showAdd: "",
                                    clearable: "",
                                    label: "Tipo Insalubridade",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "unsanitary_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-discipline-type", {
                                  attrs: {
                                    model: _vm.model.discipline_type_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.discipline_type_id,
                                    clearable: "",
                                    "auto-complete": "",
                                    label: "Tipo de Disciplina",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "discipline_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-company", {
                                  attrs: {
                                    model: _vm.model.company_id,
                                    label: "Empresa",
                                    dense: "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "company_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-dynamic-time-grouping-type", {
                                  attrs: {
                                    model:
                                      _vm.model.dynamic_time_grouping_type_id,
                                    label: "Tipo de Agrupamento",
                                    dense: "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "dynamic_time_grouping_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }