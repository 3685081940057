<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="model.id ? 'Editar Curso' : 'Cadastrar Curso'" 
        icon="mdi-book-open-page-variant" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field autofocus dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.initials" v-model="model.initials" label="Sigla"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código" />
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <sys-select-occupation-area
                            :model.sync="model.occupation_area_id"
                            :error="errors.length > 0"  
                            :errors="errors.occupation_area_id"
                            label="Área de atuação"
                            show-add
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <sys-select-category
                            :model.sync="model.category_id"
                            :error="errors.length > 0"  
                            :errors="errors.category_id"
                            label="Categoria"
                            clearable
                        />
                    </v-col>
                 </v-row>
                 <v-row>
                     <v-col cols="12" sm="6">
                        <sys-select-course-level
                                :model.sync="model.course_level_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_level_id"
                                label="Nível de Curso*"
                                dense
                            />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <sys-select-course-modality
                                :model.sync="model.course_modality_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_modality_id"
                                label="Modalidade de ensino*"
                                dense
                            />
                    </v-col>
                </v-row>
                 <v-row>
                     <v-col cols="12" sm="6">
                        <sys-select-course-degree
                                :model.sync="model.course_academic_degree_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_academic_degree_id"
                                label="Grau acadêmico"
                            />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <sys-select-qualifier
                                :model.sync="model.qualifier_id"
                                :error="errors.length > 0"  
                                :errors="errors.qualifier_id"
                                label="Qualificador"
                                auto-complete
                            />
                    </v-col>
                </v-row>
                <v-row>
                     <v-col cols="12" sm="6">
                        <sys-select-cost-center
                            :model.sync="model.cost_center_id"
                            :error="errors.length > 0"  
                            :errors="errors.cost_center_id"
                            label="Centro de Custo"
                            clearable
                            auto-complete
                        /> 
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                    </v-col>
                    <!-- <v-col cols="12" sm="6">
                        <sys-select-work-time-date
                                :model.sync="model.work_time_date_id"
                                :error="errors.length > 0"  
                                :errors="errors.work_time_date_id"
                                label="Periodicidade"
                            />
                    </v-col> -->
                </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysRegisterCourse',
    props: {
        model: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
    }),
    watch: {  
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = 1;
                }
                
            }

        },
    },
    mounted() {
        
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    methods: {
        ...mapActions('course', ['ActionUpdateCourse', 'ActionCreateCourse']),
        
        clear() {
            this.error = false
            this.message = ''
            this.errors = {}
        },    

        save()
        {
            this.clear()
            this.loading = true

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            if(this.model.id) {
                this.ActionUpdateCourse(this.model)
                .then((res) => {
                    this.message = res.message
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = error
                    this.errors = error.errors
                })
                .finally(() => {
                    this.loading = false
                })
            } else {

                this.ActionCreateCourse(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = error
                    this.errors = error.errors
                })
                .finally(() => {
                    this.loading = false
                })

            }
        },
    },
}
</script>