<template>
    <sys-find-base 
        v-bind:show.sync="dialog"
        :collection="items" 
        :headers="headers" 
        :icon="icon"
        @onSearch="searchItem($event)" 
        @onSelect="select($event)"
        :loading="loading"
        :title="title"
        :singleSelect="singleSelect"
        :maxWidth="maxWidth"
        :showSelect="false"
        
    >
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope"/>
        </template>
    </sys-find-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysFindTeacher",
    data: (vm) => ({
        search: '',
        selected: [],
        dialog: false,
        loading: false,
        items: []
    }),
    created(){
        
    },

    computed: {
      
    },

    watch: {
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.searching = false;
                this.selected = [];
                this.search = "";
                this.items = [];
            }
        },
     
    
    },
    props: {
        headers: {
            type: Array,
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "70%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: true
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionFindTeachers']),
        
        select(val){
            this.dialog = false;
            this.$emit('onSelect', val);
        },

        searchItem(search){
            
            this.loading = true;
            this.items = [];
            
            const payload = {
                column_order: 'name',
                direction: 'asc',
                search: search,
                showAllCampuses: true,
                showAllTeachers: true,
                withContract: true,
                with: 'availability.availabilities.campuses,institution.hourGroups,emails,confirmation,campuses.institution,contracts.position',
                find_columns: {
                    active: 1
                }
            }

            this.ActionFindTeachers(payload)
                .then((res) => {
                    this.items = res.data;
                })
                .finally(() => {
                    this.loading  = false;
                });
            
        },
    }
}
</script>