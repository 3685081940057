<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            :showAdd="showAdd"
            :clearable="clearable"
            @change="$emit('change', $event)"
            :dense="dense"
        />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelecEventScope',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        dense: {
            type: Boolean,
            default: false
        },
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('calendar', ['ActionSearchScopes']),   
        
        async getItems() {

            this.loading = true;
            this.items = [];

            await this.ActionSearchScopes()
                    .then((res) => {
                        var finds = [1,2,3,5,7];
                        res.data.forEach(item => {
                            if(finds.includes(item.id)){
                                this.items.push(item);
                            }
                        });
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

    newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>