var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      disabledSave: _vm.disabled,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            dense: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shifts,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shifts,
                            label: "Turno",
                            dense: "",
                            multiple: "",
                            "select-all": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shifts", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campuses,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.campuses,
                            label: "Campus*",
                            multiple: "",
                            dense: "",
                            "select-all": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campuses", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-dynamic-offer-type", {
                          attrs: {
                            model: _vm.model.dynamic_offer_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.dynamic_offer_type_id,
                            label: "Tipo",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "dynamic_offer_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("SysSelectWorkTime", {
                          attrs: {
                            model: _vm.model.work_time_id_origin,
                            label: "Período Origem",
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.work_time_id_origin,
                            "filter-active": "",
                            dense: "",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "work_time_id_origin",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }