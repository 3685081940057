<template>
    <emc-card-stats
        color="blue darken-2"
        icon="mdi-flask-outline"
        icon-large
        :title="title"
        :value="ambiences"
        sub-text="Número de ambientes"
        >
        <template v-slot:actions>
            <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
        </template>
        </emc-card-stats>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartAmbienceNumber',
    data () {
        return {
            ambiences: '0',
            title: '',
            updated_at: null,
            loading: false,
            attempts: 1
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartAmbienceNumber', 'ActionAmbienceNumbersRefresh']),
        async getData() {
            await this.ActionChartAmbienceNumber()
                .then((r) => {
                    this.ambiences = r.data.value.toString()
                    this.title = r.data.title
                    this.updated_at = r.data.updated_at
                })
        },
        refresh() {
            this.loading = true

            this.ActionAmbienceNumbersRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let number = this.ambiences;

            setTimeout(async () => {

                    await this.getData()
                    
                    if(number != this.ambiences || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 5000)
        }
    },
    created(){
        this.getData()
    }

    
}
</script>