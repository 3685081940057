<template>
    <EmcCardStats 
        color="blue lighten-1"
        icon="mdi-loupe"
        icon-large
        :title="title"
        :value="value"
        :show-actions="showActions"
        :text-actions="title"
        :sub-text="showActions ? null : 'CH Média Extraclasse'"
    >
        <template v-if="showMoreButton" slot="actions">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMoreWorkLoadAvg')">
                        <v-icon btn>mdi-monitor-eye</v-icon>
                    </v-btn>
                </template>
                <span>
                    Ver Mais...
                </span>
            </v-tooltip>
        </template>

    </EmcCardStats>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartActivityWorkLoadAvg',
    data () {
        return {
            value: '0',
            title: ''
        }
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        item: { },
        noLoad: {
            type: Boolean,
            default: false,
        },
        showActions: {
            type: Boolean,
            default: false,
        }
    },
    
    methods: {
        ...mapActions('chart', ['ActionChartActivityWorkLoadAvg']),
        
        getData() {
            
            if(this.noLoad){
                this.value = this.item.value;
                this.title = this.item.title;
                return;
            }

            this.ActionChartActivityWorkLoadAvg()
                .then((r) => {
                    this.value = r.data.value.toString()
                    this.title = r.data.title
                })
        }
    },
    created(){
        this.getData()
    }

    
}
</script>