<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :search.sync="search"
            :clearable="clearable"
            :showAdd="showAdd"
            :autoComplete="autoComplete"
            :itemText="itemText"
            :outlined="outlined"
        ></sys-select-base>
        <sys-register-discipline @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectDiscipline',
    data: () => ({
        selected: '', //Define o ID do item selecionado
        items: [],      //Coleção de objetos que será passada para o select
        loading: false, //Para mostrar que os itens estão sendo carregados
        showForm: false, //Exibe o modal para cadastro de nova disciplina
        search: ''
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    created() {
        
        if(this.data instanceof Object){
            this.items.push(this.data);
            this.selected = this.model;
        } else {
            
            if(this.selected.length <= 0) return

            this.getItems();
        }
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        model: {
            type: Number
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'name'
        },
        data: {
            required: false
        },
        modelObject: {

        },
        disciplines:{
            
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {

        /**
         * Captura que a informação do objeto data foi alterado
         * por quem chama esse componente
         */
        data(val) {
            
            this.items = []
            
            if(val){
                this.items.push(val);
                this.selected = this.model;
            }
            
        },
        /**
         * Emite o evento de model para informar que outro item 
         * foi selecionado no select
         */
        selected(val){
            
            this.$emit('update:model', val);

            if(val && this.items){
                this.$emit('update:modelObject', this.items.find(i => i.id == val));
            }
        },
        /**
         * Captura informação que o objeto model foi alterado
         * por quem chama esse componente
         */
        
        model(val){
            this.selected = val;
        },

        search(newSearch, oldSearch) {

            this.getItems()
            
        },
    },
    methods: {
        ...mapActions('discipline', ['ActionFindDisciplines']),   
        /**
         * Busca as disciplinas para preencher o objeto do select
         */
        async getItems() {
            
            this.loading = true
            var payload = {
                column_order: 'name',
                direction: 'asc',
                search: this.search,
                find_columns: {
                    active: 1
                }
            }

            await this.ActionFindDisciplines(payload)
                    .then((res) => {
                        
                        this.items = res.data;

                        if(this.disciplines && this.disciplines.length > 0){
                            this.items.forEach(disc => {
                                if(this.disciplines.includes(disc.id)){
                                    disc.full_name += ' [Matriz]';
                                }
                            });
                        }

                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },
        /** 
         * Sempre que um item novo for cadastrado
         * inclui no objeto do select e define esse item
         * como selecionado
         */
        newItem(item){  
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>