<template>
    <sys-register-base 
        :model="{}"
        title="Impressão de Horários" 
        icon="mdi-printer-check" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
        :showRequiredFields="false"
        :showButton="false"
    >
        
        <emc-loader-progress v-if="renderComponent" :show="progress" />

        <template v-slot:form>
            <v-container>
                <v-row>
                   <v-col>
                        <v-tabs class="ma-0" v-model="tab">
                            <v-tab key="groups">Turmas</v-tab>
                            <v-tab key="teachers">Professores</v-tab>
                            <v-tab key="ambiences">Ambientes</v-tab>
                        </v-tabs>
                        <v-tabs-items  v-model="tab">
                            <v-tab-item key="groups">
                                <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        class="pa-0"
                                        v-model="searchGroup"
                                        prepend-inner-icon="search"
                                        x-small
                                        placeholder="Pesquisar" 
                                        rounded 
                                        single-line
                                        clearable
                                        autofocus
                                    />
                                    <emc-util-scroll 
                                        outlined
                                        height="45vh"
                                    >
                                    <v-treeview 
                                        v-model="modelGroups" 
                                        ref="treeGroups"
                                        :items="itemsGroups"
                                        item-key="key"
                                        item-name="name" 
                                        return-object 
                                        item-children="children" 
                                        selectable 
                                        :open-all="false"
                                        :search="searchGroup"
                                    />
                                </emc-util-scroll>
                            </v-col>
                            <v-col cols="12" sm="6">
                                    <v-list>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir professor no horário das turmas e ambientes" v-model="config.showTeacher" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Professor</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir aulas pendentes ainda não alocadas no horário" v-model="config.showPending" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Pendências</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir ambiente no horário das turmas e professores" v-model="config.showAmbience" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Ambiente</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir todas as disciplinas presenciais e à distância" v-model="config.showAllGrids" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Todas Disciplinas</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir os horários das atividades no horário do professor" v-model="config.showActivities" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Horário das Atividades</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                            <s-switch inset text-helper="Exibir subturmas das ofertas" v-model="config.showSubGroup" color="purple"/>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir subturmas</v-list-item-title>
                                    </v-list-item>

                                    </v-list>
                            </v-col>
                        </v-row>
                     </v-container>
                            </v-tab-item>
                            <v-tab-item key="teachers">
                                <v-list>
                                    <!-- <v-subheader>PROFESSORES</v-subheader> -->
                                    <v-list-item-group
                                        v-model="selectedTeacher"
                                        color="primary"
                                    >
                                            <v-list-item
                                                ripple
                                                @mousedown.prevent
                                                @click="toggle"
                                            >
                                                <v-list-item-action>
                                                    <v-icon v-if="itemsTeachers.length" :color="selectedIndexTeachers.length > 0 ? 'indigo darken-4' : ''">
                                                        {{ icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ itemsTeachers.length > 0 ? 'Selecionar todos' : 'Selecione as turmas para listar os professores' }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                
                                                <v-progress-linear
                                                    v-if="loadingTeachers"
                                                    indeterminate
                                                    color='yellow darken-2'
                                                />

                                                <emc-util-scroll height="45vh">
                                                    <v-list-item
                                                        v-for="(item, index) in itemsTeachers"
                                                        :key="index"
                                                        @mousedown.prevent
                                                        @click="selectTeacher(index)"
                                                        >
                                                        <v-list-item-icon>
                                                            <v-icon :color="isCheckedTeacher(index) ? 'indigo darken-4' : ''" v-text="isCheckedTeacher(index) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'" />
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </emc-util-scroll>
                                    </v-list-item-group>
                                </v-list>
                            </v-tab-item>
                            <v-tab-item key="ambiences">
                                <v-row>
                                    <v-col>
                                        <emc-util-scroll 
                                            outlined
                                            height="45vh"
                                        >
                                            <sys-ambience-tree-view-select :active.sync="modelAmbiences" />
                                        </emc-util-scroll>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                
                   
                    
                </v-col>
                </v-row>
            </v-container>
            </template>

            <template v-slot:actions>
                            <v-tooltip  v-if="tab === 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color='success' @click.prevent.stop="printTimeTable('group')" :disabled="!modelGroups || modelGroups.length == 0" >Imprimir<v-icon right dark>mdi-microsoft-excel</v-icon></v-btn>
                                </template>
                                <span>Imprimir horário das turmas selecionadas</span>
                            </v-tooltip>

                            <v-tooltip v-if="tab === 1"  bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color='success' @click.prevent.stop="printTimeTable('teacher', false)" :disabled="selectedIndexTeachers.length == 0" >Imprimir<v-icon right dark>mdi-microsoft-excel</v-icon></v-btn>
                                </template>
                                <span>Imprimir horário dos professores selecionadas</span>
                            </v-tooltip>

                            <v-tooltip v-if="tab === 2"  bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" color='success' @click.prevent.stop="printTimeTable('ambience', false)" :disabled="modelAmbiences.length == 0" >Imprimir<v-icon right dark>mdi-microsoft-excel</v-icon></v-btn>
                                </template>
                                <span>Imprimir horário dos ambientes selecionadas</span>
                            </v-tooltip>
                            
                    </template>
            
    </sys-register-base>

</template>

<script>

import { myMixin } from '../../../mixins';
import { mapActions } from 'vuex'

export default {
    name: "SysPrintTimeTable",
    mixins: [myMixin],  
    data: () => ({
        tab: 0,
        searchGroup: '',
        searchTeacher: '',
        modelGroups: [],
        modelAmbiences: [],
        itemsGroups: [],
        itemsTeachers: [],
        selectedTeacher: null,
        selectedIndexTeachers: [],
        loadingTeachers: false,
        dialog: false,
        progress: false,
        renderComponent: false,
        config: {
            showTeacher: true,
            showPending: true,
            showAllGrids: false,
            showActivities: true,
            showSubGroup: true,
            showAmbience: true,
        }
    }),
    created(){
        
    },
    computed: {
        likesAllTeacher () {
            return this.selectedIndexTeachers.length === this.itemsTeachers.length
        },
        likesSomeTeacher () {
            return this.selectedIndexTeachers.length > 0 && !this.likesAllTeacher
        },
        icon () {
            if (this.likesAllTeacher) return 'mdi-close-box'
            if (this.likesSomeTeacher) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        
        dialog(val_new, val_old) {
            
            if(val_new !== val_old) {
                this.$emit('update:show', val_new)
            }

        },

        tab(val){

            if(val == 1){
                this.loadTeachers();
            }

        },

        show(val_new, val_old) {
            
            this.dialog = val_new;

            if(val_new){
                
                this.tab = 0;
                
                if(this.itemsGroups.length == 0){
                    this.loadTree();
                }
            }
        },

        searchGroup(val){
            
            if(val && val != ''){
                this.$refs.treeGroups.updateAll(true);
            }else{
                this.$refs.treeGroups.updateAll(false);
            }
        },
        
    },
    props: {
        
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('group', ['ActionGetGroupsTreePrint']),
        ...mapActions('teacher', ['ActionFindTeachers']),

        loadTree(){
           
            this.progress = true;
            this.forceRerender();

            let payload = {
                loadGrids: false,
            };
            
            this.ActionGetGroupsTreePrint(payload)
            .then((res) => {
                this.itemsGroups = res.data;

                this.itemsGroups.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

                this.itemsGroups.forEach(campus => {
                    campus.children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    
                    campus.children.forEach(turno => {
                        turno.children.forEach(categoria => {
                            categoria.children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                        });
                    });

                });

            })
            .catch((error) =>{
                
            })
            .finally(() => {
              this.progress = false;
            });
        },

        loadTeachers(){

            this.itemsTeachers = [];
            this.selectedIndexTeachers = [];

            if(this.modelGroups.length == 0){
                return;
            }

            this.loadingTeachers = true;

            var payload = {
                find_columns: { active: 1 },
                filter_relations: [{
                    name:   'attributionsActive.grid',
                    key:    'group_id', 
                    value:  this.modelGroups.map(o => o['id']).join(','),
                    clause: 'whereIn'
                }]
            };
             
            this.ActionFindTeachers(payload)
                .then((res) => {
                    this.itemsTeachers = res.data;
                     this.itemsTeachers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }).finally(() => {
                    this.loadingTeachers = false;
                });

        },

        printTimeTable(type, byGroup = false){
            
            var ids = '';

            if(byGroup){
                ids = this.modelGroups.map(o => o['id']).join(',');
                type += '&byGroups=true';
            }else{
                
                if(type == 'group'){
                    ids = this.modelGroups.map(o => o['id']).join(',');
                }else if(type == 'teacher'){

                    this.selectedIndexTeachers.forEach(i => {
                        
                        if(ids.length > 0){
                            ids += ',';
                        }
                        ids += this.itemsTeachers[i]['id'];
                    });

                }else{

                    this.modelAmbiences.forEach(amb => {
                        
                        if(ids.length > 0){
                            ids += ',';
                        }
                        ids += amb['id'];
                    });
                }
            }

            var configs = Object.entries(this.config).map(([key, val]) => `config[${key}]=${val}`).join('&');
            this.download(4, "type=" + type + "&items_id=" + ids + '&' + configs);
            
        },

        toggle () {
            
            this.$nextTick(() => {
                
                if (this.likesAllTeacher) {
                   this.selectedIndexTeachers = [];
                }else{
                    for (let index = 0; index < this.itemsTeachers.length; index++) {
                        if(!this.selectedIndexTeachers.includes(index)){
                            this.selectedIndexTeachers.push(index);
                        }
                   }
                }
                
            })
        },

        isCheckedTeacher(index){
            return this.selectedIndexTeachers.includes(index);
        },

        selectTeacher(index){
            
            if(this.selectedIndexTeachers.includes(index)){
                this.selectedIndexTeachers.splice(this.selectedIndexTeachers.indexOf(index), 1);
            }else{
                this.selectedIndexTeachers.push(index);
            }
            
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
      },
    }
}
</script>