<template>
  
    <v-app-bar
      color="grey lighten-4"
      class="elevation-0"
      app
      fixed
      v-if="show"
    >

    <v-toolbar-title><v-img src="/img/sysclass_logo.png" max-width="150" /></v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn v-if="false && user.id"  to="faq-questions" icon>
      <v-icon color="indigo" large>mdi-help-circle-outline</v-icon>
    </v-btn>
    
    <v-menu v-if="user.id" :close-on-content-click="false" :nudge-width="100" offset-x>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <EmcBaseAvatar 
                :size="36"
                :src="user.url_avatar"
                :text="user.initials"
                v-if="user.id"
              />
              <v-icon color="grey darken-1">keyboard_arrow_down</v-icon>

            </v-btn>
          </template>

            <v-card :max-width="400">
              <v-list>
                 <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar color="grey">
                      <img
                        v-if="user.id && user.url_avatar"
                        :src="user.url_avatar"
                      >
                      <v-icon v-else dark>mdi-account-circle</v-icon>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                  <v-list-item v-if="acesso.instituicao && acesso.instituicao.id" to="profile" @click="showMenu = !showMenu">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Meus Dados</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click.stop.prevent="sair()">
                    <v-list-item-icon>
                      <v-icon>{{ exit ? 'mdi-spin mdi-loading' : 'exit_to_app'}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Sair</v-list-item-title>
                  </v-list-item>

                </v-list>

            </v-card>
    </v-menu>

    </v-app-bar>

</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    name: "SysCoreToolbarNew",
    data () {
      return {
        showMenu: false,
        exit: false
      }
    },
    computed: {
        ...mapState('auth', ['acesso', 'user', 'menu']),
        show() {
            return this.$route.name !== 'login' && this.$route.name !== 'access-teacher' && this.$route.name !== 'login/teacher';
        }
    },
    methods: {
      ...mapActions('auth', ['ActionSignOut']),
      sair(){
        this.exit = true;
        this.ActionSignOut()
            .then(() => { this.exit = false; })
      }
    }
}
</script>