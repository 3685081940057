import { service } from '../../../helpers/http'
export * from '../region/store/actions'
export * from '../departament/store/actions'
export * from '../establishment/store/actions'
export * from '../partner_company/store/actions'

export const ActionFindInstitutions = ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'findInstitutions',
        payload: payload
    })
}

export const ActionDeleteInstitution = ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'deleteInstitution',
        payload: payload
    })
}

export const ActionUpdateInstitution = ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'updateInstitution',
        payload: payload
    })
}

export const ActionCreateInstitution = ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'createInstitution',
        payload: payload
    })
}


export const ActionIndexInstitutions = ( ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'index',
        payload: payload
    })
})

export const ActionInstitutionUploadCompanyLogo = ({ dispatch }, payload) => {

    return service({
        module: 'institution',
        action: 'uploadCompanyLogo',
        payload: payload
    })
}

export const ActionIndexAllInstitutions = ({ dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'indexAllInstitutions',
        payload: payload
    })
}

export const ActionShowInstitution = ({ dispatch }, payload) => {

    return service({
        module: 'institution',
        action: 'showInstitution',
        payload: payload
    })
}

export const ActionUpdateIntegrationParameter = ( { dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'updateIntegrationParameter',
        payload: payload
    })
}

export const ActionStoreIntegrationParameter = ( { dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'storeIntegrationParameter',
        payload: payload
    })
}

export const ActionDestroyIntegrationParameter = ( {dispatch }, payload) => {
    return service({
        module: 'institution',
        action: 'destroyIntegrationParameter',
        payload: payload
    })
}