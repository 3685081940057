export default [
    {
        path: '/webhooks',
        name: 'webhooks',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Lista de webhooks',
            icon: 'mdi-webhook',
            breadcrumbs: [
                { text: 'Lista de webhooks', disabled: true }
            ]
        }
    },
]