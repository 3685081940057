var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0" },
    [
      false
        ? _c(
            "v-app-bar",
            { attrs: { prominent: "", height: "40px" } },
            [
              _c("v-text-field", {
                attrs: {
                  "prepend-inner-icon": "search",
                  "x-small": "",
                  placeholder: "Pesquisar",
                  rounded: "",
                  "single-line": "",
                  clearable: "",
                  autofocus: ""
                },
                model: {
                  value: _vm.searchInner,
                  callback: function($$v) {
                    _vm.searchInner = $$v
                  },
                  expression: "searchInner"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "ma-1",
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : _vm._e(),
      _c("v-treeview", {
        ref: "tree",
        attrs: {
          active: _vm.active,
          items: _vm.items,
          "load-children": _vm.fetchItems,
          open: _vm.open,
          activatable: "",
          "item-children": "children",
          search: _vm.searchInner,
          color: "warning",
          "open-on-click": true,
          transition: "",
          "item-key": "key",
          "return-object": ""
        },
        on: {
          "update:active": [
            function($event) {
              _vm.active = $event
            },
            function($event) {
              return _vm.selectItem($event[0])
            }
          ],
          "update:open": function($event) {
            _vm.open = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              return [
                item.level == 4
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c("br"),
                          _vm._v(" Capacidade: " + _vm._s(item.capacity) + " ")
                        ])
                      ]
                    )
                  : _c("span", [_vm._v(_vm._s(item.name))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }