<template>
        <div>
            <v-progress-linear
                class="ma-1"
                v-if="loading"
                indeterminate
                color="yellow darken-2"
            />
            <v-text-field
                v-if="showSearch"
                v-model="searchInner"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                clearable
                dense
                class="pa-1"
            />
            <v-switch class="ml-3" inset :loading="loadingPendings" @change="changeOnlyPendinds()" v-model="onlyPendings" label="Pendentes"></v-switch>
            <v-treeview
                ref="tree"
                v-model="selection"
                :active.sync="active"
                :items="items"
                :load-children="fetchItems"
                :open.sync="open"
                activatable
                item-children="children"
                color="warning"
                :open-on-click="false"
                transition
                item-key="key"
                return-object
                @update:active="selectItem($event[0])"
                :selectable="selectable"
                dense
                :search="searchInner"
            >
                    <template v-slot:label="{ item }">
                    <draggable v-if="allowDrag && item.level >= 2" @start="start($event, item)" @end="end($event, item)">
                        <v-tooltip v-if="item.level == 2" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" style="cursor: pointer;" >{{item.name}}</span>
                                <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                            </template>
                            <span>{{ item.course.name }}</span>
                            <span v-if="item.time_course"><br/>Período: {{ item.time_course }}</span>
                        </v-tooltip>
                        <span style="cursor: pointer;" v-else>
                            <span v-if="item.level == 3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" style="cursor: pointer;" >{{ item.work_load_type.initials + ' - ' + item.name}}</span>
                                        <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                        <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                                    </template>
                                    <span>
                                        <span>{{ item.work_load_type.initials + ' - ' + item.name + ' (' + item.work_load + ')' }}</span>
                                        <span v-if="item.students_join">
                                            <br/>Alunos: {{ item.students_join }}
                                        </span>
                                        <span v-if="item.students_join_forecast">
                                            <br/>Previsão Alunos: {{ item.students_join_forecast }}
                                        </span>
                                        <span v-if="item.teacher">
                                            <br/>
                                            {{ item.teacher.name }}
                                        </span>
                                        
                                    </span>
                                </v-tooltip>
                            </span>
                            <span v-else>
                                <span>{{item.name}}</span>
                                <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                            </span>
                        </span>
                    </draggable>
                    <span v-else>
                        <v-tooltip v-if="item.level == 2" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="cursor: default;" >{{item.name}}</span>
                                    <span><v-icon v-if="item.is_complete" small color="green" btn>mdi-check-bold</v-icon></span>
                                    <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                                </template>
                                <span>{{ item.course.name }}</span>
                                <span v-if="item.time_course"><br/>Período: {{ item.time_course }}</span>
                        </v-tooltip>
                        <span v-else>
                            <span style="cursor: default;" >{{item.name}}</span>
                            <span><v-icon v-if="showPendencies && item.checked" small color="green" btn>mdi-check-bold</v-icon></span>
                        </span>
                        <emc-util-icon-category v-if="showCategory" :item="item" />
                    </span>
                </template>

            </v-treeview>
        </div>
   
</template>

<script>

import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
    name: "SysGroupTreeView",
    components: {
        draggable,
    },
    data: () => ({
      campuses: [],
      active: [],
      open: [],
      searchInner: '',
      selection: [],
      loading: true,
      onlyPendings: false,
      loadingPendings: false,
    }),
    computed: {
       
        items () {
            return this.campuses;
        },
      
    },

    created() {
      this.getCampuses();
    },
    
    watch: {
        
        search(val){
            
            this.searchInner = val;

            if(this.autoUpdateAll){
                if(val && val != ''){
                    this.$refs.tree.updateAll(true);
                }else{
                    this.$refs.tree.updateAll(false);
                }
            }
        },

        selection(val){
            this.$emit('update:selected', val.map(o => ({ id: o['id'], level: o['level'], key: o['key'] })))
        },

        pendencies(val){
            
            if(val != -1){
                
                if(this.active && this.active[0].level == 2){
                    this.active[0].is_complete = val <= 0;
                }
               
            }
        }
        
    },
    props: {
        
        model: {
            type: Boolean,
        },
        selectable:{
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        autoUpdateAll: {
            type: Boolean,
            default: false
        },
        showSearch: {
            type: Boolean,
            default: false
        },
        allowDrag: {
            type: Boolean,
            default: false
        },
        showGrids: {
            type: Boolean,
            default: false
        },
        pendencies: {
            type: Number,
            default: -1
        },
        showPendencies: {
            type: Boolean,
            default: false
        },
        showCategory: {
            type: Boolean,
            default: false
        },
        selected : { }
        
    },
    methods: {
        ...mapActions('campus', ['ActionFindCampusesFilterGroup']),
        ...mapActions('hour', ['ActionGetShiftsFilter']),
        ...mapActions('group', ['ActionFindGroups']),
        ...mapActions('grid', ['ActionFindGrids']),
        
        updatePendencies(){

            this.items.forEach(campus => {
                
                let countShifts = campus.children.length;
                let chechedsShifts = 0;

                campus.children.forEach(shift => {
                    
                    let countGroups = shift.children.length;
                    let checkedsGroups = 0;
                    
                    shift.children.forEach(group => {
                        if(group.is_complete){
                            checkedsGroups++;
                        }
                    });

                    shift.checked = countGroups == checkedsGroups;

                    if(shift.checked){
                        chechedsShifts++;
                    }

                });

                campus.checked = countShifts == chechedsShifts;

            });

        },

        changeOnlyPendinds(){

            this.loadingPendings = true;
            this.getCampuses();

        },
        
        async fetchItems(item) {
        
            switch (item.level) {
                case 0:
                    return await this.getShifts(item);
                case 1:
                    return await this.getGroups(item);
                case 2:
                    return await this.getGrids(item);
            }
        },
        
        getCampuses(){
    
            this.campuses = [];
            this.loading = true;

            var payload = {
                onlyPendings: this.onlyPendings
            };

            this.ActionFindCampusesFilterGroup(payload)
                .then((res) => {
                
                    res.data.forEach(campus => {
                        this.campuses.push({
                            id: campus.id,
                            key: campus.id,
                            level: 0,
                            name: campus.name,
                            checked: false,
                            children: []
                        })
                    });
                })
                .finally(()=>{
                    this.loading = false;
                    this.loadingPendings = false;
                });
        },

        async getShifts(campus) {
            
            var payload = {
                campus_id: campus.id,
                onlyPendings: this.onlyPendings
            };

            return this.ActionGetShiftsFilter(payload)
                .then((res) => {
                    res.data.forEach(shift => {
                        campus.children.push({
                            id: shift.id,
                            campus_id: campus.id,
                            key: campus.key + '|' + shift.id,
                            level: 1,
                            name: shift.name,
                            checked: false,
                            children: []
                        });
                    });
            })
        },

        async getGroups(shift){
      
            
            var payload = {
                per_page:   1000,
                column_order: 'name',
                // onlyPendings: this.onlyPendings,
                find_columns: {
                    campus_id: shift.campus_id,
                    shift_id: shift.id,
                    status_group_id: '1,2',
                },
                with: 'course,campus.hourGroups,category'
            }

            if(this.onlyPendings){
                payload.filter_columns = [
                    {
                        column: 'pendencies',
                        value: 0,
                        operator: 'greater'
                    }
                ];
            }

            return this.ActionFindGroups(payload)
                .then((res) => {
                    
                    res.data.forEach(group => {
                        let children = {
                            id:                 group.id,
                            key:                shift.key + '|' + group.id,
                            level:              2,
                            name:               group.name,
                            shift_id:           group.shift_id,
                            pendencies:         this.showPendencies ? group.pendencies : null,
                            checked:            this.showPendencies && group.pendencies == 0,
                            classes:            [],
                            allocations:        [],
                            course:             group.course,
                            has_message:        group.has_message,
                            validated_time:     group.validated_time,
                            hour_group_id:      this.getHourGroupID(group),
                            students:           group.students,
                            students_forecast:  group.students_forecast,
                            time_course:        group.time_course,
                            time_shift_id:      group.time_shift_id,
                            category:           group.category,
                        };

                         if(this.showGrids){
                             children.children = [];
                         }

                        shift.children.push(children);
                    })
                    
                })
                .finally(() => {
                    this.loadingPendings = false;
                });
        },

        async getGrids(group, selectedItem = false){
      
            this.loading = true;
            
            var payload = {
                withStudentsJoin: true,
                per_page:   1000,
                find_columns: {
                    group_id: group.id,
                },
                with: 'discipline,workLoadType,teacher,classes.ambience.block'
            }

            return this.ActionFindGrids(payload)
                .then((res) => {
                    
                    let classes = [];
                    let allocations = [];
                    let grids = [];

                    res.data.forEach(grid => {
                        
                        grid.classes.forEach(c => {
                            c.grid = grid;
                            c.grid.group = group;
                            classes.push(c);
                        });

                        grids.push({
                            id:                     grid.id,
                            key:                    grid.id,
                            level:                  3,
                            name:                   grid.discipline_name,
                            work_load_type:         grid.work_load_type,
                            work_load:              grid.work_load,
                            students:               grid.students,
                            students_forecast:      grid.students_forecast,
                            students_join:          grid.students_join,
                            students_join_forecast: grid.students_join_forecast,
                            teacher:                grid.teacher,
                            group_number:           grid.group_number,
                            teacher_number:         grid.teacher_number,
                            group:                  group,
                            classes:                grid.classes,
                        });

                    });

                    group.children      = grids;
                    group.classes       = classes;
                    group.allocations   = allocations;

                    if(selectedItem){
                        this.$emit('select', group);
                    }
                    
                }).finally(()=>{
                    this.loading = false;
                });
        },

        getHourGroupID(group){

            if(group.hour_group_id){
                return group.hour_group_id;
            }else{
                
                if(group.category_group_id){

                   var hourGroup = group.campus.hour_groups.find(g => g.category_group_id == group.category_group_id);
                   
                   if(hourGroup){
                        return hourGroup.hour_group_id;
                   }

                }
                
                return group.campus.hour_group_id;
            }

        },

        selectItem(item){
            
            if(item && item.level >= 2){
                
                if(item.level == 2){
                    
                    if(this.showGrids){
                        this.getGrids(item, true);
                    }else{
                        this.$emit('select', item);
                    }

                }else if(item.level == 3){
                    this.$emit('select', item.group);
                }
            }

        },

        start(event, item){
            
            let classes = [];

            if(item.classes){
                item.classes.forEach(c => {
                    classes.push(c.id);
                });
            }

            this.$emit('start', classes);
            
        },

        end(event, item){
            
            let classes = [];

            if(item.classes){
                item.classes.forEach(c => {
                    classes.push(c.id);
                });
            }
            
            this.$emit('end',  classes);
        }
        
    }
}
</script>