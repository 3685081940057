<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-tag" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <sys-select-course
                                    :model.sync="model.course_id"
                                    :error="errors.course_id && errors.length > 0"  
                                    :errors="errors.course_id"
                                    label="Curso*"
                                    autoComplete
                                />
                        </v-col>
                         <v-col cols="12" sm="4">
                            <sys-select-shift
                                    :model.sync="model.shift_id"
                                    :error="errors.shift_id && errors.length > 0"  
                                    :errors="errors.shift_id"
                                    label="Turno*"
                                />
                        </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-curriculum
                                        :model.sync="model.curriculum_id"
                                        :error="errors.curriculum_id && errors.length > 0"  
                                        :errors="errors.curriculum_id"
                                        label="Matriz"
                                        :courseId="model.course_id"
                                        :disabled="model.course_id == null"
                                        clearable
                                    />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-campus
                                        :model.sync="model.campus_id"
                                        :error="errors.campus_id && errors.length > 0"  
                                        :errors="errors.campus_id"
                                        label="Campus*"
                                    />
                            </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" sm="6">
                            <v-text-field dense autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.students" :error-messages="errors.students" v-model="model.students" label="Alunos"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.year" :error-messages="errors.year" v-model="model.year" label="Ano*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.period" :error-messages="errors.period" v-model="model.period" label="Período*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="model.note"
                                outlined
                                name="input-7-4"
                                label="Anotações"
                                :value="model.note"
                                :error="errors.length > 0"  
                                :error-messages="errors.note"
                                rows="2"
                                row-height="20"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-switch inset v-model="model.active" label="Turma Ativa"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>

        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterBaseGroup",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        headers: [
            { text: 'Disciplina', value: 'name', sortable: false},
            { text: 'Carga Horária', value: 'work_load', sortable: false},
            { text: 'Ações', value: 'actions', sortable: false}
    ]
        
    }),
    created(){
      
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = true;
                }
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-tag"
        },
        title: {
            type: String,
            default: "Turma Base"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('baseGroup', ['ActionUpdateBaseGroup', 'ActionCreateBaseGroup']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            if(edit) {
                this.ActionUpdateBaseGroup(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateBaseGroup(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>