var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pb-2 pa-0 mt-2",
      attrs: { outlined: "", "max-height": "350" }
    },
    [
      _c(
        "v-app-bar",
        { staticClass: "elevation-0", attrs: { dense: "" } },
        [
          _c("v-toolbar-title", { staticClass: "text-body-2" }, [
            _c("strong", [_vm._v("Legenda")])
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mb-2" }),
      _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "pb-2 pl-1 pr-0 grey--text text--darken-1",
                              staticStyle: { cursor: "help" }
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-checkbox-marked-circle")
                          ]),
                          _vm._v("Horário da Turma")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Horário da Turma")])]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "pb-2 pl-1 pr-0 grey--text text--darken-1",
                              staticStyle: { cursor: "help" }
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-checkbox-marked-circle")
                          ]),
                          _vm._v("Horário do Ambiente")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(" Horário do Ambiente ")])]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "pb-2 pl-1 pr-0 grey--text text--darken-1",
                              staticStyle: { cursor: "help" }
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-checkbox-marked-circle")
                          ]),
                          _vm._v("Choque de Ambiente")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(" Horário disponível pelo ambiente ")])]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "pb-2 pl-1 pr-0 grey--text text--darken-1",
                              staticStyle: { cursor: "help" }
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-checkbox-marked-circle")
                          ]),
                          _vm._v("Horário Disponível")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(" Horário ocupado pelo ambiente ")])]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "span",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass:
                                "pb-2 pl-1 pr-0 grey--text text--darken-1",
                              staticStyle: { cursor: "help" }
                            },
                            "span",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-checkbox-marked-circle")
                          ]),
                          _vm._v("Já alocado")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(" Disciplina já alocada no ambiente ")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }