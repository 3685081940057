var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.modelBase,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "98%",
      actions: false
    },
    on: {
      "update:model": function($event) {
        _vm.modelBase = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "tools",
        fn: function() {
          return undefined
        },
        proxy: true
      },
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "main-container",
              {
                attrs: {
                  showToolBar: false,
                  icon: "mdi-account-cash",
                  title: "Folha de Pagamento"
                }
              },
              [
                _c("emc-table-items", {
                  attrs: {
                    headers: _vm.headers,
                    params: _vm.params,
                    collections: _vm.collections,
                    meta: _vm.meta,
                    itemDelete: _vm.itemDelete,
                    itemEdit: _vm.modelItem,
                    loading: _vm.loading,
                    find: false,
                    showDelete: _vm.showDeleteButton,
                    hideEdit: _vm.hideEdit
                  },
                  on: {
                    "update:params": function($event) {
                      _vm.params = $event
                    },
                    "update:collections": function($event) {
                      _vm.collections = $event
                    },
                    "update:meta": function($event) {
                      _vm.meta = $event
                    },
                    "update:itemDelete": function($event) {
                      _vm.itemDelete = $event
                    },
                    "update:item-delete": function($event) {
                      _vm.itemDelete = $event
                    },
                    "update:itemEdit": function($event) {
                      _vm.modelItem = $event
                    },
                    "update:item-edit": function($event) {
                      _vm.modelItem = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.month",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(item.month) +
                              " / " +
                              _vm._s(item.year) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("emc-table-icon-edit", {
                            attrs: {
                              color: "success",
                              icon: "mdi-calendar-account",
                              "tool-tip": "Ver Marcações"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getSheetItems(item)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("emc-alert-modal", {
                  attrs: {
                    text: "Deseja excluir o item?",
                    disabled: _vm.deleting,
                    show: _vm.showDelete
                  },
                  on: {
                    "update:show": function($event) {
                      _vm.showDelete = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                loading: _vm.deleting,
                                disabled: _vm.deleting
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.deleteItem()
                                }
                              }
                            },
                            [
                              _vm._v("Excluir "),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v("mdi-delete")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            }),
            _c("sys-occurrence-sheet-item-register", {
              attrs: {
                model: _vm.modelItem,
                show: _vm.showFormItems,
                hideDelete: "",
                showEditMark: ""
              },
              on: {
                onCompleted: function($event) {
                  return _vm.getData()
                },
                "update:show": function($event) {
                  _vm.showFormItems = $event
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }