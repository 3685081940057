var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", width: _vm.width, persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { class: _vm.color, attrs: { dark: "" } },
            [
              _c("v-icon", { attrs: { color: _vm.colorIcon, left: "" } }, [
                _vm._v(" " + _vm._s(_vm.icon) + " ")
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.classTitle }, [_vm._v(_vm._s(_vm.title))])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [_vm._v(" " + _vm._s(_vm.text) + " "), _vm._t("text")],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _vm._t("actions-prepend"),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.disabled },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setCancel($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.textCancel))]
              ),
              _vm._t("actions")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }