import { service } from '../../../helpers/http'

export const ActionFindCharts = () => {

    return service({
        module: 'chart',
        action: 'chartFind'
    })

}

export const ActionChartEfficiencyOfWorkload = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'chartEfficiencyOfWorkload',
        payload: payload
    })

}

export const ActionChartEvolutionOfWorkload = () => {

    return service({
        module: 'chart',
        action: 'chartEvolutionOfWorkload'
    })

}

export const ActionChartGroupNumber = () => {

    return service({
        module: 'chart',
        action: 'chartGroupNumbers'
    })

}

export const ActionChartTeacherNumber = () => {

    return service({
        module: 'chart',
        action: 'chartTeacherNumbers'
    })

}

export const ActionChartTeacherWorkLoadAvg = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'chartTeacherWorkLoadAvg',
        payload: payload,
    })

}

export const ActionChartAmbienceNumber = () => {

    return service({
        module: 'chart',
        action: 'chartAmbienceNumbers'
    })

}

export const ActionChartGridInHiringNumber = () => {

    return service({
        module: 'chart',
        action: 'chartGridInHiringNumbers'
    })

}

export const ActionChartTeacherTitlings = () => {

    return service({
        module: 'chart',
        action: 'chartTeacherTitlings'
    })

}

export const ActionChartBudgetClass = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'budgetClass',
        payload: payload,
    })

}

export const ActionChartBudgetActivity = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'budgetActivity',
        payload: payload,
    })
}

export const ActionChartBudgetGeneral = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'budgetGeneral',
        payload: payload,

    })
}

export const ActionChartTeacherBalance = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'teacherBalance',
        payload: payload,
    })
}

export const ActionIndexChartsOfferItems = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'indexChartsOfferItems',
        payload: payload,
    })
}

export const ActionChartEfficiencyClassWorkLoad = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'chartEfficiencyClassWorkLoad',
        payload: payload,
    })

}

export const ActionChartPerformanceStudents = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'chartPerformanceStudents',
        payload: payload,
    })

}

export const ActionChartActivityWorkLoadAvg = ({ dispatch }, payload) => {

    return service({
        module: 'chart',
        action: 'chartActivityWorkLoadAvg',
        payload: payload,
    })

}