var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "8" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.name,
                        label: "Nome*"
                      },
                      model: {
                        value: _vm.model.name,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "name", $$v)
                        },
                        expression: "model.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "4" } },
                  [
                    _c("SysSelectPlanType", {
                      attrs: {
                        model: _vm.model.plan_type_id,
                        error: _vm.errors.length > 0,
                        errors: _vm.errors.plan_type_id
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(_vm.model, "plan_type_id", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "mask-decimal",
                          rawName: "v-mask-decimal.br",
                          value: 2,
                          expression: "2",
                          modifiers: { br: true }
                        }
                      ],
                      attrs: {
                        dense: "",
                        error: _vm.errors.length > 0,
                        "error-messages": _vm.errors.price,
                        label: "Preço*",
                        maxlength: "6"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend-inner",
                          fn: function() {
                            return [_c("strong", [_vm._v("R$")])]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.model.price,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "price", $$v)
                        },
                        expression: "model.price"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-textarea", {
                      attrs: { label: "Descrição" },
                      model: {
                        value: _vm.model.description,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "description", $$v)
                        },
                        expression: "model.description"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c(
                          "v-app-bar",
                          { attrs: { dense: "", elevation: "0" } },
                          [
                            _c(
                              "v-toolbar-title",
                              { staticClass: "text-subtitle-1" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-layers-triple")]
                                ),
                                _vm._v(" Módulos")
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c("v-switch", {
                              attrs: {
                                label: "Todos",
                                "hide-details": "",
                                inset: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.selectAll,
                                callback: function($$v) {
                                  _vm.selectAll = $$v
                                },
                                expression: "selectAll"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          [
                            _vm.errors.modules
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "text-center caption",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      color: "red"
                                    }
                                  },
                                  _vm._l(_vm.errors.modules, function(
                                    message,
                                    i
                                  ) {
                                    return _c("div", { key: i }, [
                                      _vm._v(_vm._s(message))
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  _vm._l(_vm.modules, function(module, i) {
                                    return _c(
                                      "v-col",
                                      { key: i, attrs: { cols: "auto" } },
                                      [
                                        _c("v-switch", {
                                          attrs: {
                                            label: module.name,
                                            value: module,
                                            "hide-details": "",
                                            inset: "",
                                            dense: ""
                                          },
                                          model: {
                                            value: _vm.model.modules,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "modules",
                                                $$v
                                              )
                                            },
                                            expression: "model.modules"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c(
                          "v-app-bar",
                          { attrs: { elevation: "0" } },
                          [
                            _c(
                              "v-toolbar-title",
                              { staticClass: "text-subtitle-1" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-1", attrs: { small: "" } },
                                  [_vm._v("mdi-cash-multiple")]
                                ),
                                _vm._v(" Tipos de cobrança")
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "5" } },
                              [
                                _c("SysSelectChargeType", {
                                  attrs: {
                                    model: _vm.chargeTypeId,
                                    hint: "Selecione o tipo de cobrança"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.chargeTypeId = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-card-text", [
                          _vm.model.charge_types.length === 0
                            ? _c(
                                "div",
                                { staticClass: "text-caption text-center" },
                                [_vm._v("A cobrança padrão será por turma.")]
                              )
                            : _c(
                                "div",
                                [
                                  _c("v-simple-table", {
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function() {
                                          return [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Tipo")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Preço")]
                                                ),
                                                _c("th")
                                              ])
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.model.charge_types,
                                                function(charge, i) {
                                                  return _c("tr", { key: i }, [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(charge.type.name)
                                                      )
                                                    ]),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("v-text-field", {
                                                          directives: [
                                                            {
                                                              name:
                                                                "mask-decimal",
                                                              rawName:
                                                                "v-mask-decimal.br",
                                                              value: 2,
                                                              expression: "2",
                                                              modifiers: {
                                                                br: true
                                                              }
                                                            }
                                                          ],
                                                          attrs: {
                                                            dense: "",
                                                            error:
                                                              _vm.errors
                                                                .length > 0,
                                                            "error-messages":
                                                              _vm.errors.price,
                                                            maxlength: "6"
                                                          },
                                                          model: {
                                                            value: charge.price,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                charge,
                                                                "price",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "charge.price"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.deleteChargePlan(
                                                                  charge
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color: "red"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12" } },
                  [
                    _c("v-switch", {
                      attrs: {
                        color: "primary",
                        label: "Ativo",
                        inset: "",
                        dense: ""
                      },
                      model: {
                        value: _vm.model.active,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "active", $$v)
                        },
                        expression: "model.active"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }