var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { color: "indigo darken-1", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:show", true)
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(" Novo Cadastro ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }