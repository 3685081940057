var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-warehouse",
      show: _vm.dialog,
      maxWidth: "50%",
      disabledSave: _vm.tab == 1
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-tabs",
              {
                staticClass: "ma-0",
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                _c(
                  "v-tab",
                  { key: "address", attrs: { disabled: !_vm.model.id } },
                  [_vm._v("Endereço")]
                ),
                _c("v-tab", { key: "hourGroups" }, [
                  _vm._v("Grupos de Horário")
                ]),
                _c("v-tab", { key: "others" }, [_vm._v("Demais Dados")])
              ],
              1
            ),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "main" },
                  [
                    _c(
                      "v-container",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    code: "",
                                    dense: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "9" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.name,
                                    label: "Nome do Campus*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.initials,
                                    label: "Sigla do Campus*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.initials,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "initials", $$v)
                                    },
                                    expression: "model.initials"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "9" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.abbreviation,
                                    label: "Abreviação*",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.abbreviation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "abbreviation", $$v)
                                    },
                                    expression: "model.abbreviation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Se essa oprção for marcada, o campus NÃO será considerado na validação de deslocamento na elaboração do horário",
                                    inset: "",
                                    label: "Campus Virtual"
                                  },
                                  model: {
                                    value: _vm.model.is_virtual,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "is_virtual", $$v)
                                    },
                                    expression: "model.is_virtual"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Se essa estiver ativada, os items serão integrados com outros sistemas",
                                    inset: "",
                                    label: "Permitir Integração"
                                  },
                                  model: {
                                    value: _vm.model.allow_integration,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "allow_integration",
                                        $$v
                                      )
                                    },
                                    expression: "model.allow_integration"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: { inset: "", label: "Ativo" },
                                  model: {
                                    value: _vm.model.active,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "active", $$v)
                                    },
                                    expression: "model.active"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "address" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-address-register", {
                                  attrs: { model: _vm.model, type: "campus" },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.model = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "hourGroups" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.itemHourGroups,
                                    "hide-default-footer": true,
                                    "items-per-page": -1
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "top",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "indigo darken-1",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.addItem()
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.hour_group_id",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("sys-select-hour-group", {
                                            attrs: {
                                              model: item.hour_group_id,
                                              error: _vm.errors.length > 0,
                                              errors:
                                                _vm.errors[
                                                  "hourGroups." +
                                                    index +
                                                    ".hour_group_id"
                                                ],
                                              label: "",
                                              dense: ""
                                            },
                                            on: {
                                              "update:model": function($event) {
                                                return _vm.$set(
                                                  item,
                                                  "hour_group_id",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.category_group_id",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("sys-select-category-group", {
                                            attrs: {
                                              model: item.category_group_id,
                                              error: _vm.errors.length > 0,
                                              errors:
                                                _vm.errors[
                                                  "hourGroups." +
                                                    index +
                                                    ".category_group_id"
                                                ],
                                              label: "",
                                              dense: "",
                                              clearable: ""
                                            },
                                            on: {
                                              "update:model": function($event) {
                                                return _vm.$set(
                                                  item,
                                                  "category_group_id",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.actions",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                dense: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.deleteItem(item)
                                                }
                                              }
                                            },
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-hour-group", {
                                      attrs: {
                                        model: _vm.model.hour_group_id,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.hour_group_id,
                                        label: "Grupo de Horário*",
                                        required: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "hour_group_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-tab-item",
                  { key: "others" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-institution", {
                                  attrs: {
                                    model: _vm.model.institution_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.institution_id,
                                    label: "Instituição",
                                    "auto-complete": "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "institution_id",
                                        $event
                                      )
                                    },
                                    change: _vm.changeIes
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-company", {
                                  attrs: {
                                    model: _vm.model.company_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.company_id,
                                    label: "Empresa",
                                    "auto-complete": "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "company_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-branch", {
                                  attrs: {
                                    model: _vm.model.branches,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.branches,
                                    label: "Filial",
                                    multiple: "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "branches",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.company_name,
                                    label: "Razão Social",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.company_name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "company_name", $$v)
                                    },
                                    expression: "model.company_name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-campus", {
                                  attrs: {
                                    model: _vm.model.campus_id_parent,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.campus_id_parent,
                                    label: "Campus Gerencial",
                                    clearable: "",
                                    "auto-complete": "",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "campus_id_parent",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask-cnpj",
                                      rawName: "v-mask-cnpj"
                                    }
                                  ],
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.cnpj,
                                    label: "Cnpj",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.cnpj,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "cnpj", $$v)
                                    },
                                    expression: "model.cnpj"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.business_unit,
                                    label: "Unidade Negócio"
                                  },
                                  model: {
                                    value: _vm.model.business_unit,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "business_unit", $$v)
                                    },
                                    expression: "model.business_unit"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }