var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("EmcChartLoading") : _vm._e(),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "pa-2"
        },
        [
          _c("canvas", {
            attrs: { id: _vm.name, width: _vm.width, height: _vm.height }
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.showMoreButton
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { color: "primary", icon: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "showMore",
                                              _vm.item
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { btn: "" } }, [
                                      _vm._v("mdi-monitor-eye")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4053892403
                      )
                    },
                    [_c("span", [_vm._v(" Ver Mais... ")])]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }