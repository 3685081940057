<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-format-wrap-square" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field :error="!!errors.initials" :error-messages="errors.initials" v-model="model.initials" label="Abreviação*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="model.description" 
                                label="Descrição"
                                outlined
                                clearable
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Ambiente Virtual" inset v-model="model.is_virtual" label="Ambiente Virtual"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Necessita vincular ambiente" inset v-model="model.allow_ambience_link" label="Vincular Ambiente"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Permite Agendamento Externo" inset v-model="model.allow_external_allocation" label="Agendamento Externo"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Tipo de Ambiente Padrão" inset v-model="model.is_default" label="Padrão"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch inset v-model="model.active" label="Ativo"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterAmbienceType",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.allow_external_allocation = 1;
                    this.model.active = 1;
                }
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Tipo de Ambiente"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('ambienceType', ['ActionUpdateAmbienceType', 'ActionCreateAmbienceType']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateAmbienceType(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateAmbienceType(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>