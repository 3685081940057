<template>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        width="350"
    >
        <v-card
            class="mx-auto elevation-0"
            height="100%"
        >
            <v-app-bar
                v-if="showActions"
                prominent
                height="40px"
            >
            
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon @click.prevent.stop="$emit('open-dialog-new')">
                        <v-icon btn color="green darken-1">mdi-plus</v-icon>
                    </v-btn>
                </template>
                    <span>
                    Novo
                </span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="disabledEdit" v-bind="attrs" v-on="on" small icon @click.prevent.stop="openDialog(selecteItem)">
                        <v-icon btn color="purple darken-1">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                    <span>
                    Editar
                </span>
            </v-tooltip>

            </v-app-bar>

            <sys-group-tree-view-single ref="treeView" v-if="treeViewSingle" autofocus showFilterPendencies showPendencies :pendencies="pendencies" @select="selectItem($event)" />
            <sys-group-tree-view v-else ref="treeView" autofocus showFilterPendencies showPendencies :pendencies="pendencies" @select="selectItem($event)" />
            
        </v-card>
    </v-navigation-drawer>
   
</template>

<style scoped>
.v-text-field{
    width: 200px;
}
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleDrawer",
    data: () => ({
      componentKey: "componentKey",
      render: true,
      drawer: false,
      selected: [],
      disabledEdit: true,
      selecteItem: null,
      active: [],
      options: {
          selectable: false,
          dense: true,
          activatable: true,
          hoverable: false,
          openOnClick: false,
          expandOnHover: true,
          miniVariant: false,
          shaped: false,
          rounded: true,
          color: 'primary',
          selectedColor: 'accent',
          selectedColors: [
            'accent',
            'teal',
            'red',
            'success',
            'warning lighten-2',
          ],
      },
      
    }),
        
    watch: {
        
        model(model_new, model_old) {
            
            if(model_new !== model_old) {
                this.drawer = model_new;
            }

            if(model_new){
                this.componentKey = "componentKey";
            }else{
                this.componentKey = "";
            }
        },

        drawer(drawer_new, drawer_old) {
            
            if(drawer_new !== drawer_old) {
                this.$emit('update:model', drawer_new);
            }

            if(drawer_new){
                this.$refs.treeView.setFocus();
            }
        },

    },
    props: {
        model: {
            type: Boolean,
        },
        items: {
          type: Array,
          required: true
        },
        showActions: {
          type: Boolean,
          default: false
        },
        pendencies: {
            type: Number,   
            default: -1
        },
        treeViewSingle: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      
    
        selectItem(item){
          
          this.disabledEdit = true;
          this.selecteItem = item;

          let level = this.treeViewSingle ? 2 : 3;
          
          if(item && item.level == level){
            this.disabledEdit = false;
            this.$emit('on-select', item);
          }
      },

      openDialog(item){
          
          if(item && item.level == 2){
            this.$emit('on-dblclick', item);
          }
      },

    }
}
</script>