var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.tag,
                            "error-messages": _vm.errors.tag,
                            label: "Tag*"
                          },
                          model: {
                            value: _vm.model.tag,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "tag", $$v)
                            },
                            expression: "model.tag"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.field,
                            "error-messages": _vm.errors.field,
                            label: "Campo*"
                          },
                          model: {
                            value: _vm.model.field,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "field", $$v)
                            },
                            expression: "model.field"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.model,
                            "error-messages": _vm.errors.model,
                            label: "Model*"
                          },
                          model: {
                            value: _vm.model.model,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "model", $$v)
                            },
                            expression: "model.model"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.type,
                            "error-messages": _vm.errors.type,
                            label: "Tipo"
                          },
                          model: {
                            value: _vm.model.type,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "type", $$v)
                            },
                            expression: "model.type"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.format,
                            "error-messages": _vm.errors.format,
                            label: "Formato"
                          },
                          model: {
                            value: _vm.model.format,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "format", $$v)
                            },
                            expression: "model.format"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição",
                            counter: 255,
                            rows: "2",
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-switch", {
                          attrs: { color: "purple", inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }