var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "offset-y": "",
        origin: "top right",
        transition: "scale-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var attrs = ref.attrs
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2",
                      attrs: { "min-width": "0", icon: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: _vm.color,
                        overlap: "",
                        value: _vm.messages.filter(function(m) {
                          return !m.read
                        }).length,
                        content: _vm.messages.filter(function(m) {
                          return !m.read
                        }).length
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.selectedItem,
                callback: function($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            },
            _vm._l(_vm.messages, function(item, i) {
              return _c(
                "v-list-item",
                { key: i },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", [
                        _vm._v(_vm._s(item.read ? _vm.iconRead : _vm.icon))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        class: { "font-weight-bold": !item.read },
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        right: "",
                        small: "",
                        text: ""
                      }
                    },
                    [_vm._v(" Ver todos ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }