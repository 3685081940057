
import { mapActions } from 'vuex'

export default {
  data: () => ({
    snackbar: false,
    loading: false,
    dialog: false,
    tab: 'login',
    type: 'error',
    message: null,
    disabled: false,
    token: null,
    errors: [],
    showCamera: false,
    show: false,
    model: {
      mode:         null,
      email:      'edmir.m.carvalho@gmail.com',
      password:   '123456',
      number_cpf: '226.819.328-40',
      birth_date: '1983-11-03',
      mobile_phone: '(13) 9 8806-9909'
    },
    messages: {
      login: 'Acesso direto com login e senha',
      email: 'Informe seu e-mail cadastrado para receber link de acesso no seu email cadastrado',
      data: 'Informe seu dados para receber o link de acesso no seu email cadastrado',
      phone: 'Informe seu telefone para receber link de acesso no seu email cadastrado',
      photo: 'Envie uma foto para receber o link de acesso no seu email cadastrado',
    },
    selectedItem: -1,
    items: [],
  }),
  created (){
      
  },
  watch: {
      
      
  },
  methods: {
    
    ...mapActions('auth', ['ActionDoLoginTeacher']),
    
    completeCapture(file){
      let form = new FormData();
      form.append('photo', file);
      this.access(form);
    },

    access(form = null){
      
      this.disabled = true;
      this.loading = true;
      this.errors = [];

      let payload;

      if(form){
        form.append('mode', this.tab);
        payload = form;
      }else{
        this.model.mode = this.tab;
        payload = this.model;
      }

      this.ActionDoLoginTeacher(payload).then((res) => {
            
            if(this.tab == 'login'){
              this.$router.push({ path: '/teacher/page' });
              
            }else{
              this.type = 'success';
              this.message = res.message;
            }

          })
          .catch((error) =>{
              this.message = error.message;
              this.errors = error.errors ? error.errors : [];
              this.type = 'error';
          }).finally(() =>{
              this.snackbar = true;
              this.loading = false;
              this.disabled = false;
          });
    },

  },
}