export default [
    {
        path: '/offer-item-emphasis',
        name: 'offer-item-emphasis',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Ênfases de Oferta',
            icon: 'mdi-format-list-checks',
            breadcrumbs: [
                { text: 'Ênfases de Oferta', disabled: true }
            ]
        }
    }
]