import { service } from '../../../../helpers/http'

export const ActionShowTimeSheet = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'showTimeSheet',
        payload: payload
    })

}