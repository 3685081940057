<template>
    <emc-dialog-default :show.sync="dialog" title="Lista de cores" icon="mdi-format-color-fill">
        <template v-slot:text>

            <v-row class="my-2">
                <v-col
                    v-for="(color, index) in colors"
                    :key="index"
                    class="d-flex child-flex"
                    cols="4">

                    <v-row>
                        <v-col cols="12">
                            <v-card tile :color="color.base">
                                <v-card-title>
                                    {{ index }}
                                </v-card-title>
                            </v-card>

                            <v-card tile :color="item" v-for="(item, i) in color" :key="i" @click="setSelected(index, i)">
                                <v-card-actions>
                                    <v-row class="text-caption pa-4">
                                        <v-col cols="6">
                                            {{ i }}
                                        </v-col>
                                        <v-col cols="6" class="text-right">
                                            <v-spacer></v-spacer>{{ item }}
                                        </v-col>
                                        </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </template>
    </emc-dialog-default>
</template>

<script>

import colors from 'vuetify/lib/util/colors';

export default {
    name: 'EmcDialogColors',
    data() {
        return {
            colors: colors,
            labels: {
                red: "Vermelho",
                pink: "Rosa",
                purple: "Roxo",
                deepPurple: "Roxo escuro",
                indigo: "Azul escuro"
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            required: false
        }
    },
    computed: {
        dialog: {
            get() {
              return this.show  
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        selected: {
            get() {
                return this.color
            },
            set(value) {
                this.$emit('update:color', value)
                this.dialog = false
            }
        }
    },
    methods: {
        setSelected(base, child) {

            if(child === 'base') {
                this.selected = this.camelCaseToSlug(base)
                return;
            }                

            let baseStr = this.camelCaseToSlug(base)
            let childStr = this.camelCaseToSlug(child)

            let color = baseStr + ' ' + childStr

            this.selected = color
        },
        
        camelCaseToSlug(camelCaseString) {
            //Dividir as palavras usando regex
            const words = camelCaseString.replace(/([a-z])([A-Z0-9])/g, "$1 $2"); 

            //Transformar para letras minúsculas e substituir espaços por hífens
            const slug = words.toLowerCase().split(" ").join("-");

            return slug;
        }
    }
}
</script>