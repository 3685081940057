import { default as region } from './region/services'
import { default as departament } from './departament/services'
import { default as establishment } from './establishment/services'
import { default as partnerCompany } from './partner_company/services'
import { default as setting } from './setting/services'

export default {
    ...region,
    ...departament,
    ...establishment,
    ...partnerCompany,
    ...setting,
    findInstitutions:           { method: 'get',    url: 'institutions?page={page}&per_page={per_page}' },
    deleteInstitution:          { method: 'delete', url: 'institutions/{id}'},
    updateInstitution:          { method: 'put',    url: 'institutions/{id}'},
    createInstitution:          { method: 'post',   url: 'institutions'},
    index:                      { method: 'get',    url: 'institutions' },
    uploadCompanyLogo:          { method: 'post',   url: 'institutions/upload/avatar' },
    indexAllInstitutions:       { method: 'get',    url: 'institutions/all' },
    showInstitution:            { method: 'get',    url: 'institutions/{id}' },
    updateIntegrationParameter: { method: 'put',    url: 'integration-parameters/{id}' },
    storeIntegrationParameter:  { method: 'post',   url: 'integration-parameters'},
    destroyIntegrationParameter: { method: 'delete',    url: 'integration-parameters/{id}' },
}