var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Campus")]),
                        _c("th", [_vm._v("Mês")]),
                        _c("th", [_vm._v("Espelho")])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [_vm._v(_vm._s(item.campus.name))]),
                          _c("td", [_vm._v(_vm._s(item.month))]),
                          _c(
                            "td",
                            [
                              _c("emc-table-icon-download-pdf", {
                                attrs: {
                                  toolTip: "Espelho de Ponto",
                                  route:
                                    "pdf/export/" +
                                    _vm.token +
                                    "/" +
                                    item.id +
                                    "/time_sheet",
                                  item: item
                                }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }