var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("emc-table-icon", {
        attrs: {
          "cancel-auto-small": !_vm.autoSmall,
          loading: _vm.loading,
          disabled: _vm.loading,
          color: _vm.color,
          small: _vm.small,
          icon: _vm.icon,
          "tool-tip": _vm.toolTip
        },
        on: {
          click: function($event) {
            return _vm.clickAction()
          }
        }
      }),
      _c("emc-excel-user-download-filter", {
        attrs: {
          "report-id": _vm.report,
          show: _vm.showFilter,
          showFilterPaymentExport: _vm.showFilterPaymentExport
        },
        on: {
          onCompleted: function($event) {
            return _vm.exportReport($event)
          },
          "update:show": function($event) {
            _vm.showFilter = $event
          }
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width":
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? "90%"
                : "40%",
            persistent: ""
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "grey lighten-5 mb-4 elevation-1'",
                      attrs: { dense: "", height: "45" }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-download-outline")
                      ]),
                      _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                        _vm._v(" Downloads ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialog = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", { staticClass: "text-center text-h6 text--primary" }, [
                  _vm._v(" " + _vm._s(_vm.message) + " "),
                  _c("br"),
                  _c("br"),
                  _vm._v(" Deseja gerar um novo relatório? ")
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "ligth green" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.downloadAllowLast()
                        }
                      }
                    },
                    [_vm._v("Sim - Gerar Novo")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Não - Aproveitar Disponível")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width":
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? "90%"
                : "40%",
            persistent: ""
          },
          model: {
            value: _vm.dialogMessage,
            callback: function($$v) {
              _vm.dialogMessage = $$v
            },
            expression: "dialogMessage"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "grey lighten-5 mb-4 elevation-1'",
                      attrs: { dense: "", height: "45" }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-file-download-outline")
                      ]),
                      _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                        _vm._v(" Downloads ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { icon: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogMessage = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", { staticClass: "text-center text-h6 text--primary" }, [
                  _vm._v(
                    " Relatório sendo gerado e em breve estará disponível para download! "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary green",
                        to: "user-downloads",
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v("Ir para Downloads"),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("mdi-page-next-outline")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.dialogMessage = false
                        }
                      }
                    },
                    [_vm._v("Fechar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }