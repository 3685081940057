import { service } from '../../../../helpers/http'

export const ActionIndexGeneralSettings = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'indexGeneralSettings',
        payload: payload
    });
}

export const ActionShowGeneralSetting = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'showGeneralSetting',
        payload: payload
    });
}

export const ActionCreateGeneralSetting = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'createGeneralSetting',
        payload: payload
    });
}

export const ActionUpdateGeneralSetting = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'updateGeneralSetting',
        payload: payload
    });
}

export const ActionDestroyGeneralSetting = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'destroyGeneralSetting',
        payload: payload
    });
}
