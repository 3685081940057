var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "emc-card-stats",
    {
      attrs: {
        icon: "mdi-account-clock-outline",
        color: "blue accent-1",
        "sheet-width": "50",
        "sheet-heigth": "50",
        "icon-class": "px-3 py-1",
        subtitle: "Disponibilidade",
        "sub-icon": "mdi-clock-outline",
        "sub-text": _vm.limited,
        tools: ""
      }
    },
    [
      _c(
        "template",
        { slot: "tools" },
        [
          _c("emc-button-icon", {
            attrs: {
              loading: _vm.loading,
              icon: "mdi-refresh-circle",
              text: "Atualizar",
              color: "success"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.refresh()
              }
            }
          }),
          !_vm.hideTools
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "indigo darken-1",
                                      icon: "",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.download(1)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1225084573
                  )
                },
                [_c("span", [_vm._v("Resumido")])]
              )
            : _vm._e(),
          !_vm.hideTools
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "green darken-1",
                                      icon: "",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.download(10)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-microsoft-excel")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    29573620
                  )
                },
                [_c("span", [_vm._v("Detalhado")])]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 360,
                size: 140,
                width: 15,
                value:
                  _vm.availability && _vm.availability.percent
                    ? _vm.availability.percent
                    : 0,
                color: "blue accent-1"
              }
            },
            [
              [
                _c("span", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.availability && _vm.availability.percent
                          ? _vm.availability.percent
                          : 0
                      ) + "%"
                    )
                  ]),
                  _c("br"),
                  _vm.availability && _vm.availability.total
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.availability.completed) +
                            " / " +
                            _vm._s(_vm.availability.total)
                        )
                      ])
                    : _vm._e()
                ])
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }