<template>
    <v-card tile class="elevation-1">
        
            <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" class="mb-4 elevation-1">
                        <v-icon left></v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="tools"></slot>
                        
                        <emc-button-icon v-if="!disabled" icon="mdi-eraser" text="Limpar" color="orange lighten-2" @click.prevent.stop="clear()" />
                        <emc-button-icon :disabled="disabled" :loading="loadingSaving" icon="mdi-content-save" text="Salvar" color="primary" @click.prevent.stop="save()" />

                    </v-app-bar>
                </v-card-title>
        
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field 
                        ref="field_cep"
                        v-mask="'99999-999'" 
                        :error="errors.length > 0" 
                        :error-messages="errors.cep" 
                        v-model="address.zip_code" 
                        label="Cep"
                        v-on:keyup.enter="searchCity()"
                        :loading="loading"
                        dense
                    >
                        <template v-slot:append>
                            
                            <emc-button-icon :disabled="!address || address.zip_code == null || address.zip_code.length < 9" icon="mdi-location-enter" text="Carregar dados..." @click.prevent.stop="searchCity()" />
                            <emc-button-icon icon="mdi-magnify" text="Pesquisar CEP" @click.prevent.stop="show = true" />
                            
                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  v-bind="attrs" v-on="on" icon @click.prevent.stop="searchCity()">
                                        <v-icon btn>mdi-location-enter</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                Carregar dados...
                                </span>
                            </v-tooltip> -->

                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click.prevent.stop="show = true">
                                        <v-icon btn>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                Pesquisar CEP
                                </span>
                            </v-tooltip> -->

                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                    <v-text-field class="mt-3" dense :error="errors.length > 0" :error-messages="errors.city_id" disabled label="Município" v-model="cityName"></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="3">
                    <v-text-field v-if="address && address.state" :error="errors.length > 0" :error-messages="errors.state_id" disabled dense label="UF" v-model="address.state.initials"></v-text-field>
                </v-col> -->
            </v-row>
            <v-row>
                <v-col cols="12" sm="9">
                    <v-text-field :disabled="disabled" :error="errors.length > 0" :error-messages="errors.address" dense v-model="address.address" label="Endereço"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">           
                    <v-text-field :disabled="disabled" :error="errors.length > 0" :error-messages="errors.address_number" dense v-model="address.address_number" label="Número"></v-text-field>
                </v-col>
            </v-row>
            <v-row>  
                <v-col cols="12" sm="4">           
                    <v-text-field :disabled="disabled" :error="errors.length > 0" :error-messages="errors.complement" dense v-model="address.complement" label="Complemento"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">           
                    <v-text-field :disabled="disabled" :error="errors.length > 0" :error-messages="errors.district"  dense v-model="address.district" label="Bairro"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea
                        v-model="address.note" 
                        :error="errors.length > 0" 
                        :error-messages="errors.note" 
                        dense
                        label="Observação"
                        rows="2"
                        clearable
                        outlined
                        :disabled="disabled"
                      />
                </v-col>
            </v-row>
        </v-card-text>
        <sys-address-find :show.sync="show" @onSelect="selectedCep" />
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
    name: "SysAddressRegister",
    data: () => ({
        loading: false,
        show: false,
        states: [],
        address: { zip_code: '' },
        loadingSaving: false,
        message: '',
        error: false,
        errors: [],
        cityName: null,
        disabled: true,
    }),

    props: {
        
        model: {
            required: true
        },

        type: {
            type: String,
            required: true,
            validator(value, props) {
                return ['teacher', 'campus', 'institution'].includes(value)
            }
        },

        title: {
            type: String,
            default: 'Endereço'
        }
        
       
    },

    created(){
        this.getStates();
        this.loadAddress();
    },

    watch: {
        
        model(val){
            this.clear();
            this.loadAddress();
        }

    },

    methods:{
        ...mapActions('city', ['ActionSearchCities', 'ActionCreateCity', 'ActionSearchStates']),
        ...mapActions('address', ['ActionIndexAddresses', 'ActionStoreAddress', 'ActionUpdateAddress', 'ActionDestroyAddress']),

        async getStates(){

            var payload = {
                per_page: 50
            };

            await this.ActionSearchStates(payload)
                .then((res) => {
                    this.states = res.data;
                })
                .finally(() => {
                    
                });
        },
        
        getCity(data){
            
            this.loading = true;
            
            var payload = {
                with: 'state',
                showAllCities: true,
                find_columns: {
                    ibge: data.ibge
                }
            }
        
            this.ActionSearchCities(payload)
                .then((res) => {
                   
                    if(res.data && res.data.length == 0){
                        this.createCity(data);
                    }else{
                        this.address.city       = res.data[0];
                        this.address.city_id    = this.address.city.id;
                        this.address.state      = this.address.city.state;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    if(this.address && this.address.city){
                        this.cityName = this.address.city.name + '/' + this.address.state.initials;
                    }
                    this.disabled = false;
                });
        },
        
        createCity(data){

            var payload = {

                state_id:   this.states.find(i => i.initials.toUpperCase() == data.uf.toUpperCase()).id,
                name:       data.localidade,
                ibge:       data.ibge,
                gia:        data.gia,
                ddd:        data.ddd,
                siafi:      data.siafi,
            };
            
            this.ActionCreateCity(payload)
                .then((res) => {
                    this.address.city = res.data;
                    this.address.city_id = this.address.city.id;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                });

        },


        async loadAddress(){

            
            
            if(this.model && this.model.id){
                
                var payload = {
                    with: 'city,state,country',
                };

                if(this.type == 'teacher'){
                    payload.filter_relations = [
                        {
                            name:   'teachers',
                            key:    'teachers.id', 
                            value:  this.model.id,
                        }
                    ];
                }else{
                    
                    if(!this.model.address_id){
                        return;
                    }
                    
                    payload.find_columns = {
                        id: this.model.address_id
                    };
                }
                
                await this.ActionIndexAddresses(payload)
                    .then((res) => {
                        
                        if(res.data && res.data.length > 0){
                            this.address    = res.data[0];
                            this.cityName   = this.address.city.name + '/' + this.address.state.initials;
                        }

                    })
                    .finally(() => {
                        this.disabled = false;
                    });
            }

        },

        clear(){

            this.address = { zip_code: '' };
            this.cityName = null;
            this.disabled = true;
            this.error = false;
            this.errors = [];
            this.message = '';
            
            this.$refs.field_cep.focus();

        },
        
        searchCity(){
            
            if(!this.address || this.address.zip_code < 9){
                return;
            }
            
            this.loading = true;
            var cep = this.address.zip_code.replace('-', '').replace('.', '');
            this.message = '';
                
            axios.get('https://viacep.com.br/ws/' + cep + '/json/')
                    .then(res => {
                        
                        if(res.status && res.status == 200){
                            let data = res.data;
                            this.address = {
                                zip_code:   data.cep,
                                district:   data.bairro,
                                address:    data.logradouro,
                                city:       { name: data.localidade  },
                                state:      { initials: data.uf  }
                            };
                            this.getCity(data);
                        }else{
                            this.message = 'Cep não Localizado';
                        }
                    })
                    .catch((error) =>{
                        this.message = 'Cep não Localizado';
                    })
                    .finally(() => {
                        this.loading = false;
                        
                    });
        },

        save(){
            
            let payload = {};
            this.loadingSaving = true;
            this.message = '';
            this.error = false;
            this.errors = [];
            var method;

            Object.assign(payload, this.address);

            payload.type            = this.type;
            payload.model_id        = this.model.id;

            if(this.address.id){
                method = 'ActionUpdateAddress';
            }else{
                payload.address_type_id = this.type == 'teacher' ? 1 : 2;
                payload.country_id      = this.address.state.country_id;
                payload.state_id        = this.address.state.id;
                method                  = 'ActionStoreAddress';
            }

            this[method](payload)
                .then((res) => {
                    this.address = res.data;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.message    = error.message;
                    this.errors     = error.errors;
                })
                .finally(() => {
                    this.loadingSaving = false;
                });
        },

        selectedCep(ev){
            this.address.zip_code = ev.cep;
            this.searchCity();
            this.show = false;
        }
      
    }
    
}
</script>