var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ma-1" }, [
    _c("video", {
      ref: "video",
      attrs: {
        width: _vm.width,
        height: _vm.height,
        src: _vm.source,
        autoplay: _vm.autoplay,
        playsinline: _vm.playsinline
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }