var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "offset-x": "",
            left: "",
            bottom: "",
            "max-width":
              _vm.$vuetify.breakpoint.name == "xs" ||
              _vm.$vuetify.breakpoint.name == "sm"
                ? "90%"
                : "90%"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { icon: "", color: "grey", small: _vm.small }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [_vm._v("mdi-cog")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.toolTip))])]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-app-bar",
                    {
                      staticClass: "grey lighten-5 mb-4 elevation-1'",
                      attrs: { dense: "" }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " ")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-1",
                                          attrs: { icon: "", small: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.menu = false
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red lighten-2" } },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Fechar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "emc-util-scroll",
                        { attrs: { maxHeight: "400px" } },
                        [
                          _vm._l(_vm.configs, function(config, index) {
                            return _c(
                              "v-list-item",
                              { key: index },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    config.type == "boolean"
                                      ? _c("s-switch", {
                                          staticClass: "ml-3",
                                          attrs: {
                                            "text-helper": config.textHelper,
                                            label: config.label,
                                            inset: ""
                                          },
                                          model: {
                                            value: _vm.model[config.field],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                config.field,
                                                $$v
                                              )
                                            },
                                            expression: "model[config.field]"
                                          }
                                        })
                                      : config.type == "text-area"
                                      ? _c("v-textarea", {
                                          attrs: {
                                            outlined: "",
                                            name: "input-7-4",
                                            label: config.label,
                                            error: _vm.errors
                                              ? _vm.errors.length > 0
                                              : false,
                                            errors: _vm.errors
                                              ? _vm.errors[config.field]
                                              : null,
                                            clearable: "",
                                            "auto-grow": "",
                                            rows: "1"
                                          },
                                          model: {
                                            value: _vm.model[config.field],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                config.field,
                                                $$v
                                              )
                                            },
                                            expression: "model[config.field]"
                                          }
                                        })
                                      : config.type == "text-field"
                                      ? _c("s-text-field", {
                                          attrs: {
                                            "text-helper": config.textHelper,
                                            "error-messages": _vm.errors
                                              ? _vm.errors[config.field]
                                              : null,
                                            label: config.label
                                          },
                                          model: {
                                            value: _vm.model[config.field],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                config.field,
                                                $$v
                                              )
                                            },
                                            expression: "model[config.field]"
                                          }
                                        })
                                      : config.type == "time-picker"
                                      ? _c("emc-calendar-time-picker", {
                                          attrs: {
                                            error: !!_vm.errors[config.field],
                                            errors: _vm.errors[config.field],
                                            time: _vm.model[config.field],
                                            label: config.label
                                          },
                                          on: {
                                            "update:time": function($event) {
                                              return _vm.$set(
                                                _vm.model,
                                                config.field,
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _vm.$slots.custom || !!_vm.$scopedSlots.custom
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [_vm._t("custom")],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }