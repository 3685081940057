var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-microsoft",
      show: _vm.dialog,
      maxWidth: "40%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campus_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.campus_id,
                            label: "Campus*",
                            dense: "",
                            disabled: _vm.campusId != null
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-branch", {
                          attrs: {
                            model: _vm.model.branch_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.branch_id,
                            "campus-id": _vm.model.campus_id,
                            label: "Filial",
                            clearable: "",
                            dense: "",
                            disabled: _vm.model.campus_id == null
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "branch_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.name,
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.initials,
                            "error-messages": _vm.errors.initials,
                            label: "Abreviação"
                          },
                          model: {
                            value: _vm.model.initials,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "initials", $$v)
                            },
                            expression: "model.initials"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            "text-helper": "Código do Prédio Externo",
                            code: "",
                            "error-messages": _vm.errors.code_control,
                            label: "Código Prédio"
                          },
                          model: {
                            value: _vm.model.code_control,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code_control", $$v)
                            },
                            expression: "model.code_control"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }