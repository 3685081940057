var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-x": "",
            left: "",
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "", color: "grey", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Configurações")]),
                          _c("v-list-item-subtitle", [_vm._v("Ambientes")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.fav ? "red--text" : "",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.fav = !_vm.fav
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.menu = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: { inset: "", color: "purple" },
                            on: {
                              change: function($event) {
                                return _vm.updateConfigs()
                              }
                            },
                            model: {
                              value: _vm.config.capacity.validate,
                              callback: function($$v) {
                                _vm.$set(_vm.config.capacity, "validate", $$v)
                              },
                              expression: "config.capacity.validate"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Validar Capacidade")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                disabled: !_vm.config.capacity.validate
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateConfigs()
                                }
                              },
                              model: {
                                value: _vm.config.capacity.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.config.capacity, "type", $$v)
                                },
                                expression: "config.capacity.type"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { color: "success", value: "capacity" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", [
                                          _vm._v("Capacidade "),
                                          _c(
                                            "strong",
                                            { staticClass: "success--text" },
                                            [_vm._v("Real")]
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "primary",
                                  value: "extended_capacity"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", [
                                          _vm._v("Capacidade "),
                                          _c(
                                            "strong",
                                            { staticClass: "primary--text" },
                                            [_vm._v("Estendida")]
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "purple",
                                  value: "reduced_capacity"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", [
                                          _vm._v("Capacidade "),
                                          _c(
                                            "strong",
                                            { staticClass: "purple--text" },
                                            [_vm._v("Reduzida")]
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-subtitle", [_vm._v("Periodicidade")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-radio-group",
                            {
                              on: {
                                change: function($event) {
                                  return _vm.updateConfigs()
                                }
                              },
                              model: {
                                value: _vm.config.periodicity,
                                callback: function($$v) {
                                  _vm.$set(_vm.config, "periodicity", $$v)
                                },
                                expression: "config.periodicity"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { color: "success", value: "1" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [_vm._v(" Semanal ")]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-radio", {
                                attrs: { color: "primary", value: "2" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [_vm._v(" Quinzenal ")]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("v-radio", {
                                attrs: { color: "purple", value: "3" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [_vm._v(" Mensal ")]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-actions", [_c("v-spacer")], 1)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }