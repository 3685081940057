var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("EmcBaseProgressBar")
        : _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Termo")]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Data")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Status")
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v("Ações")
                        ])
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [_vm._v(_vm._s(item.term.name))]),
                          _c("td", { staticClass: "text-center" }, [
                            item.term_signature_status_id == 2
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("dateTime")(item.updated_at))
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(" " + _vm._s(item.status.name) + " "),
                            item.term_signature_status_id != 1
                              ? _c("small", [
                                  _c("br"),
                                  item.term.is_required
                                    ? _c("span", [
                                        item.agree
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.term.label))
                                            ])
                                          : _vm._e()
                                      ])
                                    : _c("span", [
                                        item.option
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.option.label))
                                            ])
                                          : _vm._e()
                                      ])
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              item.term_signature_status_id != 1
                                ? _c("emc-table-icon-download-pdf", {
                                    attrs: {
                                      toolTip: "Download do Termo",
                                      route: _vm.token
                                        ? "teachers/token/" +
                                          _vm.token +
                                          "/" +
                                          item.id +
                                          "/pdf-download/teacher-term-signature"
                                        : null,
                                      type: "teacher-term-signature",
                                      token: _vm.token,
                                      item: item,
                                      disabled:
                                        item.term_signature_status == 1 ||
                                        !item.url_document
                                    }
                                  })
                                : _vm._e(),
                              _c("emc-table-icon-edit", {
                                attrs: {
                                  "tool-tip":
                                    item.term_signature_status_id == 1
                                      ? "Assinar Termo"
                                      : "Visualizar Termo",
                                  color:
                                    item.term_signature_status_id == 1
                                      ? "success"
                                      : "purple",
                                  icon:
                                    item.term_signature_status_id == 1
                                      ? "mdi-file-document-edit"
                                      : "mdi-text-box-search-outline"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.itemSelected = item
                                    _vm.showForm = true
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c("sys-register-term-signature-form", {
        attrs: {
          view: _vm.itemSelected.term_signature_status_id != 1,
          show: _vm.showForm,
          token: _vm.token,
          "signature-id": _vm.itemSelected.id
        },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          },
          onCompleted: function($event) {
            return _vm.getData()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }