var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-select-base", {
        attrs: {
          model: _vm.selected,
          items: _vm.items,
          loading: _vm.loading,
          label: _vm.label,
          error: _vm.error,
          errors: _vm.errors,
          clearable: _vm.clearable,
          showAdd: _vm.showAdd,
          dense: _vm.dense
        },
        on: {
          "update:model": function($event) {
            _vm.selected = $event
          },
          onShowForm: function($event) {
            _vm.showForm = true
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          }
        }
      }),
      _c("sys-occurrence-type-register", {
        attrs: { model: {}, show: _vm.showForm },
        on: {
          onCompleted: _vm.newItem,
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }