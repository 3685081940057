var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "elevation-0" },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "ma-1",
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : _vm._e(),
      _c("v-treeview", {
        ref: "tree",
        attrs: {
          items: _vm.items,
          "item-key": "key",
          "item-name": "name",
          "return-object": "",
          "item-children": "children",
          selectable: "",
          activatable: "",
          "open-all": false,
          search: _vm.search
        },
        on: {
          input: function($event) {
            return _vm.selectItem($event[0])
          }
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }