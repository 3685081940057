var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _c(
        "v-list-item",
        { attrs: { "two-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "headline" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ]),
              _c(
                "v-list-item-subtitle",
                [
                  _vm.subTitle
                    ? _c("span", [_vm._v(_vm._s(_vm.subTitle))])
                    : _vm._e(),
                  _vm._t("subtitle")
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        rotate: 360,
                        size: 120,
                        width: 15,
                        value: _vm.data.percent,
                        color: _vm.color
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.data.percent + "%") + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-subtitle", [
                        _vm._v("Concluídos: "),
                        _c("strong", [_vm._v(_vm._s(_vm.data.completed))]),
                        _vm._v(" / "),
                        _c("strong", [_vm._v(_vm._s(_vm.data.total))])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-card-actions", [_vm._t("actions")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }