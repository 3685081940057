<template>
    <emc-card-stats
        color="blue accent-1"
        icon="mdi-account-group-outline"
        icon-large
        :title="this.title"
        :value="this.groups"
        sub-text="Número de turmas cadastradas"
        >
        <template v-slot:actions>
            <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
        </template>
        </emc-card-stats>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartGroupNumber',
    data () {
        return {
            groups: '0',
            title: '',
            updated_at: null,
            loading: false,
            attempts: 1
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartGroupNumber', 'ActionGroupNumbersRefresh']),
        async getData() {
            await this.ActionChartGroupNumber()
                .then((r) => {
                    this.groups = r.data.value.toString()
                    this.title = r.data.title
                    this.updated_at = r.data.updated_at
                })
        },
        refresh() {
            this.loading = true

            this.ActionGroupNumbersRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let number = this.groups;

            setTimeout(async () => {

                    await this.getData()
                    
                    if(number != this.groups || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 5000)
        }
    },
    created(){
        this.getData()
    }

    
}
</script>