var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.error ? "error" : "success" },
      model: {
        value: _vm.snackbar,
        callback: function($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar"
      }
    },
    [
      _vm._v(" " + _vm._s(_vm.texto) + " "),
      _c(
        "v-btn",
        {
          attrs: { color: _vm.error ? "error" : "success", outlined: "" },
          on: {
            click: function($event) {
              _vm.snackbar = false
            }
          }
        },
        [_vm._v(" Fechar ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }