<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus</th>
                        <th>Curso</th>
                        <th>Turno</th>
                        <th>Turma</th>
                        <th>Disciplina</th>
                        <th>Período</th>
                        <th v-if="!hideRegister">Cadastro</th>
                        <th>Carga Horária</th>
                        <th>CH Paga</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="att in atts" :key="att.id">
                        <td>{{ att.grid.group.campus.abbreviation }}</td>
                        <td>{{ att.grid.group.course.name }}</td>
                        <td>{{ att.grid.group.shift.name }}</td>
                        <td>
                            {{ att.grid.group.name}}
                            <v-tooltip v-if="att.grid.workTimeDate" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small color="primary">event</v-icon>
                                </template>
                                <span>
                                    {{ att.grid.workTimeDate.name }}
                                    <br/>
                                    {{ att.grid.workTimeDate.start|date }} - {{ att.grid.workTimeDate.end|date }}
                                </span>
                            </v-tooltip>
                            <span v-if="renderComponent && att.grid.join">
                                 <emc-info-help icon="mdi-call-split" :title="'Junção: ' + att.grid.join.join_id">
                                        <template v-slot:text>
                                            <ul type="none">
                                                <li :key="grid.id" v-for="grid in att.grid.join.grids">
                                                    {{ grid.group.name + ' | ' + grid.discipline.name }}
                                                </li>
                                            </ul>
                                        </template>
                                </emc-info-help>
                            </span>
                        </td>
                        <td>
                            {{ att.grid.discipline_name }}
                            <small v-if="att.grid.subGroup"><br/>{{ att.grid.subGroup.name }}</small>
                        </td>
                        <td>
                            {{ att.date_start|date }}
                            <br/>
                            {{ att.date_end|date }}
                            <small v-if="att.status.id != 1">
                                <br/><strong class="purple--text">[{{ att.status.name }}]</strong>
                            </small>
                        </td>
                        <td v-if="!hideRegister">
                            {{ att.user.name }}
                            <small><br/>{{ att.updated_at | dateTime }}</small>
                        </td>
                        <td>{{ att.grid.work_load }} ({{ att.grid.work_load_type.initials}})</td>
                        <td class="justify-center">{{ att.work_load }}</td>
                        
                    </tr>
                </tbody>
                <tfoot v-if="atts.length > 0">
                    <tr>
                        <td :colspan="hideRegister ? 7 : 8" class="text-right">Total:</td>
                        <td class="justify-center"><strong>{{ parseFloat(workLoadPay).toFixed(2) }}</strong></td>
                    </tr>
                    <tr  v-if="gridListJoins.length > 0">
                        <td :colspan="hideRegister ? 9 : 10">
                            <v-icon mr-2 x-small color="indigo">mdi-call-split</v-icon>
                            <strong class="text-center ma-0">Disciplinas com Junção:</strong>
                            <ul type="none">
                                <li :key="i" v-for="(grid, i) in gridListJoins">
                                    {{ grid }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherAttributionTable',
    data: function() {
        return {
            atts: [],
            workLoadPay: 0,
            loading: true,
            renderComponent: true,
            gridListJoins: [],
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        token: {
            type:   String,
            default: null
        },
        workTime: {
            type:   Object,
            required: true
        },
        hideRegister: {
            type:   Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('attribution', ['ActionFindAttributions']),
        ...mapActions('availability', ['ActionGetTeacherByTokenAttribution']),
        ...mapActions('join', ['ActionFindJoinByGroupId', 'ActionFindJoinByGroupIdToken']),

        getData() {

            this.atts = [];
            this.gridListJoins = [];
            this.workLoadPay = 0;
            
            if(this.teacher.id === undefined) {
                return []
            }

            this.loading = true;

            var params = {
                find_columns: {
                   teacher_id:   this.teacher.id,
                   work_time_id: this.workTime.id
                },
                with: 'user,status,grid.subGroup,grid.join,grid.workTimeDate,grid.discipline,grid.group.course,grid.group.campus,grid.group.shift,grid.workLoadType',
                showAllCampuses: 'true'
            }

            

            if(this.token){
                params.token = this.token;
            }

            this.ActionFindAttributions(params)
                .then((r) => {
                    
                    var atts = r.data;
                    this.sortAtts(atts);

                    atts.forEach(att => {
                        if(att.attribution_status_id == 1 && (!att.grid.join || att.grid.join.main === true)){
                            this.atts.push(att);
                        }
                    });
                    
                    this.loadJoins(this.atts);
                    this.sumWorkLoads(this.atts);

                })
                .finally(()=> {
                    this.loading = false
                })
        },

        sortAtts(atts){

            atts.forEach(att => {
                att.key = att.grid.group.campus.name + '|' + att.grid.group.course.name + '|' + att.grid.group.shift.id + '|' + att.grid.group.name + '|' + att.grid.discipline.name + '|' + att.grid.group_number.toString().padStart(3, '0') + '|' + att.date_start;
            });

            atts.sort(function(a, b){
                return a.key.toString().localeCompare(b.key);
            });

        },

        sumWorkLoads(atts){

            this.workLoadPay = 0;
            var sum = 0;
            
            atts.forEach(att => {
                if(att.attribution_status_id == 1){
                    sum += parseFloat(att.work_load);
                }
            });

            this.workLoadPay = sum.toFixed(2);

        },

        loadJoins(atts){

            atts.forEach(att => {
                this.loadJoin(att.grid);
            });

        },

        loadJoin(grid){

            if(!grid.join){
                return;
            }

            var method = this.token ? 'ActionFindJoinByGroupIdToken' : 'ActionFindJoinByGroupId';
            var payload;
            
            if(this.token){
                payload = {
                    id: grid.group_id,
                    token: this.token,
                }
            }else{
                payload = {
                    group_id: grid.group_id,
                    showAllCampuses: true,
                    with: "grids.join,grids.discipline,grids.group.campus,grids.subGroup"
                }
            }
           
            this[method](payload).then((res) => {
                   
                var join = res.data.find(j => j.id == grid.join.join_id);
                
                if(join){
                    
                    grid.join.grids = join.grids;

                    var text = grid.discipline.name + ' (' + grid.work_load_type.initials + '): ';
                    var textGroups = '';
                    
                    join.grids.forEach(g => {
                        
                        if(textGroups.length > 0){
                            textGroups += ', ';
                        }
                        
                        textGroups += g.group.name;
                    });

                    var textGrid = text + textGroups;

                    if(!this.gridListJoins.includes(textGrid)){
                        this.gridListJoins.push(textGrid);
                    }

                }

                this.forceRerender();
                
            });
        },
        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>