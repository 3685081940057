export default [
    {
        path: '/offer-item-theme-cores',
        name: 'offer-item-theme-cores',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Temas do Core Curriculum',
            icon: 'mdi-format-list-bulleted-type',
            breadcrumbs: [
                { text: 'Temas do Core Curriculum', disabled: true }
            ]
        }
    }
]