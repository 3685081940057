var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                "clipped-left": "",
                flat: "",
                color: _vm.isStaging ? "red lighten-1" : "grey lighten-4"
              }
            },
            [
              _vm.menu.length > 0
                ? _c("v-app-bar-nav-icon", {
                    on: {
                      click: function($event) {
                        _vm.drawer = !_vm.drawer
                      }
                    }
                  })
                : _vm._e(),
              _c("v-toolbar-title", [
                _c(
                  "a",
                  { attrs: { href: "/" } },
                  [
                    _c("v-img", {
                      attrs: {
                        src: "/img/sysclass_logo.png",
                        "max-width": "150",
                        alt: "Logo SysClass"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("v-spacer"),
              _vm.isStaging
                ? _c("strong", { staticClass: "font-bold white--text" }, [
                    _vm._v("VOCÊ ESTÁ EM UM AMBIENTE DE TESTE!")
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showChangeWorkTime,
                      expression: "showChangeWorkTime"
                    }
                  ],
                  attrs: { justify: "end" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _vm.menu.length > 0
                        ? _c("SysSelectWorkTime", {
                            ref: "selectWorkTime",
                            staticClass: "mt-6 pr-2",
                            attrs: {
                              loading: _vm.loadingWorkTime,
                              label: "Período",
                              "filter-active": "",
                              model: _vm.acesso.periodo,
                              dense: "",
                              outlined: "",
                              "return-object": ""
                            },
                            on: {
                              "update:model": function($event) {
                                return _vm.$set(_vm.acesso, "periodo", $event)
                              },
                              input: _vm.changeWorkTime
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _vm.user.id && _vm.user.mode == "user"
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticStyle: { cursor: "help" },
                                        attrs: {
                                          small: "",
                                          to: "/faq-questions",
                                          icon: ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", color: "indigo" } },
                                      [_vm._v("mdi-help-circle-outline")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2372908207
                  )
                },
                [_c("span", [_vm._v(" Ajuda ")])]
              ),
              _vm.user &&
              _vm.user.id &&
              _vm.$route.name != "availability-teacher"
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-width": 100,
                        "offset-y": "",
                        "open-on-hover": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-1",
                                      attrs: { icon: "" }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.user.id
                                      ? _c("EmcBaseAvatar", {
                                          attrs: {
                                            size: 36,
                                            src: _vm.user.url_avatar,
                                            text: _vm.user.initials
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2874179426
                      )
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { "max-width": 400 } },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _vm.user.id
                                        ? _c("EmcBaseAvatar", {
                                            attrs: {
                                              size: 36,
                                              src: _vm.user.url_avatar,
                                              text: _vm.user.initials
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.user.name))
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(_vm._s(_vm.user.email))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-list",
                            [
                              _vm.acesso.instituicao &&
                              _vm.acesso.instituicao.id
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: { to: "/profile" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = !_vm.showMenu
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-account")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Meus Dados")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.acesso.instituicao &&
                              _vm.acesso.instituicao.id &&
                              _vm.user.is_master
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: { to: "/my-tickets" },
                                      on: {
                                        click: function($event) {
                                          _vm.showMenu = !_vm.showMenu
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [_vm._v("mdi-text-box")])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Meus chamados")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.sair()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.exit
                                              ? "mdi-spin mdi-loading"
                                              : "exit_to_app"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("Sair")])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "", left: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _vm._l(_vm.menu, function(item) {
                return [
                  Object.keys(item.children).length > 0
                    ? _c(
                        "v-list-group",
                        {
                          key: item.id,
                          attrs: { "prepend-icon": item.icon },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.text))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.model,
                            callback: function($$v) {
                              _vm.$set(item, "model", $$v)
                            },
                            expression: "item.model"
                          }
                        },
                        _vm._l(item.children, function(child, i) {
                          return _c(
                            "v-list-item",
                            { key: i, attrs: { link: "", to: child.to } },
                            [
                              child.icon
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-icon", [_vm._v(_vm._s(child.icon))])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(child.text))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "v-list-item",
                        { key: item.id, attrs: { link: "", to: item.to } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.text))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }