import { default as teacher } from  './teacher/services'

export default {
    ...teacher,
    login:                      { method: 'post',   url: 'login'},
    token:                      { method: 'post',   url: 'login/token'},
    logout:                     { method: 'post',   url: 'logout'},
    loadSession:                { method: 'post',   url: 'auth/refresh'},
    loadAcesso:                 { method: 'get',    url: 'educational_groups/all' },
    menus:                      { method: 'get',    url: 'auth/menu' },
    sendMailResetPassword:      { method: 'post',   url: 'password/email' },
    sendConfirmResetPassword:   { method: 'post',   url: 'password/reset' },
    setSelected:                { method: 'post',   url: 'auth/selected' },
    authLoginFace:              { method: 'post',   url: 'aws_rekognitions/auth/face' },

}