var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "ma-1",
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : _vm._e(),
      _c(
        "div",
        {
          on: {
            drop: function($event) {
              return _vm.$emit("drop")
            }
          }
        },
        [
          _vm.showToolbar
            ? _c(
                "v-sheet",
                { attrs: { height: "64" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { outlined: "", color: "grey darken-2" },
                          on: { click: _vm.setAll }
                        },
                        [
                          _vm.date.all
                            ? _c(
                                "span",
                                [
                                  _vm._v("Todo Semestre "),
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "success" } },
                                    [_vm._v("mdi-check-all")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _vm._v("Por Data "),
                                  _c("v-icon", { attrs: { color: "indigo" } }, [
                                    _vm._v("mdi-calendar")
                                  ])
                                ],
                                1
                              )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { outlined: "", color: "grey darken-2" },
                          on: { click: _vm.setToday }
                        },
                        [_vm._v(" Hoje ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2"
                          },
                          on: { click: _vm.prev }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-chevron-left ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2"
                          },
                          on: { click: _vm.next }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-chevron-right ")
                          ])
                        ],
                        1
                      ),
                      _vm.$refs.calendar
                        ? _c("v-toolbar-title", [
                            _vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              outlined: "",
                                              color: "grey darken-2"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.typeToLabel[_vm.type])
                                          )
                                        ]),
                                        _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v(" mdi-menu-down ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3056662313
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.type = "day"
                                    }
                                  }
                                },
                                [_c("v-list-item-title", [_vm._v("Dia")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.type = "week"
                                    }
                                  }
                                },
                                [_c("v-list-item-title", [_vm._v("Semana")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.type = "month"
                                    }
                                  }
                                },
                                [_c("v-list-item-title", [_vm._v("Mês")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-sheet",
            { attrs: { height: "280" } },
            [
              _c("v-calendar", {
                ref: "calendar",
                attrs: {
                  color: "primary",
                  events: _vm.events,
                  "event-color": _vm.getEventColor,
                  type: _vm.type,
                  "first-time": "18:00",
                  "interval-count": "5",
                  start: _vm.start,
                  "show-week": "",
                  weekdays: [1, 2, 3, 4, 5, 6]
                },
                on: {
                  "click:event": _vm.showEvent,
                  "click:more": _vm.viewDay,
                  "click:date": _vm.viewDay,
                  change: _vm.updateRange
                },
                scopedSlots: _vm._u([
                  {
                    key: "day-label-header",
                    fn: function(ref) {
                      return [!_vm.showToolbar ? _c("span") : _vm._e()]
                    }
                  },
                  {
                    key: "interval",
                    fn: function(ref) {
                      var time = ref.time
                      return [_c("span", { on: { click: time } })]
                    }
                  }
                ]),
                model: {
                  value: _vm.focus,
                  callback: function($$v) {
                    _vm.focus = $$v
                  },
                  expression: "focus"
                }
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    activator: _vm.selectedElement,
                    "offset-x": ""
                  },
                  model: {
                    value: _vm.selectedOpen,
                    callback: function($$v) {
                      _vm.selectedOpen = $$v
                    },
                    expression: "selectedOpen"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        color: "grey lighten-4",
                        "min-width": "350px",
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: _vm.selectedEvent.color, dark: "" } },
                        [
                          _c("v-toolbar-title", {
                            domProps: {
                              innerHTML: _vm._s(_vm.selectedEvent.name)
                            }
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { icon: "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.remove(_vm.selectedEvent)
                                    }
                                  }
                                },
                                [_vm._v("mdi-delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.selectedEvent.details)
                          }
                        })
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "secondary" },
                              on: {
                                click: function($event) {
                                  _vm.selectedOpen = false
                                }
                              }
                            },
                            [_vm._v(" CANCELAR ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }