var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.text && _vm.text != ""
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "top",
                        "colored-border": "",
                        type: "info",
                        elevation: "2"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.text) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "ma-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-date-picker", {
                attrs: {
                  multiple: "",
                  min: _vm.workTime.classes_start,
                  max: _vm.workTime.classes_end
                },
                on: { "click:date": _vm.clickDate },
                model: {
                  value: _vm.dates,
                  callback: function($$v) {
                    _vm.dates = $$v
                  },
                  expression: "dates"
                }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  loading: _vm.loadingSave,
                                  color: "primary",
                                  dark: ""
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.save()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("save")
                              ]),
                              _vm._v(" Salvar ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Salvar")])]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items.filter(function(i) {
                    return ![0].includes(i.availability_type_id)
                  }),
                  "items-per-page": -1,
                  "hide-default-footer": "",
                  "fixed-header": "",
                  height: "410",
                  "item-key": "index",
                  loading: _vm.loadingSave
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.date",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                color: _vm.colors[item.availability_type_id],
                                dark: "",
                                dense: ""
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("date")(item.date)) + " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.day",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                color: "deep-orange lighten-1",
                                dark: "",
                                dense: ""
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.dayOfWeek.initials) + " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    !_vm.workTime.availability_date_by_hour_group
                      ? {
                          key: "item.hour_start",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              _c("emc-calendar-time-picker", {
                                attrs: {
                                  error: !!_vm.errors.hour_start,
                                  errors:
                                    _vm.errors[
                                      "items." + index + ".hour_start"
                                    ],
                                  time: item.hour_start,
                                  label: "Início*"
                                },
                                on: {
                                  "update:time": function($event) {
                                    return _vm.$set(item, "hour_start", $event)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      : null,
                    !_vm.workTime.availability_date_by_hour_group
                      ? {
                          key: "item.hour_end",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              _c("emc-calendar-time-picker", {
                                attrs: {
                                  error: !!_vm.errors.hour_end,
                                  errors:
                                    _vm.errors["items." + index + ".hour_end"],
                                  time: item.hour_end,
                                  label: "Fim*"
                                },
                                on: {
                                  "update:time": function($event) {
                                    return _vm.$set(item, "hour_end", $event)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      : null,
                    _vm.workTime.availability_date_by_hour_group
                      ? {
                          key: "item.hour",
                          fn: function(ref) {
                            var item = ref.item
                            var index = ref.index
                            return [
                              _c("sys-select-hour-group-date", {
                                attrs: {
                                  model: item.name,
                                  start: item.hour_start,
                                  end: item.hour_end,
                                  error: _vm.errors.length > 0,
                                  errors:
                                    _vm.errors[
                                      "items." + index + ".hour_start"
                                    ],
                                  label: "",
                                  dense: "",
                                  dayId: item.dayOfWeek.id,
                                  token: _vm.token,
                                  itemText: "name_complete"
                                },
                                on: {
                                  "update:start": function($event) {
                                    return _vm.$set(item, "hour_start", $event)
                                  },
                                  "update:end": function($event) {
                                    return _vm.$set(item, "hour_end", $event)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      : null,
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.allowAddItem
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { small: "", color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addItem(item)
                                    }
                                  }
                                },
                                [_vm._v(" mdi-plus ")]
                              )
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", color: "error" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteItem(item)
                                }
                              }
                            },
                            [_vm._v(" mdi-close ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }