<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="collection"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :clearable="clearable"
            :showAdd="showAdd"
            :disableAdd="disableAdd"
            :disabled="disabled"
            :dense="dense"
            :autoComplete="autoComplete"
            :multiple="multiple"
            :selectAll="selectAll"
            :item-text="itemText"
            @change="$emit('change', $event)"
            :outlined="outlined"
        />
        <sys-register-group  @onCompleted="newItem" :model="{course: modelObject.course}"  v-if="modelObject && modelObject.course" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectGroup',
    data: () => ({
        collection: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        if(this.courseId){
            this.getItems();
        }
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        modelObject: {
            type: Object,
            default: () => {}
        },
        items: {
            type: Array,
            default: null
        },
        disableAdd: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense:{
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        courseId: {
            required: true
        },
        campusId: {
           
        },
        itemText: {
            type: String,
            default: 'name'
        },
        withCurriculum: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },

        courseId(){
            this.getItems();
        },

        campusId(){
            this.getItems();
        },
        
        model(val){
            this.selected = val;
        },

        
    },
    methods: {
        
        ...mapActions('group', ['ActionFindGroups']),        
        
        async getItems() {

            if(!this.courseId){
                return;
            }
            
            this.loading = true;
            var payload = {
                column_order: 'name'
            };

            if(this.courseId){

                payload.find_columns = {
                    course_id: this.courseId,
                };

            }

            if(this.campusId){

                if(!payload.find_columns){
                    payload.find_columns = {};
                }
                
                payload.find_columns.campus_id = this.campusId;

            }

            if(this.withCurriculum){
                payload.with = 'curriculum.sequencies.sequencyDisciplines';
            }

            await this.ActionFindGroups(payload)
                    .then((res) => {
                        
                        this.collection = res.data;

                        if(this.withCurriculum){
                            this.collection.forEach(group => {
                                group.disciplinesCurriculum = [];

                                if(group.curriculum){
                                    group.curriculum.sequencies.forEach(seq => {
                                        seq.sequencyDisciplines.forEach(disc => {
                                            group.disciplinesCurriculum.push(disc.discipline_id);
                                        });
                                    });
                                }
                            });
                        }

                        this.selected = this.model;

                        var find = this.collection.find(i => i.id == this.selected);
                        if(find){
                            this.$emit('change', find);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
                
                if(item){
                    this.collection.push(item);
                    this.selected = item.id;
                    this.showForm = false;
                }
            }
        },
    
}
</script>