var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-select-base", {
        attrs: {
          model: _vm.selected,
          items: _vm.items,
          loading: _vm.loading,
          label: _vm.label,
          error: _vm.error,
          errors: _vm.errors,
          clearable: _vm.clearable,
          showAdd: _vm.showAdd,
          multiple: _vm.multiple,
          dense: _vm.dense,
          disabled: _vm.disabled,
          selectAll: _vm.selectAll,
          autoComplete: _vm.autoComplete
        },
        on: {
          "update:model": function($event) {
            _vm.selected = $event
          },
          onShowForm: function($event) {
            _vm.showForm = true
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.showSlotItem
              ? {
                  key: "dataItem",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.name) + " ")]
                  }
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }