var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      disabled: _vm.disabled,
      model: _vm.model,
      title: "Alterar o dia da aula",
      icon: "mdi-clock-outline",
      show: _vm.dialog,
      maxWidth: "30%",
      showRequiredFields: false,
      showButton: false
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { attrs: { align: "center" } },
              [
                _c("v-date-picker", {
                  attrs: {
                    "year-icon": "mdi-calendar-blank",
                    "show-current": false,
                    "full-width": "",
                    readonly: ""
                  },
                  model: {
                    value: _vm.model.date,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "date", $$v)
                    },
                    expression: "model.date"
                  }
                })
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _vm.model.periodicity === "D"
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.loadingDestroy,
                      disabled: _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.destroy()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }