<template>
    <v-container>
        <v-row v-if="text && text != ''">
            <v-col>
                <v-alert border="top"
                        colored-border
                        type="info"
                        elevation="2"
                >
                    {{ text }}
                </v-alert>
            </v-col>
        </v-row>
        <v-row class="ma-1">
         <v-col cols="12" sm="4">
            <v-date-picker
                v-model="dates"
                multiple
                :min="workTime.classes_start"
                :max="workTime.classes_end"
                @click:date="clickDate"
                
            />
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                <v-btn :loading="loadingSave" v-on="on" color="primary" dark @click.prevent.stop="save()">
                    <v-icon dark>save</v-icon>
                        Salvar
                </v-btn>
                </template>
                <span>Salvar</span>
            </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6">
            <v-data-table
                :headers="headers"
                :items="items.filter(i => ![0].includes(i.availability_type_id))"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
                fixed-header
                height="410"
                item-key="index"
                :loading="loadingSave"
            >
                <template class='ma-0' v-slot:item.date="{ item }">
                    <v-chip
                        :color="colors[item.availability_type_id]"
                        dark
                        dense
                    >
                        {{ item.date | date }}
                    </v-chip>
                </template>

                <template class='ma-0' v-slot:item.day="{ item }">
                    <v-chip
                        color="deep-orange lighten-1"
                        dark
                        dense
                    >
                        {{ item.dayOfWeek.initials }}
                    </v-chip>
                </template>

                <template v-if="!workTime.availability_date_by_hour_group" v-slot:item.hour_start="{ item, index }">
                    <emc-calendar-time-picker :error="!!errors.hour_start" :errors="errors['items.' + index + '.hour_start']" :time.sync="item.hour_start" label="Início*" />
                </template>

                <template v-if="!workTime.availability_date_by_hour_group" v-slot:item.hour_end="{ item, index }">
                    <emc-calendar-time-picker :error="!!errors.hour_end" :errors="errors['items.' + index + '.hour_end']" :time.sync="item.hour_end" label="Fim*" />
                </template>

                <template v-if="workTime.availability_date_by_hour_group" v-slot:item.hour="{ item, index }">
                    <sys-select-hour-group-date
                        :model="item.name"
                        :start.sync="item.hour_start"
                        :end.sync="item.hour_end"
                        :error="errors.length > 0"  
                        :errors="errors['items.' + index + '.hour_start']"
                        label=""
                        dense
                        :dayId="item.dayOfWeek.id"
                        :token="token"
                        itemText="name_complete"
                    />
                </template>

                <template v-slot:item.actions="{ item }">
                    
                    <v-icon
                        v-if="allowAddItem"
                        small
                        @click="addItem(item)"
                        color="success"
                    >
                        mdi-plus
                    </v-icon>
                    
                    <v-icon
                        small
                        @click="deleteItem(item)"
                        color="error"
                    >
                        mdi-close
                    </v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</v-container>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysTeacherAvailabilityDate',
    data: () => ({
        items: [],
        dates: [],
        allowAddItem: false,
        days: [ 
                { id: 1, initials: 'Dom' },  
                { id: 2, initials: 'Seg' },  
                { id: 3, initials: 'Ter' },  
                { id: 4, initials: 'Qua' },  
                { id: 5, initials: 'Qui' },  
                { id: 6, initials: 'Sex' },  
                { id: 7, initials: 'Sáb' },  
            ],
        hourDefault: { start: '09:00', end: '18:00' },
        loading: false,
        loadingSave: false,
        arrayEvents: null,
        error: false,
        errors: [],
    }),
    computed: {
        ...mapState('auth', ['acesso']),
        
        headers: function(){
            
            if(this.workTime.availability_date_by_hour_group){
                return [
                        { text: 'Data',     value: 'date',                  align: 'center', sortable: false },
                        { text: 'Dia',      value: 'day',                   align: 'center', sortable: false },
                        { text: 'Horário',  value: 'hour',            align: 'center', sortable: false },
                        { text: '',         value: 'actions',               align: 'center', sortable: false },
                    ];
            }else{
                return [
                        { text: 'Data',   value: 'date',                  align: 'center', sortable: false },
                        { text: 'Dia',    value: 'day',                   align: 'center', sortable: false },
                        { text: 'Início', value: 'hour_start',            align: 'center', sortable: false },
                        { text: 'Fim',    value: 'hour_end',              align: 'center', sortable: false },
                        { text: '',       value: 'actions',               align: 'center', sortable: false },
                    ];
            }

        }
    },
    created() {
        this.getItems();
    },
    props: {
        
        release: {
            type: Boolean,
            default: false
        },
        workTime:{
            type: Object,
            required: true
        },
        teacher: {},
        token: {},
        colors: {},
        text: {
            type: String,
            default: null
        }
        
    },
    watch: {
        
        teacher(val){
            this.getItems();
        },

        token(val){
            this.getItems();
        },
    },
    methods: {
        ...mapActions('availability', ['ActionGetByDates', 'ActionUpdateAvailabilityDateAll', 'ActionGetByDatesByToken', 'ActionUpdateAvailabilityDateAllToken']),        
        
        async getItems() {

            this.loading = true;
            this.dates = [];
            this.items = [];
            var availability_id = 0;

            if(this.teacher && this.teacher.availabilities && this.teacher.availabilities.length > 0){
                availability_id = this.teacher.availabilities[0].id;
            }

            const method = this.token ? 'ActionGetByDatesByToken' : 'ActionGetByDates';
            var payload = this.token ? {token: this.token} : {find_columns: { availability_id: availability_id }};

            this[method](payload)
                .then((res) => {
                    this.items = res.data;
                    this.orderItems();
                    this.dates = this.items.filter(o => [1,3].includes(o.availability_type_id)).map(o => o.date);
                    this.setIndex();
                    
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingSave = false;
                });
        },

        orderItems(){
            this.items.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
        },

        setIndex(){
            
            var index = 0;
            
            this.items.forEach(i => {
                i.index = (index++) + '';
            });
        },

        save(){
            
            this.loadingSave = true;
            this.error = false;
            this.errors = [];
            const method = this.token ? 'ActionUpdateAvailabilityDateAllToken' : 'ActionUpdateAvailabilityDateAll';

            var payload = {
                items:              this.items.filter(i => ![0].includes(i.availability_type_id)),
                alter_from_user:    this.release,
                token:              this.token,
                availability_id:    this.teacher.availabilities[0].id,
            };

            this[method](payload)
                .then((res) => {
                    this.$emit('onComplete', { text: res.message, error: false  });
                    this.getItems();
                }).catch((error) =>{
                    
                    this.errors = error.errors;
                    this.error = true;
                    var message = null;

                    if(error.errors.length > 0){
                        message = error.errors[0];
                    }else{
                        message = error.message;
                    }

                    this.$emit('onComplete', { text: message, error: false  });

                }).finally(() => {
                    this.loadingSave = false;
                });       
            
        },

        clickDate(date){
            
            var item = this.items.find(i => i.date == date);
            
            if(this.dates.includes(date)){
                this.insertItem(item, date);
            }else{
                this.removeItem(item, date);
            }
        },

        addItem(item){
            this.newItem(item.date);
        },

        deleteItem(item){
            
            this.removeItem(item);
            
            var index = this.dates.indexOf(item.date);
            if(index != -1){
                this.dates.splice(index, 1);
            }
        },

        insertItem(item, date){

            if(item){
                
                var items = this.items.filter(i => i.date == date);

                items.forEach(item2 => {
                    item2.availability_type_id = this.release ? 3 : 1;
                });
                
            }else{
                this.newItem(date);
            }
        },

        newItem(date){
            
            var day = new Date(date).getUTCDay() + 1;
            var dayOfWeek = this.days.find(i => i.id == day);
            var item = {
                id:                     0,
                index:                  this.items.length + '',
                availability_id:        this.teacher.availabilities[0].id,
                availability_type_id:   this.release ? 3 : 1,
                date:                   date,
                hour_start:             this.hourDefault.start,
                hour_end:               this.hourDefault.end,
                name:                   '',
                dayOfWeek:              dayOfWeek,
                total_classes:          1
            };

            this.items.push(item);

            this.orderItems();
        },

        removeItem(item, date = null){
            
            if(!item){
                return;
            }

            var items = [];

            if(date){
                items = this.items.filter(i => i.date == date);
            }else{
                items = [item];
            }

            items.forEach(item2 => {
                
                if(item2.id == 0){
                    this.items.splice(this.items.indexOf(item2), 1);
                }else if(this.release){
                    if(item2.availability_type_id == 3){
                        item2.availability_type_id = 0;
                    }else{
                        item2.availability_type_id = 2;
                    }
                }else{
                    item2.availability_type_id = 0;
                }

            });
        }
    },
    
}
</script>