<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="model.id ? 'Editar Instituição' : 'Cadastrar Instituição'" 
        icon="mdi-city" 
        v-bind:show.sync="dialog"
        maxWidth="70%"
    >
        <template v-slot:tools>
            <sys-institution-pop-over   :model="model" :errors="errors" />
            <!-- <sys-institution-pop-over2  :model="model" :errors="errors" /> -->
        </template>
        
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab v-if="false && model && model.id" key="extract-setting">PIT</v-tab>
                <v-tab v-if="model && model.id" key="address">Endereço</v-tab>
                <v-tab v-if="model && model.id" key="integration">Integração</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <sys-select-brand
                                    :model.sync="model.brand_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.brand_id"
                                    label="Marca*"
                                    auto-complete
                                    show-all
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.initials" v-model="model.initials" label="Sigla*" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <s-text-field code dense :error-messages="errors.code" v-model="model.code" label="Código" />
                            </v-col>
                            <v-col v-if="model.id" cols="12" sm="3">
                                <emc-avatar-institution width="80" height="80" :model="model" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <sys-select-company
                                    :model.sync="model.company_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.company_id"
                                    label="Empresa"
                                    clearable
                                    dense
                                    autoComplete
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.company_name" v-model="model.company_name" label="Razão Social" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field dense :error="errors.length > 0" v-mask-cnpj :error-messages="errors.cnpj" v-model="model.cnpj" label="Cnpj" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-switch text-helper="Professores com vínculo poderão ser atribuídos em qualquer unidade" inset v-model="model.allow_all_attributions" label="Permitir Atribuições"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                            </v-col>
                        </v-row>
                       
                        <v-row v-if="!model.id">
                            <v-container>
                                <v-card outlined>
                                    <v-app-bar color="grey lighten-3 grey--text" class="elevation-0" dense>
                                        <v-toolbar-title>Campus</v-toolbar-title>
                                    </v-app-bar>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="2">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.campus_code" v-model="model.campus_code" label="Código"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.campus_initials" v-model="model.campus_initials" label="Sigla*" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="7">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.campus_name" v-model="model.campus_name" label="Nome*" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="7">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.campus_abbreviation" v-model="model.campus_abbreviation" label="Abreviação*" required></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>
                                </v-card>
                            </v-container>
                            <v-container>
                                <v-card outlined>
                                    <v-app-bar color="grey lighten-3 grey--text" class="elevation-0" dense>
                                        <v-toolbar-title>Período Letivo</v-toolbar-title>
                                    </v-app-bar>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.worktime_code" v-model="model.worktime_code" label="Código"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.worktime_year" v-model="model.worktime_year" label="Ano*" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.worktime_period" v-model="model.worktime_period" label="Período*" required></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="8">
                                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.worktime_name" v-model="model.worktime_name" label="Descrição*" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <sys-select-frequency
                                                        :model.sync="model.worktime_frequency_id"
                                                        :error="errors.length > 0"  
                                                        :errors="errors.worktime_frequency_id"
                                                        label="Regime*"
                                                        dense
                                                    />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-container>
                        </v-row>
                    </v-container>
                    </v-tab-item>
                    <v-tab-item v-if="false" key="extract-setting">
                        <v-container>
                            <emc-util-scroll maxHeight="">
                                <v-row>
                                    <v-col v-if="model.teacherExtractSetting" cols="12" sm="12">
                                        <s-text-field placeholder="Plano Individual de Trabalho" text-helper="Cabeçalho do Extrato" dense :error-messages="errors.title" v-model="model.teacherExtractSetting.title" label="Título" />
                                    </v-col>
                                </v-row>
                            </emc-util-scroll>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="address">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-address-register :model.sync="model" type='institution' />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                     <v-tab-item key="integration">
                        <v-container>
                          <sysInstitutionIntegration :institution="model"/>
                        </v-container>
                     </v-tab-item>
                </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysRegisterInstitution',
    data: () => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        tab: null
    }),
    props: {
        model: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    
    watch: {  
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            this.dialog = show_new;
            
            if(show_new){
                
                this.errors = {};
                this.tab = null;
                
                if(!this.model.id){
                    this.model.max_day_hours = 8;
                    this.model.max_times_break_day = 6;
                    this.model.min_time_break = '01:00';
                    this.model.active = 1;
                }
            }
        },
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    methods: {
        ...mapActions('institution', ['ActionUpdateInstitution', 'ActionCreateInstitution']),
        save()
        {
            this.error = false
            this.loading = true
            this.message = ''
            this.errors = {};

            if(this.model.city_id === 0){
                this.model.city_id = null;
            }

            if(this.model.id) {
                
                this.ActionUpdateInstitution(this.model)
                .then((res) => {
                    this.message = res.message
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = error;
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false
                })
            } else {

                this.ActionCreateInstitution(this.model)
                .then((res) => {
                    this.message = res.message
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = error
                    this.errors = error.errors
                })
                .finally(() => {
                    this.loading = false
                })

            }
        },
    },
}
</script>