var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, sm: _vm.sm, md: _vm.md } },
    [
      _c(
        "v-card",
        { staticClass: "py-2 px-3", attrs: { outlined: "" } },
        [
          _c("span", { staticClass: "grey--text text--darken-1" }, [
            _vm._v("Cor: ")
          ]),
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "" },
              on: {
                click: function($event) {
                  _vm.showColors = true
                }
              }
            },
            [
              !_vm.colorSelected
                ? _c("v-icon", [_vm._v("mdi-select")])
                : _c("v-sheet", {
                    attrs: {
                      color: _vm.colorSelected,
                      height: "20",
                      width: "20",
                      outlined: ""
                    }
                  })
            ],
            1
          ),
          _c("EmcDialogColors", {
            attrs: { show: _vm.showColors, color: _vm.colorSelected },
            on: {
              "update:show": function($event) {
                _vm.showColors = $event
              },
              "update:color": function($event) {
                _vm.colorSelected = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }