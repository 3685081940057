var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          value: _vm.value,
          error: _vm.errorMessages && _vm.errorMessages.length > 0,
          autofocus: _vm.autofocus,
          "error-messages": _vm.errorMessages,
          label: _vm.label,
          dense: _vm.dense,
          required: _vm.required,
          type: _vm.type,
          readonly:
            _vm.code &&
            (!_vm.user || !_vm.user.is_master) &&
            (!_vm.user || !_vm.user.is_internal),
          "persistent-placeholder": _vm.persistentPlaceholder,
          placeholder: _vm.placeholder,
          clearable: _vm.clearable,
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.code
              ? {
                  key: "append",
                  fn: function() {
                    return [
                      _c("emc-info-help", {
                        attrs: {
                          "icon-color": "error",
                          title: "Informações",
                          text: _vm.textHelper
                            ? _vm.textHelper
                            : "Código externo utilizado para integração com outros sistemas. É permitido alteração apenas pelo administrador."
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : _vm.textHelper
              ? {
                  key: "append",
                  fn: function() {
                    return [
                      _c("emc-info-help", {
                        attrs: { title: "Informações", text: _vm.textHelper }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.innerModel,
          callback: function($$v) {
            _vm.innerModel = $$v
          },
          expression: "innerModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }