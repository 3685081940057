var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-find-base", {
        attrs: {
          show: _vm.dialog,
          collection: _vm.items,
          headers: _vm.headers,
          icon: _vm.icon,
          loading: _vm.loading,
          title: "Pesquisar Disciplina",
          singleSelect: _vm.singleSelect,
          maxWidth: _vm.maxWidth,
          showSelect: _vm.showSelect,
          selecteds: _vm.selected,
          showAdd: _vm.showAdd
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          onSearch: function($event) {
            return _vm.searchDisciplines($event)
          },
          onSelect: function($event) {
            return _vm.select($event)
          },
          "update:selecteds": function($event) {
            _vm.selected = $event
          },
          onAdd: function($event) {
            _vm.dialogAddDiscipline = true
          }
        },
        scopedSlots: _vm._u(
          [
            _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
              return {
                key: slot,
                fn: function(scope) {
                  return [_vm._t(slot, null, null, scope)]
                }
              }
            })
          ],
          null,
          true
        )
      }),
      _c("sys-register-discipline", {
        attrs: { model: {}, show: _vm.dialogAddDiscipline },
        on: {
          onCompleted: function($event) {
            return _vm.selectDiscipline($event)
          },
          "update:show": function($event) {
            _vm.dialogAddDiscipline = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }