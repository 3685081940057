<template>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <v-data-table
                  v-if="renderComponent"
                  :headers="headersFiltered"
                  :items="model.timeTables"
                  :items-per-page="-1"
                  item-key="index"
                  hide-default-footer
                  fixed-header
                  height="300"
                 
              >
                  <template v-slot:top="{ on, attrs }">
                        <v-btn 
                          color="indigo darken-1" 
                          @click.prevent.stop="addItem()" 
                          icon v-bind="attrs" 
                          v-on="on">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                  </template>

                  <template v-slot:item.day_of_week_id="{item, index}">
                    <v-select
                        v-model="item.day_of_week_id"
                        item-value="id"
                        item-text="name"
                        :items="days.filter(d => d.visible)"
                        :error="!!errors.day_of_week_id"
                        :error-messages="errors['timeTables.' + index + '.day_of_week_id']" 
                        label=""
                        dense
                    />
                  </template>

                  <template v-slot:item.date="{item, index}">
                    <emc-calendar-date-picker2 
                          :model.sync="item.date" 
                          label="" 
                          :errorMessages="errors['timeTables.' + index + '.date']"
                          :show="dialog"
                          :min="acesso.periodo.start"
                          :max="acesso.periodo.end"
                          dense
                      />
                  </template>

                  <!-- <template dense v-slot:item.teacher_id="{item, index}">
                    <sys-select-ambience
                            :model.sync="item.teacher_id"
                            :error="!!errors.teacher_id" 
                            :errors="errors['timeTables.' + index + '.teacher_id']" 
                            label=""
                            clearable
                            autoComplete
                        />
                  </template> -->

                  <!-- <template dense v-slot:item.ambience_id="{item, index}">
                    <sys-select-ambience
                            :model.sync="item.ambience_id"
                            :error="!!errors.ambience_id" 
                            :errors="errors['timeTables.' + index + '.ambience_id']" 
                            label=""
                            clearable
                            autoComplete
                        />
                  </template> -->
 
                  <template dense v-slot:item.hour_start="{item, index}">
                      <emc-calendar-time-picker 
                        dense 
                        :error="!!errors.hour_start" 
                        :errors="errors['timeTables.' + index + '.hour_start']" 
                        :time.sync="item.hour_start" 
                        label=""
                      />
                  </template>

                  <template dense v-slot:item.hour_end="{item, index}">
                    <emc-calendar-time-picker 
                      dense 
                      :error="!!errors.hour_end" 
                      :errors="errors['timeTables.' + index + '.hour_end']" 
                      :time.sync="item.hour_end" 
                      label=""
                    />
                  </template>

                  <template v-slot:item.actions="{ item, index }">
                      
                      <v-btn
                        @click.prevent.stop="deleteItem(item, index)"
                        :loading="loading && indexDelete == index"
                        :disabled="loading"
                        icon
                      >
                        <v-icon
                            small
                            color="error"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                      </v-btn>
                      
                      
                  </template>

                  <template v-slot:footer>
                    <tr>
                        <td :colspan="headers.length">
                          <v-switch @change="forceRerender()" class="ml-4" inset v-model="model.by_date" label="Horário por Data"></v-switch>
                        </td>
                    </tr>
                </template>
                            
            </v-data-table>
        </v-col>
      </v-row>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysActivityItemRegisterTimeTable",
    data: () => ({
      fav: true,
      dialog: false,
      loading: false,
      indexDelete: -1,
      renderComponent: true,
      headers: [
          { text: 'Dia',      value: 'day_of_week_id', day: true },
          { text: 'Data',     value: 'date', date: true },
          // { text: 'Professor', value: 'teacher_id' },
          // { text: 'Ambiente', value: 'ambience_id' },
          { text: 'Início',   value: 'hour_start' },
          { text: 'Fim',      value: 'hour_end' },
          { text: '',         value: 'actions', sortable: false, width: "2%"},
      ],
      days: [
          {id: 1, name: 'Domingo',  visible: false},
          {id: 2, name: 'Segunda',  visible: true},
          {id: 3, name: 'Terça',    visible: true},
          {id: 4, name: 'Quarta',   visible: true},
          {id: 5, name: 'Quinta',   visible: true},
          {id: 6, name: 'Sexta',    visible: true},
          {id: 7, name: 'Sábado',   visible: true}
        ]
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {

        }
        
    },
     watch: {
       
     },
     
     computed: {
      ...mapState('auth', ['acesso']),
      headersFiltered(){
        
          if(this.renderComponent && this.model.by_date){
            return this.headers.filter(h => !h.day);
          }else{
            return this.headers.filter(h => !h.date);
          }
        
        
       }
    },
    methods: {
      ...mapActions('activityItem', ['ActionDeleteActivityItemTimeTable']),
      
      deleteItem(item, index){
          
          if(!item.id){
            this.removeItem(item);
            return;
          }
          
          this.loading = true;
          this.indexDelete = index;
          
          this.ActionDeleteActivityItemTimeTable({id: item.id})
            .then((res) => {
                this.removeItem(item);
            })
            .catch((error) =>{
                
                
            })
            .finally(() => {
                  this.loading = false;
                  this.indexDelete = -1;
                  this.forceRerender();
            });
            
      },

      removeItem(item){
          var index = this.model.timeTables.indexOf(item);
          this.model.timeTables.splice(index, 1);
          this.forceRerender();
      },

      addItem(){
          
          if(!this.model){
            return;
          }

          var length = this.model.timeTables.length;
          var hour_start = null;
          var hour_end = null;

          if(length > 0){
            hour_start = this.model.timeTables[length - 1]['hour_start'];
            hour_end = this.model.timeTables[length - 1]['hour_end'];
          }
          
          var item = {
              index:          length,
              id:             0,
              day_of_week_id: null,
              date:           null,
              teacher_id:     null,
              ambience_id:    null,
              hour_start:     hour_start,
              hour_end:       hour_end,
          };

          this.model.timeTables.push(item);
          this.forceRerender();
          
      },

      forceRerender () {
      
        this.renderComponent = false;

        this.$nextTick(() => {
            this.renderComponent = true;
      });

  },
    }
}
</script>