var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("span", { staticClass: "body-2" }, [
            _vm._v("Relação de configurações para integração")
          ]),
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                icon: "",
                                loading: _vm.loadingReplicate
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.replicate()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("mdi-content-copy")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(" Replicar registros do período anterior ")])]
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  _vm.show = true
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          )
        ],
        1
      ),
      _c("emcTableItems", {
        attrs: {
          headers: _vm.headers,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          find: false
        },
        on: {
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        }
      }),
      _c("SysRegisterWorkTimeIntegration", {
        attrs: { model: _vm.model, show: _vm.show, workTime: _vm.workTime },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.show = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          text: _vm.textDelete,
          disabled: _vm.deleting,
          show: _vm.showDelete
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }