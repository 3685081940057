var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": "auto",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "50%",
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto elevation-0 overflow-y-auto",
          attrs: {
            outlined: "",
            height: "75vh",
            prominent: "",
            loading: _vm.loading
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "mb-4 elevation-1",
                  attrs: { dense: "", height: "45" }
                },
                [
                  _c("v-toolbar-title", { staticClass: "subtitle-1" }, [
                    _vm._v(" Filtrar por turmas ")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.clearFilter()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary lighten-2" } },
                                    [_vm._v("mdi-filter-remove-outline")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Limpar Filtro")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "red lighten-2" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-2" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "7" } },
                        [
                          _c("v-treeview", {
                            ref: "treeGroups",
                            attrs: {
                              items: _vm.items,
                              "item-key": "key",
                              "item-name": "name",
                              "return-object": "",
                              "item-children": "children",
                              selectable: "",
                              "open-all": false,
                              search: _vm.searchGroup
                            },
                            model: {
                              value: _vm.groups,
                              callback: function($$v) {
                                _vm.groups = $$v
                              },
                              expression: "groups"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "5" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-grid-category", {
                                    attrs: {
                                      model: _vm.categories,
                                      label: "Categoria",
                                      multiple: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.categories = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-course-modality", {
                                    attrs: {
                                      model: _vm.modalities,
                                      label: "Modalidade de ensino",
                                      multiple: "",
                                      dense: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.modalities = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isAnima()
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "item-value": "id",
                                          "item-text": "name",
                                          items: _vm.attibutionTypeItems,
                                          label: "Tipo de Atribuição",
                                          multiple: "",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.attributionTypes,
                                          callback: function($$v) {
                                            _vm.attributionTypes = $$v
                                          },
                                          expression: "attributionTypes"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAnima()
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "12" } },
                                    [
                                      _c("sys-select-join-category", {
                                        attrs: {
                                          model: _vm.joinTypes,
                                          label: "Tipo de Junção",
                                          multiple: "",
                                          dense: ""
                                        },
                                        on: {
                                          "update:model": function($event) {
                                            _vm.joinTypes = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c("sys-select-work-load-type", {
                                    attrs: {
                                      model: _vm.workLoadTypes,
                                      label: "Tipo de CH",
                                      multiple: ""
                                    },
                                    on: {
                                      "update:model": function($event) {
                                        _vm.workLoadTypes = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-switch", {
                attrs: { inset: "", color: "purple", label: "Meus cursos" },
                model: {
                  value: _vm.by_courses,
                  callback: function($$v) {
                    _vm.by_courses = $$v
                  },
                  expression: "by_courses"
                }
              }),
              _c("v-switch", {
                staticClass: "ml-2",
                attrs: { inset: "", color: "purple", label: "Sem professor" },
                model: {
                  value: _vm.config.no_teacher,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "no_teacher", $$v)
                  },
                  expression: "config.no_teacher"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", disabled: _vm.disableButton },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.filter()
                    }
                  }
                },
                [
                  _vm._v(" Filtrar "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-filter-menu-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }