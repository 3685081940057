<template>
            
    <emc-chart-apex-base :series="series" type="line" :options="chartOptions" :loading.sync="loading">

        <template v-slot:actions>

            <emc-chart-filter :show.sync="showFilter" @filter="filter($event)" />

            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="showFilter = true">
                        <v-icon btn>mdi-filter-outline</v-icon>
                    </v-btn>
                </template>
                <span>
                    Selecionar Categorias
                </span>
            </v-tooltip>
            <emc-button-icon btn-small :loading="loading" icon="mdi-refresh-circle" :text="updated_at || `Atualizar`" color="success" @click.prevent.stop="refresh()" />
        </template>

    </emc-chart-apex-base>

</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartEfficiencyOfWorkload',
    data () {
        return {
            loading: false,
            showFilter: false,
            filterPayload: {},
            series: [],
            chartOptions: {
                chart: {
                stacked: true,
                zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                stroke: {
                    width: [0, 0]
                },
                title: {
                    text: ''
                },
                labels: [],
                colors: [],
            yaxis: [{
              title: {
                text: 'Carga horária',
              },
            
            }],
            fill: {
              opacity: 1
            }
          },
            updated_at: null,

            name: 'EfficiencyOfWorkload',
            chartData: []
        }
    },
    props: {
        itemId: {
            type: Number,
        },
    },
    methods: {
        ...mapActions('chart', ['ActionChartEfficiencyOfWorkload', 'ActionEfficiencyOfWorkloadRefresh']),
        async getData() {
            
            this.loading = true

            //buscando dados do gráfico
            await this.ActionChartEfficiencyOfWorkload(this.filterPayload)
                .then((r) => {
                    this.series = r.data.series
                    this.chartOptions.labels = r.data.categories
                    this.chartOptions.colors = r.data.colors
                    this.chartOptions.title.text = r.data.title
                    this.updated_at = r.data.updated_at
                })
                .finally(() => {
                    this.loading = false
                })
            
        },
        refresh() {

            this.loading = true

            this.ActionEfficiencyOfWorkloadRefresh()
                .then(() => {
                    this.getDataRefresh()
                })
        },
        getDataRefresh()
        {
            let updated_at = this.updated_at;

            setTimeout(async () => {
                    
                    await this.getData()
                    
                    if(updated_at != this.updated_at || this.attempts == 3) {
                        this.loading = false
                    } else {
                        this.attempts++
                        this.getDataRefresh()
                    }

            }, 10000)
        },
        filter(ev){
            this.filterPayload = ev;
            this.showFilter = false;
            this.getData();
        },
    },

    created() {
        this.getData()
    }
}
</script>