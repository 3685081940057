<template>
  <div>
    <sys-select-base
        :model.sync="selected"
        :items="items"
        :loading="loading"
        :label="label"
        :error="error"
        :errors="errors"
        clearable
        :dense="dense"
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'SysSelectPlanType',
  data: () => ({
    items: [],
    loading: false,
    selected: null,
    label: 'Tipo de plano'
  }),
  created() {
    this.getItems();
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
    },
    dense: {
      type: Boolean,
      default: false
    },
    model: {}
  },
  watch: {

    selected(val){
      this.$emit('update:model', val);
    },

    model(val){
      this.selected = val;
    },
  },
  methods: {
    ...mapActions('plan', ['ActionIndexPlanType']),
    async getItems() {
      this.loading = true
      const params = {
        column_order: 'name'
      }
      await this.ActionIndexPlanType(params)
          .then((res) => {
            this.items = res.data;
            this.selected = this.model;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>