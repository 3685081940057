<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab key="others">Eventos de Variação</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container class="mt-2">
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-text-field dense :error="!!errors.initials" :error-messages="errors.initials" v-model="model.initials" label="Sigla*"></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="6">
                                <sys-select-company
                                    :model.sync="model.companies"
                                    :error="errors.length > 0"  
                                    :errors="errors.companies"
                                    label="Empresa"
                                    dense
                                    multiple
                                />
                            </v-col> -->
                            <v-col cols="12" sm="3">
                                <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código" />
                            </v-col>
                        <!-- <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-payment-type
                                    :model.sync="model.paymentTypes"
                                    :error="errors.length > 0"  
                                    :errors="errors.paymentTypes"
                                    label="Tipo de Pagamento"
                                    dense
                                    multiple
                                /> 
                            <v-col cols="12" sm="6">
                                <sys-select-teacher-titling 
                                    :model.sync="model.teacher_titling_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.teacher_titling_id"
                                    label="Titulação"
                                    clearable
                                />
                            </v-col>
                        </v-row> -->
                            <v-col cols="12" sm="3">
                                <sys-select-payment-periodicity-type
                                        :model.sync="model.payment_periodicity_type_id"
                                        :error="errors.length > 0"  
                                        :errors="errors.payment_periodicity_type_id"
                                        label="Periodicidade"
                                        clearable
                                        dense
                                    /> 
                            </v-col>
                            <v-col cols="12" sm="3">
                                <sys-select-value-type
                                    :model.sync="model.value_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.value_type_id"
                                    label="Tipo de Valor"
                                    dense
                                    clearable
                                /> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    :error="!!errors.description" 
                                    :error-messages="errors.description" 
                                    v-model="model.description" 
                                    label="Descrição"
                                    :counter="255"
                                    rows="2"
                                    :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                    outlined
                                    clearable
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <s-switch inset v-model="model.full_hour" label="Hora Cheia" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <s-switch inset v-model="model.has_salary" label="Compõe Salário" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <sys-select-payment-event-nature
                                    :model.sync="model.payment_event_nature_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_event_nature_id"
                                    label="Natureza"
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <s-text-field text-helper="Tipo de Eventos: T, E ou C" :error-messages="errors.type" v-model="model.type" label="Tipo*" />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch color="purple" inset v-model="model.active" label="Ativo"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="others">
                    <v-container class="mt-2">
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-alert
                                    outlined
                                    color="info"
                                    prominent
                                    type="info"
                                    border="left"
                                    >
                                    <div class="text-center">
                                        Essa configuração irá refletir apenas na exportação de <strong>Variação Salarial - Atividades</strong>
                                    </div>
                                    
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <sys-select-payment-event
                                    :model.sync="model.payment_event_id_discount"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_event_id_discount"
                                    label="Evento de Desconto"
                                    clearable
                                    auto-complete
                                    dense
                                    :nature="3"
                                /> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <sys-select-payment-event
                                    :model.sync="model.payment_event_id_difference"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_event_id_difference"
                                    label="Evento de Diferença"
                                    clearable
                                    auto-complete
                                    dense
                                    :nature="2"
                                /> 
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterPaymentEvent",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        range: null,
        message: '',
        loading: false,
        tab: null,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.range = null;
                this.tab = 0;

                if(!this.model.id){
                    this.model.active = 1;
                    this.model.has_salary = 1;
                    this.model.payment_event_nature_id = 1;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Cadastro"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('paymentExport', ['ActionUpdatePaymentEvent', 'ActionCreatePaymentEvent']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdatePaymentEvent(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreatePaymentEvent(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },
    }
}
</script>