var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    _vm._l(_vm.collections, function(item, key) {
      return _c(
        "v-col",
        { key: key, attrs: { cols: "4" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-alert",
                {
                  attrs: { outlined: "", color: item.active ? "green" : "red" }
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { "three-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "div",
                                { staticClass: "text-overline mb-4" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: item.active ? "green" : "red"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.active
                                            ? "mdi-account-check"
                                            : "mdi-account-lock"
                                        ) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-h5 mb-1" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-inline-block text-truncate",
                                      staticStyle: { "max-width": "260px" }
                                    },
                                    [_vm._v(_vm._s(item.name) + " ")]
                                  )
                                ]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(item.email))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-avatar",
                            { attrs: { tile: "", size: "80" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mdi-48px",
                                  attrs: { color: "grey" }
                                },
                                [_vm._v(" mdi-account ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-caption grey--text font-weight-light"
                        },
                        [_vm._v(_vm._s(item.updated_at))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "", color: "primary", small: "" } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        { attrs: { icon: "", color: "red", small: "" } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }