var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.toolTip
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  to: _vm.to,
                                  target: _vm.target,
                                  disabled: _vm.disabled,
                                  loading: _vm.loading,
                                  icon: ""
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small:
                                    _vm.small ||
                                    (!_vm.cancelAutoSmall &&
                                      _vm.$vuetify.breakpoint.name != "xs"),
                                  color: _vm.color
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.$emit("click")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1967590182
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.toolTip))])]
          )
        : _c(
            "v-btn",
            {
              attrs: {
                to: _vm.to,
                target: _vm.target,
                disabled: _vm.disabled,
                loading: _vm.loading,
                icon: ""
              }
            },
            [
              _c(
                "v-icon",
                {
                  attrs: {
                    small:
                      _vm.small ||
                      (!_vm.cancelAutoSmall &&
                        _vm.$vuetify.breakpoint.name != "xs"),
                    color: _vm.color
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.$emit("click")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }