<template>
    <div>
        <v-app-bar app clipped-left flat :color="isStaging ? 'red lighten-1' : 'grey lighten-4'" v-if="show">
            <v-app-bar-nav-icon v-if="menu.length > 0" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title><a href="/"><v-img src="/img/sysclass_logo.png" max-width="150" alt="Logo SysClass" /></a></v-toolbar-title>
            <v-spacer></v-spacer>
            <strong class="font-bold white--text" v-if="isStaging">VOCÊ ESTÁ EM UM AMBIENTE DE TESTE!</strong>
            <v-spacer></v-spacer>
                <v-row v-show="showChangeWorkTime" justify="end">
                    <v-col cols="12" md="4">
                        <SysSelectWorkTime
                            ref="selectWorkTime"
                            v-if="menu.length > 0"
                            :loading="loadingWorkTime"
                            label="Período"
                            class="mt-6 pr-2"
                            filter-active
                            v-bind:model.sync="acesso.periodo"
                            @input="changeWorkTime"
                            dense
                            outlined
                            return-object
                        />
                    </v-col>
                </v-row>
                
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn style="cursor: help;" small v-bind="attrs" v-on="on" v-if="user.id && user.mode == 'user'"  to="/faq-questions" icon>
                        <v-icon small color="indigo">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span> Ajuda </span>
            </v-tooltip>
            

            <v-menu v-if="user && user.id && $route.name != 'availability-teacher'" :close-on-content-click="false" :nudge-width="100" offset-y open-on-hover>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="mr-1">
                        <EmcBaseAvatar 
                            :size="36"
                            :src="user.url_avatar"
                            :text="user.initials"
                            v-if="user.id"
                        />
                        <!-- <v-icon color="grey darken-1">keyboard_arrow_down</v-icon> -->
                    </v-btn>
                </template>

                <v-card :max-width="400">
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                
                                    <EmcBaseAvatar 
                                        :size="36"
                                        :src="user.url_avatar"
                                        :text="user.initials"
                                        v-if="user.id"
                                    />
                                
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ user.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list>
                        
                        <v-list-item v-if="(acesso.instituicao && acesso.instituicao.id)" to="/profile" @click="showMenu = !showMenu">
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Meus Dados</v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if="(acesso.instituicao && acesso.instituicao.id && user.is_master)" to="/my-tickets" @click="showMenu = !showMenu">
                            <v-list-item-icon>
                                <v-icon>mdi-text-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Meus chamados</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click.stop.prevent="sair()">
                            <v-list-item-icon>
                                <v-icon>{{ exit ? 'mdi-spin mdi-loading' : 'exit_to_app'}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Sair</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

        </v-app-bar>

        <v-navigation-drawer app clipped left v-model="drawer" temporary>
            <v-list nav dense>
                <template v-for="item in menu">
                    <v-list-group  v-if="Object.keys(item.children).length > 0"  :key="item.id"  v-model="item.model"  :prepend-icon="item.icon">
                
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.to">

                            <v-list-item-action v-if="child.icon" >
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                
                  <v-list-item-content>
                    <v-list-item-title >{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

            </v-list-group>

            <v-list-item v-else :key="item.id" link :to="item.to">
              
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
            </v-list>
        </v-navigation-drawer>

    </div>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import { user } from '../../../modules/auth/store/getters';

export default {
    name: "SysCoreNavigationBar",
    data: () => ({
        drawer: false,
        group: null,
        showMenu: false,
        exit: false,
        env_sysclass: process.env.VUE_APP_ENV,
        model: null,
        showChangeWorkTime: true,
        loadingWorkTime: false,
    }),
    computed: {
        ...mapState('auth', ['acesso', 'user', 'menu']),
        show() {
            return this.$route.name !== 'login' && this.$route.name !== 'access-teacher' && this.$route.name !== 'login/teacher';
        },

        isStaging(){
            return !this.env_sysclass || !this.env_sysclass.toString().includes('production');
        },
    },
    watch: {
        
        $route (to, from){
            
            if(to.query.refreshWorkTime){
                this.$refs.selectWorkTime.getItems();
            }
            
            this.showChangeWorkTime = (to.path != '/worktime');
        }
    },
    methods: {
      ...mapActions('auth', ['ActionSignOut', 'ActionSetAcesso', 'ActionSetSession', 'ActionSetToken']),
      async changeWorkTime(periodo) {
        
        var newAccess = {};
        Object.assign(newAccess, this.acesso);
        newAccess.periodo = periodo;
        this.loadingWorkTime = true;

        await this.ActionSetAcesso(newAccess);

        var sessionPayload = {
            educational_group_id:   newAccess.grupo.id,
            institution_id:         newAccess.instituicao.id,
            campuses:               newAccess.campus.map(o => ({ id: o['id'], name: o['name'], initials: o['initials'], abbreviation: o['abbreviation'], institution_id: o['institution_id'], brand_id: o['brand_id'] })),
            work_time_id:           periodo.id,
        }

        await this.ActionSetSession(sessionPayload)
                    .then((r) => {
                      this.ActionSetToken(r.data.token)
                        .finally(() => {
                            this.loadingWorkTime = false;
                            window.location.reload()
                        });
                    })

      },
      sair(){
        this.exit = true;
        this.ActionSignOut()
            .then(() => { this.exit = false; })
      }
    }
}
</script>