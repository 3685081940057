<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model.teacher" 
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                :disabled="teacher != null"
                                autofocus
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field 
                                v-model="model.registration" 
                                :error="!!errors.registration" 
                                :error-messages="errors.registration" 
                                label="Matrícula"
                                clearable
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-company
                                :model.sync="model.company_id"
                                :error="errors.length > 0"  
                                :errors="errors.company_id"
                                label="Empresa"
                                auto-complete
                                clearable
                                dense
                            /> 
                        </v-col>
                        
                        <v-col cols="12" sm="6">
                            <sys-select-branch
                                :model.sync="model.branch_id"
                                :error="errors.length > 0"  
                                :errors="errors.branch_id"
                                label="Filial"
                                auto-complete
                                clearable
                                :company-id="model.company_id"
                                :disabled="model.company_id == null"
                                dense
                            /> 
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-combobox
                                v-model="model.campuses"
                                :items="filteredItemsCampus"
                                :error="!!errors.campuses" 
                                :error-messages="errors.campuses" 
                                label="Campus"
                                item-text="abbreviation"
                                multiple
                                dense
                                clearable
                            >
                                <template v-slot:selection="{ item, index }">

                                    <span v-if="index === 0">{{ item.abbreviation }}</span>
                                
                                    <span
                                        v-if="index === 1"
                                        class="grey--text caption ml-1"
                                    >
                                        (+{{ model.campuses.length - 1 }} outros)
                                    </span>
                                </template>
                                
                            </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-teacher-work-regime 
                                :model.sync="model.teacher_work_regime_id"
                                :error="errors.length > 0"  
                                :errors="errors.teacher_work_regime_id"
                                label="Regime de Trabalho*"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-teacher-position
                                :model.sync="model.teacher_position_id"
                                :error="errors.length > 0"  
                                :errors="errors.teacher_position_id"
                                label="Cargo*"
                                autoComplete
                                dense
                                itemText="name_complete"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-relationship-type
                                :model.sync="model.relationship_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.relationship_type_id"
                                label="Tipo Relação*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-course-level
                                :model.sync="model.courseLevels"
                                :error="errors.length > 0"  
                                :errors="errors.courseLevels"
                                label="Nível de Curso*"
                                multiple
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-course-modality
                                :model.sync="model.courseModalities"
                                :error="errors.length > 0"  
                                :errors="errors.courseModalities"
                                label="Modalidade de Ensino*"
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_admission" 
                                label="Data Admissão" 
                                :errorMessages="errors.date_admission"
                                :show="dialog"
                                :readonly="false"
                                :openOnClick="false"
                                clearable
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_resignation" 
                                label="Data Desligamento" 
                                :errorMessages="errors.date_resignation"
                                :show="dialog"
                                :readonly="false"
                                :openOnClick="false"
                                clearable
                                dense
                            />
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-text-field 
                                v-model="model.hour_value" 
                                append-icon="mdi-cash" 
                                dense 
                                :error="errors.length > 0" 
                                :error-messages="errors.hour_value" 
                                label="Valor Hora" 
                                v-mask-decimal.br="2" 
                                maxlength="6"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field 
                                v-model="model.salary" 
                                append-icon="mdi-account-cash" 
                                dense 
                                :error="errors.length > 0" 
                                :error-messages="errors.salary" 
                                label="Salário" 
                                v-mask-decimal.br="2" 
                                maxlength="10"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field 
                                v-model="model.workload" 
                                append-icon="mdi-clock-check-outline" 
                                dense 
                                :error="errors.length > 0" 
                                :error-messages="errors.workload" 
                                label="Carga Horária" 
                                v-mask-decimal.br 
                                maxlength="3"
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-text-field 
                                v-model="model.code"
                                code
                                :error-messages="errors.code" 
                                label="Código"
                                dense
                            />
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="model.note"
                                outlined
                                name="input-7-4"
                                label="Anotações"
                                :value="model.note"
                                :error="errors.length > 0"  
                                :error-messages="errors.note"
                                rows="2"
                                row-height="20"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                clearable
                             />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-teacher-contract-type
                                :model.sync="model.teacher_contract_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.teacher_contract_type_id"
                                label="Tipo Vínculo"
                            /> 
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-switch inset v-model="model.is_main" label="Vínculo Principal"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-switch inset color="purple" v-model="model.active" label="Vínculo Ativo"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterTeacherContract",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        itemsCampus: [],
        
    }),
    created(){
        this.loadCampus();
    },

    computed: {
      ...mapState('auth', ['acesso']),

      filteredItemsCampus () {

        return this.itemsCampus;
        
        // if(this.model && this.model.branch_id){
        //     //this.model.campuses = [];
        //     return this.itemsCampus.filter(c => c.branch_id == this.model.branch_id);
        // }else{
        //     return this.itemsCampus;
        // }

      },
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.teacher_contract_type_id = 1;
                    this.model.teacher_work_regime_id = 1;
                    this.model.is_main = 1;
                    this.model.active = 1;
                }

                if(this.teacher){
                    this.model.teacher_id = this.teacher.id;
                    this.model.teacher = this.teacher;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-handshake"
        },
        title: {
            type: String,
            default: "Vínculo do Professor"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        }
    },
    methods:{
        ...mapActions('teacherContract', ['ActionUpdateTeacherContract', 'ActionCreateTeacherContract']),
        ...mapActions('campus', ['ActionFindCampuses']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateTeacherContract(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateTeacherContract(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        loadCampus(){
            
            let payload = {
                column_order: 'name',
            };
            
            this.ActionFindCampuses(payload)
                .then((res) => {
                    this.itemsCampus = res.data; 
                })
                .finally(() => {
                    
                })
        },

    }
}
</script>