var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.model.subGroups,
              "hide-default-footer": "",
              "items-per-page": -1,
              "item-key": "index",
              "fixed-header": "",
              height: "300px"
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              disabled: !_vm.model || !_vm.model.id,
                              color: "indigo darken-1",
                              icon: ""
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.addItem()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.code",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("s-text-field", {
                      attrs: {
                        code: "",
                        "error-messages":
                          _vm.errors["items." + index + ".code"],
                        dense: ""
                      },
                      model: {
                        value: item.code,
                        callback: function($$v) {
                          _vm.$set(item, "code", $$v)
                        },
                        expression: "item.code"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.code_curriculum",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("s-text-field", {
                      attrs: {
                        "error-messages":
                          _vm.errors["items." + index + ".code_curriculum"],
                        dense: ""
                      },
                      model: {
                        value: item.code_curriculum,
                        callback: function($$v) {
                          _vm.$set(item, "code_curriculum", $$v)
                        },
                        expression: "item.code_curriculum"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.name",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("v-text-field", {
                      attrs: {
                        error: _vm.errors.length > 0,
                        "error-messages":
                          _vm.errors["items." + index + ".name"],
                        dense: ""
                      },
                      model: {
                        value: item.name,
                        callback: function($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name"
                      }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.showDeleteItem(item, index)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "error", dense: "" } },
                          [_vm._v(" mdi-delete ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ma-1",
                                attrs: {
                                  loading: _vm.loadingSave,
                                  disabled:
                                    _vm.loadingSave ||
                                    !_vm.model.id ||
                                    _vm.model.subGroups.length == 0,
                                  color: "success"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.updateItems()
                                  }
                                }
                              },
                              [
                                _vm._v(" Salvar "),
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", dense: "" } },
                                  [_vm._v(" mdi-content-save ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("emc-alert-modal", {
        attrs: {
          text: "Confirma e exclusão?",
          disabled: _vm.loadingDelete,
          show: _vm.show
        },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.loadingDelete,
                      disabled: _vm.loadingDelete
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }