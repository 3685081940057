export default [
    {
        path: '/partner_companies',
        name: 'partner_companies',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Empresa Parceira',
            icon: 'mdi-handshake',
        }
    },
]