var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "290px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-date-picker",
        {
          attrs: { scrollable: "" },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        },
        [
          _c("v-spacer"),
          _c("v-btn", { attrs: { text: "", color: "primary" } }, [
            _vm._v("Fechar")
          ]),
          _c("v-btn", { attrs: { text: "", color: "primary" } }, [_vm._v("OK")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }