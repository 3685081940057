var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-message-base", {
        ref: "message",
        attrs: {
          items: _vm.items,
          title: _vm.titleFormated,
          show: _vm.dialog,
          loading: _vm.loading,
          loadingUpdate: _vm.loadingUpdate,
          sent: _vm.sent
        },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          onSend: _vm.sendMessage,
          onDelete: _vm.deleteItem,
          onUpdate: _vm.updateMessage,
          getData: function($event) {
            return _vm.getData(false)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "tools",
            fn: function() {
              return [
                _c("s-switch", {
                  staticClass: "mt-5",
                  attrs: {
                    "text-helper": "Registro no nível da oferta",
                    label: "Oferta"
                  },
                  model: {
                    value: _vm.offerLevel,
                    callback: function($$v) {
                      _vm.offerLevel = $$v
                    },
                    expression: "offerLevel"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }