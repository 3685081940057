var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            data: _vm.model.teacher,
                            errors: _vm.errors.teacher_id,
                            label: "Professor*",
                            autoComplete: "",
                            disabled: _vm.teacher != null,
                            autofocus: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.registration,
                            "error-messages": _vm.errors.registration,
                            label: "Matrícula",
                            clearable: "",
                            dense: ""
                          },
                          model: {
                            value: _vm.model.registration,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "registration", $$v)
                            },
                            expression: "model.registration"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-company", {
                          attrs: {
                            model: _vm.model.company_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.company_id,
                            label: "Empresa",
                            "auto-complete": "",
                            clearable: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "company_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-branch", {
                          attrs: {
                            model: _vm.model.branch_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.branch_id,
                            label: "Filial",
                            "auto-complete": "",
                            clearable: "",
                            "company-id": _vm.model.company_id,
                            disabled: _vm.model.company_id == null,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "branch_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            items: _vm.filteredItemsCampus,
                            error: !!_vm.errors.campuses,
                            "error-messages": _vm.errors.campuses,
                            label: "Campus",
                            "item-text": "abbreviation",
                            multiple: "",
                            dense: "",
                            clearable: ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                var index = ref.index
                                return [
                                  index === 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.abbreviation))
                                      ])
                                    : _vm._e(),
                                  index === 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "grey--text caption ml-1"
                                        },
                                        [
                                          _vm._v(
                                            " (+" +
                                              _vm._s(
                                                _vm.model.campuses.length - 1
                                              ) +
                                              " outros) "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.model.campuses,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "campuses", $$v)
                            },
                            expression: "model.campuses"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-teacher-work-regime", {
                          attrs: {
                            model: _vm.model.teacher_work_regime_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.teacher_work_regime_id,
                            label: "Regime de Trabalho*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "teacher_work_regime_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-teacher-position", {
                          attrs: {
                            model: _vm.model.teacher_position_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.teacher_position_id,
                            label: "Cargo*",
                            autoComplete: "",
                            dense: "",
                            itemText: "name_complete"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "teacher_position_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-relationship-type", {
                          attrs: {
                            model: _vm.model.relationship_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.relationship_type_id,
                            label: "Tipo Relação*",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "relationship_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-course-level", {
                          attrs: {
                            model: _vm.model.courseLevels,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.courseLevels,
                            label: "Nível de Curso*",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "courseLevels", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-course-modality", {
                          attrs: {
                            model: _vm.model.courseModalities,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.courseModalities,
                            label: "Modalidade de Ensino*",
                            multiple: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "courseModalities",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_admission,
                            label: "Data Admissão",
                            errorMessages: _vm.errors.date_admission,
                            show: _vm.dialog,
                            readonly: false,
                            openOnClick: false,
                            clearable: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "date_admission",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("emc-calendar-date-picker2", {
                          attrs: {
                            model: _vm.model.date_resignation,
                            label: "Data Desligamento",
                            errorMessages: _vm.errors.date_resignation,
                            show: _vm.dialog,
                            readonly: false,
                            openOnClick: false,
                            clearable: "",
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "date_resignation",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "mask-decimal",
                              rawName: "v-mask-decimal.br",
                              value: 2,
                              expression: "2",
                              modifiers: { br: true }
                            }
                          ],
                          attrs: {
                            "append-icon": "mdi-cash",
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.hour_value,
                            label: "Valor Hora",
                            maxlength: "6",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.hour_value,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "hour_value", $$v)
                            },
                            expression: "model.hour_value"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "mask-decimal",
                              rawName: "v-mask-decimal.br",
                              value: 2,
                              expression: "2",
                              modifiers: { br: true }
                            }
                          ],
                          attrs: {
                            "append-icon": "mdi-account-cash",
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.salary,
                            label: "Salário",
                            maxlength: "10",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.salary,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "salary", $$v)
                            },
                            expression: "model.salary"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "mask-decimal",
                              rawName: "v-mask-decimal.br",
                              modifiers: { br: true }
                            }
                          ],
                          attrs: {
                            "append-icon": "mdi-clock-check-outline",
                            dense: "",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.workload,
                            label: "Carga Horária",
                            maxlength: "3",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.workload,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "workload", $$v)
                            },
                            expression: "model.workload"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código",
                            dense: ""
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            outlined: "",
                            name: "input-7-4",
                            label: "Anotações",
                            value: _vm.model.note,
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.note,
                            rows: "2",
                            "row-height": "20",
                            counter: 255,
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.note,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "note", $$v)
                            },
                            expression: "model.note"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-teacher-contract-type", {
                          attrs: {
                            model: _vm.model.teacher_contract_type_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.teacher_contract_type_id,
                            label: "Tipo Vínculo"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "teacher_contract_type_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Vínculo Principal" },
                          model: {
                            value: _vm.model.is_main,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_main", $$v)
                            },
                            expression: "model.is_main"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-switch", {
                          attrs: {
                            inset: "",
                            color: "purple",
                            label: "Vínculo Ativo"
                          },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }