import { service } from '../../../helpers/http'

export const ActionWebhooksIndex = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhooksIndex',
        payload: payload
    })
}

export const ActionWebhooksShow = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhooksShow',
        payload: payload
    })
}

export const ActionWebhooksStore = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhooksStore',
        payload: payload
    })
}

export const ActionWebhooksUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhooksUpdate',
        payload: payload
    })
}

export const ActionWebhooksDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhooksDestroy',
        payload: payload
    })
}

export const ActionWebhookEventsIndex = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhookEventsIndex',
        payload: payload
    })
}

export const ActionWebhookCallReload = ({ dispatch }, payload) => {
    return service({
        module: 'webhook',
        action: 'webhookCallReload',
        payload: payload
    })
}