var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-view-grid-plus-outline",
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-tabs",
                  {
                    staticClass: "ma-0",
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tab", { key: "main" }, [_vm._v("Dados Principais")]),
                    _c("v-tab", { key: "others" }, [_vm._v("Demais Dados")]),
                    _c("v-tab", { key: "dates" }, [_vm._v("Cronograma")]),
                    _c("v-tab", { key: "ambience" }, [_vm._v("Ambiente")]),
                    _c("v-tab", { key: "vacancies" }, [_vm._v("Alunos|Vagas")])
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c(
                      "v-tab-item",
                      { key: "main" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-course", {
                                  attrs: {
                                    modelObject: _vm.course,
                                    error:
                                      _vm.errors.course_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.course_id,
                                    label: "Curso*",
                                    disabled: _vm.disabledFields,
                                    autoComplete: ""
                                  },
                                  on: {
                                    "update:modelObject": function($event) {
                                      _vm.course = $event
                                    },
                                    "update:model-object": function($event) {
                                      _vm.course = $event
                                    },
                                    onLoad: _vm.loadGroups
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("sys-select-group", {
                                  attrs: {
                                    model: _vm.group_id,
                                    "course-id": _vm.course.id,
                                    modelObject: { course: _vm.course },
                                    error:
                                      _vm.errors.group_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.group_id,
                                    label: "Turma*",
                                    disableAdd: !_vm.course.id,
                                    disabled:
                                      _vm.disabledFields || !_vm.course.id,
                                    "auto-complete": "",
                                    "item-text": "name_complete",
                                    "with-curriculum": "",
                                    showAdd: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      _vm.group_id = $event
                                    },
                                    change: function($event) {
                                      _vm.selectedGroup = $event
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-discipline", {
                                  attrs: {
                                    model: _vm.model.discipline_id,
                                    data: _vm.model.discipline,
                                    error:
                                      _vm.errors.discipline_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.discipline_id,
                                    label: "Disciplina*",
                                    autoComplete: "",
                                    showAdd: "",
                                    itemText: "full_name",
                                    disciplines: _vm.selectedGroup
                                      ? _vm.selectedGroup.disciplinesCurriculum
                                      : null
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "discipline_id",
                                        $event
                                      )
                                    },
                                    "update:data": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "discipline",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.newGrid
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-work-load-type", {
                                      attrs: {
                                        model: _vm.model.work_load_type_id,
                                        error:
                                          _vm.errors.work_load_type_id &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.work_load_type_id,
                                        label: "Tipo CH*",
                                        dense: "",
                                        "auto-complete": ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "work_load_type_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "2" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.work_load &&
                                          _vm.errors.length > 0,
                                        "error-messages": _vm.errors.work_load,
                                        label: "CH Original*",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.work_load,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "work_load", $$v)
                                        },
                                        expression: "model.work_load"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "2" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.work_load_pay &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.work_load_pay,
                                        label: "CH Paga",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.work_load_pay,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "work_load_pay",
                                            $$v
                                          )
                                        },
                                        expression: "model.work_load_pay"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "2" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.work_load_pay &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.work_load_time,
                                        label: "CH Horário",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.work_load_time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "work_load_time",
                                            $$v
                                          )
                                        },
                                        expression: "model.work_load_time"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("sys-select-periodicity", {
                                  attrs: {
                                    model: _vm.model.periodicity_type_id,
                                    error:
                                      _vm.errors.periodicity_type_id &&
                                      _vm.errors.length > 0,
                                    errors: _vm.errors.periodicity_type_id,
                                    label: "Periodicidade*",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "periodicity_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    "error-messages": _vm.errors.code,
                                    label: "Código"
                                  },
                                  model: {
                                    value: _vm.model.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code", $$v)
                                    },
                                    expression: "model.code"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    code: "",
                                    disabled: "",
                                    "error-messages": _vm.errors.code_control,
                                    label: "Código Controle"
                                  },
                                  model: {
                                    value: _vm.model.code_control,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code_control", $$v)
                                    },
                                    expression: "model.code_control"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      disabled: !_vm.model.id,
                                                      loading:
                                                        _vm.loadingDivisionCreate,
                                                      color: "success darken-1"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.createDivisionGrid()
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v(" Dividir")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" Acrescentar divisão ")
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    name: "input-7-4",
                                    label: "Anotações",
                                    value: _vm.model.note,
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.note,
                                    rows: "2",
                                    "row-height": "20",
                                    counter: 255,
                                    rules: [
                                      function(v) {
                                        return (
                                          !v ||
                                          v.length <= 255 ||
                                          "Máximo de 255 caracteres atingido"
                                        )
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.model.note,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "note", $$v)
                                    },
                                    expression: "model.note"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "others" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-shift", {
                                  attrs: {
                                    model: _vm.model.shift_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.shift_id,
                                    label: "Turno da Disponibilidade",
                                    clearable: "",
                                    maxShift: 4
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "shift_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-work-time-date", {
                                  attrs: {
                                    model: _vm.model.work_time_date_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.work_time_date_id,
                                    label: "Período de Oferta"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "work_time_date_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-grid-modality", {
                                  attrs: {
                                    model: _vm.model.grid_modality_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.grid_modality_id,
                                    showAdd: "",
                                    clearable: "",
                                    label: "Modalidade"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "grid_modality_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-sub-group", {
                                  attrs: {
                                    model: _vm.model.sub_group_id,
                                    group: { id: _vm.group_id },
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.sub_group_id,
                                    label: "SubTurma",
                                    showAdd: "",
                                    clearable: "",
                                    "auto-complete": ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "sub_group_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-hour-group", {
                                  attrs: {
                                    model: _vm.model.hour_group_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.hour_group_id,
                                    clearable: "",
                                    label: "Grupo de Horário"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "hour_group_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-grid-category", {
                                  attrs: {
                                    model: _vm.model.grid_category_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.grid_category_id,
                                    showAdd: "",
                                    clearable: "",
                                    label: "Categoria"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "grid_category_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-specificity", {
                                  attrs: {
                                    model: _vm.model.specificity_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.specificity_id,
                                    showAdd: "",
                                    clearable: "",
                                    label: "Especialidade",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "specificity_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    dense: "",
                                    "text-helper":
                                      "Código utilizado para identificar disciplinas que pertecem ao mesmo grupo",
                                    "error-messages": _vm.errors.code_link,
                                    label: "Código Cluster"
                                  },
                                  model: {
                                    value: _vm.model.code_link,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "code_link", $$v)
                                    },
                                    expression: "model.code_link"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error:
                                      _vm.errors.work_load &&
                                      _vm.errors.length > 0,
                                    "error-messages": _vm.errors.group_number,
                                    label: "Nº Divisão",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.group_number,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "group_number", $$v)
                                    },
                                    expression: "model.group_number"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    error:
                                      _vm.errors.work_load &&
                                      _vm.errors.length > 0,
                                    "error-messages": _vm.errors.teacher_number,
                                    label: "Nº Professor",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.model.teacher_number,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "teacher_number", $$v)
                                    },
                                    expression: "model.teacher_number"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-date-mask-type", {
                                  attrs: {
                                    model: _vm.model.date_mask_type_id,
                                    error: _vm.errors.date_mask_type_id > 0,
                                    errors: _vm.errors.date_mask_type_id,
                                    label: "Máscara",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "date_mask_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-dynamic-time-grouping-type", {
                                  attrs: {
                                    model:
                                      _vm.model.dynamic_time_grouping_type_id,
                                    label: "Tipo de Agrupamento",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "dynamic_time_grouping_type_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  attrs: {
                                    "text-helper":
                                      "Bloquear atribuição de professor",
                                    inset: "",
                                    label: "Bloquear Atribuição"
                                  },
                                  model: {
                                    value: _vm.model.lock_attribution,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "lock_attribution",
                                        $$v
                                      )
                                    },
                                    expression: "model.lock_attribution"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-row"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("s-switch", {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "text-helper":
                                      "Grade paga via outras atividades",
                                    inset: "",
                                    label: "Pagar por Atividade"
                                  },
                                  model: {
                                    value: _vm.model.payment_by_activity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "payment_by_activity",
                                        $$v
                                      )
                                    },
                                    expression: "model.payment_by_activity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      dense: "",
                                                      disabled: !_vm.model.id,
                                                      loading:
                                                        _vm.loadingDivisionCreate,
                                                      color: "primary darken-1"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        $event.stopPropagation()
                                                        return _vm.addTeacher()
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-small": "" } },
                                                  [_vm._v("mdi-plus")]
                                                ),
                                                _vm._v("Professor")
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(" Adicionar Professor ")
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "dates" },
                      [
                        _c("sys-grid-register-dates", {
                          attrs: { model: _vm.model, errors: _vm.errors }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "ambience" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "4" } },
                                  [
                                    _c("sys-select-category-ambience", {
                                      attrs: {
                                        model: _vm.model.category_ambience_id,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.category_ambience_id,
                                        label: "Categoria de Ambiente",
                                        clearable: "",
                                        "auto-complete": "",
                                        dense: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "category_ambience_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "8" } },
                                  [
                                    _c("sys-select-ambience", {
                                      attrs: {
                                        model: _vm.model.ambience_id,
                                        error:
                                          _vm.errors.ambience_id &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.ambience_id,
                                        label: "Ambiente",
                                        clearable: "",
                                        dense: "",
                                        autoComplete: "",
                                        "category-id":
                                          _vm.model.category_ambience_id,
                                        "campus-id":
                                          _vm.model && _vm.model.group
                                            ? _vm.model.group.campus_id
                                            : -1
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "ambience_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-resource", {
                                      attrs: {
                                        model: _vm.model.resources,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.resources,
                                        multiple: "",
                                        label: "Recursos Necessários",
                                        dense: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "resources",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("sys-select-ambience-type", {
                                      attrs: {
                                        model: _vm.model.ambience_type_id,
                                        error: _vm.errors.ambience_type_id > 0,
                                        errors: _vm.errors.ambience_type_id,
                                        label: "Tipo de Ambiente",
                                        clearable: "",
                                        "auto-complete": "",
                                        dense: ""
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "ambience_type_id",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        clearable: "",
                                        dense: "",
                                        "error-messages": _vm.errors.link,
                                        label: "Link da Aula"
                                      },
                                      model: {
                                        value: _vm.model.link,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "link", $$v)
                                        },
                                        expression: "model.link"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Habilitado caso a grade deve possuir link digital de aula",
                                        inset: "",
                                        label: "Possui Link"
                                      },
                                      model: {
                                        value: _vm.model.has_link,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "has_link", $$v)
                                        },
                                        expression: "model.has_link"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c("s-switch", {
                                      attrs: {
                                        "text-helper":
                                          "Necessário vínculo de ambiente",
                                        inset: "",
                                        label: "Possui Ambiente"
                                      },
                                      model: {
                                        value: _vm.model.has_ambience,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "has_ambience",
                                            $$v
                                          )
                                        },
                                        expression: "model.has_ambience"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "vacancies" },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students &&
                                          _vm.errors.length > 0,
                                        "error-messages": _vm.errors.students,
                                        label: "Alunos",
                                        clearable: "",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.students,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "students", $$v)
                                        },
                                        expression: "model.students"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_forecast &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.students_forecast,
                                        label: "Previsão Alunos",
                                        clearable: "",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.model.students_forecast,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_forecast",
                                            $$v
                                          )
                                        },
                                        expression: "model.students_forecast"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_max &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.students_max,
                                        label: "Vagas",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.model.students_max,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_max",
                                            $$v
                                          )
                                        },
                                        expression: "model.students_max"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_max &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.students_max_waiting_list,
                                        label: "Vagas Lista de Espera",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.model.students_max_waiting_list,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_max_waiting_list",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.students_max_waiting_list"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_max &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.students_max_freshmen,
                                        label: "Vagas Calouros",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.model.students_max_freshmen,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_max_freshmen",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.students_max_freshmen"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_max &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors
                                            .students_max_others_curriculums,
                                        label: "Vagas Outras Matrizes",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.model
                                            .students_max_others_curriculums,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_max_others_curriculums",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.students_max_others_curriculums"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3" } },
                                  [
                                    _c("s-text-field", {
                                      attrs: {
                                        dense: "",
                                        error:
                                          _vm.errors.students_max &&
                                          _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors
                                            .students_max_others_courses,
                                        label: "Vagas Outros Cursos",
                                        clearable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.model.students_max_others_courses,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "students_max_others_courses",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.students_max_others_courses"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.errors.length > 0
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function(error) {
                      return _c("li", { key: error }, [
                        _vm._v(" " + _vm._s(error) + " ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }