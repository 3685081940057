var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _c("v-progress-linear", {
        staticClass: "mb-0",
        attrs: { indeterminate: "", color: "blue" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }