var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : _vm.maxWidth,
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [_vm._t("body")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }