var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "dialog-top-transition",
        scrollable: "",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "60%"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "primary lighten-2 mb-4 elevation-1",
                  attrs: { dense: "", height: "35" }
                },
                [
                  _vm.model
                    ? _c("v-toolbar-title", [
                        _c("span", { staticClass: "white--text subtitle-1" }, [
                          _vm._v(_vm._s(_vm.model.name_complete))
                        ])
                      ])
                    : _vm._e(),
                  _c("v-spacer"),
                  _vm.model && _vm.model.images.length > 1
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _c("v-switch", {
                                          attrs: { "hide-details": "" },
                                          model: {
                                            value: _vm.cycle,
                                            callback: function($$v) {
                                              _vm.cycle = $$v
                                            },
                                            expression: "cycle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1223858145
                          )
                        },
                        [_c("span", [_vm._v(" Apresentação automática ")])]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      nativeOn: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { small: "", color: "white lighten-2" } },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.model
                ? _c(
                    "v-carousel",
                    {
                      attrs: {
                        cycle: _vm.cycle,
                        "show-arrows": _vm.model && _vm.model.images.length > 1,
                        "show-arrows-on-hover":
                          _vm.model && _vm.model.images.length > 1,
                        "hide-delimiters":
                          !_vm.model || _vm.model.images.length <= 1
                      }
                    },
                    _vm._l(_vm.model.images, function(item, i) {
                      return _c("v-carousel-item", {
                        key: i,
                        attrs: {
                          src: item.url,
                          "reverse-transition": "fade-transition",
                          transition: "fade-transition"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "ma-2 white--text title" },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ma-2 white--text subtitle-1"
                                    },
                                    [_vm._v(_vm._s(item.description))]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }