<template>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-x
        left
        bottom
        :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="grey"
            :small="small"
          > 
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-cog</v-icon>  
                </template>
                <span>{{ toolTip }}</span>
            </v-tooltip>
            
          </v-btn>
        </template>

      <v-card>
        <v-card-title class="ma-0 pa-0">
            <v-app-bar dense class="grey lighten-5 mb-4 elevation-1'">
                <v-toolbar-title class="subtitle-1">
                  {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="toolbar" />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small class="mr-1" @click.native="menu = false" v-bind="attrs" v-on="on">
                          <v-icon color="red lighten-2">mdi-close</v-icon>
                      </v-btn>
                  </template>
                  <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>
        </v-card-title>
        <v-card-text>
              <emc-util-scroll maxHeight="400px">
                <v-container>
                <v-row>
                  <v-col cols="12" :sm="config.sm ? config.sm : 12" v-for="(config, index) in configs" :key="index">
                      <!-- Switch -->
                      <s-switch 
                          v-if="config.type == 'boolean'" 
                          :text-helper="config.textHelper" 
                          :label="config.label" 
                          inset
                          v-model="model[config.field]"
                        />

                        <!-- Text-Area -->
                        <v-textarea
                          v-else-if="config.type == 'text-area'"
                          v-model="model[config.field]"
                          outlined
                          name="input-7-4"
                          :label="config.label"
                          :error="errors ? errors.length > 0 : false"  
                          :errors="errors ? errors[config.field] : null"
                          clearable
                          auto-grow
                          rows="1"
                        />

                        <!-- Text Field -->
                        <s-text-field 
                          v-else-if="config.type == 'text-field'"
                          :text-helper="config.textHelper" 
                          :error-messages="errors ? errors[config.field] : null" 
                          v-model="model[config.field]" 
                          :label="config.label"
                          :clearable="config.clearable"
                          :placeholder="config.placeholder"
                        />

                        <!-- Time Picker -->
                        <emc-calendar-time-picker 
                          v-else-if="config.type == 'time-picker'"
                          :error="!!errors[config.field]" 
                          :errors="errors[config.field]" 
                          :time.sync="model[config.field]" 
                          :label="config.label" 
                        />
                      </v-col>
                  </v-row>
                </v-container>

                <!-- <v-list-item v-for="(config, index) in configs" :key="index">
                  
                  <v-list-item-content>

                  </v-list-item-content>
                  
                </v-list-item>

                <v-list-item v-if="$slots.custom || !!$scopedSlots.custom">
                  <v-list-item-content>
                    <slot name="custom" />
                  </v-list-item-content>
                </v-list-item> -->

              </emc-util-scroll>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
    name: "SysPopOverConfigBase",
    data: () => ({
      menu: false,
    }),
    watch: {
        
        model(model_new, model_old) {
            
            if(!model_new.id){
              this.menu = false;
            }
           
        },

    },
    props: {
        model: {
            type: Object,
            required: true
        },
        configs: {
            type: Array,
            required: true
        },
        configs2: {
            type: Array,
            default: null,
        },
        title: {
            type: String,
            default: 'Configurações'
        },
        errors: {
          type: Object,
        },
        small: {
          type: Boolean,
          default: false,
        },
        toolTip: {
            type: String,
            default: 'Configurações'
        }
    },
}
</script>