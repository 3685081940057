var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, sm: _vm.sm, md: _vm.md } },
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "290px",
            "min-width": "auto"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.label,
                            "append-icon": "mdi-calendar",
                            readonly: _vm.readonly,
                            outlined: _vm.outlined,
                            error: _vm.error,
                            "error-messages": _vm.errorMessages
                          },
                          model: {
                            value: _vm.dateFormatted,
                            callback: function($$v) {
                              _vm.dateFormatted = $$v
                            },
                            expression: "dateFormatted"
                          }
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c("v-date-picker", {
            attrs: { color: _vm.color },
            on: {
              input: function($event) {
                _vm.menu = false
              }
            },
            model: {
              value: _vm.date,
              callback: function($$v) {
                _vm.date = $$v
              },
              expression: "date"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }