<template>
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '80%' : maxWidth" persistent>
        <v-card
                class="mx-auto elevation-0"
                height="80hv"
                
            >
                    <v-card-title dark class="grey lighten-5">
                        <v-icon left></v-icon>&nbsp;&nbsp;
                        Impressão de Diário de Aula
                    </v-card-title>
                    <v-card-text>
                        <sys-print-time-sheet-base />
                    </v-card-text>
            <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn :color="type=='excel' ? 'success' : 'error'" @click.prevent.stop="printTimeSheet()" :disabled="modelTeachers.length == 0" >Imprimir<v-icon right dark>{{ type == 'excel' ? 'mdi-microsoft-excel' : 'mdi-file-pdf-box-outline' }}</v-icon></v-btn> -->
                    <v-btn @click.native="dialog = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: "SysPrintTimeSheetCard",
     data: () => ({
         dialog: false,
     }),
     watch: {
        
        dialog(val_new, val_old) {
            
            if(val_new !== val_old) {
                this.$emit('update:show', val_new)
            }

        },

        show(val_new, val_old) {
            this.dialog = val_new;
        },
    },
    props: {
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            default: false
        },
        
    },
}
</script>