<template>
    <v-col :cols="cols" :sm="sm" :md="md">
        <v-card outlined class="py-2 px-3">
            <span class="grey--text text--darken-1">Cor: </span>
            <v-btn text @click="showColors = true" icon>
                <v-icon v-if="!colorSelected">mdi-select</v-icon>
                <v-sheet v-else :color="colorSelected" height="20" width="20" outlined></v-sheet>
            </v-btn>
            <EmcDialogColors :show.sync="showColors" :color.sync="colorSelected"/>

        </v-card>
    </v-col>
</template>

<script>
export default {
    name: 'EmcButtonColor',
    data() {
        return {
            showColors: false
        }
    },
    props: {
        value: { type: String },
        sm: { type: String, default: "6" },
        md: { type: String, default: "4" },
        cols: { type: String, default: "12" },
    },
    computed: {
        colorSelected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>

<style lang="css" scoped>

.theme--light.v-sheet--outlined {
    border-color: #949393 !important;
}
</style>