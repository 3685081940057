<template>
    <span>
        <emc-button-icon :color="color" :icon="icon" @click.prevent.stop="download(report)" />
    </span>
</template>

<script>
import  { myMixin } from '../../../mixins';
export default {
    name: 'EmcButtonIconExport',
    mixins: [myMixin],
    props: {
        color: {
            type: String,
            default: "green darken-1"
        },
        icon: {
            type: String,
            default: "mdi-microsoft-excel"
        },
        btnSmall: {
            type: Boolean,
            default: false
        },
        iconSmall: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: "Baixar Relatório"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        href: {

        },
        target: {
            type: String,
            default: '_blank'
        },
        report: {
            required: true
        }
    },
    emits: ['click']
}
</script>