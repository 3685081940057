var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { app: "", fixed: "" } },
    [
      _c("span", { attrs: { id: "footer" } }, [
        _c(
          "a",
          { attrs: { href: "https://emcsoft.com.br", target: "_blank" } },
          [
            _c("v-img", {
              attrs: { src: "/img/emcsoft_logo.png", "max-width": "80" }
            })
          ],
          1
        )
      ]),
      _c("v-spacer"),
      _c("span", { attrs: { id: "footer" } }, [
        _vm.acesso.grupo != null
          ? _c("span", { attrs: { color: "white" } }, [
              _c("strong", [_vm._v(_vm._s(_vm.footer))])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }