var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item && _vm.item.category && !_vm.item.category.default
    ? _c(
        "span",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            _vm._b(
                              {
                                staticStyle: { cursor: "help" },
                                attrs: {
                                  color: _vm.color
                                    ? _vm.color
                                    : _vm.item.category.color
                                    ? _vm.item.category.color
                                    : "primary",
                                  label: "",
                                  "x-small": "",
                                  outlined: ""
                                }
                              },
                              "v-chip",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.icon
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { "x-small": "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("click")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.icon))]
                                )
                              : _vm.item.category.icon
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { "x-small": "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("click")
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.item.category.icon))]
                                )
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.item.category.initials) + " "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                4253801734
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.item.category.name))])]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }