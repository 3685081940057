var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sys-pop-over-config-base", {
        attrs: { model: _vm.model, configs: _vm.configs, errors: _vm.errors },
        scopedSlots: _vm._u(
          [
            _vm.model.settings
              ? {
                  key: "custom",
                  fn: function() {
                    return [
                      _c("sys-select-activity-item-status", {
                        attrs: {
                          model: _vm.model.settings.activity_item_status,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.activity_item_status_id,
                          label: "Status Atividade para pagamento",
                          multiple: ""
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(
                              _vm.model.settings,
                              "activity_item_status",
                              $event
                            )
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }