var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-file-input", {
        attrs: {
          counter: _vm.counter,
          label: _vm.label,
          multiple: _vm.multiple,
          placeholder: "Selecione os arquivos",
          outlined: _vm.outlined,
          "show-size": 1000,
          name: _vm.name,
          error: _vm.error
        },
        on: { change: _vm.handleFileChange },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var index = ref.index
              var text = ref.text
              return [
                index < 2
                  ? _c(
                      "v-chip",
                      {
                        attrs: {
                          outlined: "",
                          label: "",
                          small: "",
                          color: "grey grey--text text--darken-3"
                        }
                      },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    )
                  : index === 2
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "text-overline grey--text text--darken-3 mx-2"
                      },
                      [
                        _vm._v(
                          " +" + _vm._s(_vm.model.length - 2) + " Arquivo(s) "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }