var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            inset: "",
                            dense: "",
                            "error-messages": _vm.errors.name,
                            label: "Nome*"
                          },
                          model: {
                            value: _vm.model.name,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "name", $$v)
                            },
                            expression: "model.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-user", {
                          attrs: {
                            model: _vm.model.user_id_focal,
                            data: _vm.model.user,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.user_id_focal,
                            label: "Usuário Focal",
                            autoComplete: "",
                            dense: "",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "user_id_focal",
                                $event
                              )
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "user", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-big-occupation-area", {
                          attrs: {
                            model: _vm.model.big_occupation_area_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.big_occupation_area_id,
                            label: "Grande Área*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "big_occupation_area_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("sys-select-occupation-area", {
                          attrs: {
                            model: _vm.model.occupation_area_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.occupation_area_id,
                            "big-occupation-area-id":
                              _vm.model.big_occupation_area_id,
                            label: "Área*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "occupation_area_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-discipline", {
                          attrs: {
                            model: _vm.model.discipline_id,
                            data: _vm.model.discipline,
                            error:
                              _vm.errors.discipline_id && _vm.errors.length > 0,
                            errors: _vm.errors.discipline_id,
                            label: "Unidade Curricular*",
                            "item-text": "name",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "discipline_id",
                                $event
                              )
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "discipline", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Se a parceria for local, obrigatório informar marca e campus",
                            inset: "",
                            label: "Local"
                          },
                          model: {
                            value: _vm.model.is_local,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "is_local", $$v)
                            },
                            expression: "model.is_local"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "5" } },
                      [
                        _c("sys-select-brand", {
                          attrs: {
                            model: _vm.model.brand_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.brand_id,
                            label: "Marca",
                            clearable: "",
                            disabled: !_vm.model.is_local
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "brand_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "5" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campus_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.campus_id,
                            "brand-id": _vm.model.brand_id,
                            label: "Campus",
                            clearable: "",
                            disabled: !_vm.model.is_local
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campus_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-partner-company", {
                          attrs: {
                            model: _vm.model.companies,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.companies,
                            label: "Empresas*",
                            multiple: "",
                            "auto-complete": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "companies", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-text-field", {
                          attrs: {
                            inset: "",
                            code: "",
                            "error-messages": _vm.errors.code,
                            label: "Código"
                          },
                          model: {
                            value: _vm.model.code,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "code", $$v)
                            },
                            expression: "model.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: { inset: "", label: "Parceria Ativa" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição",
                            counter: 255,
                            rows: "2",
                            rules: [
                              function(v) {
                                return (
                                  !v ||
                                  v.length <= 255 ||
                                  "Máximo de 255 caracteres atingido"
                                )
                              }
                            ],
                            outlined: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }