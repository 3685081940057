var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-comment-question-outline",
      show: _vm.dialog,
      maxWidth: "50%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabledCategory,
                        expression: "!disabledCategory"
                      }
                    ]
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-faq-category", {
                          attrs: {
                            model: _vm.model.faq_category_id,
                            error:
                              _vm.errors.faq_category_id &&
                              _vm.errors.length > 0,
                            errors: _vm.errors.faq_category_id,
                            label: "Categoria*",
                            showAdd: "",
                            disabled: _vm.disabledCategory
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "faq_category_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.title,
                            "error-messages": _vm.errors.title,
                            label: "Título*"
                          },
                          model: {
                            value: _vm.model.title,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "title", $$v)
                            },
                            expression: "model.title"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("span", [_vm._v("Conteúdo*")]),
                        _c("vue-editor", {
                          attrs: { editorToolbar: _vm.customToolbar },
                          model: {
                            value: _vm.model.content,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "content", $$v)
                            },
                            expression: "model.content"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.key_words,
                            "error-messages": _vm.errors.key_words,
                            label: "Palavras chave"
                          },
                          model: {
                            value: _vm.model.key_words,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "key_words", $$v)
                            },
                            expression: "model.key_words"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: !!_vm.errors.order,
                            "error-messages": _vm.errors.order,
                            label: "Ordem*"
                          },
                          model: {
                            value: _vm.model.order,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "order", $$v)
                            },
                            expression: "model.order"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-switch", {
                          attrs: { inset: "", label: "Ativo" },
                          model: {
                            value: _vm.model.active,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "active", $$v)
                            },
                            expression: "model.active"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }