import { default as teacher } from './teacher/routes'

export default [
    ...teacher,
    {
        path: '/login',
        name: 'login',
        component: () => import ('./pages/Login')
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        component: () => import ('./pages/ResetPassword')
    },
    {
        path: '/confirm_password/token/:token/email/:email',
        name: 'confirm_password',
        component: () => import ('./pages/ConfirmPassword')
    },
    {
        path: '/404',
        name: '404',
        component: () => import ('./pages/404'),
        meta: {
            title: 'Página não encontrada',
            icon: '',
            breadcrumbs: [
                { text: 'Página não encontrada', disabled: true, href: '/' }, 
            ],
        }    
    },
    {
        path: '/403',
        name: '403',
        component: () => import ('./pages/403'),
        meta: {
            title: 'Página não autorizada',
            icon: '',
            breadcrumbs: [
                { text: 'Página não autorizada', disabled: true, href: '/' }, 
            ],
        }    
    },

    {
        path: '/500',
        name: '500',
        component: () => import ('./pages/500'),
        meta: {
            title: 'Erro interno',
            icon: '',
            breadcrumbs: [
                { text: 'Erro interno', disabled: true, href: '/' }, 
            ],
        }    
    },
    {
        path: '/503',
        name: '503',
        component: () => import ('./pages/503'),
        meta: {
            title: 'Manutenção',
            icon: '',
            breadcrumbs: [
                { text: 'Manutenção', disabled: true, href: '/' }, 
            ],
        }    
    },
]