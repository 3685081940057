import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=55ca2eb4&"
import script from "./Details.vue?vue&type=script&lang=js&"
export * from "./Details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAppBar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VIcon,VSimpleTable,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VToolbarTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55ca2eb4')) {
      api.createRecord('55ca2eb4', component.options)
    } else {
      api.reload('55ca2eb4', component.options)
    }
    module.hot.accept("./Details.vue?vue&type=template&id=55ca2eb4&", function () {
      api.rerender('55ca2eb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sys/activity_item/Details.vue"
export default component.exports