var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto elevation-0 ma-1 mr-2",
      style: _vm.style,
      attrs: {
        outlined: _vm.outlined,
        prominent: _vm.prominent,
        width: _vm.width
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }