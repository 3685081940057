var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      ref: "dialog",
      attrs: { persistent: "", width: "290px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        label: _vm.label,
                        "append-icon": "access_time",
                        readonly: "",
                        error: _vm.error,
                        "error-messages": _vm.errors,
                        dense: _vm.dense,
                        clearable: _vm.clearable,
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.modelText,
                        callback: function($$v) {
                          _vm.modelText = $$v
                        },
                        expression: "modelText"
                      }
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.modal,
        callback: function($$v) {
          _vm.modal = $$v
        },
        expression: "modal"
      }
    },
    [
      _vm.modal
        ? _c(
            "v-time-picker",
            {
              attrs: { "full-width": "", format: "24hr" },
              model: {
                value: _vm.modelHour,
                callback: function($$v) {
                  _vm.modelHour = $$v
                },
                expression: "modelHour"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.setTime(_vm.modelHour)
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }