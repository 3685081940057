var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "50%",
      disabledSave: _vm.disabledUpdate,
      textButtonSave: "Salvar"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-container",
                  [
                    _vm.registerType == "offer"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-offer-item-status", {
                                  attrs: {
                                    model: _vm.model.offer_item_status_id,
                                    error: _vm.errors.length > 0,
                                    errors:
                                      _vm.errors[
                                        "items.0.offer_item_status_id"
                                      ],
                                    label: "Status Oferta",
                                    dense: "",
                                    statusIds: "3",
                                    includeNull: "",
                                    nameNull: "A Cursar"
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "offer_item_status_id",
                                        $event
                                      )
                                    },
                                    change: _vm.changeStatus
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-offer-item-day", {
                                  attrs: {
                                    model: _vm.model.offer_item_day_id,
                                    label: "Dia da Semana",
                                    dense: "",
                                    clearable: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "offer_item_day_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-grid-modality", {
                                  attrs: {
                                    model: _vm.model.grid_modality_id_rule,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.grid_modality_id_rule,
                                    clearable: "",
                                    label: "Modalidade",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "grid_modality_id_rule",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "offer"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("sys-select-join-category", {
                                  attrs: {
                                    model: _vm.model.join_category_id,
                                    error: _vm.errors.length > 0,
                                    errors: _vm.errors.join_category_id,
                                    clearable: "",
                                    label: "Tipo de Junção",
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "join_category_id",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "8" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    name: "input-7-4",
                                    label: "Observação do Gestor",
                                    value: _vm.model.annotation,
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.annotation,
                                    clearable: "",
                                    rows: "2",
                                    "row-height": "20"
                                  },
                                  model: {
                                    value: _vm.model.annotation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "annotation", $$v)
                                    },
                                    expression: "model.annotation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("sys-select-base-group-status", {
                                  attrs: {
                                    model:
                                      _vm.model.base_group_status_id_calculate,
                                    label: "Status*",
                                    error:
                                      _vm.errors.brand_id_destiny &&
                                      _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.base_group_status_id_calculate,
                                    dense: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "base_group_status_id_calculate",
                                        $event
                                      )
                                    },
                                    change: function($event) {
                                      return _vm.forceRerender()
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-brand", {
                                      attrs: {
                                        model: _vm.model.brand_id_destiny,
                                        label: "Marca*",
                                        error:
                                          _vm.errors.brand_id_destiny &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.brand_id_destiny,
                                        dense: "",
                                        autoComplete: "",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "brand_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "5" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-institution", {
                                      attrs: {
                                        model: _vm.model.institution_id_destiny,
                                        label: "Instituicao*",
                                        error:
                                          _vm.errors.institution_id_destiny &&
                                          _vm.errors.length > 0,
                                        errors:
                                          _vm.errors.institution_id_destiny,
                                        dense: "",
                                        "auto-complete": "",
                                        "brand-id": _vm.model.brand_id_destiny,
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "institution_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-campus", {
                                      attrs: {
                                        model: _vm.model.campus_id_destiny,
                                        label: "Campus*",
                                        error:
                                          _vm.errors.campus_id_destiny &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.campus_id_destiny,
                                        dense: "",
                                        "auto-complete": "",
                                        "brand-id": _vm.model.brand_id_destiny,
                                        "institution-id":
                                          _vm.model.institution_id_destiny,
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "campus_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-shift", {
                                      attrs: {
                                        model: _vm.model.shift_id_destiny,
                                        label: "Turno*",
                                        error:
                                          _vm.errors.shift_id_destiny &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.shift_id_destiny,
                                        dense: "",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "shift_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-course", {
                                      attrs: {
                                        model: _vm.model.course_id_destiny,
                                        error:
                                          _vm.errors.course_id &&
                                          _vm.errors.length > 0,
                                        errors: _vm.errors.course_id_destiny,
                                        label: "Curso*",
                                        autoComplete: "",
                                        dense: "",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "course_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-category", {
                                      attrs: {
                                        model: _vm.model.category_id_destiny,
                                        error: _vm.errors.length > 0,
                                        errors: _vm.errors.category_id_destiny,
                                        clearable: "",
                                        dense: "",
                                        label: "Modalidade*",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "category_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _vm.renderComponent
                                  ? _c("sys-select-base-group", {
                                      attrs: {
                                        model: _vm.model.base_group_id_destiny,
                                        error: _vm.errors.length > 0,
                                        errors:
                                          _vm.errors.base_group_id_destiny,
                                        label: "Turma Composição Destino",
                                        "course-id":
                                          _vm.model.course_id_destiny,
                                        "campus-id":
                                          _vm.model.campus_id_destiny,
                                        "shift-id": _vm.model.shift_id_destiny,
                                        dense: "",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.model,
                                            "base_group_id_destiny",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("s-text-field", {
                                  attrs: {
                                    "error-messages":
                                      _vm.errors.curriculum_name_destiny,
                                    label: "Matriz*",
                                    disabled:
                                      _vm.model
                                        .base_group_status_id_calculate != 2,
                                    dense: ""
                                  },
                                  model: {
                                    value: _vm.model.curriculum_name_destiny,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "curriculum_name_destiny",
                                        $$v
                                      )
                                    },
                                    expression: "model.curriculum_name_destiny"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.renderComponent &&
                    _vm.registerType == "relocation" &&
                    _vm.model.base_group_status_id_calculate == 3
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    outlined: "",
                                    name: "input-7-4",
                                    label: "Motivo do Encerramento*",
                                    value: _vm.model.reason_note,
                                    error: _vm.errors.length > 0,
                                    "error-messages": _vm.errors.reason_note,
                                    clearable: "",
                                    rows: "2",
                                    "row-height": "20",
                                    counter: 255,
                                    rules: [
                                      function(v) {
                                        return (
                                          !v ||
                                          v.length <= 255 ||
                                          "Máximo de 255 caracteres atingido"
                                        )
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.model.reason_note,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "reason_note", $$v)
                                    },
                                    expression: "model.reason_note"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _vm.renderComponent
                                  ? _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        name: "input-7-4",
                                        label:
                                          "Observação do Remanejamento/Encerramento",
                                        value: _vm.model.note,
                                        error: _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors.observation_relocation,
                                        clearable: "",
                                        rows: "2",
                                        "row-height": "20",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      model: {
                                        value: _vm.model.observation_relocation,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "observation_relocation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.observation_relocation"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _vm.renderComponent
                                  ? _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        name: "input-7-4",
                                        label:
                                          "Observação do Remanejamento/Encerramento - Gestor",
                                        value: _vm.model.note,
                                        error: _vm.errors.length > 0,
                                        "error-messages":
                                          _vm.errors
                                            .observation_relocation_manager,
                                        clearable: "",
                                        rows: "2",
                                        "row-height": "20",
                                        disabled:
                                          _vm.model
                                            .base_group_status_id_calculate != 2
                                      },
                                      model: {
                                        value:
                                          _vm.model
                                            .observation_relocation_manager,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "observation_relocation_manager",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.observation_relocation_manager"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "relocation"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.observations,
                                    "item-key": "id",
                                    "item-text": "name",
                                    clearable: "",
                                    label: "Observação de Status"
                                  },
                                  model: {
                                    value: _vm.model.observation,
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, "observation", $$v)
                                    },
                                    expression: "model.observation"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.registerType == "equivalence"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c("sys-select-discipline", {
                                  attrs: {
                                    model: _vm.model.discipline_id_equivalence,
                                    data: _vm.model.disciplineEquivalence,
                                    error:
                                      _vm.errors.discipline_id_equivalence &&
                                      _vm.errors.length > 0,
                                    errors:
                                      _vm.errors.discipline_id_equivalence,
                                    label: "Unidade Curricular Equivalente",
                                    "item-text": "full_name",
                                    autoComplete: "",
                                    clearable: "",
                                    autofocus: ""
                                  },
                                  on: {
                                    "update:model": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "discipline_id_equivalence",
                                        $event
                                      )
                                    },
                                    "update:data": function($event) {
                                      return _vm.$set(
                                        _vm.model,
                                        "disciplineEquivalence",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm.errors.length > 0
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function(error) {
                      return _c("li", { key: error }, [
                        _vm._v(" " + _vm._s(error) + " ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }