var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EmcCardStats",
    {
      attrs: {
        color: "blue lighten-1",
        icon: "mdi-speedometer-medium",
        "icon-large": "",
        title: "CH Média",
        value: _vm.value,
        "show-actions": _vm.showActions,
        "text-actions": _vm.title,
        "sub-text": _vm.showActions ? null : "CH Média Docente"
      }
    },
    [
      _vm.showMoreButton
        ? _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { color: "primary", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("showMoreWorkLoadAvg")
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { btn: "" } }, [
                                  _vm._v("mdi-monitor-eye")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3093801949
                  )
                },
                [_c("span", [_vm._v(" Ver Mais... ")])]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }