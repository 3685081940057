<template>
    <div>

        <v-btn>
            <v-icon>mdi-</v-icon>
        </v-btn>

    <h3>Selecione um Ícone:</h3>
    
    <v-text-field
            label="Solo"
            solo
            v-model="search"
          ></v-text-field>

    <div class="icon-grid">
            
            <v-card>
                <v-tabs
                    dark
                    background-color="teal darken-3"
                    show-arrows
                    v-model="model"
                    >
                    <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

                    <v-tab
                        v-for="(icons, category) in categorizedIcons"
                        :key="category"
                        :href="'#tab-' + category"
                    >
                    <v-icon>mdi-{{ icons[icons.length -1].name }}</v-icon>
                    </v-tab>
                    </v-tabs>
                
                    <v-tabs-items v-model="model">
                        <v-tab-item
                            v-for="(icons, category) in categorizedIcons"
                            :key="category"
                            :value="`tab-${category}`"
                        >
                        <v-row class="pa-3">
                            <!-- <v-col
                                
                                cols="12"
                                md="12"
                                class="pa-2"
                            > -->
                                <v-icon class="ma-2" v-for="(icon, index) in icons" :key="index">mdi-{{ icon.name }}</v-icon>
                            <!-- </v-col> -->
                        </v-row>


                        <!-- <v-row class="flex-child pa-3">
                            <v-col
                                class="d-flex"
                                cols="12"
                                md="2"
                                v-for="(icon, index) in icons"
                                :key="index"
                            > -->
                            <!-- <v-sheet
                                rounded
                                class="ma-auto text-center elevation-1"
                                height="100"
                                width="100"
                            >
                            
                                <v-icon class="ma-2">mdi-{{ icon.name }}</v-icon> <br />
                                <sheet-footer>
                                    {{ icon.name }}
                                </sheet-footer>

                            </v-sheet> -->
                                
                            <!-- <v-item>
                                <v-btn small>
                                    <v-icon left>mdi-{{ icon.name }}</v-icon>
                                    {{ icon.name }}
                                </v-btn> -->
                                <!-- <v-card
                                class="d-flex align-center"
                                height="100"
                                width="100"
                                >
                                <v-scroll-y-transition>
                                    <div
                                    class="flex-grow-1 text-center"
                                    >
                                    <v-icon class="ma-2">mdi-{{ icon.name }}</v-icon> <br />
                                    {{ icon.name }}
                                    </div>
                                </v-scroll-y-transition>
                                </v-card> -->
                            <!-- </v-item>

                            </v-col>
                        </v-row> -->

                        </v-tab-item>
                    </v-tabs-items>
                    <!-- <pre>{{ icons }}</pre> -->
                    <!-- <v-icon v-for="(icon, index) in icons">mdi-{{ icon.name }}</v-icon> -->
                    <!-- <svg width="24" height="24" viewBox="0 0 24 24">
                        <path :d="path" />
                    </svg>             -->
                
            </v-card>
            
            <!-- {{ icons.length }} <br /><br /> -->
        <!-- <pre>{{ categorizedIcons }}</pre> -->
      <!-- <div
        v-for="(path, name) in mdiIcons"
        :key="name"
        class="icon-item"
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path :d="path" />
        </svg>
      </div> -->

      <!-- <div
        v-for="module in mdiIcons"
        :key="module.id"
        class="icon-item"
      > -->
      <!-- {{ name }} <br />
      {{ type }} <br />
      {{ teste }} <br /> -->
      <!-- {{ module }} <br /><br /> -->
      <!-- <v-divider></v-divider> -->
    <!-- </div> -->
    </div>
  </div>
</template>

<script>

export default {
    data() {
        return {
            mdiIcons: [],
            categorizedIcons: [],
            model: '',
            search: ''
        }
    },
    computed: {
        listIcons() {
            return this.categorizedIcons.filter(item => 
                item.name.toLowerCase().includes(this.search.toLowerCase())
            );
        }
    },
    methods: {
        async fetchIcons() {
            try {
                const response = await fetch("https://cdn.jsdelivr.net/npm/@mdi/svg@5.0.45/meta.json");
                const data = await response.json();
        
                this.mdiIcons = data;
                this.groupIconsByCategory()
            } catch (err) {
                //this.error = "Erro ao carregar ícones: " + err.message;
            } finally {
                //this.loading = false;
            }
        },
        groupIconsByCategory() {
            this.categorizedIcons = this.mdiIcons.reduce((groups, icon) => {

                if(icon.tags.length === 0) {
                    const category = "Outros";    
                    return this.pushGroup(category, icon, groups)
                } else {

                    let loopGroups = null

                    
                    for (let index = 0; index < icon.tags.length; index++) {
                        const category = icon.tags[index];
                        loopGroups = this.pushGroup(category, icon, groups)
                    }

                    return loopGroups
                }
                
            }, {});

        },
        pushGroup(category, icon, groups) {

            if (!groups[category]) groups[category] = [];
                groups[category].push(icon);
                return groups;

        }
    },
    created() {
        this.fetchIcons()

        // fetch("https://cdn.jsdelivr.net/npm/@mdi/svg@5.0.45/meta.json")
        // .then(response => response.json())
        // .then(data => {
        //     this.mdiIcons = data
        // })
        // .catch(error => console.error("Erro ao buscar ícones:", error));
    }
}
</script>