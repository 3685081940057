<template>
    <v-dialog 
        v-model="dialog" 
        persistent
        :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '30%'"
    >
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>{{grid.discipline_name}}</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <emc-button-icon :disabled="items.filter(i => i.status >= 2 ).length == 0" icon="mdi-content-save" text="Salvar Tudo" color="green lighten-2" :loading="loadingSave && savingTotal > 0" @click.prevent.stop="saveClasses(true)"/>
                    <emc-button-icon icon="mdi-close" text="Fechar" color="red lighten-2" class="ml-3" @click.prevent.stop="dialog = false"/>

                  
                </v-toolbar>
                <v-card-text class="pt-3">

            <v-data-table
                  v-if="renderComponent"
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  :items-per-page="-1"
                  item-key="index"
                  fixed-header
                  hide-default-footer
                  height="400"
                  show-select
                 
              >
                  <template v-slot:item.hour="{item }">
                    <strong v-if="item.id">
                        <s class="red--text" v-if="item.status == 4">
                            {{ item.hour.start }}
                            <br/>
                            {{ item.hour.end }}
                        </s>
                        <span v-else>
                            {{ item.hour.start }}
                            <br/>
                            {{ item.hour.end }}
                        </span>
                    </strong>
                    <span v-else>
                        {{ item.hour.start }}
                        <br/>
                        {{ item.hour.end }}
                    </span>   
                  </template>

                  <template v-slot:item.date="{item}">
                    <emc-calendar-date-picker2 
                          :model.sync="item.date" 
                          label="" 
                          :show="dialog"
                          :min="acesso.periodo.start"
                          :max="acesso.periodo.end"
                          dense
                          @change="changeItem($event, item)"
                          :disabled="!selected.includes(item)"
                      />
                  </template>

                  <template v-slot:item.ambience_id="{item}">
                    {{ item.ambience_id }}
                  </template>

                  <template v-slot:item.actions="{item}">

                    <span>
                        <emc-info-help v-if="item.error" :title="item.error.title" :text="item.error.message" :icon="item.error.icon" iconColor="error" />
                        <emc-button-icon iconSmall :disabled="item.status < 2" :icon="item.status >= 2 ? 'mdi-content-save' : ''" text="Salvar" color="green lighten-2" :loading="item.loading" @click.prevent.stop="saveClasses(false, item)"/>
                    </span>

                  </template>

            </v-data-table>
                    
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysScheduleDate',
    data: () => ({
        dialog: false,
        selected: [],
        loadingSave: false,
        savingTotal: 0,
        items: [],
        classesFiltered: [],
        error: {},
        loading: false,
        error: false,
        message: null,
        indexDelete: -1,
        renderComponent: true,
        headers: [
          { text: 'Horário',    value: 'hour',          sortable: false,    width: "30%"}, // 20%
          { text: 'Data',       value: 'date',          sortable: false,    width: "68%" },// 20%
        //   { text: 'Ambiente',   value: 'ambience_id',   sortable: false,    width: "58%" },
          { text: '',           value: 'actions',       sortable: false,    width: "2%"},
      ],
    }),
    props: {
        show: {
            type: Boolean,
            required: true
        },
        grid: {
            type: Object,
            required: true
        },
        date: {
            required: true
        },
        hours: {
            type: Array,
            required: true
        },
        time:{
            required: true
        }
    },
    watch: {  
    
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },
        show(show) {
            
            this.dialog = show;

            if(show){
                this.classesFiltered = [];
                this.selected = [];
                this.loadItems();
                this.loadStatus();
            }
        },

        selected(){
            this.loadStatus();
        }

    },

    created() {
       
    },

    computed: {
        ...mapState('auth', ['acesso']),
    },

    methods: {
        ...mapActions('schedule', ['ActionCreateClasse', 'ActionUpdateClasse', 'ActionDeleteClasse']),

        loadItems(){

            let index = 0;
            let date = this.date;
            var day = new Date(date);
            let day_of_week_id = (day.getDay() + 2 === 8) ? 1 : day.getDay() + 2;
            this.items = [];
            this.classesFiltered = this.grid.classeDates.filter(c => c.date == this.date);
            
            
            this.hours.forEach(h => {
                
                let classe = this.classesFiltered.find(c => (c.hour_id == h.id && c.date == this.date));
                let selectedHour = (this.time && (this.time >= h.start && this.time <= h.end)) ? h : null;

                let item = {
                    id:             classe ? classe.id : 0,
                    grid_id:        this.grid.id,
                    index:          index++,
                    hour_id:        h.id,
                    hour:           h,
                    day_of_week_id: classe ? classe.day_of_week_id : day_of_week_id,
                    date:           date,
                    start_date:     classe ? classe.start_date : date,
                    end_date:       classe ? classe.end_date : date,
                    teacher_id:     classe ? classe.teacher_id : null,
                    ambience_id:    classe ? classe.ambience_id : null,
                    loading:        false,
                    edited:         false,
                    status:         classe ? 1 : 0,
                    error:          null
                };

                this.items.push(item);

                if(classe || (selectedHour && selectedHour.id == h.id)){
                    this.selected.push(item);
                }

            });

        },

      loadStatus(){

        // 0 - Não selecionado e sem aula
        // 1 - Selecionado e com aula e não editado
        // 2 - Selecionado e com aula e editado (atualizar)
        // 3 - Selecionado e sem aula (inserir)
        // 4 - Não selecionado e com aula (deletar)
        
        this.items.forEach(item => {
            
            let selected = this.selected.includes(item);
            let status = 0;

            if(!selected && item.id == 0){
                status = 0;
            }else if(selected && item.id && !item.edited){
                status =  1;
            }else if(selected && item.id && item.edited){
                status = 2;
            }else if(selected && !item.id){
                status = 3;
            }else if(!selected && item.id){
                status = 4;
            }else{
                status = -1;
            }

            item.status = status;

        })

      },

      changeItem(date, item){
        item.edited = (date != this.date) && item.status == 1;
        this.loadStatus();
      },
      
      saveClasses(loadingSave, item = null)
      {
        
        let items = [];
        this.loadingSave = loadingSave;
        this.savingTotal = 0;
        this.message = null;
        this.error = false;

        if(item){
            items = [item];
        }else{
            items = this.items.filter(i => i.status >= 2);
        }

        this.savingTotal = items.length;

        items.forEach(classe => {
            
            let date = classe.date;
            var day = new Date(date);
            let day_of_week_id = (day.getDay() + 2 === 8) ? 1 : day.getDay() + 2;
            let status = classe.status;
            let method = classe.id ? 'ActionUpdateClasse' : 'ActionCreateClasse';
            classe.start_date = date;
            classe.end_date = date;
            
            let payload = {
                id:             classe.id,
                grid_id:        this.grid.id,
                hour_id:        classe.hour_id,
                day_of_week_id: day_of_week_id,
                start_date:     date,
                end_date:       date,
                teacher_id:     classe.teacher_id,
                ambience_id:    classe.ambience_id,
            }

            if(status == 2){
                method = 'ActionUpdateClasse';
            }else if(status == 3){
                method = 'ActionCreateClasse';
            }else if(status == 4){
                method = 'ActionDeleteClasse';
                payload = {
                    id: payload.id
                }
            }
            
            this.save(method, payload, classe);

        });

      },

      save(method, payload, classe, saveAll){

        classe.loading = true;
        classe.error = null;
        
        this[method](payload)
              .then((res) => {
                
                let saved           = res.data;
                
                classe.id           = saved.id ? saved.id : 0;
                classe.status       = saved.id ? 1 : 0;
                classe.edited       = false;

                this.$emit('loadClasses');

                if(!this.error){
                    this.dialog = false;
                }

              })
              .catch((error) => {
                
                this.error = true;
                this.message = error.message;
                classe.error = error.errors;

              }).finally(() => {
                
                classe.loading = false;
                this.savingTotal--;

                if(this.savingTotal <= 0){
                    this.loadingSave = false;
                    this.loadStatus();
                }

              });

      },    
        
      forceRerender () {
      
        this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        }
    }
}
</script>