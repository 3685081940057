<template>
    <div>
        <EmcChartLoading v-if="loading" />
        <v-card v-else outlined>
            <v-card-text>
                <div id="chart">
                    <apexchart :type="chartType" :options="chartOptions" :series="chartSeries"></apexchart>
                </div>
            </v-card-text>
            <v-card-actions>
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'EmcChartApexBase',
    data (vm) {
        return {
            exampleChartOptions: {
                chart: {
                    height: 350,
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 2,
                        left: 7,
                        blur: 8,
                        opacity: 0.5
                    },
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: true
                    }
                },
                colors: [],
                dataLabels: {
                    enabled: true
                },
                labels: [],
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Average High & Low Temperature',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Temperature'
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    
                }
            }
        }
    },

    computed: {
        chartSeries: {
            get() {
                return this.series ? this.series : this.exampleSeries;
            },
            set(val) {
                this.$emit('update:series', val);
            },
        },
        chartType: {
            get() {
                return this.type;
            },
            set(val) {
                this.$emit('update:type', val);
            },
        },
        chartOptions: {
            get() {
                return {...this.exampleChartOptions, ...this.options };
            },
            set(val) {
                this.$emit('update:options', val);
            },
        }
    },

    props: {
        series: {
            type: Array,
            required: false,
        },
        loading: {
                type: Boolean,
                default: false,
        },
        options: {
                type: Object,
                default: () => {},
        },
        type: {
            type: String,
            required: true
        },
        caption: {
            type: String,
            required: false
        }
    },
}
</script>