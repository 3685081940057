var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        { attrs: { elevation: "2", shaped: "" } },
        [
          !_vm.isAvailable
            ? _c(
                "v-alert",
                { attrs: { dense: "", outlined: "", type: "warning" } },
                [
                  _c("strong", [
                    _vm._v(
                      "O período para confirmar a carga horária não está liberado!"
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c("v-card-title", [
            _vm._v(
              " Confirmação dos Horários e Atribuições para o período " +
                _vm._s(_vm.workTime ? _vm.workTime.name : "") +
                " "
            )
          ]),
          _c(
            "v-card-subtitle",
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    "colored-border": "",
                    type: "info",
                    elevation: "1",
                    icon: "mdi-information-variant"
                  }
                },
                [
                  _vm.text && _vm.text != ""
                    ? _c("p", [_vm._v(_vm._s(_vm.text))])
                    : _vm._e(),
                  _vm.hasDate
                    ? _c("span", [
                        _c("strong", [_vm._v("Prazo Oficial: ")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("date")(_vm.workTime.confirmation_start)
                            ) +
                            " à " +
                            _vm._s(
                              _vm._f("date")(_vm.workTime.confirmation_end)
                            ) +
                            " "
                        )
                      ])
                    : _c("span", [
                        _c("strong", [_vm._v("Prazo Oficial: ")]),
                        _vm._v(" Ainda não definido!")
                      ]),
                  _vm.teacher.is_available_confirmation
                    ? _c("span", [
                        _c("br"),
                        _c("strong", [_vm._v("Prazo Estendido: ")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.teacher.release_confirmation_until
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: {
                  error: !!_vm.errors.note_teacher,
                  "error-messages": _vm.errors.note_teacher,
                  label:
                    "Observação (Caso NÃO CONFIRME, é necessário informar o motivo)",
                  counter: 255,
                  rows: 2,
                  clearable: "",
                  disabled: !_vm.isAvailable || !_vm.allowActions,
                  rules: [
                    function(v) {
                      return (
                        !v ||
                        v.length <= 255 ||
                        "Máximo de 255 caracteres atingido"
                      )
                    }
                  ],
                  outlined: ""
                },
                model: {
                  value: _vm.note_teacher,
                  callback: function($$v) {
                    _vm.note_teacher = $$v
                  },
                  expression: "note_teacher"
                }
              }),
              _vm.confirmation
                ? _c(
                    "span",
                    [
                      _vm.confirmation.work_load_confirmation_status_id == 2
                        ? _c(
                            "v-alert",
                            { attrs: { dense: "", text: "", type: "success" } },
                            [
                              _vm._v(" Você "),
                              _c("strong", [_vm._v("CONFIRMOU")]),
                              _vm._v(" sua carga horária em "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateTime")(
                                      _vm.confirmation.date_confirmation
                                    )
                                  )
                                )
                              ]),
                              _vm._v(". ")
                            ]
                          )
                        : _vm._e(),
                      _vm.confirmation.work_load_confirmation_status_id == 3
                        ? _c(
                            "v-alert",
                            {
                              attrs: { dense: "", outlined: "", type: "error" }
                            },
                            [
                              _vm._v(" Você informou que "),
                              _c("strong", [_vm._v("NÃO CONFIRMA")]),
                              _vm._v(" sua carga horária em "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateTime")(
                                      _vm.confirmation.date_confirmation
                                    )
                                  )
                                )
                              ]),
                              _vm._v(". ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      !_vm.confirmation
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                border: "right",
                                "colored-border": "",
                                type: "error",
                                elevation: "2"
                              }
                            },
                            [
                              _vm._v(
                                " Você ainda não confirmou sua carga horária! "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _vm.allowActions
            ? _c(
                "v-card-actions",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        outlined: "",
                                        rounded: "",
                                        disabled: !_vm.isAvailable,
                                        color: "success"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessageConfirm(2)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "", dark: "" }
                                      },
                                      [_vm._v("mdi-thumb-up")]
                                    ),
                                    _vm._v(" Confirmar ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        548804168
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Estou de acordo com minha carga horária atribuída"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        outlined: "",
                                        rounded: "",
                                        disabled: !_vm.isAvailable,
                                        color: "error"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showMessageConfirm(3)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { small: "", dark: "" }
                                      },
                                      [_vm._v("mdi-thumb-down")]
                                    ),
                                    _vm._v(" Não Confirmar ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        77952871
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Não estou de acordo com minha carga horária atribuída"
                        )
                      ])
                    ]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("emc-alert-modal", {
        attrs: {
          text: _vm.confirmText,
          disabled: _vm.loading,
          show: _vm.showConfirm
        },
        on: {
          "update:show": function($event) {
            _vm.showConfirm = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: _vm.confirmColor,
                      loading: _vm.loading,
                      disabled: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.save()
                      }
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.confirmButtonText)),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v(_vm._s(_vm.confirmIcon))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }