var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      disabledSave: _vm.disabled,
      icon: _vm.icon,
      show: _vm.dialog,
      maxWidth: "60%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            autofocus: "",
                            error: !!_vm.errors.description,
                            "error-messages": _vm.errors.description,
                            label: "Descrição*"
                          },
                          model: {
                            value: _vm.model.description,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "description", $$v)
                            },
                            expression: "model.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shift_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shift_id,
                            label: "Turno",
                            maxShift: 3,
                            disabled: _vm.model.dynamic_time_status_id != 1,
                            dense: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shift_id", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            items: _vm.itemsCampus,
                            label: "Campus",
                            "item-text": "name",
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.campuses,
                            multiple: "",
                            dense: "",
                            clearable: "",
                            disabled: _vm.model.dynamic_time_status_id != 1
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-item",
                                fn: function() {
                                  return [
                                    _c("v-checkbox", {
                                      staticClass: "ml-2",
                                      attrs: { label: "Todos" },
                                      on: { change: _vm.changeCheckbox },
                                      model: {
                                        value: _vm.selectAllCampus,
                                        callback: function($$v) {
                                          _vm.selectAllCampus = $$v
                                        },
                                        expression: "selectAllCampus"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              _vm.renderComponent
                                ? {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        index === 0
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ])
                                          : _vm._e(),
                                        index === 1
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "grey--text caption ml-1"
                                              },
                                              [
                                                _vm._v(
                                                  " (+" +
                                                    _vm._s(
                                                      _vm.model.campuses
                                                        .length - 1
                                                    ) +
                                                    " outros) "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                : null
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.model.campuses,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "campuses", $$v)
                            },
                            expression: "model.campuses"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2  " } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            error: !!_vm.errors.total_grouping,
                            "error-messages": _vm.errors.total_grouping,
                            label: "Agrupamentos*"
                          },
                          model: {
                            value: _vm.model.total_grouping,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "total_grouping", $$v)
                            },
                            expression: "model.total_grouping"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _c("v-select", {
                          attrs: {
                            "item-value": "id",
                            "item-text": "name",
                            items: _vm.minutes.filter(function(m) {
                              return m.active
                            }),
                            error: !!_vm.errors.limit_seconds,
                            "error-messages": _vm.errors.limit_seconds,
                            label: "Tempo máximo",
                            dense: ""
                          },
                          model: {
                            value: _vm.model.limit_seconds,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "limit_seconds", $$v)
                            },
                            expression: "model.limit_seconds"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-category-group", {
                          attrs: {
                            model: _vm.model.categories,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.categories,
                            label: "Categoria",
                            multiple: "",
                            dense: "",
                            required: "",
                            selectAll: "",
                            disabled: _vm.model.dynamic_time_status_id != 1
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "categories", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-hour-group", {
                          attrs: {
                            model: _vm.model.hour_group_id,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.hour_group_id,
                            label: "Grupo de Horário",
                            dense: "",
                            clearable: "",
                            disabled: _vm.model.dynamic_time_status_id != 1
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(
                                _vm.model,
                                "hour_group_id",
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    false
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: !!_vm.errors.max_classes_teacher,
                                "error-messages":
                                  _vm.errors.max_classes_teacher,
                                label: "Máximo Aulas Professor"
                              },
                              model: {
                                value: _vm.model.max_classes_teacher,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "max_classes_teacher",
                                    $$v
                                  )
                                },
                                expression: "model.max_classes_teacher"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    false
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "3" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                error: !!_vm.errors.max_classes_group,
                                "error-messages": _vm.errors.max_classes_group,
                                label: "Máximo Aulas Turma"
                              },
                              model: {
                                value: _vm.model.max_classes_group,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "max_classes_group", $$v)
                                },
                                expression: "model.max_classes_group"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper": "Tentar reduzir o número de janelas",
                            inset: "",
                            label: "Reduzir Janelas"
                          },
                          model: {
                            value: _vm.model.reduce_windows,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "reduce_windows", $$v)
                            },
                            expression: "model.reduce_windows"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper": "Tentar otimizar o número de dias",
                            inset: "",
                            label: "Reduzir Dias"
                          },
                          model: {
                            value: _vm.model.reduce_days,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "reduce_days", $$v)
                            },
                            expression: "model.reduce_days"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper":
                              "Validar interjonada entre dias consecutivos",
                            inset: "",
                            label: "Interjornada"
                          },
                          model: {
                            value: _vm.model.validate_journey,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "validate_journey", $$v)
                            },
                            expression: "model.validate_journey"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper": "Validar deslocamento no turno",
                            inset: "",
                            label: "Deslocamento"
                          },
                          model: {
                            value: _vm.model.validate_desloc,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "validate_desloc", $$v)
                            },
                            expression: "model.validate_desloc"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("s-switch", {
                          attrs: {
                            "text-helper": "Bloco de Aulas por dia",
                            inset: "",
                            label: "Bloco de Aulas"
                          },
                          model: {
                            value: _vm.model.block_by_day,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "block_by_day", $$v)
                            },
                            expression: "model.block_by_day"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }