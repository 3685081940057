var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emc-chart-apex-base", {
    attrs: {
      series: _vm.series,
      type: "pie",
      options: _vm.chartOptions,
      loading: _vm.loading
    },
    on: {
      "update:loading": function($event) {
        _vm.loading = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function() {
          return [
            _c("v-spacer"),
            _c("emc-button-icon", {
              attrs: {
                "btn-small": "",
                loading: _vm.loading,
                icon: "mdi-refresh-circle",
                text: _vm.updated_at || "Atualizar",
                color: "success"
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.refresh()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }