var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        "max-height": "80%",
        "max-width":
          _vm.$vuetify.breakpoint.name == "xs" ||
          _vm.$vuetify.breakpoint.name == "sm"
            ? "90%"
            : "30%",
        persistent: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                "v-app-bar",
                {
                  staticClass: "primary lighten-2 mb-4 elevation-1'",
                  attrs: { dense: "", height: "30" }
                },
                [
                  _c("v-toolbar-title", [
                    _c("span", { staticClass: "subtitle-1" })
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "", "x-small": "" },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialog = false
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "white" } },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Fechar")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("emc-avatar-webcam", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showCapture,
                    expression: "!showCapture"
                  }
                ],
                ref: "webcam",
                attrs: {
                  "device-id": _vm.deviceId,
                  facingModeFrontal: _vm.facingModeFrontal,
                  width: "100%",
                  height: "100%"
                },
                on: {
                  started: _vm.onStarted,
                  stopped: _vm.onStopped,
                  error: _vm.onError,
                  cameras: _vm.onCameras,
                  "camera-change": _vm.onCameraChange
                }
              }),
              _c(
                "figure",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCapture,
                      expression: "showCapture"
                    }
                  ],
                  staticStyle: {
                    border: "3px solid green",
                    "border-radius": "5px"
                  }
                },
                [
                  _c("img", {
                    staticClass: "img-responsive",
                    attrs: { width: "100%", src: _vm.img }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              false && !_vm.showCapture
                ? _c(
                    "v-btn",
                    {
                      staticClass: "d-flex d-sm-none mr-4",
                      attrs: {
                        color: "white lighten-2",
                        fab: "",
                        "x-small": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.facingModeFrontal = !_vm.facingModeFrontal
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-camera-retake-outline")])],
                    1
                  )
                : _vm._e(),
              !_vm.showCapture
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary lighten-2", fab: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.onCapture()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("mdi-camera")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showCapture
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: { color: "error", icon: "", small: "" },
                      on: {
                        click: function($event) {
                          _vm.showCapture = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-delete")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showCapture
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "success", icon: "", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.onSelect()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "" } }, [
                        _vm._v("mdi-check-circle")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }