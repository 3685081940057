var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  _c(
                    "v-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showLogin,
                          expression: "showLogin"
                        }
                      ],
                      staticClass: "elevation-12"
                    },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [_vm._v("SysClass")]),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.nameRules,
                                  label: "Email",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.email,
                                  required: "",
                                  autofocus: ""
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.validate()
                                  }
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": _vm.show
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  rules: _vm.senhaRules,
                                  type: _vm.show ? "text" : "password",
                                  label: "Senha",
                                  error: _vm.errors.length > 0,
                                  "error-messages": _vm.errors.password
                                },
                                on: {
                                  "click:append": function($event) {
                                    _vm.show = !_vm.show
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.validate()
                                  }
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              staticStyle: { cursor: "pointer" },
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.validate()
                                }
                              }
                            },
                            [_vm._v(" Entrar ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: { type: "error" },
                              model: {
                                value: _vm.snackbar,
                                callback: function($$v) {
                                  _vm.snackbar = $$v
                                },
                                expression: "snackbar"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.erro) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-1 mb-1",
                              attrs: { small: "", text: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.resetPassword()
                                }
                              }
                            },
                            [_vm._v("Esqueceu sua senha?")]
                          ),
                          _c("v-spacer"),
                          _vm.showAccessTeacher
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1 mb-1",
                                  attrs: { small: "", text: "", color: "teal" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.toAccessTeacher()
                                    }
                                  }
                                },
                                [_vm._v("Sou Professor")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }