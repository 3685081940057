import store from '../store'
import { eventBus } from '../components/js/eventBus.js'

const DEFAULT_TITLE = 'SysClass - Seu Sistema de Planejamento Acadêmico';

export default async (to, from , next) => {
    
    //Definindo título da página
    document.title = to.meta.title ? 'SysClass - ' + to.meta.title : DEFAULT_TITLE;

    eventBus.$emit('in-login', (to.name === 'login' || to.name === 'availability-teacher'));
    
    var noAuth = [
        'availability-teacher', 
        'AccountIndex', 
        '404', 
        '403', 
        '500', 
        '503',
        'login', 
        'worktime', 
        'teacher-mail-confirm', 
        'reset_password',
        'confirm_password',
        'access-teacher',
        'login/teacher',
        'teacher/page',
        'teacher/class-diary'
        // '/login/anima/redirect',
    ]

    if(noAuth.includes(to.name) || noAuth.includes(to.path)){
        next();
        return;
    }

    if(to.name !== 'login' && !store.getters['auth/hasToken']){
        try {
            await store.dispatch('auth/ActionCheckToken')
            next({path: to.path, query: to.query})
            return;

        } catch(error) {
            next({name: 'login'})
            return;
        }
    }else if(to.name !== 'login' && to.name !== 'worktime' && !store.getters['auth/hasAcesso']){
        next( { name: 'worktime' } );
        return;
    }else{

        var user = store.getters['auth/user']
        var now = new Date(new Date().toString()).getTime() / 1000;
        var menu = store.getters['auth/menu']

        if(user.expire_in == undefined) {
            await store.dispatch('auth/ActionSignOut')
            next({ name: 'login' })
            return;
        }

        if(now >  user.expire_in) {
            await store.dispatch('auth/ActionSignOut')
            next({ name: 'login' })
            return;
        }
        
        var bAccess = false;

        if(menu && (typeof menu.find === 'function')){
            menu.find((element, key) => {

                if(to.path === element.to) {
                    bAccess = true;
                }else if(element.children) {
                    element.children.find((children, keyChildren) => {
                        if(to.path === children.to) {
                            bAccess = true;
                        }
                    })
                }
            })
        }

        var noMenu = ['profile', 'faq-questions', 'my-tickets', 'dynamic-time-results', 'dynamic-allocation-results']

        if(bAccess || noMenu.includes(to.name)) {
            next();
        } else {
            next({ path: '/404'})
        }
    }
}