var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EmcCardStats", {
    attrs: {
      color: "blue accent-1",
      icon: "mdi-account-group-outline",
      "icon-large": "",
      title: this.title,
      value: this.groups,
      "sub-text": "Número de turmas cadastradas"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }