<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-notebook" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab key="others">Demais Dados</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container>
                        <v-row class="mt-1">
                            <v-col cols="12" sm="12">
                                <v-text-field autofocus dense :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.initials" v-model="model.initials" label="Abreviação" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field dense :error="errors.length > 0" :error-messages="errors.work_load_total" v-model="model.work_load_total" label="CH Total" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código" />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <s-text-field dense :error-messages="errors.code_control" v-model="model.code_control" label="Código Controle" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    :headers="filterHeaders"
                                    :items="itemsWorkLoads"
                                    sort-by="calories"
                                    class="elevation-1"
                                    :hide-default-footer="true"
                                    :items-per-page="-1"
                                >
                                    <template v-slot:top="{}">
                                        <v-row>
                                            <v-col cols="12" sm="10">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="indigo darken-1" @click.prevent.stop="addItem()" icon v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                                                    </template>
                                                    <span>Adicionar Carga Horária</span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-tooltip v-if="itemsWorkLoads.length > 0 && !hasWorkLoadPay" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="success" icon v-bind="attrs" v-on="on" @click.prevent.stop="showColumns = !showColumns"><v-icon>{{ showColumns ? 'mdi-arrow-expand-right' : 'mdi-arrow-expand-left' }}</v-icon></v-btn>
                                                    </template>
                                                    <span>Mais opções</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </template>

                                    <template dense v-slot:item.work_load_type="{ item, index }">
                                        <sys-select-work-load-type
                                            :model.sync="item.work_load_type_id"
                                            :error="errors.length > 0"  
                                            :errors="errors['workLoads.' + index + '.work_load_type_id']"
                                            label=""
                                            dense
                                            auto-complete
                                        />
                                    </template>
                                    
                                    
                                    <template dense v-slot:item.work_load="{ item, index }">
                                        <v-text-field
                                            v-model="item.work_load"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['workLoads.' + index + '.work_load']"
                                            dense
                                        ></v-text-field>
                                    </template>

                                    <template dense v-slot:item.work_load_pay="{ item, index }">
                                        <v-text-field
                                            v-model="item.work_load_pay"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['workLoads.' + index + '.work_load_pay']"
                                            dense
                                        ></v-text-field>
                                    </template>

                                    <template dense v-slot:item.work_load_time="{ item, index }">
                                        <v-text-field
                                            v-model="item.work_load_time"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['workLoads.' + index + '.work_load_time']"
                                            dense
                                        ></v-text-field>
                                    </template>

                                    <template dense v-slot:item.number_divisions="{ item, index }">
                                        <v-text-field
                                            v-model="item.number_divisions"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['workLoads.' + index + '.number_divisions']"
                                            dense
                                        ></v-text-field>
                                    </template>
                                    
                                    <template v-slot:item.actions="{ item }">
                                        <v-icon
                                            small
                                            color="error"
                                            @click.prevent.stop="deleteItem(item)"
                                            dense
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="model.syllabus"
                                    outlined
                                    name="input-7-4"
                                    label="Ementa"
                                    :value="model.syllabus"
                                    :error="errors.length > 0"  
                                    :errors="errors.syllabus"
                                    rows="3"
                                    row-height="20"
                                    :counter="600"
                                    clearable
                                    :rules="[v => (!v || v.length <= 600) || 'Máximo de 600 caracteres atingido']"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="showConfigs">
                            <v-col cols="12" sm="3">
                                <v-switch inset v-model="model.has_teacher" label="Com Professor"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch inset v-model="model.has_paid_workload" label="Carga Hor. Paga"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch inset v-model="model.has_time_table" label="Possui Horário"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch inset v-model="model.active" label="Ativa"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="6">
                                <v-switch inset v-model="model.show_availability" label="Exibir na página de disponibilidade"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-switch inset v-model="model.active" label="Disciplina Ativa"></v-switch>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-tab-item>
                <v-tab-item key="others">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-category-ambience
                                    :model.sync="model.category_ambience_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.category_ambience_id"
                                    clearable
                                    label="Categoria de Ambiente"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-ambience
                                    :model.sync="model.ambience_id"
                                    :error="errors.ambience_id && errors.length > 0"  
                                    :errors="errors.ambience_id"
                                    label="Ambiente"
                                    clearable
                                    autoComplete
                                    dense
                                    :category-id="model.category_ambience_id"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-resource
                                    :model.sync="model.resources"
                                    :error="errors.length > 0"  
                                    :errors="errors.resources"
                                    multiple
                                    label="Recursos Necessários"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-unsanitary-type
                                    :model.sync="model.unsanitary_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.unsanitary_type_id"
                                    showAdd
                                    clearable
                                    label="Tipo Insalubridade"
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-discipline-type
                                    :model.sync="model.discipline_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.discipline_type_id"
                                    clearable
                                    auto-complete
                                    label="Tipo de Disciplina"
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-company
                                    :model.sync="model.company_id"
                                    label="Empresa"
                                    dense
                                    clearable
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-dynamic-time-grouping-type
                                    :model.sync="model.dynamic_time_grouping_type_id"
                                    label="Tipo de Agrupamento"
                                    dense
                                    clearable
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
            
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDiscipline",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        itemsWorkLoads: [],
        showColumns: false,
        showConfigs: false,
        tab: 0,
        headers: [
            {
            text: 'Tipo de Carga Horária',
            align: 'start',
            sortable: false,
            value: 'work_load_type',
            visible: true
            },
            { text: 'Carga Horária Total', value: 'work_load', sortable: false, visible: true },
            { text: 'CH Paga', value: 'work_load_pay', sortable: false, visible: false },
            { text: 'CH Horário', value: 'work_load_time', sortable: false, visible: false },
            { text: 'Divisões', value: 'number_divisions', sortable: false, visible: false },
            { text: '', value: 'actions', sortable: false,  visible: true },
        ],
    }),
    created(){
       
    },

    computed: {
        ...mapState('auth', ['acesso']),

        filterHeaders(){
            if(this.showColumns || this.hasWorkLoadPay){
                //this.showColumns = true;
                return this.headers;
            }else{
                return this.headers.filter(h => h.visible);
            }
        },
        hasWorkLoadPay(){
            
            if(this.itemsWorkLoads.length > 0){
                var items = this.itemsWorkLoads.filter(h => (h.work_load_pay || h.work_load_time));
                return items.length > 0;
            }else{
                return false;
            }
        }

    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
           
            if(show_new){
                
                this.errors = {};
                this.itemsWorkLoads = [];
                this.showColumns = false;
                this.tab = 0;

                if(this.model.id){
                    
                    if(this.model.workLoads){
                        Object.assign(this.itemsWorkLoads, this.model.workLoads);
                    }

                }else{
                    this.model.active = true;
                    this.model.has_paid_workload = true;
                    this.model.has_time_table = true;
                    this.model.has_teacher = true;
                }
                
            }
                
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "70%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Disciplina"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('discipline', ['ActionCreateDiscipline', 'ActionUpdateDiscipline', 'ActionGetCategories']),
        
        addItem(){
            
            var item = {
                discipline_id: this.model.id ? this.model.id : 0,
                work_load_type_id: null,
                work_load: 0,
                work_load_pay: null,
                work_load_time: null,
                number_divisions: 1,
            };

            this.itemsWorkLoads.push(item);
            
        },

        deleteItem(item){
            var index = this.itemsWorkLoads.indexOf(item);
            this.itemsWorkLoads.splice(index, 1);
            
        },

        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;
           
            Object.assign(this.model, 
                { 
                    workLoads: this.itemsWorkLoads 
                }
            );

            if(edit) {
                this.ActionUpdateDiscipline(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    
                    this.error      = error;
                    this.errors     = error.errors;
                    if(this.errors.workLoads){
                        this.message = error.errors.workLoads[0];
                    }else{
                        this.message  = error.message;
                    }
                    
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateDiscipline(this.model)
                .then((res) => {
                    
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    
                    this.error      = error;
                    this.errors     = error.errors;

                    if(error.errors.workLoads){
                        this.message = error.errors.workLoads[0];
                    }else{
                        this.message = error.message;
                    }
                    
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>