var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-6 mb-4 pl-3 pr-3 mx-auto",
      attrs: { "max-width": "400" }
    },
    [
      _c("v-skeleton-loader", {
        staticClass: "v-sheet--offset",
        attrs: { "max-height": "330", type: "image, article" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }