var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-0 pa-0" },
    [
      _c("v-switch", {
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          color: _vm.color,
          inset: _vm.inset,
          loading: _vm.loading,
          dense: _vm.dense,
          error: _vm.errorMessages && _vm.errorMessages.length > 0,
          "error-messages": _vm.errorMessages
        },
        on: {
          change: function($event) {
            return _vm.$emit("input", !!$event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _vm.textHelper
                  ? _c("emc-info-help", {
                      attrs: { title: "Informações", text: _vm.textHelper }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.label) + " ")
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.innerModel,
          callback: function($$v) {
            _vm.innerModel = $$v
          },
          expression: "innerModel"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }