var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "v-card",
        {
          staticClass: "mx-auto",
          style: _vm.position,
          attrs: { "max-width": "300", tile: "" },
          on: {
            mousedown: function($event) {
              _vm.movingCard = true
            },
            mousemove: _vm.mouseMove,
            mouseup: function($event) {
              _vm.movingCard = false
            },
            mouseleave: function($event) {
              _vm.movingCard = false
            }
          }
        },
        [
          _c(
            "v-list",
            { attrs: { shaped: "" } },
            [
              _c(
                "v-subheader",
                [
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _c(
                    "v-btn",
                    { staticClass: "red--text", attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c(
                    "emc-util-scroll",
                    { attrs: { maxHeight: "300px" } },
                    _vm._l(_vm.items, function(item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          on: {
                            click: function($event) {
                              return _vm.$emit("on-select", item)
                            }
                          }
                        },
                        [
                          item
                            ? _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-list-item-title",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.name
                                                          )
                                                        }
                                                      },
                                                      "v-list-item-title",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.campus.name) + " "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " + _vm._s(item.course.name) + " "
                                        ),
                                        item.time_course
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v(
                                                "Período: " +
                                                  _vm._s(item.time_course)
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }