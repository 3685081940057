var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.model,
      title: _vm.title,
      icon: "mdi-book-open-page-variant",
      show: _vm.dialog,
      maxWidth: "40%",
      textButtonSave: "Gerar Diários"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.model = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "8" } },
                      [
                        _c("sys-select-campus", {
                          attrs: {
                            model: _vm.model.campuses,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.campuses,
                            label: "Campus*",
                            multiple: "",
                            "only-active": "",
                            "select-all": "",
                            autofocus: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "campuses", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-shift", {
                          attrs: {
                            model: _vm.model.shifts,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.shifts,
                            label: "Turno*",
                            multiple: "",
                            "only-active": "",
                            "select-all": ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "shifts", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-occurrence-sheet-type", {
                          attrs: {
                            model: _vm.model.types,
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.types,
                            label: "Tipo*",
                            dense: "",
                            multiple: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "types", $event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            error: _vm.errors.length > 0,
                            "error-messages": _vm.errors.year,
                            label: "Ano*",
                            disabled: "",
                            dense: ""
                          },
                          model: {
                            value: _vm.model.year,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "year", $$v)
                            },
                            expression: "model.year"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c("sys-select-month", {
                          attrs: {
                            model: _vm.model.month,
                            dense: "",
                            error: _vm.errors.length > 0,
                            errors: _vm.errors.month,
                            label: "Mês*"
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "month", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12" } },
                      [
                        _c("sys-select-teacher", {
                          attrs: {
                            model: _vm.model.teacher_id,
                            error:
                              _vm.errors.teacher_id && _vm.errors.length > 0,
                            data: _vm.model.teacher,
                            errors: _vm.errors.teacher_id,
                            label:
                              "Professor (Opcional - Deixar vazio para gerar de todos)",
                            autoComplete: "",
                            clearable: ""
                          },
                          on: {
                            "update:model": function($event) {
                              return _vm.$set(_vm.model, "teacher_id", $event)
                            },
                            "update:data": function($event) {
                              return _vm.$set(_vm.model, "teacher", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("emc-alert-snackbar", {
              attrs: {
                show: !!_vm.message.length,
                message: _vm.message,
                color: _vm.error ? "error" : "success"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }