<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="20%"
        :show-button="type == 'edit'"
    >
        <template v-slot:form>
            <v-container v-if="model">
                    <v-row v-if="newItem">
                        <v-col cols="12" sm="12">
                            <sys-select-teacher
                                :model.sync="model2.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model2.teacher"
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                autofocus
                                clearable
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field 
                                v-model="model2.work_load"    
                                :error="!!errors.work_load" 
                                :error-messages="errors.work_load" 
                                label="Carga Horária*"
                                :disabled="type != 'edit'"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <emc-calendar-date-picker2 
                                :model.sync="model2.date_start" 
                                label="Data Início*"
                                :errorMessages="errors.date_start" 
                                :show="dialog"
                                :min="acesso.periodo.start"
                                :max="acesso.periodo.end"
                                :disabled="type != 'edit'"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <emc-calendar-date-picker2 
                                :model.sync="model2.date_end" 
                                label="Data Fim*"
                                :errorMessages="errors.date_end" 
                                :show="dialog"
                                :min="acesso.periodo.start"
                                :max="acesso.periodo.end"
                                :disabled="type == 'remove'"
                            />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="errors.length > 0" :errors="errors" :color="error ? 'error' : 'success'"/>

            <!-- Modal usado para mensagem e ação de remoção -->
            <emc-alert-modal text="Essa ação vai gerar um desconto de pagamento. Deseja continuar?" :show.sync="showRemove">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="remove()" :loading="loading" >Remover <v-icon right dark>mdi-account-remove</v-icon></v-btn>
                </template>
            </emc-alert-modal>

            <!-- Modal usado para mensagem e ação de finalização -->
            <emc-alert-modal text="Essa ação irá gerar um desconto de pagamento caso a data fim seja retroativa. Deseja continuar?" :show.sync="showFinalize">
                <template v-slot:actions>
                    <v-btn color="purple" @click.prevent.stop="finalize()" :loading="loading" >Finalizar <v-icon right dark>mdi-account-convert</v-icon></v-btn>
                </template>
            </emc-alert-modal>


        </template>

        <template v-if="model && model.exported" v-slot:actions>
            
            <!-- <v-btn color="primary" @click.prevent.stop="finalize()" :loading="loading" >Finalizar<v-icon right dark>mdi-content-save</v-icon></v-btn> -->
            
            <v-btn
                v-if="type == 'remove'" 
                color="error"
                @click.prevent.stop="showRemove = true" 
            >
                Remover<v-icon right dark>mdi-account-remove</v-icon>
            </v-btn>

            <v-btn
                v-if="type == 'finalize'" 
                color="purple"
                @click.prevent.stop="showFinalize = true" 
            >
                Finalizar<v-icon right dark>mdi-account-convert</v-icon>
            </v-btn>

        </template>

        

    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherAttributionRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: [],
        message: '',
        loading: false,
        showRemove: false,
        showFinalize: false,
        model2: {},
        
    }),

    created(){
        Object.assign(this.model2, this.model);
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        model(val){
            Object.assign(this.model2, val);
        },
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = [];
                this.model2.date_start  = this.model.date_start ? this.model.date_start : this.acesso.periodo.start;
                this.model2.date_end    = this.model.date_end   ? this.model.date_end   : this.acesso.periodo.end;
            }
        },
        
    },
    props: {
        model: {
           
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Atribuição"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "edit"
        },
        newItem: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('attribution', ['ActionCreateAttribution', 'ActionUpdateAttribution', 'ActionFinalizeAttribution', 'ActionRemoveAttribution']),

        save()
        {
            this.error = false;
            this.errors = [];
            this.message = '';
            this.loading = true;

            if(this.newItem){
                this.ActionCreateAttribution(this.model2)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted');
                    })
                    .catch((error) =>{
                        this.error      = true;
                        this.errors     = error.errors;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }else{
                this.ActionUpdateAttribution(this.model2)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted');
                    })
                    .catch((error) =>{
                        this.error      = true;
                        this.errors     = error.errors;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }

            


        },

        finalize(){
            
            this.error = false;
            this.errors = [];
            this.message = '';
            this.loading = true;

            let payload = {
                id:         this.model2.id,
                date_start: this.model2.date_start,
                date_end:   this.model2.date_end,
             }

            this.ActionFinalizeAttribution(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                })
                .finally(() => {
                     this.loading = false;
                     this.showFinalize = false;
                });

        },

        remove(){
            
            this.error = false;
            this.errors = [];
            this.message = '';
            this.loading = true;

            let payload = {
                id: this.model2.id,
            }

            this.ActionRemoveAttribution(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                })
                .finally(() => {
                     this.loading = false;
                     this.showRemove = false;
                });
        },


    }
}
</script>